/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  bool, instanceOf, number, string
} from "prop-types";
import {
  Bar, BarChart, Cell, Label, LabelList, ResponsiveContainer, XAxis, YAxis
} from "recharts";

const COLORS_EMPLOYMENT_CHART = ["#69B5B5", "#DEA8DD", "#F29469"];

const VerticalBarChart = ({
  data,
  xKey,
  yKey,
  unit,
  hideX,
  hideY,
  barColor,
  activeBarColor,
  showLabelList,
  labelSize,
  minHeight,
  multiplierDist,
  ...VerticalBarChartProps
}) => {
  const [activeIndex, setActiveIndex] = useState();
  const handleNoDataAvailable = () => {
    const totalData = data?.map(
      (item) => item[xKey]
    ).reduce((prev, current) => prev + current, 0);

    if (totalData === 0) {
      return "No data available";
    }
    return xKey;
  };

  const renderCustomizedLabel = ({
    x, y, fill, value
  }) => {
    const multiplier = value >= 12 ? multiplierDist[1] : multiplierDist[0];
    return (
      <text
        x={x + value * multiplier}
        y={y}
        dy={10}
        fontSize={labelSize}
        fill={fill}
        textAnchor="right"
        dominantBaseline="central"
        style={{
          position: "right"
        }}
      >
        {`${value}%`}
      </text>
    );
  };

  const handleColorChange = (data, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height={minHeight}>
      <BarChart
        data={data}
        barCategoryGap={1}
        layout="vertical"
        margin={{ left: -20 }}
        {...VerticalBarChartProps}
      >
        <YAxis
          dataKey={xKey}
          type="category"
          fontSize="10px"
          tick={!hideY}
          tickLine={false}
        />
        <XAxis
          dataKey={yKey}
          type="number"
          hide={hideX}
          unit={unit}
        />
        <Bar
          dataKey={yKey}
          fill={barColor}
          radius={9}
          unit="%"
          barSize={20}
          onMouseEnter={activeBarColor && (handleColorChange)}
          label={renderCustomizedLabel}
        >
          {showLabelList && (
          <LabelList
            dataKey={xKey}
            position="insideBottomLeft"
            fill="#FFFF"
            fontSize="11px"
            fontWeight={500}
          />
          )}
          {
            !barColor ? data?.map((entry, index) => (
              <Cell fill={COLORS_EMPLOYMENT_CHART[index % COLORS_EMPLOYMENT_CHART.length]} key={`cell-${index}`} />
            )) : (
              data?.map((entry, index) => (
                <Cell fill={index === activeIndex ? activeBarColor : barColor} key={`cell-${index}`} />
              )))
          }

        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

VerticalBarChart.propTypes = {
  data: instanceOf(Array).isRequired,
  xKey: string.isRequired,
  showLabelList: bool,
  hideX: bool,
  hideY: bool,
  barColor: string,
  activeBarColor: string,
  minHeight: number,
  multiplierDist: instanceOf(Array),
  labelSize: string
};

VerticalBarChart.defaultProps = {
  hideX: true,
  showLabelList: false,
  hideY: false,
  barColor: null,
  activeBarColor: null,
  minHeight: 200,
  labelSize: "11px",
  multiplierDist: [5, 6]
};

export default VerticalBarChart;
