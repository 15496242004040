import {
  createSelector
} from "reselect";

const getGuidebookTopics = (state) => state.guidebooks.guidebookTopics;

const getGuidebookTopic = (state) => state.guidebooks.topic;

const getTopicsByExpert = (state) => state.guidebooks.expertTopics;

const getIsLoading = (state) => state.guidebooks.loadingAll;

export const getGuidebookTopicsSelector = createSelector(
  getGuidebookTopics,
  (guidebookTopics) => guidebookTopics
);

export const getGuidebookTopicSelector = createSelector(
  getGuidebookTopic,
  (topic) => topic
);

export const getTopicsByExpertSelector = createSelector(
  getTopicsByExpert,
  (topic) => topic
);

export const getisLoadingTopicsSelector = createSelector(
  getIsLoading,
  (loading) => loading
);
