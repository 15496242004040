import React, { useState, useEffect } from "react";
import {
  Checkbox, Typography
} from "@mui/material";
import { func, instanceOf, string } from "prop-types";

const CheckboxItem = ({
  children, id, onChange, selected
}) => {
  const [checked, setChecked] = useState(selected[id] || false);

  useEffect(() => {
    if (!Object.keys(selected).length) {
      setChecked(false);
    }
  }, [selected]);

  const handleCheckItem = (event) => {
    setChecked(event.target.checked);

    const updatedValues = { ...selected };
    if (onChange) {
      updatedValues[id] = event.target.checked;
      onChange(updatedValues);
    }
  };

  return (
    <>
      <Checkbox value={id} checked={checked} onChange={handleCheckItem} inputProps={{ "aria-label": "controlled" }} />
      <Typography variant="newBody1">{children}</Typography>
    </>

  );
};

CheckboxItem.propTypes = {
  id: string.isRequired,
  children: string.isRequired,
  onChange: func,
  selected: instanceOf(Object).isRequired
};

CheckboxItem.defaultProps = {
  onChange: null
};

export default CheckboxItem;
