import {
  createSelector
} from "reselect";

const getUsersRecords = (state) => state.userStats.usersRecords;

const getUsersStats = (state) => state.userStats.usersStats;

const getDownloadedUsersReport = (state) => state.userStats.downloadedReport;

const getUserPoints = (state) => state.userStats.userPoints;

const getUserHealth = (state) => state.userStats.userHealth;

const getUserRewards = (state) => state.userStats.userRewards;

const getUserFilters = (state) => state.userStats.filters;

const getIsLoadingHealthData = (state) => state.userStats.loadingChart;

const getIsLoadingGeneralStats = (state) => state.userStats.loadingGeneralStats;

const getIsLoading = (state) => state.userStats.loading;

export const getUsersRecordsSelector = createSelector(
  getUsersRecords,
  (usersRecords) => usersRecords
);

export const getUsersStatsSelector = createSelector(
  getUsersStats,
  (usersStats) => usersStats
);

export const getDownloadedUsersReportSelector = createSelector(
  getDownloadedUsersReport,
  (downloadedReport) => downloadedReport
);

export const getUserRewardsSelector = createSelector(
  getUserRewards,
  (userRewards) => userRewards
);

export const getUserPointsSelector = createSelector(
  getUserPoints,
  (userPoints) => userPoints
);

export const getUserHealthSelector = createSelector(
  getUserHealth,
  (userHealth) => userHealth
);

export const getUserStatsFilterSelector = createSelector(
  getUserFilters,
  (filters) => filters
);

export const getIsLoadingHealthDataSelector = createSelector(
  getIsLoadingHealthData,
  (loadingChart) => loadingChart
);

export const getIsLoadingGeneralStatsSelector = createSelector(
  getIsLoadingGeneralStats,
  (loadingGeneralStats) => loadingGeneralStats
);

export const getIsLoadingSelector = createSelector(
  getIsLoading,
  (loading) => loading
);
