import React, { useState } from "react";
import { func, string } from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLanguage } from "store/app/appSlice";

const PhoneNumberInput = ({
  value, onChangeNumber, error, ...PhoneInputProps
}) => {
  const [phoneNumber, setPhoneNumber] = useState(value);
  const [codeError, setCodeError] = useState();

  const handleChange = (val, country) => {
    setPhoneNumber(val);
    onChangeNumber(val, country);
  };

  const lang = useSelector(selectLanguage);

  return (
    <>
      <PhoneInput
        country="bh"
        value={phoneNumber}
        onChange={handleChange}
        placeholder="+973 172 177 18"
        isValid={(val, country, countries) => {
          const code = val.substring(0, 3);
          const codeExists = countries.some((item) => item.dialCode === code);
          if (!codeExists) {
            setCodeError("Provided country code does not exist");
            return false;
          }
          setCodeError();
          return true;
        }}
        {...(lang === "ar") && { inputStyle: { marginRight: "30px", paddingRight: 10 } }}
        {...PhoneInputProps}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {error && (
        <Typography variant="caption" color="#C86B6C">{error}</Typography>
        )}
        {codeError && (
        <Typography variant="caption" color="#C86B6C">{codeError}</Typography>
        )}
      </Box>
    </>
  );
};

PhoneNumberInput.propTypes = {
  value: string,
  onChangeNumber: func
};

PhoneNumberInput.defaultProps = {
  value: "",
  onChangeNumber: func.isRequired
};

export default PhoneNumberInput;
