import React from "react";
import { ReactComponent as Redemption } from "assets/icons/redemption.svg";
import { ReactComponent as Partner } from "assets/icons/partner.svg";
import { ReactComponent as Behaviour } from "assets/icons/user-behaviour.svg";

export const filterAnswer = [
  { answer: "Cashback" },
  { answer: "Travel" },
  { answer: "Low interest" },
  { answer: "Reward" },
  { answer: "Reward1" },
  { answer: "Reward2" }
];

export const tabsHeaderPoints = [
  { id: 1, title: "Finanical knowledge" },
  { id: 2, title: "Physical activity" }
];

export const bannerPoints = [
  {
    id: 1,
    title: "Do steps to earn points!",
    items: [
      {
        id: 1,
        title: "200K",
        text: "Steps Walked",
        icon: require("assets/icons/points/FlagBanner.svg").default
      },
      {
        id: 2,
        title: "300",
        text: "Points earned",
        icon: require("assets/icons/points/CircleWavyCheckOrng.svg").default
      }
    ]
  },
  {
    id: 2,
    title: "Convert knowledge to points!",
    items: [
      {
        id: 1,
        title: "2 568",
        text: "Completed quizes",
        icon: require("assets/icons/points/PersonSimpleRun.svg").default
      },
      {
        id: 2,
        title: "300",
        text: "Points earned",
        icon: require("assets/icons/points/CircleWavyCheck.svg").default
      }
    ]
  }
];

export const sidebarList = [
  {
    id: 1, name: "Dashboard", to: "/dashboard", icon: ""
  },
  {
    id: 2, name: "Settings", to: "/settings", icon: ""
  },
  {
    id: 3, name: "Stats", to: "/stats", icon: ""
  },
  {
    id: 4, name: "External", to: "/stats/external", icon: ""
  }
];

export const sidebarListExternal = [
  {
    id: 1, name: "Dashboard", to: "/dashboard", icon: ""
  },
  {
    id: 2,
    name: "Insights",
    items: [
      {
        id: 2.1,
        name: "Customer Insights",
        to: "/customer-insights"
      },
      {
        id: 2.2,
        name: "Financial Insights",
        to: "/financial-insights"
      }
    ],
    to: "/insights",
    icon: ""
  },
  {
    id: 3, name: "Products", to: "/products", icon: ""
  },
  {
    id: 4, name: "Profile", to: "/profile", icon: ""
  }
];

export const dashboardList = [
  {
    id: 1, name: "partners", to: "/partners", icon: "", access: "read:partner"
  },
  {
    id: 3, name: "productCategory", to: "/product-category", icon: "", access: "read:category"
  },
  {
    id: 4, name: "learn", to: "/learn", icon: "", access: "read:expert"
  },
  {
    id: 6, name: "quizzes", to: "/quizzes", icon: "", access: "read:quizqna"
  },
  {
    id: 7, name: "tags", to: "/tags", icon: "", access: "read:tag"
  },
  {
    id: 8, name: "users", to: "/users", icon: "", access: ""
  },
  {
    id: 9, name: "filters", to: "/filter", icon: "", access: "read:filter"
  },
  {
    id: 10, name: "rewards", to: "/rewards", icon: "", access: "read:reward"
  },
  {
    id: 11,
    name: "notifications",
    to: "/notifications",
    icon: "",
    access: "write:notifications"
  },
  {
    id: 12, name: "quizTheme", to: "/quiz-theme", icon: "", access: "read:quizTheme"
  }
];

export const settingPageItems = [
  {
    id: 1, name: "termsAndConditions", to: "/terms-and-conditions", icon: "", access: ""
  }
];

export const productsOptions = [
  { id: 1, name: "Credit Cards" },
  { id: 2, name: "Personal Loans" },
  { id: 3, name: "Auto Loans" },
  { id: 4, name: "Savings" },
  { id: 5, name: "Deposits" }
];

export const tagsOptions = [
  { id: 1, name: "Rewards" },
  { id: 2, name: "Travel" },
  { id: 3, name: "Shariah compliant" },
  { id: 4, name: "Cashback" },
  { id: 5, name: "Low interest" }
];

export const currencyOptions = [
  { id: 1, title: "None" },
  { id: 2, title: "BHD" },
  { id: 3, title: "USD" },
  { id: 4, title: "EU" },
  { id: 5, title: "%" },
  // { id: 6, title: 'BD' },
  { id: 6, title: "د.ب." }
];

export const quizTypes = [
  { id: 1, title: "multiple", value: "MULTIPLE" },
  { id: 2, title: "single", value: "SINGLE" }
];

export const filterQuestionTypes = [
  {
    id: 1, title: "multiple", value: "MULTIPLE", name: "multiple"
  },
  {
    id: 2, title: "dropdown", value: "SINGLE", name: "single"
  },
  {
    id: 3, title: "slider", value: "SLIDER", name: "ranges"
  },
  {
    id: 4, title: "checkboxes", value: "CHECKBOXES", name: "checkboxes"
  }
];

export const pushNotificationTypes = [
  { id: 1, title: "New Guidebook", value: "NEW_GUIDEBOOK" },
  { id: 2, title: "New Reward", value: "NEW_REWARD" },
  { id: 3, title: "New Product", value: "NEW_PRODUCT" },
  { id: 4, title: "New FAQ", value: "NEW_FAQ" },
  { id: 5, title: "Read FAQ", value: "READ_FAQ" },
  { id: 6, title: "Unlock Points", value: "UNLOCK_POINTS" },
  { id: 7, title: "Refer Friends", value: "REFER_FRIENDS" }
];

/* eslint-disable no-useless-escape */

export const internalRoleBasedRoutes = [
  {
    "/stats": {
      permission: ["read:stat", "write:stat"],
      reg: /^\/stats[^\/]*\/?$/,
      title: "Stats"
    }
  },
  {
    "/partners": {
      permission: ["read:partner", "write:partner"],
      reg: /^\/partners[^\/]*\/?$/,
      title: "Partners"
    }
  },
  {
    "/partners/create": {
      permission: ["write:partner"],
      reg: /^\/partners\/[^\/]*create\b[^\/]*\/?$/,
      title: "Partners"
    }
  },
  {
    "/partners/create/:id": {
      permission: ["read:partner", "write:partner"],
      reg: /^\/partners\/[^\/]*create\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Partners"
    }
  },
  {
    "/partners/details/:id": {
      permission: ["read:partner", "write:partner"],
      reg: /^\/partners\/[^\/]*details\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Partners"
    }
  },

  {
    "/products/category/:categoryId/provider/:providerId": {
      permission: ["read:product", "write:product"],
      reg: /^\/products\/[^\/]*category\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/[^\/]*provider\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/products/category/:categoryId": {
      permission: ["read:product", "write:product"],
      reg: /^\/products\/[^\/]*category\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/products/0": {
      permission: ["write:product"],
      reg: /^\/products\/[^\/]*0[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/products/:id": {
      permission: ["read:product", "write:product"],
      reg: /^\/products\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  },

  {
    "/products/create/0/category/:categoryId": {
      permission: ["write:product"],
      reg: /^\/products\/[^\/]*create\b[^\/]*\/[^\/]*0[^\/]*\/[^\/]*category\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/product-category": {
      permission: ["read:category", "write:category"],
      reg: /^\/product-category[^\/]*\/?$/,
      title: "Product Category"
    }
  },
  {
    "/product-category/0": {
      permission: ["write:category"],
      reg: /^\/product-category\/[^\/]*0[^\/]*\/?$/,
      title: "Product Category"
    }
  },
  {
    "/product-category/:id": {
      permission: ["read:category", "write:category"],
      reg: /^\/product-category\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Product Category"
    }
  },
  {
    "/experts/:id/faq": {
      permission: ["read:faq", "write:faq"],
      reg: /^\/experts\/[^\/]*[a-zA-z\d]{24}[^\/]*\/[^\/]*faq\b[^\/]*\/?$/,
      title: "Experts FAQ"
    }
  },
  {
    "/experts/:id": {
      permission: ["read:expert", "write:expert"],
      reg: /^\/experts\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Experts"
    }
  },
  {
    "/experts/add": {
      permission: ["write:expert"],
      reg: /^\/experts\/[^\/]*add\b[^\/]*\/?$/,
      title: "Experts"
    }
  },
  {
    "/resources/:id": {
      permission: ["read:guidebook", "write:guidebook"],
      reg: /^\/resources\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Resources"
    }
  },
  {
    "/resources/add": {
      permission: ["write:guidebook"],
      reg: /^\/resources\/[^\/]*add\b[^\/]*\/?$/,
      title: "Resources"
    }
  },
  {
    "/quizzes": {
      permission: ["read:quizqna", "write:quizqna"],
      reg: /^\/quizzes[^\/]*\/?$/,
      title: "Quizzes"
    }
  },
  {
    "/quizzes/:id": {
      permission: ["read:quizqna", "write:quizqna"],
      reg: /^\/quizzes\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Quizzes"
    }
  },
  {
    "/quizzes/add": {
      permission: ["write:quizqna"],
      reg: /^\/quizzes\/[^\/]*add\b[^\/]*\/?$/,
      title: "Quizzes"
    }
  },

  {
    "/tags": { permission: ["read:tag", "write:tag"], reg: /^\/tags[^\/]*\/?$/, title: "Tags" }
  },
  {
    "/tags/product/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*product\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Product Tags"
    }
  },
  {
    "/tags/quizzes": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*quizzes\b[^\/]*\/?$/,
      title: "Quizzes Tags"
    }
  },
  {
    "/tags/quizzes/add": {
      permission: ["write:tag"],
      reg: /^\/tags\/[^\/]*quizzes\b[^\/]*\/[^\/]*add\b[^\/]*\/?$/,
      title: "Quizzes Tags"
    }
  },
  {
    "/tags/quizzes/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*quizzes\b[^\/]*\/[^\/]*[a-zA-z\d]{24}\/?$/,
      title: "Quizzes Tags"
    }
  },
  {
    "/tags/rewards/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*rewards\b[^\/]*\/[^\/]*[a-zA-z\d]{24}\/?$/,
      title: "Rewards Tags"
    }
  },
  {
    "/tags/rewards/add": {
      permission: ["write:tag"],
      reg: /^\/tags\/[^\/]*rewards\b[^\/]*\/[^\/]*add\b[^\/]*\/?$/,
      title: "Rewards Tags"
    }
  },
  {
    "/tags/reward": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*reward\b[^\/]*\/?$/,
      title: "Rewards Tags"
    }
  },
  {
    "/tags/faq": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*faq\b[^\/]*\/?$/,
      title: "FAQ Tags"
    }
  },
  {
    "/tags/faq/add": {
      permission: ["write:tag"],
      reg: /^\/tags\/[^\/]*faq\b[^\/]*\/[^\/]*add\b[^\/]*\/?$/,
      title: "FAQ Tags"
    }
  },
  {
    "/tags/faq/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*faq\b[^\/]*\/[^\/]*[a-zA-z\d]{24}\/?$/,
      title: "FAQ Tags"
    }
  },
  {
    "/tags/product/add": {
      permission: ["write:tag"],
      reg: /^\/tags\/[^\/]*product\b[^\/]*\/[^\/]*add\b[^\/]*\/?$/,
      title: "Product Tags"
    }
  },
  {
    "/tags/product/add/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*product\b[^\/]*\/[^\/]*add\b[^\/]*\/[^\/]*[a-zA-z\d]{24}\/?$/,
      title: "Product Tags"
    }
  },
  {
    "/tags/filter/add": {
      permission: ["write:tag"],
      reg: /^\/tags\/[^\/]*filter\b[^\/]*\/[^\/]*add\b[^\/]*\/?$/,
      title: "Filter Tags"
    }
  },
  {
    "/tags/filter/:id": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*filter\b[^\/]*\/[^\/]*[a-zA-z\d]{24}\/?$/,
      title: "Filter Tags"
    }
  },
  {
    "/tags/filter": {
      permission: ["read:tag", "write:tag"],
      reg: /^\/tags\/[^\/]*filter\b[^\/]*\/?$/,
      title: "Filter Tags"
    }
  },
  { "/users": { permission: [], reg: /^\/users[^\/]*\/?$/, title: "Users" } },
  { "/users/add": { permission: [], reg: /^\/users\/[^\/]*add\b[^\/]*\/?$/, title: "Users" } },
  {
    "/users/:id": {
      permission: [],
      reg: /^\/users\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Users"
    }
  },
  {
    "/filter": {
      permission: ["read:filter", "write:filter"],
      reg: /^\/filter[^\/]*\/?$/,
      title: "Filters"
    }
  },
  {
    "/filter/:id": {
      permission: ["read:filter", "write:filter"],
      reg: /^\/filter\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Filters"
    }
  },
  {
    "/rewards/": {
      permission: ["read:reward", "write:reward"],
      reg: /^\/rewards[^\/]*\/?$/,
      title: "Rewards"
    }
  },
  {
    "/rewards/add": {
      permission: ["write:reward"],
      reg: /^\/rewards\/[^\/]*add\b[^\/]*\/?$/,
      title: "Rewards"
    }
  },
  {
    "/rewards/:id": {
      permission: ["read:reward", "write:reward"],
      reg: /^\/rewards\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Rewards"
    }
  },
  {
    "/quiz-theme": {
      permission: ["read:quizTheme", "write:quizTheme"],
      reg: /^\/quiz-theme[^\/]*\/?$/,
      title: "Quiz Theme"
    }
  },
  {
    "/quiz-theme/0": {
      permission: ["read:quizTheme", "write:quizTheme"],
      reg: /^\/quiz-theme\/[^\/]*0[^\/]*\/?$/,
      title: "Quiz Theme"
    }
  },
  {
    "/notifications/0": {
      permission: ["write:notifications"],
      reg: /^\/notifications\/[^\/]*0[^\/]*\/?$/,
      title: "Notifications"
    }
  },
  {
    "/learn": {
      permission: ["read:expert"],
      reg: /^\/learn[^\/]*\/?$/,
      title: "Learn"
    }
  }
];

export const externalRoleBasedRoutes = [
  {
    "/:id/customer-insights": {
      permission: [],
      reg: /^\/customer-insights[^\/]*\/?$/,
      title: "Customer Insights"
    }
  },
  {
    "/:id/financial-insights": {
      permission: [],
      reg: /^\/financial-insightsy[^\/]*\/?$/,
      title: "Financial Insights"
    }
  },
  {
    "/:id/profile": {
      permission: [],
      reg: /^\/profile[^\/]*\/?$/,
      title: "Profile"
    }
  },
  {
    "/:id/products": {
      permission: ["read:product", "write:product"],
      reg: /^\/products[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/:id/products/add": {
      permission: ["write:product"],
      reg: /^\/products\/[^\/]*0[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/:id/products/:id": {
      permission: ["read:product", "write:product"],
      reg: /^\/products\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  },
  {
    "/:id/products/create/0/category/:categoryId": {
      permission: ["write:product"],
      reg: /^\/products\/[^\/]*create\b[^\/]*\/[^\/]*0[^\/]*\/[^\/]*category\b[^\/]*\/[^\/]*[a-zA-z\d]{24}[^\/]*\/?$/,
      title: "Products"
    }
  }
];

export const newCMSroles = [
  { id: "INTERNAL_EDITOR", title: "internalEditor" },
  { id: "INTERNAL_PUBLISHER", title: "internalPublisher" },
  { id: "EXTERNAL", title: "external" }

];

export const roles = [
  { id: "INTERNAL_EDITOR", title: "internalEditor" },
  { id: "INTERNAL_PUBLISHER", title: "internalPublisher" },
  { id: "EXTERNAL_EDITOR", title: "externalEditor" },
  { id: "EXTERNAL_PUBLISHER", title: "externalPublisher" },
  { id: "EXTERNAL_BASIC", title: "Basic" },
  { id: "EXTERNAL_PRO", title: "Pro" },
  { id: "EXTERNAL_PREMIUM", title: "Premium" }

];

export const externalRoles = [
  { id: "EXTERNAL_BASIC", title: "Basic" },
  { id: "EXTERNAL_PRO", title: "Pro" },
  { id: "EXTERNAL_PREMIUM", title: "Premium" }
];

export const notificationType = [
  { id: 1, name: "Push Notifications", to: "/push/0" },
  { id: 2, name: "Tailored Notifications", to: "/tailored" }
];

export const tailoredNotificationType = [
  {
    id: "HEALTH_CONNECTION_REMINDER",
    name: "Health Connection Reminder",
    path: "Health-Connection-Reminder"
  },
  {
    id: "PROFILE_COMPLETION_REMINDER",
    name: "Profile Completion Reminder",
    path: "Profile-Completion-Reminder"
  },
  {
    id: "REWARD_REDEMPTION_REMINDER",
    name: "Reward Redemption Reminder",
    path: "Reward-Redemption-Reminder"
  },
  { id: "REVIEW_REMINDER", name: "Review Reminder", path: "Review-Reminder" }
];

export const reportData = [{
  categoryId: "62ef8b90e67ec553260e3b72",
  categoryName: "Credit Card",
  click: [
    {
      date: "2022-12-01", // "month": "2022-12" // "year": "2022" // "date": "2022-12-01"
      day: "SUNDAY", // this field only if weekly or date option is selected
      nbbClickPercent: 0,
      nonnbbClickPercent: 14.8
    },
    {
      date: "2022-12-02", // "month": "2022-12" // "year": "2022" // "date": "2022-12-01"
      day: "MONDAY", // this field only if weekly or date option is selected
      nbbClickPercent: 11,
      nonnbbClickPercent: 30

    },
    {
      date: "2022-12-03", // "month": "2022-12" // "year": "2022" // "date": "2022-12-01"
      day: "TUESDAY", // this field only if weekly or date option is selected
      nbbClickPercent: 9,
      nonnbbClickPercent: 6

    },
    {
      date: "2022-12-04", // "month": "2022-12" // "year": "2022" // "date": "2022-12-01"
      day: "WEDNESDAY", // this field only if weekly or date option is selected
      nbbClickPercent: 30,
      nonnbbClickPercent: 20

    },
    {
      date: "2022-12-04", // "month": "2022-12" // "year": "2022" // "date": "2022-12-01"
      day: "THURSDAY", // this field only if weekly or date option is selected
      nbbClickPercent: 10,
      nonnbbClickPercent: 15
    }
  ],
  rating: {
    nbbRatingbAvg: 3.5,
    nonnbbRatingAvg: 2.5
  }
}
];

export const internalUserSortData = [
  {
    key: "rewards",
    label: "Rewards"
  },
  {
    key: "earned",
    label: "Points Earned"
  },
  {
    key: "redeemed",
    label: "Points Redeemed"
  },
  {
    key: "name",
    label: "User Name"
  }
];

export const redemptionsReportSortData = [
  {
    key: "date",
    label: "Date"
  },
  {
    key: "user",
    label: "User name"
  }
];

export const partnersReportSortData = [
  {
    key: "provider",
    label: "Alphabetically"
  },
  {
    key: "views",
    label: "Views"
  },
  {
    key: "clicks",
    label: "Clicks"
  },
  {
    key: "rating",
    label: "Rating"
  }
];

export const externalPartnersSortData = [
  {
    key: "name",
    label: "Product Name"
  },
  {
    key: "category",
    label: "Category"
  },
  {
    key: "views",
    label: "Views"
  },
  {
    key: "clicks",
    label: "Clicks"
  },
  {
    key: "rating",
    label: "Rating"
  }
];

export const partnersData = [
  {
    provider: "National Bank of Bahrain",
    providerId: "62ef91b1e67ec553260e3b73",
    imageUrl: "https://daleel-uat-assets.s3.me-south-1.amazonaws.com/8/220807125636NBB.png",
    products: [
      {
        id: "62ef9cf6e67ec553260e3b78",
        name: "Infinite Credit Card",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019131228NBB_Infinite_Thumbnail.png",
        categoryId: "62ef8b90e67ec553260e3b72",
        categoryName: "Credit Card",
        clicks: 0,
        rating: 5.0
      },
      {
        id: "62efe74546f793494c13be2c",
        name: "Signature Credit Card",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019131727Bank_Products_-_Thumbnail_(4)_(1).png",
        categoryId: "62ef8b90e67ec553260e3b72",
        categoryName: "Credit Card",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "62ef9830e67ec553260e3b76",
        name: "Personal Finance",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/11/221106045846Thumbnail.png",
        categoryId: "62ef8a35f820ff68e01d5f12",
        categoryName: "Personal Loan",
        clicks: 0,
        rating: 5.0
      },

      {
        id: "6303d3f07ccf9b2458cda021",
        name: "Auto Loan ",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019133854NBB.png",
        categoryId: "62f0d8edfb59a3227f83cac1",
        categoryName: "Car Loan ",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "63674a6142e9e37f7e3dd326",
        name: "Save Wave Savings Account",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/11/221106054712Thumbnail.png",
        categoryId: "62f0e7cbfb59a3227f83cac7",
        categoryName: "Savings Account ",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "6367402f42e9e37f7e3dd31e",
        name: "Personal Solar Finance",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/11/221106050342Thumbnail.png",
        categoryId: "62ef8a35f820ff68e01d5f12",
        categoryName: "Personal Loan",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "63673aa442e9e37f7e3dd31d",
        name: "Gold Credit Card",
        providerName: "National Bank of Bahrain",
        providerId: "62ef91b1e67ec553260e3b73",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/11/221106044002Bank_Products_-_Thumbnail_(1)_(1)_(1)_(1).png",
        categoryId: "62ef8b90e67ec553260e3b72",
        categoryName: "Credit Card",
        clicks: 0,
        rating: 0.0
      }
    ]
  },
  {
    provider: "Khaleeji Commercial Bank",
    providerId: "62efbb6e46f793494c13be1d",
    imageUrl: "https://daleel-uat-assets.s3.me-south-1.amazonaws.com/8/220807131734KHCB.png",
    products: [
      {
        id: "63204b50f4a73e5f0e36207d",
        name: "Personal Finance",
        providerName: "Khaleeji Commercial Bank",
        providerId: "62efbb6e46f793494c13be1d",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019110602KHCB.png",
        categoryId: "62ef8a35f820ff68e01d5f12",
        categoryName: "Personal Loan",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "63205008f4a73e5f0e36207e",
        name: "Auto Finance",
        providerName: "Khaleeji Commercial Bank",
        providerId: "62efbb6e46f793494c13be1d",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019134326KHCB.png",
        categoryId: "62f0d8edfb59a3227f83cac1",
        categoryName: "Car Loan ",
        clicks: 0,
        rating: 0.0
      },
      {
        id: "63205927f4a73e5f0e362080",
        name: "Mudaraba Fixed Deposit",
        providerName: "Khaleeji Commercial Bank",
        providerId: "62efbb6e46f793494c13be1d",
        imageUrl: "https://daleel-prod-assets.s3.me-south-1.amazonaws.com/10/221019133046KHCB.png",
        categoryId: "62f0e30dfb59a3227f83cac6",
        categoryName: "Deposit Account ",
        clicks: 0,
        rating: 0.0
      }
    ]
  }
];

export const internalReports = [{
  title: "Redemption Report",
  icon: <Redemption />,
  pathname: "redemption"
},
{
  title: "Partners Report",
  icon: <Partner />,
  pathname: "partners"
},
{
  title: "User Search Behaviour Report",
  icon: <Behaviour />,
  pathname: "users-behaviour"
}
];

export const YEARS = Array.from(Array(
  new Date().getFullYear() - 1999
), (_, i) => (i + 2000).toString());

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const pointsNavTabs = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Spent",
    value: "spent"
  },
  {
    label: "Earned",
    value: "earned"
  }
];

export const navTabs = [
  {
    label: "FAQs",
    value: 1
  },
  {
    label: "Guidebooks",
    value: 2
  }
];
