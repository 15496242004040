import {
  createSelector
} from "reselect";

const getExternalReports = (state) => state.reports.externalReports;

const getExternalReportsOverview = (state) => state.reports.reportOverview;

const getExternalProducts = (state) => state.reports.externalProducts;

const getRedemptionsReport = (state) => state.reports.redemptionsReport;

const getPartnersReport = (state) => state.reports.partnersReport;

const getUsersBehaviourReport = (state) => state.reports.usersBehaviourReport;

const downloadedReport = (state) => state.reports.downloadedReport;

const getIsLoading = (state) => state.reports.loading;

const getIsLoadingExternal = (state) => state.reports.loadingExternal;

const getIsLoadingExternalOverview = (state) => state.reports.loadingExternalOverview;

export const getExternalReportsSelector = createSelector(
  getExternalReports,
  (externalReports) => externalReports
);

export const getReportOverviewSelector = createSelector(
  getExternalReportsOverview,
  (reportOverview) => reportOverview
);

export const getExternalProductsSelector = createSelector(
  getExternalProducts,
  (externalProducts) => externalProducts
);

// internal users
export const getRedemptionsReportSelector = createSelector(
  getRedemptionsReport,
  (redemptionsReport) => redemptionsReport
);

export const getPartnersReportSelector = createSelector(
  getPartnersReport,
  (partnersReport) => partnersReport
);

export const getUsersBehaviourReportSelector = createSelector(
  getUsersBehaviourReport,
  (behaviourReport) => behaviourReport
);

export const getDownloadedReportSelector = createSelector(
  downloadedReport,
  (report) => report
);

export const getisLoadingReportsSelector = createSelector(
  getIsLoading,
  (loading) => loading
);

export const getisLoadingExternalReportsSelector = createSelector(
  getIsLoadingExternal,
  (loadingExternal) => loadingExternal
);

export const getisLoadingExternalOverviewSelector = createSelector(
  getIsLoadingExternalOverview,
  (loadingExternalOverview) => loadingExternalOverview
);
