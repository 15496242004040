import { createSlice } from "@reduxjs/toolkit";
import {
  createTermsAndConditions,
  editTermsAndConditions,
  loadAllTermsAndConditions,
  loadTermsAndConditionsById,
  loadTermsAndConditionsCheckFlag
} from "store/actions/termsAndConditions";

const initialState = {
  termsAndConditions: {},
  termsAndConditionsList: [],
  isTcVersionAvailable: false,
  loading: false,
  loadingFlag: false
};

const termsAndConditionsSlice = createSlice({
  name: "termsAndConditions",
  initialState,
  extraReducers: {
    [loadTermsAndConditionsById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadTermsAndConditionsById.fulfilled]: (state, { payload }) => {
      state.termsAndConditions = payload;
      state.loading = false;
    },
    [loadTermsAndConditionsById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadAllTermsAndConditions.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadAllTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.termsAndConditionsList = payload;
      state.loading = false;
    },
    [loadAllTermsAndConditions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadTermsAndConditionsCheckFlag.pending]: (state) => {
      state.loadingFlag = true;
      state.error = null;
    },
    [loadTermsAndConditionsCheckFlag.fulfilled]: (state, { payload }) => {
      state.isTcVersionAvailable = payload;
      state.loadingFlag = false;
    },
    [loadTermsAndConditionsCheckFlag.rejected]: (state, { payload }) => {
      state.loadingFlag = false;
      state.error = payload;
    },
    [editTermsAndConditions.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editTermsAndConditions.fulfilled]: (state) => {
      state.loading = false;
    },
    [editTermsAndConditions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [createTermsAndConditions.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.termsAndConditions = payload;
      state.loading = false;
    },
    [createTermsAndConditions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default termsAndConditionsSlice.reducer;
