/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { Grid, InputLabel, TextField } from "@mui/material";
import NewBaseSelect from "components/formControl/baseSelect/NewBaseSelect";
import { currencyOptions } from "utils/staticValues";

const ProductMainFields = ({
  id, number, categoryMainFields, productCategory, label, onChange
}) => {
  const [mainFieldsFormValue, setMainFieldsFormValue] = useState({});
  const [resetDropdown, setResetDropdown] = useState(false);

  useEffect(() => {
    const resetObj = {
      [`${id}`]: "",
      [`${id}NotationId`]: ""
    };
    setMainFieldsFormValue(resetObj);
    setResetDropdown(true);
    setTimeout(() => {
      setResetDropdown(false);
    }, [200]);
  }, [productCategory]);

  const handleChangeTextfield = (e) => {
    const textObj = {
      ...mainFieldsFormValue,
      [`${id}`]: e.target.value
    };
    setMainFieldsFormValue(textObj);

    if (onChange) {
      onChange(textObj);
    }
  };

  const handleChangeSelect = (val) => {
    const currencyObj = {
      ...mainFieldsFormValue,
      [`${id}NotationId`]: val
    };
    setMainFieldsFormValue(currencyObj);

    if (onChange) { onChange(currencyObj); }
  };

  return (

    <Grid key={id} item xs={12} md={6}>
      <InputLabel sx={{ mt: 3 }} variant="outlined">
        {productCategory?.mainFields?.[number / 2]?.label}
      </InputLabel>
      <Grid container spacing={1} xs={12} alignItems="center" mt={-3}>
        <Grid item xs={8}>
          <TextField
            InputLabelProps={{ required: false }}
            sx={{ marginBottom: "24px" }}
            type="text"
            margin="normal"
            fullWidth
            label={label}
            onChange={(e) => handleChangeTextfield(e)}
            value={mainFieldsFormValue[id]}
          />
        </Grid>
        <Grid item xs={4}>
          <NewBaseSelect
            resetValues={resetDropdown}
            onChange={(value) => handleChangeSelect(value)}
            items={currencyOptions}
            sx={{ minWidth: 50, marginBottom: 0 }}
            selected={categoryMainFields[`${id}NotationId`] || ""}
          />
        </Grid>
      </Grid>
    </Grid>

  );
};

export default ProductMainFields;
