/* eslint-disable no-underscore-dangle */
import { API } from "aws-amplify";
import { getErrorStringPart } from "utils";
import RequestService from "./RequestService";

const apiName = "MyAPIGatewayAPI";
const pathname = "/cms/guidebook_category";

class GuidebookCategoryService extends RequestService {
  constructor(apiName, path, api) {
    super(apiName, path, api);
  }

  async getGuidebookTopics(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/summary`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getGuidebookTopicById(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/${params}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postGuidebookTopic(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new GuidebookCategoryService(apiName, pathname, API);
