import { API } from "aws-amplify";

import RequestService from "./RequestService";

const apiName = "MyAPIGatewayAPI";
const path = "/cms/reward";

class RewardsService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async editRewardsListOrder({ params }) {
    try {
      const response = await this._httpService.put(this._apiName, `${this._path}-order`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params.params
      });
      return response;
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new RewardsService(apiName, path, API);
