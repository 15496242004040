import React, { useEffect, useState } from "react";
import {
  Box, Card, CardContent, Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import NationalitiesBarChart from "components/Reports/NationalitiesBarChart";
import StatisticsPieChart from "components/Reports/StatisticsPieChart";
import SalaryBarChart from "components/Reports/SalaryBarChart";
import VerticalBarChart from "components/Reports/VerticalBarChart";
import CustomTable from "components/Table/CustomTable";
import PageLoader from "components/PageLoader/PageLoader";

// ACTIONS
import {
  loadAgeGenderStats,
  loadApplyingUserById,
  loadApplyingUsersByBank,
  loadEmploymentStatusStats,
  loadNationalitiesStats,
  loadSalaryStats
} from "store/actions/customerInsights";

// SELECTORS
import {
  getAgeGenderStatsSelector,
  getEmploymentStatusStatsSelector,
  getIsLoadingApplyingUsersSelector,
  getNationalitiesStatsSelector,
  getUsersByBankStatsSelector
} from "store/selectors/customerInsights";
import { getUserRoleSelector } from "store/selectors/user";

import { AppliedUsersTableHeaders } from "config/tableHeaders/AppliedUsersHeaders";
import palette from "theme/palette";

import styles from "../user.module.scss";

const CustomerInsightsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);

  const cmsUserRole = useSelector(getUserRoleSelector);
  const { ageGroup, gender } = useSelector(getAgeGenderStatsSelector);
  const employtmentStats = useSelector(getEmploymentStatusStatsSelector);
  const nationalitiesStats = useSelector(getNationalitiesStatsSelector);
  const {
    totalPages,
    currentPage, userApplyingDTOList: applyingUsers
  } = useSelector(getUsersByBankStatsSelector);
  const isLoadingApplyingUsers = useSelector(getIsLoadingApplyingUsersSelector);

  useEffect(() => {
    dispatch(loadAgeGenderStats());
    dispatch(loadEmploymentStatusStats());
    dispatch(loadNationalitiesStats());
    dispatch(loadApplyingUsersByBank({ page: 1 }));
  }, []);

  useEffect(() => {
    dispatch(loadApplyingUserById({
      id:
    "379a9014-8056-4a83-b277-11b680ba119a"
    }));
  }, []);

  const handlePrevArrowClick = () => {
    setActivePage(activePage - 1);
    const updatedPage = {
      page: activePage - 1
    };

    dispatch(loadApplyingUsersByBank(updatedPage));
  };

  const handleNextArrowClick = () => {
    setActivePage(activePage + 1);
    const updatedPage = {
      page: activePage + 1
    };

    dispatch(loadApplyingUsersByBank(updatedPage));
  };

  if (isLoadingApplyingUsers) return <PageLoader />;

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("external.customerInsights")}</Typography>
      </Box>
      <Typography variant="newSubtitle">{t("external.demographics")}</Typography>
      <Box my={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Card sx={{
          width: "45%", height: "350px", padding: 2, borderRadius: "25px", filter: cmsUserRole === "BASIC" ? "blur(4px)" : "none"
        }}
        >
          <CardContent>
            <Box display="flex" sx={{ justifyContent: "space-between" }}>
              <Box sx={{ width: "45%" }}>
                <Typography variant="smallTitle">Age Group</Typography>
                <StatisticsPieChart
                  data={ageGroup}
                  key="value"
                />
                <Typography variant="smallTitle">Gender</Typography>
                <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="newBody2">
                    Female -
                    {" "}
                    {gender?.female}
                    %
                  </Typography>
                  <Typography variant="newBody2">
                    Male -
                    {" "}
                    {gender?.male}
                    %
                  </Typography>
                </Box>
              </Box>
              <Box width="50%">
                <Typography variant="smallTitle">Nationalities</Typography>
                <Box mt={2}>
                  <NationalitiesBarChart
                    colorChanges
                    nationalitiesData={nationalitiesStats}
                    key1="quantityRounded"
                    xKey="nationality"
                    unit="k"
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{
          width: "45%", height: "350px", padding: 2, borderRadius: "25px", filter: cmsUserRole === "BASIC" ? "blur(4px)" : "none"
        }}
        >
          <CardContent>
            <Box display="flex" sx={{ justifyContent: "space-between" }}>
              <Box sx={{ width: "45%" }}>
                <Typography variant="smallTitle">Employment Status</Typography>
                <VerticalBarChart
                  data={employtmentStats}
                  multiplierDist={[5, 5]}
                  hideY
                  xKey="status"
                  yKey="value"
                  labelSize="13px"
                  showLabelList
                  unit="%"
                  margin={{ left: -55 }}
                  minHeight={150}
                />
                <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="smallTitle" mb={1}>Average Monthly Balance</Typography>
                  <Typography fontSize="18px" lineHeight="25px" variant="title" mb={3}>
                    1,230
                    <Typography variant="newBody1">BHD</Typography>
                  </Typography>
                  <Typography variant="smallTitle">Average Spending</Typography>
                  <Typography fontSize="18px" lineHeight="25px" variant="title">
                    5,305
                    <Typography variant="newBody1">BHD</Typography>
                  </Typography>
                </Box>
              </Box>
              <Box width="50%">
                <Typography variant="smallTitle">Salary Range</Typography>
                <Box mt={2}>
                  <SalaryBarChart
                  // data
                    colorChanges
                    key1="salaryRounded"
                    xKey="salaryGroup"
                    unit="k"
                  />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography fontSize="10px" color={palette.variables.greyLight}>Salary ranges in BHD</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4}>
        <Typography variant="newSubtitle">{t("external.usersApplyingText")}</Typography>
        <Box mt={2}>
          <CustomTable
            width="100%"
          //  hideContent
            data={applyingUsers}
            loading={false}
            headers={AppliedUsersTableHeaders}
            pagination={{
              activePage: currentPage,
              totalPages,
              next: handleNextArrowClick,
              prev: handlePrevArrowClick
            }}
          />
        </Box>
      </Box>

    </Box>

  );
};

export default CustomerInsightsPage;
