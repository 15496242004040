import React, { useState } from "react";
import { ReactComponent as Filter } from "assets/icons/filter-icon.svg";
import { useSelector } from "react-redux";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import { func, instanceOf } from "prop-types";
import {
  Box, Typography
} from "@mui/material";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";

const BehaviourMenuItemWithChildren = ({
  onChangeFilter, selectedFilters
}) => {
  const { productCategory } = useSelector((state) => state.products);

  const [filters, setFilters] = useState({ ...selectedFilters });

  let selectedButtonsArray = filters?.filterCategory?.length ? filters.filterCategory.split(",") : [];

  // solo cambia el valor de los filtros
  const handleFilterChange = (value, type) => {
    const updatedFilters = { ...filters };

    if (type === "toggle") {
      const key = "filterCategory";

      if (!value.length) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = value.toString();
      }
      setFilters(updatedFilters);
    }
  };

  // handles submit logic
  const handleConfirm = () => {
    const arr = [];
    Object.keys(filters).forEach((item) => {
      if (item === "filterCategory") {
        arr.push("category");
      }
    });

    onChangeFilter({
      ...filters
    }, arr);
  };

  const handleClear = () => {
    setFilters({});
    selectedButtonsArray = [];
    onChangeFilter({}, []);
  };

  const renderBody = () => (
    <Box mb={2}>
      <Typography variant="smallTitle">Category</Typography>
      <Box mt={1}>
        <MultipleSelectionToggleBtn
          data={productCategory}
          selected={selectedButtonsArray}
          onChange={(val) => handleFilterChange(val, "toggle")}
          isFilter
        />
      </Box>
    </Box>
  );

  return (
    <ButtonWithModal
      isIcon
      size="small"
      title={<Typography variant="newSubtitle">Filter</Typography>}
      sx={{
        width: "max-content",
        backgroundColor: "#F2F2F2",
        fontSize: "12px"
      }}
      handleAction={handleConfirm}
      body={renderBody()}
      handleClear={handleClear}
      hideCancel
    >
      <Filter style={{ color: "gray" }} />
    </ButtonWithModal>
  );
};

BehaviourMenuItemWithChildren.propTypes = {
  onChangeFilter: func.isRequired,
  selectedFilters: instanceOf(Object).isRequired
};

export default BehaviourMenuItemWithChildren;
