import { instanceOf } from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getAuthUserSelector } from "store/selectors/user";

const PublicRoute = ({ children }) => {
  const authUser = useSelector(getAuthUserSelector);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") {
      document.title = "Login | Daleel";
    }
    window.scrollTo(0, 0);
  }, [location]);

  return authUser && authUser !== null ? <Navigate to="/" state={{ from: location }} replace /> : children;
};

PublicRoute.propTypes = {
  children: instanceOf(Object).isRequired
};

export default PublicRoute;
