import React from "react";
import {
  Box, Divider, Grid, IconButton, Modal, Tooltip, Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import palette from "theme/palette";
import { InfoOutlined } from "@mui/icons-material";
import BaseButton from "components/formControl/baseButton/BaseButton";
import {
  bool, func, instanceOf, string
} from "prop-types";

const WarningEntityLinksModal = ({
  warningText, open, handleClose, onDelete, list
}) => {
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "50%",
    bgcolor: "white",
    border: `2px solid ${palette.variables.orange}`,
    boxShadow: 24,
    borderRadius: 2,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column"
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ p: 2 }}
            component="p"
            variant="bodyBig"
            color={palette.variables.darkPurple}
          >
            Important action
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ padding: 3, pl: 2 }}
            variant="body1"
            color={palette.variables.darkPurple}
          >
            {warningText}
          </Typography>
        </Box>
        <Divider />
        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
          <Grid item xs={3}>
            <Typography
              sx={{ ml: 0 }}
              variant="subtitle1"
              component="h4"
              color={palette.variables.orange}
            >
              {t("fields.type")}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              sx={{ ml: 0 }}
              variant="subtitle1"
              component="h4"
              color={palette.variables.orange}
            >
              {t("fields.label")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{ ml: 0 }}
              variant="subtitle1"
              component="h4"
              color={palette.variables.orange}
            >
              {t("fields.published")}
            </Typography>
          </Grid>
        </Grid>
        <Box>
          {list?.map((item) => (
            <Grid key={item.label} container spacing={2} mb={2}>
              <Grid item xs={3} sx={{ display: "flex" }}>
                <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                  {item.type}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                  {item.label}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                  {item.published ? "True" : "False"}
                  {item.published && (
                  <Tooltip
                    title={(
                      <span style={{ fontSize: 14 }}>
                        You need to publish the linked entity after saving it.
                      </span>
                        )}
                  >
                    <IconButton size="small" aria-label="info">
                      <InfoOutlined
                        style={{ fill: palette.variables.orange }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
        <Divider />
        <Box sx={{
          display: "flex", justifyContent: "flex-end", p: 2, alignSelf: "flex-end"
        }}
        >
          <BaseButton
            customColor={palette.variables.darkPurple}
            variant="contained"
            element="OK"
            onClick={onDelete}
            sx={{ display: "flex", width: 100 }}
          />
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            element="Cancel"
            onClick={handleClose}
            sx={{ display: "flex", ml: 3, width: 100 }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

WarningEntityLinksModal.propTypes = {
  warningText: string.isRequired,
  open: bool.isRequired,
  handleClose: func.isRequired,
  list: instanceOf(Array).isRequired,
  onDelete: func.isRequired
};

export default WarningEntityLinksModal;
