/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  Box,
  ButtonGroup,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Toolbar, Typography
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  bool, func, instanceOf, number, string
} from "prop-types";

const CustomTable = ({
  headers,
  data,
  pagination,
  loading,
  actions,
  hideHeaders,
  minHeight,
  maxHeight,
  toolbar,
  width,
  hideContent
}) => (
  <Paper sx={{ width: "100%", borderRadius: "25px !important" }}>
    {Boolean(toolbar) && (
    <Toolbar sx={{
      width: "100%", paddingLeft: "10px !important", paddingTop: 2, minHeight: "45px !important"
    }}
    >
      {toolbar}
    </Toolbar>
    )}
    <TableContainer sx={{ minHeight, maxHeight }}>
      {loading && (
      <LinearProgress sx={{ width: "100%" }} />
      )}
      <Table
        stickyHeader
        sx={{
          width,
          borderBotton: "none"
        }}
      >
        {!hideHeaders && (
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom !important" }}>
            {headers.map(({
              label, key, minWidth, textAlign, color, sticky
            }) => (
              <TableCell
                key={key}
                style={{
                  ...(sticky && {
                    position: "sticky", left: 1, zIndex: 3, backgroundColor: "white"
                  })
                }}
                sx={{
                  height: "35px",
                  fontWeight: 600,
                  fontSize: "11px",
                  lineHeight: "14px",
                  minWidth: minWidth || 120,
                  textAlign: textAlign || "center",
                  color
                }}
              >
                {label}
              </TableCell>
            ))}
            {actions && <TableCell />}
          </TableRow>
        </TableHead>
        ) }
        <TableBody>
          { data?.map((item, index) => (
            <TableRow
              key={index}
              sx={{ "& th": { textAlign: "center" }, filter: hideContent ? "blur(3px)" : "none" }}
            >
              { headers?.map(({
                Component, key, textAlign, color, sticky, minWidth, fontWeight
              }) => (
                Component
                  ? (
                    <TableCell
                      key={key}
                      component="th"
                      style={{
                        ...(sticky && {
                          position: "sticky", left: 1, zIndex: 2, backgroundColor: "white"
                        })
                      }}
                      sx={{
                        borderBottom: "none", textAlign: textAlign || "center", fontWeight: 400, fontSize: "11px", lineHeight: "14px", minWidth
                      }}
                    >
                      <Component rowData={item}>{item[key]}</Component>
                    </TableCell>
                  )
                  : (
                    <TableCell
                      key={key}
                      component="th"
                      style={{
                        ...(sticky && {
                          position: "sticky", left: 1, zIndex: 2, backgroundColor: "white"
                        })
                      }}
                      sx={{
                        borderBottom: "none", textAlign, color, fontWeight: fontWeight || 400, fontSize: "11px", lineHeight: "14px", minWidth
                      }}
                    >
                      {item[key]}
                    </TableCell>
                  )
              ))}
              {Boolean(actions)
                && (
                <TableCell component="th" style={{ borderBottom: "none", textAlign: "center" }}>
                  <ButtonGroup>
                    {actions(item)}
                  </ButtonGroup>
                </TableCell>
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {Boolean(pagination)
      && (
      <TableFooter sx={{ width: "100%" }}>
        <TableRow sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: 5
        }}
        >
          <IconButton
            size="small"
            disabled={pagination.activePage === 1}
            onClick={pagination.prev}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography>{pagination.activePage }</Typography>
          <IconButton
            size="small"
            disabled={pagination.activePage === pagination?.totalPages}
            onClick={pagination.next}
          >
            <KeyboardArrowRight />
          </IconButton>
          <Typography variant="caption" mr={2}>
            Total pages:
            {" "}
            {pagination?.totalPages}
          </Typography>
          {pagination.totalRecords && (
          <Typography variant="caption">
            Total records:
            {" "}
            {pagination?.totalRecords}
          </Typography>
          )}
        </TableRow>
      </TableFooter>
      )}
  </Paper>
);

CustomTable.propTypes = {
  data: instanceOf(Array),
  headers: instanceOf(Array).isRequired,
  hideHeaders: bool,
  actions: func,
  width: string,
  minHeight: string,
  toolbar: instanceOf(Object),
  maxHeight: number,
  hideContent: bool
};

CustomTable.defaultProps = {
  data: [],
  actions: null,
  hideHeaders: false,
  width: "max-content !important",
  minHeight: "330px",
  toolbar: null,
  maxHeight: 550,
  hideContent: false
};

export default CustomTable;
