import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners } from "store/actions/partners";
import { getPartnersSummarySelector, getisLoadingPartnersSelector } from "store/selectors/partners";
import { fetchCategorySummary } from "store/actions/products";
import CustomTable from "components/Table/CustomTable";
import { PartnersTableHeaders } from "config/tableHeaders/InternalUsers/PartnersTableHeaders";
import { Permissions } from "components/Permissions";

import { useNavigate } from "react-router-dom";
import Searchbar from "components/Searchbar/Searchbar";
import PageLoader from "components/PageLoader/PageLoader";
import { getIsLoadingProductCategoriesSelector } from "store/selectors/products";
import styles from "../admin.module.scss";

const PartnersDraftAdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partners = useSelector(getPartnersSummarySelector);
  const isLoading = useSelector(getisLoadingPartnersSelector);
  const isLoadingCategories = useSelector(getIsLoadingProductCategoriesSelector);
  const [filter, setFilter] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (!partners?.length) {
      dispatch(fetchPartners());
      dispatch(fetchCategorySummary({ id: "summary" }));
    }
  }, []);

  const partnersWithSearchFilter = () => {
    if (filter.length) {
      return (
        partners.filter(
          (partner) => partner.name && partner.name.toLowerCase().includes(filter.toLowerCase())
        ) || []
      );
    }
    return partners;
  };

  if (isLoading || isLoadingCategories) return <PageLoader />;

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
        mb={3}
      >
        <Typography variant="newTitle">
          {" "}
          {t("admin.partners")}
        </Typography>
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "end"
        }}
        >
          <Searchbar onChange={(value) => setFilter(value)} />
          <Permissions permission="write:partner">
            <Button
              variant="newButton"
              sx={{
                backgroundColor: "#F2F2F2", fontSize: "12px", height: "35px !important", marginLeft: 1
              }}
              onClick={() => navigate("/partners/create")}
            >
              {t("admin.addPartner")}
            </Button>
          </Permissions>
        </Box>
      </Box>
      <Box sx={{
        display: "flex", justifyContent: "center", alignItems: "center", width: "100%"
      }}
      >
        <CustomTable
          headers={PartnersTableHeaders}
          data={partnersWithSearchFilter()}
          width="100%"
          minHeight="550px"
        />
      </Box>
    </Box>
  );
};

export default PartnersDraftAdminPage;
