import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box, Divider, Grid, IconButton, Modal, Tooltip, Typography
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import BaseButton from "components/formControl/baseButton/BaseButton";
import palette from "theme/palette";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import {
  bool, func, instanceOf, string
} from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "50%",
  bgcolor: "white",
  border: `2px solid ${palette.variables.orange}`,
  boxShadow: 24,
  borderRadius: 2,
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column"
};

const UnlinkedEntitiesModal = ({
  handleAction,
  onReload,
  onClose,
  text,
  list,
  open,
  onPressGoTo
}) => {
  const { t } = useTranslation();

  const headers = [t("fields.type"), t("fields.label"), t("fields.published"), t("fields.goToLink")];

  const emptyList = !list?.length;

  // if (loading) return <Box><CircularProgress /></Box>;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ p: 2 }}
            component="p"
            variant="bodyBig"
            color={palette.variables.darkPurple}
          >
            Important Information
          </Typography>
          {!emptyList && (
          <IconButton size="small" aria-label="reload" onClick={onReload}>
            <RefreshIcon sx={{
              display: "flex", alignSelf: "center", mr: 2, ml: 2
            }}
            />
          </IconButton>
          )}
        </Box>
        <Divider />
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ padding: 3, pl: 2 }}
            variant="body1"
            color={palette.variables.darkPurple}
          >
            {emptyList ? "You deselected some categories/topics. Are you sure you want to save this expert?" : text}
          </Typography>
        </Box>
        <Divider />
        {!emptyList && (
          <>
            <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
              {headers.map((header) => (
                <Grid item xs={3} key={header}>
                  <Typography
                    sx={{ ml: 0 }}
                    variant="subtitle1"
                    component="h4"
                    color={palette.variables.orange}
                  >
                    {header}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Box>
              {list?.map((item) => (
                <Grid key={item.label} container spacing={2} mb={2}>
                  <Grid item xs={3} sx={{ display: "flex" }}>
                    <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                      {item.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ ml: 3 }} variant="subtitle1" component="h4">
                      {item.published ? "True" : "False"}
                      {item.published && (
                      <Tooltip
                        title={(
                          <span style={{ fontSize: 14 }}>
                            You need to publish the linked entity after saving it.
                          </span>
                     )}
                      >
                        <IconButton size="small" aria-label="info">
                          <InfoOutlined
                            style={{ fill: palette.variables.orange }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        ml: 3,
                        cursor: "pointer",
                        color: palette.variables.orange,
                        textDecoration: "underline"
                      }}
                      variant="subtitle1"
                      component="h4"
                      onClick={() => onPressGoTo(item.id, item.type)}
                    >
                      Go To Link
                    </Typography>
                    <LaunchIcon
                      fontSize="20px"
                      style={{ marginLeft: 5, color: palette.variables.orange }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{
          display: "flex", justifyContent: "flex-end", p: 2, alignSelf: "flex-end"
        }}
        >
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            onClick={onClose}
            element="Cancel"
            sx={{
              display: "flex", width: 100, marginX: 2
            }}
          />
          <span style={{ cursor: !emptyList ? "not-allowed" : "pointer" }}>
            <BaseButton
              customColor={palette.variables.darkPurple}
              disabled={!emptyList}
              variant="contained"
              element="Save Expert"
              onClick={handleAction}
              sx={{
                display: "flex", width: 150, color: "#FFF !important"
              }}
            />
          </span>
        </Box>
      </Box>
    </Modal>
  );
};

UnlinkedEntitiesModal.propTypes = {
  handleAction: func.isRequired,
  onReload: func.isRequired,
  text: string.isRequired,
  list: instanceOf(Array),
  open: bool.isRequired,
  onPressGoTo: func.isRequired
};

UnlinkedEntitiesModal.defaultProps = {
  list: []
};

export default UnlinkedEntitiesModal;
