import React from "react";
import {
  Box, Card, CardContent, Grid, Typography
} from "@mui/material";
import { func, instanceOf } from "prop-types";

import { ReactComponent as Wealth } from "assets/icons/Wealth.svg";

// import palette from 'theme/palette';
import styles from "../../Admin.module.scss";

const AdminListItem = ({ item, onClick }) => {
  const icon = item.iconUrl || item.imageUrl;

  return (
    <Card onClick={onClick} className={styles.cardPartner}>
      <CardContent sx={{ padding: "16px 15px 8px" }}>
        <Grid container alignItems="center">
          <Grid item>
            {icon ? (
              <img style={{ height: "55px", maxWidth: "70px", marginRight: "20px" }} src={icon} alt="" />
            ) : (
              <Box ml={1} mr={5}>
                <Wealth />
              </Box>
            )}
          </Grid>

          <Grid item ml={2}>
            <Typography variant="bodyBig" color="common.darkPurple">
              {item.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AdminListItem.propTypes = {
  item: instanceOf(Object).isRequired,
  onClick: func.isRequired
};

export default AdminListItem;
