import React, { useEffect, useState, useRef } from "react";
import {
  Box, Grid, ImageListItem, ToggleButton, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { setImage } from "store/actions/image";
import { getExperts } from "store/actions/experts";
import { Formik } from "formik";
import { createResource } from "store/actions/resources";
import { useLocation, useNavigate } from "react-router-dom";
import { successToast } from "utils";

// COMPONENTS
import BaseButton from "components/formControl/baseButton/BaseButton";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import BaseSelect from "components/formControl/baseSelect/BaseSelect";

// ACTIONS
import { loadCategoriesByExpertId } from "store/actions/products";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import palette from "theme/palette";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { removeSpaces } from "utils/functions";
import { loadTopicsByExpertId } from "store/actions/topics";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  cursor: "pointer",
  color: theme.palette.text.secondary
}));

const ResourcesDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [previewText, setPreviewText] = useState(EditorState.createEmpty());

  const [fullText, setFullText] = useState(EditorState.createEmpty());
  const [selectedFile, setSeletectedFile] = useState("");
  const inputFile = useRef(null);
  const { experts } = useSelector((state) => state.experts);
  const { error: categoryError } = useSelector((state) => state.products);
  const lang = useSelector(selectLanguage);

  const [categoriesByAuthor, setCategoriesByAuthor] = useState([]);
  const [topicsByAuthor, setTopicsByAuthor] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const authorId = location.state?.author;

  useEffect(() => {
    dispatch(getExperts());
    if (authorId) {
      dispatch(loadCategoriesByExpertId({
        params: { id: authorId },
        cb: (res) => {
          setCategoriesByAuthor(res);
        }
      }));
      dispatch(loadTopicsByExpertId({
        params: {
          id: authorId
        },
        cb: (res) => {
          setTopicsByAuthor(res);
        }
      }));
    }
  }, [authorId, lang]);

  const uploadImage = (fileName) => {
    setSeletectedFile(fileName);
    inputFile.current.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const url = await dispatch(setImage(file));
    return url.payload.url;
  };

  const onChangeAuthorName = (value, event, onChange, onChangeField) => {
    onChange({
      ...event,
      target: { name: "authorId", value }
    });
    onChangeField("categoryId", []);
    onChangeField("topics", []);
    dispatch(loadCategoriesByExpertId({
      params: {
        id: value
      },
      cb: (res) => {
        setCategoriesByAuthor(res);
      }
    }));
    dispatch(loadTopicsByExpertId({
      params: {
        id: value
      },
      cb: (res) => {
        setTopicsByAuthor(res);
      }
    }));
  };

  const handleCreateGuidebook = (values) => {
    const domElPreview = draftToHtml(convertToRaw(previewText.getCurrentContent()))?.toString();
    const domElFull = draftToHtml(convertToRaw(fullText.getCurrentContent()))?.toString();

    const { categoryId, topics, authorId: author } = values;

    const emptyPreview = domElPreview.includes("<p></p>") && !domElPreview.includes("</span>");
    const emptyFullText = domElFull.includes("<p></p>") && !domElFull.includes("</span>");

    const errorObj = {
      ...formErrors,
      ...(!author && { author: true }),
      ...(!categoryId?.length && !topics?.length && { expertise: true }),
      ...(emptyPreview && { previewText: true }),
      ...(emptyFullText && { fullText: true })
    };

    setFormErrors(errorObj);

    if (Object.values(errorObj).some((item) => item)) return;
    const isArray = Array.isArray(categoryId);

    const params = {
      ...values,
      categoryId: isArray ? categoryId[0] : (categoryId || ""),
      previewText: removeSpaces(
        draftToHtml(convertToRaw(previewText.getCurrentContent()))
      ),
      fullText: removeSpaces(draftToHtml(convertToRaw(fullText.getCurrentContent())))
    };
    console.log(params);

    dispatch(
      createResource({
        params,
        cb: (response) => {
          successToast("Resource has been created");
          navigate(`/resources/${response.id}${lang === "ar" ? "?lang=ar" : ""}`, { replace: true });
        }
      })
    );
  };

  const ResourceSchema = Yup.object().shape({
    name: Yup.string().required(t("validation.required"))
  });

  return (
    <Box mt={3}>
      <Formik
        initialValues={{
          name: "",
          authorId: authorId || "",
          categoryId: [],
          topics: [],
          iconUrl: "https://daleel-assets.s3.me-south-1.amazonaws.com/6/220621115853add_(1).png",
          imageUrl: "https://daleel-assets.s3.me-south-1.amazonaws.com/6/220621115853add_(1).png"
        }}
        validationSchema={ResourceSchema}
        onSubmit={(values) => handleCreateGuidebook(values)}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          setErrors,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                mb: 6
              }}
            >
              <BaseTextField
                name="name"
                label={t("fields.title")}
                id="name"
                fullWidth
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                color={!errors.name && values.name ? "success" : ""}
              />

              <ToggleButton
                sx={{
                  display: "flex",
                  gap: "8px",
                  marginRight: "10px",
                  flexWrap: "nowrap",
                  width: "max-content"
                }}
                selected={lang !== "en"}
                onChange={() => {
                  dispatch(setLanguage(lang === "en" ? "ar" : "en"));
                  setErrors({});
                }}
                value="check"
              >
                {lang !== "en" ? "English" : "Arabic"}
              </ToggleButton>
            </Box>
            <Box>
              <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                <Grid item xs={2}>
                  <Item onClick={() => uploadImage("iconUrl")}>
                    <h4>{t("fields.thumbnail")}</h4>
                    <ImageListItem key={values.iconUrl} style={{ height: "200px" }}>
                      <img
                        src={`${values.iconUrl}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${values.iconUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="iconUrl"
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Item>
                </Grid>
                <Grid item xs={2}>
                  <Item onClick={() => uploadImage("imageUrl")}>
                    <h4>{t("fields.fullsizeImage")}</h4>
                    <ImageListItem key={values.imageUrl} style={{ height: "200px" }}>
                      <img
                        src={`${values.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${values.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="imageUrl"
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Item>
                </Grid>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={async (event) => {
                    const url = await onChangeFile(event);
                    handleChange({ ...event, target: { name: selectedFile, value: url } });
                  }}
                />
              </Grid>
              <Box mb={3}>
                <Typography variant="newBody2">Max size allowed 75KB</Typography>
              </Box>
              <BaseSelect
                name="authorName"
                label={t("fields.authorName")}
                items={experts.map((x) => ({
                  id: x.id,
                  title: x.name
                }))}
                placeholder="expert"
                initvalue={values?.authorId}
                onChange={(value, event) => {
                  onChangeAuthorName(value, event, handleChange, setFieldValue);
                  setFormErrors({ ...formErrors, author: false });
                }}
                error={formErrors.author}
                sx={{ width: "100%", marginBottom: 2, ml: 0 }}
              />
              <Box mt={1}>
                <Typography variant="subtitle1">
                  {t("admin.productCategory")}
                  {" "}
                  (Max 1 selected)
                </Typography>
                <Box mt={2}>
                  {
                      categoriesByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={categoriesByAuthor}
                          exclusive
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "categoryId", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={values.categoryId}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No categories available for this author</Typography>
                      )

                    }
                </Box>
              </Box>
              <Box my={2}>
                <Typography color={palette.variables.darkPurple2} variant="subtitle1">
                  {t("fields.topics")}
                </Typography>
                <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                  {topicsByAuthor?.length ? (
                    <MultipleSelectionToggleBtn
                      data={topicsByAuthor}
                      onChange={(event, value) => {
                        handleChange({
                          ...event,
                          target: { name: "topics", value }
                        });
                        setFormErrors({ ...formErrors, expertise: false });
                      }}
                      selected={values.topics}
                    />
                  ) : (
                    <Typography variant="smallTitle" fontWeight={400}>No topics available for this author</Typography>
                  )}
                  {Boolean(formErrors.expertise) && (
                  <FormRequiredText>At least one category or topic is required</FormRequiredText>
                  )}
                </Box>
              </Box>

              <Typography
                style={{ fontWeight: 500 }}
                variant="body2"
                color="common.darkPurple"
                sx={{ mt: 3 }}
              >
                {t("fields.previewText")}
              </Typography>
              <RichTextEditor
                value={previewText}
                onChange={(editorState) => {
                  setPreviewText(editorState);
                  setFormErrors({ ...formErrors, previewText: false });
                }}
                error={formErrors.previewText}
              />
              <Typography
                style={{ fontWeight: 500 }}
                variant="body2"
                color="common.darkPurple"
                sx={{ mt: 3 }}
              >
                {t("fields.about")}
              </Typography>
              <RichTextEditor
                value={fullText}
                onChange={(editorState) => {
                  setFullText(editorState);
                  setFormErrors({ ...formErrors, fullText: false });
                }}
                error={formErrors.fullText}
              />
              <BaseButton
                customColor={palette.variables.darkPurple}
                type="submit"
                fullWidth
                variant="contained"
                element={t("save")}
                sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ResourcesDetailsPage;
