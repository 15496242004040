import React from "react";
import { Box, ToggleButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DisablePermissions } from "components/DisablePermissions";
import ToggleButtonCustom from "components/formControl/toggleButton/ToggleButton";
import BaseButton from "components/formControl/baseButton/BaseButton";
import palette from "theme/palette";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateFaqByExpert } from "store/actions/faqs";
import { successToast } from "utils";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import { func, instanceOf } from "prop-types";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import { getExpertById } from "store/actions/experts";

const ExpertFaqsConfigButtons = ({ faq, onDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(selectLanguage);
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const handleFaqDeletion = () => {
    if (onDelete) {
      onDelete(faq);
    }
  };

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      <DisablePermissions disable permission="publish:faq">
        <ToggleButtonCustom
          style={{ width: "100px", height: "40px" }}
          text="Publish"
          onChange={() => {
            dispatch(
              updateFaqByExpert({
                id: `${faq.id}/publish`,
                cb: () => {
                  successToast("Faq has been published.");
                }
              })
            );
          }}
        />
      </DisablePermissions>
      <ToggleButton
        sx={{
          display: "flex",
          gap: "8px",
          overflow: "auto",
          marginRight: "10px",
          flexWrap: "nowrap",
          width: "max-content"
        }}
        selected={lang !== "en"}
        onChange={() => {
          dispatch(setLanguage(lang === "en" ? "ar" : "en"));
          dispatch(getExpertById({ id }));
          localStorage.setItem("faqId", faq.id);
        }}
        value="check"
      >
        {lang !== "en" ? "English" : "Arabic"}
      </ToggleButton>
      <DisablePermissions permission="write:faq" disable>
        <BaseButton
          customColor={palette.variables.darkPurple}
          type="submit"
          fullWidth
          variant="contained"
          element={t("save")}
          sx={{
            display: "block",
            maxWidth: "100px",
            margin: 2,
            marginRight: 0,
            marginLeft: 0
          }}
        />
      </DisablePermissions>
      <Box
        sx={{
          display: "flex"
        }}
      >
        <ButtonWithModal
          title="Warning"
          handleAction={handleFaqDeletion}
          body={<Typography>{`Are you sure you want to delete ${faq.question}`}</Typography>}
        >
          <Typography
            color={palette.variables.pink}
            variant="newCaption"
            sx={{ textTransform: "none" }}
          >
            Delete
          </Typography>
        </ButtonWithModal>

      </Box>
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <Typography
          color={palette.variables.lightGreen}
          variant="newCaption"
          style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
          onClick={() => navigate(
            `/experts/published/${faq.id}/faq/current${!!searchParams.get("lang") && searchParams.get("lang") === "ar" ? "?lang=ar" : ""}`,
            { state: { expertId: id } }
          )}
        >
          View Current
        </Typography>

        <Typography
          color={palette.variables.lightGreen}
          variant="newCaption"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(
            `/experts/published/${faq.id}/faq/published${!!searchParams.get("lang") && searchParams.get("lang") === "ar" ? "?lang=ar" : ""}`,
            { state: { expertId: id } }
          )}
        >
          View Published
        </Typography>

      </Box>
    </Box>
  );
};

ExpertFaqsConfigButtons.propTypes = {
  faq: instanceOf(Object).isRequired,
  onDelete: func
};

ExpertFaqsConfigButtons.defaultProps = {
  onDelete: null
};

export default ExpertFaqsConfigButtons;
