import React, { useState } from "react";
import {
  Box, Tabs, Tab
} from "@mui/material";
import { func, instanceOf } from "prop-types";

const NavFilters = ({ data, onChange }) => {
  const [value, setValue] = useState("all");

  const handleTabChange = (event, val) => {
    setValue(val);
    onChange(val);
  };

  return (
    <Box sx={{
      borderBottom: 1, borderColor: "divider", width: "97%"
    }}
    >
      <Tabs value={value} onChange={handleTabChange}>
        {data.map((item) => (
          <Tab
            sx={{
              fontSize: "11px", lineHeight: "12px", fontWeight: 600, textTransform: "none"
            }}
            key={item.value}
            label={item.label}
            value={item.value}
          />
        ))}
      </Tabs>
    </Box>
  );
};

NavFilters.propTypes = {
  onChange: func.isRequired,
  data: instanceOf(Array).isRequired
};

export default NavFilters;
