import React from "react";
import { instanceOf } from "prop-types";
import palette from "theme/palette";
import StatisticsBarChart from "./StatisticsBarChart";

const data = [
  { salaryGroup: "1-199", value: 5000 },
  { salaryGroup: "200-699", value: 9800 },
  { salaryGroup: "700-1499", value: 10300 },
  { salaryGroup: "1500-3499", value: 2200 },
  { salaryGroup: "3500-8000", value: 1700 }
];
const SalaryBarChart = ({ ...SalaryBarChartProps }) => {
  const formattedData = data?.map((item) => ({
    ...item,
    salaryRounded: Math.round(Math.abs(item.value / 1000))
  }));

  return (
    <StatisticsBarChart
      data={formattedData}
      activeBarColor={palette.variables.darkPurple2}
      tooltipValue="value"
      barColor={palette.variables.lightPink}
      minHeight={280}
      {...SalaryBarChartProps}
    />
  );
};
SalaryBarChart.propTypes = {
  salaryData: instanceOf(Array).isRequired
};

export default SalaryBarChart;
