import React, { useEffect, useState } from "react";
import {
  Box, Card, CardContent, CircularProgress, Typography
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { instanceOf, string } from "prop-types";
import ProductService from "services/ProductService";
import { failureToast } from "utils";
import palette from "theme/palette";
import { useTranslation } from "react-i18next";
import Carousel from "components/Carousel/Carousel";

const categoryFieldFilter = {
  creditCard: {
    fields: ["Interest Rate", "Annual Fee"]
  },
  personalLoan: {
    fields: ["Interest Rate", "Administration Fee"]
  },
  carLoan: {
    fields: ["Interest Rate", "Administration Fee"]
  },
  depositAccount: {
    fields: ["Minimum Deposit", "Interest Rate"]
  },
  savingsAccount: {
    fields: ["Card Replacement Fee", "Minimum Balance"]
  },
  mazayaLoan: {
    fields: ["Interest Rate", "Administration Fee"]
  },
  prepaidCard: {
    fields: ["Issuing Fees", "Maximum Balance"]
  }
};

const ProductsByCategoryCards = ({ categoryId, name, categoryItem }) => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState();
  const { t } = useTranslation();
  const nameNoSpaces = name.replace(/\s/g, "");
  const nameKey = nameNoSpaces.charAt(0).toLowerCase() + nameNoSpaces.slice(1);
  //  console.log(nameNoSpaces);

  const { fields } = categoryFieldFilter[nameKey];

  const fetchProductsByCategory = async (params) => {
    try {
      setIsLoading(true);
      const response = await ProductService.getCategoryProducts({ params });
      setProducts(response);
      setIsLoading(false);
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      setIsLoading(false);
      failureToast(`Error loading user products  ${err.msg}`);
    }
  };

  useEffect(() => {
    searchParams.set("categoryId", categoryId);
    searchParams.set("providerId", providerId);
    fetchProductsByCategory({ queryParams: searchParams.toString() });
  }, []);

  const findMainFields = (mainFields, index) => {
    const selectedFields = [];
    categoryItem?.mainFields?.forEach((field) => fields.forEach((item) => {
      if (field.label === item) {
        selectedFields.push(field.id);
      }
    }));

    let mainField = "";
    if (selectedFields[index] in mainFields) {
      const key = selectedFields[index];
      mainField = mainFields[key];
    }
    return mainField;
  };

  if (isLoading) return <CircularProgress />;

  if (!products?.length) return <Typography variant="newBody1">{t("admin.noProductsAvailable")}</Typography>;

  return (
    <Carousel>
      { products?.map((product) => (
        <Card
          key={product.id}
          sx={{
            borderRadius: "25px", padding: 1, marginBottom: 1, width: "90%", height: "162px"
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={2}>
              <Typography lineHeight="25px" variant="newSubtitle" color={palette.variables.darkPurple2}>{product.name}</Typography>
              <img src={product.imageUrl} width="80px" height="54px" alt="logo" style={{ objectFit: "contain" }} />
            </Box>
            <Box sx={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}
            >
              <Box sx={{
                display: "flex", flexDirection: "column", alignItems: "start"
              }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }} mb={1}>
                  <Typography variant="newBody1" mr={1}>
                    {fields[0]}
                  </Typography>
                  <Typography variant="newSubtitle" color={palette.variables.darkPurple}>
                    {findMainFields(product.mainFields, 0) || "N/A"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="newBody1" mr={1}>
                    {fields[1]}
                  </Typography>
                  <Typography variant="newSubtitle" color={palette.variables.darkPurple}>
                    {findMainFields(product.mainFields, 1) || "N/A"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end", height: "45px" }}>
                <Typography
                  onClick={() => navigate(`/products/${product.id}`)}
                  color={palette.variables.lightGreen}
                  sx={{
                    fontSize: "10px", fontWeight: 700, lineHeight: "12px", cursor: "pointer"
                  }}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )) }
    </Carousel>
  );
};

ProductsByCategoryCards.propTypes = {
  categoryId: string.isRequired,
  categoryItem: instanceOf(Object).isRequired,
  name: string.isRequired
};

export default ProductsByCategoryCards;
