import { createAsyncThunk } from "@reduxjs/toolkit";
import { failureToast } from "utils";
import GuidebookCategoryService from "services/GuidebookCategory";
import ExpertsService from "services/ExpertsService";

export const loadGuidebookTopics = createAsyncThunk("getGuidebookTopics", async (params, thunkAPI) => {
  try {
    const response = await GuidebookCategoryService.getGuidebookTopics(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadGuidebookTopicById = createAsyncThunk("getGuidebookTopicyById", async (params, thunkAPI) => {
  const { id } = params;
  try {
    const response = await GuidebookCategoryService.getGuidebookTopicById(id);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const createGuidebookTopic = createAsyncThunk(
  "postGuidebookTopic",
  async (params, thunkAPI) => {
    try {
      const response = await GuidebookCategoryService.setDataList({ params: params.params });
      if (params.cb) params.cb(response);

      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Topic could not be created");

      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const editGuidebookTopic = createAsyncThunk(
  "editGuidebookTopic",
  async (params, thunkAPI) => {
    try {
      const response = await GuidebookCategoryService.editDataById({ params });
      if (params.cb) params.cb(response);

      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Topic could not be updated");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deleteGuidebookTopic = createAsyncThunk(
  "deleteGuidebookTopic",
  async (params, thunkAPI) => {
    try {
      const response = await GuidebookCategoryService.deleteDataById({ params });
      if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Topic could not be deleted");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getTopicLinksById = createAsyncThunk(
  "getLinksForTopic",
  async (params, thunkAPI) => {
    try {
      const response = await GuidebookCategoryService.getDataById({ params });
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const loadTopicsByExpertId = createAsyncThunk(
  "getTopicsByExpertId",
  async (params, thunkAPI) => {
    const payload = params.params;
    try {
      const response = await ExpertsService.getTopicsByExpertId(payload.id);
      if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Topics could not be loaded");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
