import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BaseButton from "components/formControl/baseButton/BaseButton";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import palette from "theme/palette";
import * as Yup from "yup";
import { Formik } from "formik";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import BaseSelect from "components/formControl/baseSelect/BaseSelect";
import { createNotifications, loadNotificationsTypes } from "store/actions/notifications";
import { successToast } from "utils";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoadingNotificationsSelector, getNotificationsTypesSelector } from "store/selectors/notifications";
import PageLoader from "components/PageLoader/PageLoader";
import { capitalizeFirstLetter } from "utils/functions";

const NotificationsCreateAdminPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  // const navigate = useNavigate()
  const dispatch = useDispatch();

  const notificationTypes = useSelector(getNotificationsTypesSelector);
  const isLoadingTypes = useSelector(getIsLoadingNotificationsSelector);

  useEffect(() => {
    dispatch(loadNotificationsTypes());
  }, []);

  const cleanNotificationTypes = () => {
    const notificationTypeList = notificationTypes?.map(({ name }) => ({
      id: name,
      title: capitalizeFirstLetter(name?.toLowerCase(), "_"),
      value: name
    }));
    return notificationTypeList;
  };

  const initialState = {
    title: "",
    body: "",
    notificationType: 1,
    deeplinkId: ""
  };

  const NotificationsCreateSchema = Yup.object().shape({
    title: Yup.string().required(t("validation.required")),
    body: Yup.string().required(t("validation.required")),
    notificationType: Yup.string().required(t("validation.required"))
  });

  const handleSubmit = async (values, { resetForm }) => {
    const notification = {
      params: {
        title: values.title,
        body: values.body,
        deeplinkId: values.deeplinkId,
        notificationType: notificationTypes.find((n) => n.name === values.notificationType)?.name
      },
      cb: () => {
        successToast("Notification has sent.");
        if (+id === 0) {
          resetForm && resetForm();
          // navigate(`/dashboard`, { replace: true })
        }
      }
    };
    try {
      if (+id === 0) {
        await dispatch(createNotifications(notification));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  if (isLoadingTypes) return <PageLoader loading />;

  return (
    <Box mt={2}>
      <Box sx={{
        display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3
      }}
      >
        <Typography variant="h3">Add New Push Notification</Typography>
      </Box>
      <Formik
        initialValues={initialState}
        validationSchema={NotificationsCreateSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          values, errors, handleChange, handleSubmit, setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box component="div">
              <BaseSelect
                name="type"
                label={t("fields.type")}
                items={cleanNotificationTypes()}
                initvalue={values.notificationType}
                sx={{
                  width: "100%", marginBottom: 0, ml: 0, mb: 3
                }}
                onChange={(value) => {
                  setFieldValue("notificationType", value);
                }}
              />
              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: "24px" }}
                margin="normal"
                fullWidth
                id="title"
                label={t("fields.title")}
                name="title"
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
                value={values.title}
                color={!errors.title && values.title ? "success" : ""}
              />

              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: "24px" }}
                margin="normal"
                fullWidth
                id="body"
                label={t("fields.body")}
                name="body"
                onChange={handleChange}
                error={!!errors.body}
                helperText={errors.body}
                value={values.body}
                color={!errors.body && values.body ? "success" : ""}
              />

              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: "24px" }}
                margin="normal"
                fullWidth
                id="deeplinkId"
                label={t("fields.deeplinkId")}
                name="deeplinkId"
                onChange={handleChange}
                error={!!errors.deeplinkId}
                helperText={errors.deeplinkId}
                value={values.deeplinkId}
                color={!errors.deeplinkId && values.deeplinkId ? "success" : ""}
              />
              {/* <DisablePermissions permission={'write:partner'} disable> */}
              <BaseButton
                customColor={palette.variables.darkPurple}
                type="submit"
                fullWidth
                variant="contained"
                element={t("send")}
                sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
              />
              {/* </DisablePermissions> */}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default NotificationsCreateAdminPage;
