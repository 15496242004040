import React, { useState } from "react";
import {
  Box, IconButton, ToggleButton, Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DisablePermissions } from "components/DisablePermissions";
import { useNavigate } from "react-router-dom";
import ToggleButtonCustom from "components/formControl/toggleButton/ToggleButton";
import CreatedByModal from "components/modal/CreatedByModal";
import { useSelector } from "react-redux";
import { selectLanguage } from "store/app/appSlice";
import palette from "theme/palette";
import {
  bool, func, instanceOf, string
} from "prop-types";
import { Permissions } from "components/Permissions";

const AdminConfigButtons = ({
  entity,
  entityName,
  onPublish,
  onChangelang,
  onDelete,
  navigateCurrentLink,
  navigatePublishLink,
  hideDelete,
  hideVersionNumber
}) => {
  const navigate = useNavigate();
  const lang = useSelector(selectLanguage);

  const [open, setOpen] = useState(false);
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" sx={{ justifyContent: "flex-end" }}>
        <DisablePermissions disable permission={`publish:${entityName}`}>
          <ToggleButtonCustom
            text="Publish"
            disabled={entity?.version === entity?.publishedVersion
              || entity?.version === entity?.lastPublished}
            onChange={onPublish}
          />
        </DisablePermissions>
        <ToggleButton
          sx={{
            display: "flex",
            gap: "8px",
            overflow: "auto",
            marginRight: "10px",
            flexWrap: "nowrap",
            width: "max-content"
          }}
          selected={lang !== "en"}
          onChange={onChangelang}
          value="check"
        >
          {lang !== "en" ? "English" : "Arabic"}
        </ToggleButton>
        {(entity?.publishedVersion || entity?.lastModifiedBy) && (
        <>
          <ToggleButton
            sx={{
              display: "flex",
              gap: "8px",
              overflow: "auto",
              flexWrap: "nowrap",
              width: "max-content"
            }}
            onChange={() => setOpen(true)}
          >
            Created By
          </ToggleButton>
          {open && (
          <CreatedByModal
            open={open}
            handleClose={() => setOpen(false)}
            info={entity}
          />
          )}
        </>
        )}
        {!hideDelete && (
        <Box
          sx={{
            display: "flex"
          }}
        >
          <IconButton aria-label="delete" onClick={onDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2} mr={2}>
        <>
          {(entity?.version || entity?.version === 0) && (
          <Typography
            color={palette.variables.orange}
            variant="caption"
            style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
            onClick={() => navigate(navigateCurrentLink)}
          >
            {!hideVersionNumber
              ? `(Current ${entityName} ${entity?.version})`
              : "(See Current Version)"}
          </Typography>
          )}
          {(entity?.publishedVersion
        || entity?.publishedVersion === 0 || entity?.publishedAt) && (
          <Permissions permission="publish:all">
            <Typography
              color={palette.variables.orange}
              variant="caption"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(navigatePublishLink)}
            >
              {!hideVersionNumber
                ? `(Published ${entityName} ${entity?.publishedVersion || entity?.lastPublished})`
                : "(See Published Version)"}
            </Typography>
          </Permissions>
          )}
        </>
      </Box>
    </Box>
  );
};

AdminConfigButtons.propTypes = {
  entity: instanceOf(Object).isRequired,
  entityName: string,
  onPublish: func.isRequired,
  onChangelang: func.isRequired,
  navigateCurrentLink: string.isRequired,
  navigatePublishLink: string.isRequired,
  hideDelete: bool,
  hideVersionNumber: bool
};

AdminConfigButtons.defaultProps = {
  entityName: "",
  hideDelete: false,
  hideVersionNumber: false
};

export default AdminConfigButtons;
