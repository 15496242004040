import React from "react";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import StarRating from "components/StarRating/StarRating";
import ProductCategoryFormatter from "components/ProductCategoryFormatter/ProductCategoryFormatter";

export const PartnerReportTableHeaders = [
  {
    key: "name",
    label: "Product",
    Component: (props) => (
      <ImageFormatter
        imgKey="imageUrl"
        fontVariant="rowHeading"
        fontWeight={700}
        {...props}
      />
    ),
    minWidth: 180,
    textAlign: "start"
  },
  {
    key: "categoryId",
    label: "Category",
    Component: ProductCategoryFormatter,
    textAlign: "center"
  },
  {
    key: "views",
    label: "Views",
    textAlign: "center"
  },
  {
    key: "clicks",
    label: "Clicks On Apply",
    textAlign: "center"
  },
  {
    key: "rating",
    label: "Rating",
    Component: (props) => <StarRating hideText {...props} />,
    minWidth: 150,
    textAlign: "center"
  }
];
