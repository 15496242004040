import { createAsyncThunk } from "@reduxjs/toolkit";
import CustomerInsightsService from "services/CustomerInsightsService";

export const loadAgeGenderStats = createAsyncThunk("customerInsights/getAgeGender", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getAgeGenderStats();
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadNationalitiesStats = createAsyncThunk("customerInsights/getNationalities", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getNationalitiesrStats();
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadEmploymentStatusStats = createAsyncThunk("customerInsights/geEmploymentStatus", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getEmploymentStatusStats();
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadSalaryStats = createAsyncThunk("customerInsights/getSalary", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getSalaryStats();
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadApplyingUsersByBank = createAsyncThunk("customerInsights/getApplyingUsersByBank", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getApplyingUsersStats(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadApplyingUserById = createAsyncThunk("customerInsights/getApplyingUserById", async (params, thunkAPI) => {
  try {
    const response = await CustomerInsightsService.getApplyingUser(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
