import {
  createSelector
} from "reselect";

const getPartners = (state) => state.partners.partners;

const getPartnerById = (state) => state.partners.partner;

const getIsLoading = (state) => state.partners.isLoading;

export const getPartnersSummarySelector = createSelector(
  getPartners,
  (partners) => partners
);

export const getPartnerByIdSelector = createSelector(
  getPartnerById,
  (partner) => partner
);

export const getisLoadingPartnersSelector = createSelector(
  getIsLoading,
  (loading) => loading
);
