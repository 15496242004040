import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPartnerSummary } from "store/actions/partners";
import { getisLoadingPartnersSelector, getPartnersSummarySelector } from "store/selectors/partners";
import Dropdown from "components/Dropdown/Dropdown";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageLoading from "components/PageLoading";
import { useTranslation } from "react-i18next";
import styles from "../admin.module.scss";

const PartnerSelectionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partners = useSelector(getPartnersSummarySelector);
  const isLoadingPartners = useSelector(getisLoadingPartnersSelector);
  const [selectedPartner, setSelectedPartner] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadPartnerSummary({ id: "published" }));
  }, []);

  const onSelectPartner = (value) => {
    setSelectedPartner(value);
  };

  const handleContinue = () => {
    navigate(selectedPartner);
  };

  if (isLoadingPartners) return <PageLoading loading={isLoadingPartners} />;

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("statistics.partnerSelection")}</Typography>
      </Box>
      <Card sx={{
        width: "60%", height: "250px", padding: 2, borderRadius: "25px"
      }}
      >
        <CardContent>
          <Box width="100%" height="150px">
            <Dropdown
              data={partners}
              selected=""
              onChange={onSelectPartner}
              objectKey="id"
            />
          </Box>
        </CardContent>
        <CardActions>
          <Box sx={{
            display: "flex", justifyContent: "flex-end", alignItems: "center", width: " 100%"
          }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={!selectedPartner?.length}
              onClick={handleContinue}
              sx={{
                backgroundColor: "#251938 !important",
                width: "140px",
                maxHeight: "30px !important",
                height: "30px"
              }}
            >
              <Typography variant="smallTitle" sx={{ color: "#fff", fontSize: "12px" }}>
                Continue
              </Typography>
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default PartnerSelectionPage;
