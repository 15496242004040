/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { internalRoleBasedRoutes } from "utils/staticValues";
import { useSelector } from "react-redux";
import { instanceOf } from "prop-types";
import { getAuthUserSelector, getUserByIdSelector } from "store/selectors/user";

const InternalPrivateRoute = ({ children }) => {
  const [allow, setAllow] = useState(true);
  const location = useLocation();

  const { permissions } = useSelector((state) => state.permissions);
  const authUser = useSelector(getAuthUserSelector);
  const cmsUser = useSelector(getUserByIdSelector);

  console.log("private cmsUser", cmsUser);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (permissions?.length) {
      let flag = false;
      internalRoleBasedRoutes.forEach((role) => {
        const key = Object.keys(role)[0];

        // searched if there is a match between the pathname an the regex
        if (role[key]?.reg?.test(location.pathname)) {
          document.title = `${role[key]?.title && `${role[key]?.title} | `}Daleel`; // setting document title

          const all = role[key]?.permission?.map((p) => `${p.split(":")[0]}:all`); // [read:all, write:all]

          // searches if users has one fo the following permissions
          const notAllow = !permissions?.find(
            (per) => all.find((p) => p === per)
              || role[key]?.permission?.find((p) => p === per)
              || per === "admin:all"
          );
          setAllow(!notAllow); // BLOCKS pages if user is not allowed
          flag = true;
        }
      });

      if (!flag) {
        setAllow(true);
      }
    }
    if (location.pathname === "/dashboard") {
      document.title = "Dashboard | Daleel";
    } else if (location.pathname === "/settings" || location.pathname === "/stats") {
      document.title = "Daleel";
    }
  }, [location]);

  // if (isLoadingCMSUser) return <span>Loading CMS...</span>;

  return (
    authUser && authUser !== null ? (
      allow ? children
        : <Navigate to="/dashboard" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  );
};

InternalPrivateRoute.propTypes = {
  children: instanceOf(Object).isRequired
};

export default InternalPrivateRoute;
