/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { removeSpaces } from "utils/functions";
import { successToast } from "utils";
import draftjsToHtml from "draftjs-to-html";
import {
  Box, Button, CircularProgress, Typography
} from "@mui/material";
import { createFaq, deleteFaq, updateFaqByExpert } from "store/actions/faqs";
import { useParams } from "react-router-dom";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import htmlToDraft from "html-to-draftjs";
import { useTranslation } from "react-i18next";
import { bool, instanceOf } from "prop-types";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import BaseButton from "components/formControl/baseButton/BaseButton";
import { DisablePermissions } from "components/DisablePermissions";
import palette from "theme/palette";
import ExpertFaqsConfigButtons from "components/ExpertFaqsConfigButtons/ExpertFaqsConfigButtons";
import AccordionListItem from "components/AccordionListItem/AccordionListItem";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import { getCategoriesByExpertSelector } from "store/selectors/products";
import { getTopicsByExpertSelector } from "store/selectors/topics";
import { getFaqsByExpert } from "store/actions/experts";
import { getisLoadingExpertFaqsSelector } from "store/selectors/experts";
import FormRequiredText from "./FormRequiredText/FormRequiredText";

// fetxh id of new faq and keep expanded props when loading aarbic

const FaqsForm = ({
  showNewFaq, handleCloseNewFaq
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const categoriesByAuthor = useSelector(getCategoriesByExpertSelector);
  const topicsByAuthor = useSelector(getTopicsByExpertSelector);
  const {
    expertFaqs: faqItems
  } = useSelector((state) => state.experts);
  const loadingFaqs = useSelector(getisLoadingExpertFaqsSelector);
  const [isLoading, setIsLoading] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [expanded, setExpanded] = useState();
  console.log("LOCAL", localStorage.getItem("faqId"));

  useEffect(() => {
    setExpanded(localStorage.getItem("faqId"));
    dispatch(getFaqsByExpert({ authorId: id }));
  }, []);

  const FaqSchema = Yup.object().shape({
    question: Yup.string().required(t("validation.required"))
  });

  const renderEditorContent = (answer) => {
    const contentBlock = htmlToDraft(`${answer}`);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
  };

  const handleCreateFaq = (values) => {
    const {
      question, answer, categoryId, topics
    } = values;

    const domElAnswer = draftjsToHtml(convertToRaw(answer.getCurrentContent()))?.toString();
    const emptyEditor = domElAnswer.includes("<p></p>") || domElAnswer.includes("<p style=\"margin-left:4px;\"></p>");
    const errorObj = {
      ...formErrors,
      ...(!categoryId && !topics?.length && { expertise: true }),
      ...(emptyEditor && { answer: true })

    };
    setFormErrors(errorObj);

    if (Object.values(errorObj).some((item) => item)) return;
    dispatch(
      createFaq({
        params: {
          question,
          answer: removeSpaces(
            draftjsToHtml(convertToRaw(values.answer.getCurrentContent()))
          ),
          authorId: id,
          categoryId: categoryId || "",
          topics: topics || []
        },
        cb: (res) => {
          successToast("Faq has been created");
          setIsLoading(true);
          handleCloseNewFaq();
          setTimeout(() => {
            // const cleanItems = faqItems.filter((item) => item.id !== res.id);
            // setFaqItems([...cleanItems, res]);
            dispatch(getFaqsByExpert({ authorId: id }));
            setIsLoading(false);
          }, 1000);
        }
      })
    );
  };

  const handleUpdateFaq = (values) => {
    const {
      question, answer, categoryId, topics
    } = values;
    const domElAnswer = draftjsToHtml(convertToRaw(answer.getCurrentContent()))?.toString();
    const emptyEditor = domElAnswer.includes("<p></p>") || domElAnswer.includes("<p style=\"margin-left:4px;\"></p>");
    const errorObj = {
      ...formErrors,
      ...(!categoryId && !topics?.length && { expertise: true }),
      ...(emptyEditor && { answer: true })

    };
    setFormErrors(errorObj);
    console.log(errorObj);

    if (Object.values(errorObj).some((item) => item)) return;

    dispatch(
      updateFaqByExpert({
        id: values.id,
        params: {
          question,
          answer: removeSpaces(
            draftjsToHtml(convertToRaw(values.answer.getCurrentContent()))
          ),
          authorId: id,
          categoryId: categoryId || "",
          topics: values.topics
        },
        cb: () => {
          successToast("Faq has been updated");
          setIsLoading(true);
          setTimeout(() => {
            dispatch(getFaqsByExpert({ authorId: id }));
            setIsLoading(false);
          }, [1000]);
        }
      })
    );
    localStorage.removeItem("faqId");
    setExpanded(false);
  };

  const onDeleteFaq = (faq) => {
    localStorage.removeItem("faqId");
    setExpanded(false);
    dispatch(
      deleteFaq({
        id: faq.id,
        cb: async () => {
          successToast("FAQ has been successfully deleted.");
          setIsLoading(true);
          setTimeout(() => {
            dispatch(getFaqsByExpert({ authorId: id }));
            setIsLoading(false);
          }, [1000]);
        }
      })
    );
  };

  if (loadingFaqs || isLoading) {
    return <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>;
  }

  if (!categoriesByAuthor.length && !topicsByAuthor.length) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="smallTitle">
          You cannot create/edit an FAQ
          if the expert has no associated categories/topics linked to it
        </Typography>
      </Box>
    );
  }

  return (
    <>
      { showNewFaq && (
      <Formik
        initialValues={{
          question: "",
          categoryId: "",
          topics: [],
          answer: EditorState.createEmpty()
        }}
        validationSchema={FaqSchema}
        onSubmit={handleCreateFaq}
      >
        {({
          values, handleChange, handleSubmit, errors, setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <AccordionListItem
                hideItem
                expanded
              >
                <Box mb={3}>
                  <BaseTextField
                    placeholder={t("fields.someText")}
                    fullWidth
                    id="faq_question_1"
                    label={t("fields.question")}
                    name="question"
                    onChange={handleChange}
                    value={values.question}
                    error={!!errors.question}
                    helperText={errors.question}
                    color={!errors.question && values.question ? "success" : ""}
                  />
                </Box>
                <RichTextEditor
                  value={values.answer}
                  onChange={(editorState) => {
                    setFieldValue("answer", editorState);
                    setFormErrors({ ...formErrors, answer: false });
                  }}
                  error={formErrors.answer}
                />
                <Box mt={2}>
                  <Typography variant="smallTitle">
                    {t("admin.productCategory")}
                  </Typography>
                  <Box mt={2}>
                    {
                      categoriesByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={categoriesByAuthor}
                          exclusive
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "categoryId", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={values._tags}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No categories available for this author</Typography>
                      )
                    }
                  </Box>
                </Box>
                <Box mt={1}>
                  <Typography variant="smallTitle">
                    {t("fields.topics")}
                  </Typography>
                  <Box mt={2}>
                    {
                      topicsByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={topicsByAuthor}
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "topics", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={values._tags}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No topics available for this author</Typography>
                      )
                    }
                  </Box>
                  {Boolean(formErrors.expertise) && (
                    <FormRequiredText>At least one category or topic is required</FormRequiredText>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DisablePermissions permission="write:faq" disable>
                    <BaseButton
                      customColor={palette.variables.darkPurple}
                      type="submit"
                      fullWidth
                      variant="contained"
                      element={t("save")}
                      sx={{
                        display: "block",
                        maxWidth: "100px",
                        margin: 2,
                        marginRight: 0,
                        marginLeft: 0
                      }}
                    />
                  </DisablePermissions>
                  <Button
                    color={palette.variables.darkPurple}
                    sx={{ backgroundColor: "#F2F2F2", height: "35px", marginLeft: 1 }}
                    onClick={handleCloseNewFaq}
                    variant="newButton"
                  >
                    Cancel
                  </Button>
                </Box>
              </AccordionListItem>
            </Box>
          </form>
        )}
      </Formik>
      )}
      { faqItems?.map((faq, index) => (
        <Formik
          key={faq.id}
          initialValues={{
            id: faq.id,
            question: faq.question,
            categoryId: faq.categoryId || "",
            topics: faq.topics,
            answer: renderEditorContent(faq.answer)
          }}
          validationSchema={FaqSchema}
          onSubmit={handleUpdateFaq}
        >
          {({
            values, handleChange, handleSubmit, errors, setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <AccordionListItem
                  item={faq}
                  itemNum={index + 1}
                  expanded={expanded === faq.id}
                  onChange={(event, isExpanded) => {
                    setExpanded(isExpanded ? faq.id : false);
                    localStorage.removeItem("faqId");
                  }}
                >
                  <Box mb={3}>
                    <BaseTextField
                      placeholder={t("fields.someText")}
                      fullWidth
                      id={`faq_question_${index}`}
                      label={t("fields.question")}
                      name="question"
                      onChange={handleChange}
                      value={values.question}
                      error={!!errors.question}
                      helperText={errors.question}
                      color={!errors.question && values.question ? "success" : ""}
                    />
                  </Box>
                  <RichTextEditor
                    value={values.answer}
                    onChange={(editorState) => {
                      setFieldValue("answer", editorState);
                      setFormErrors({ ...formErrors, answer: false });
                    }}
                    error={formErrors.answer}
                  />
                  <Box mt={2}>
                    <Typography variant="smallTitle">
                      {t("admin.productCategory")}
                    </Typography>
                    <Box mt={2}>
                      { categoriesByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={categoriesByAuthor}
                          exclusive
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "categoryId", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={[values.categoryId]}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No categories available for this author</Typography>
                      )}
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="smallTitle">
                      {t("fields.topics")}
                    </Typography>
                    <Box mt={2}>
                      {
                        topicsByAuthor?.length ? (
                          <MultipleSelectionToggleBtn
                            data={topicsByAuthor}
                            onChange={(event, value) => {
                              handleChange({
                                ...event,
                                target: { name: "topics", value }
                              });
                              setFormErrors({ ...formErrors, expertise: false });
                            }}
                            selected={values.topics}
                          />
                        ) : (
                          <Typography variant="smallTitle" fontWeight={400}>No topics available for this author</Typography>
                        )
                    }
                    </Box>
                    {Boolean(formErrors.expertise) && (
                    <FormRequiredText>At least one category or topic is required</FormRequiredText>
                    )}
                  </Box>
                  <ExpertFaqsConfigButtons
                    faq={faq}
                    onDelete={onDeleteFaq}
                  />
                </AccordionListItem>
              </Box>
            </form>
          )}
        </Formik>
      ))}
    </>

  );
};

FaqsForm.propTypes = {
  showNewFaq: bool
};

FaqsForm.defaultProps = {
  showNewFaq: false
};

export default FaqsForm;
