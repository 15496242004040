import { Box, Typography } from "@mui/material";
import React from "react";

const BehaviourQuestionFormatter = ({ children }) => (
  <Box sx={{
    width: "100%", textAlign: "start"
  }}
  >
    <Typography variant="newBody2">{children}</Typography>
  </Box>
);

export default BehaviourQuestionFormatter;
