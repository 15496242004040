/* eslint-disable react/jsx-key */
import React from "react";
import {
  Box, Card, CardContent, Typography
} from "@mui/material";
import VerticalBarChart from "components/Reports/VerticalBarChart";
import { number, string } from "prop-types";
import palette from "theme/palette";
import { ReactComponent as PlaneIcon } from "assets/icons/icon-plane.svg";
import { ReactComponent as BillIcon } from "assets/icons/icon-bills.svg";
import { ReactComponent as ShoppingIcon } from "assets/icons/icon-shopping.svg";
import { ReactComponent as HomeIcon } from "assets/icons/icon-home.svg";
import { ReactComponent as CoffeeIcon } from "assets/icons/icon-coffee.svg";

const ageChartData = [
  {
    ageGroup: "15-19",
    value: 10
  },
  {
    ageGroup: "20-24",
    value: 12
  },
  {
    ageGroup: "25-29",
    value: 15
  },
  {
    ageGroup: "30-34",
    value: 15
  },
  {
    ageGroup: "35-39",
    value: 8
  },
  {
    ageGroup: "40-44",
    value: 10
  },
  {
    ageGroup: "45-49",
    value: 16
  },
  {
    ageGroup: "50+",
    value: 14
  }
];

const iconsLists = [
  <CoffeeIcon />, <PlaneIcon />, <ShoppingIcon />, <BillIcon />, <HomeIcon />];

const CategorisedSpendingCard = ({ title, percentage, id }) => (
  <Card sx={{ borderRadius: "12.5px", height: "300px" }}>
    <CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="smallTitle">{title}</Typography>
        {iconsLists[id]}
      </Box>
      <Box mt={2}>
        <Box mb={2}>
          <Typography
            variant="newSubtitle"
            fontSize="18px"
            lineHeight="25px"
            mr={1}
          >
            {`${percentage}%`}
          </Typography>
          <Typography variant="newBody1">in total</Typography>
        </Box>
        <Box>
          <Typography variant="newBody1">By age groups</Typography>
          <Box mt={1}>
            <VerticalBarChart
              data={ageChartData}
              xKey="ageGroup"
              yKey="value"
              barColor={palette.variables.lightPink}
              activeBarColor={palette.variables.darkPurple2}
              minHeight={150}
              margin={{ left: -20, right: 25 }}
              multiplierDist={[7, 6.1]}
            />
          </Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

CategorisedSpendingCard.propTypes = {
  title: string.isRequired,
  percentage: number.isRequired
};

export default CategorisedSpendingCard;
