import React from "react";
import { Chip, Typography } from "@mui/material";
import { ddmmyyyy } from "utils/functions";
import { string } from "prop-types";
import palette from "theme/palette";

const ProductPublishedDateFormatter = ({ children }) => {
  const publishedDate = children || null;
  return (
    publishedDate ? <Typography variant="newBody1">{ddmmyyyy(new Date(children))}</Typography>
      : (
        <Chip
          size="small"
          label="Not Published"
          sx={{
            width: "100%",
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "14px",
            backgroundColor: palette.variables.orange,
            color: "#ffff"
          }}
        />
      )

  );
};

ProductPublishedDateFormatter.propTypes = {
  children: string
};

ProductPublishedDateFormatter.defaultProps = {
  children: null
};

export default ProductPublishedDateFormatter;
