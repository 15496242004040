import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import palette from "theme/palette";

// COMPONENTS
import AdminConfigButtons from "components/AdminConfigButtons/AdminConfigButtons";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import BaseButton from "components/formControl/baseButton/BaseButton";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import { DisablePermissions } from "components/DisablePermissions";
import {
  createTermsAndConditions,
  editTermsAndConditions,
  loadTermsAndConditionsById,
  loadTermsAndConditionsCheckFlag
} from "store/actions/termsAndConditions";
import { removeSpaces } from "utils/functions";
import draftToHtml from "draftjs-to-html";
import {
  getIsAvailableTCVersionSelector,
  getIsLoadingTCFlagSelector,
  getTermsAndConditionsSelector,
  getisLoadingTermsAndConditionsSelector
} from "store/selectors/termsAndConditions";
import PageLoader from "components/PageLoader/PageLoader";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import { successToast } from "utils";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = useSelector(selectLanguage);
  const isAvailableTermsAndConditions = useSelector(getIsAvailableTCVersionSelector);
  const isLoadingTC = useSelector(getisLoadingTermsAndConditionsSelector);
  const isLoadingTCFlag = useSelector(getIsLoadingTCFlagSelector);
  const termsAndConditions = useSelector(getTermsAndConditionsSelector);
  const { metaInfo } = termsAndConditions;

  const [isLoading, setIsLoading] = useState();

  const [formValue, setFormValue] = useState({
    version: "",
    consentText: EditorState.createEmpty()
  });

  useEffect(() => {
    dispatch(loadTermsAndConditionsCheckFlag());
  }, [lang]);

  useEffect(() => {
    if (isAvailableTermsAndConditions?.flag) {
      dispatch(loadTermsAndConditionsById({ id: isAvailableTermsAndConditions.id }));
    }
  }, [lang, isAvailableTermsAndConditions?.id]);

  useEffect(() => {
    if (Object.keys(termsAndConditions)?.length) {
      const txt = termsAndConditions?.consentText || "";
      const contentBlock = htmlToDraft(txt);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setFormValue({
        version: termsAndConditions.version,
        consentText: EditorState.createWithContent(contentState)
      });
    }
  }, [termsAndConditions]);

  const handleSubmit = () => {
    const params = {
      ...formValue,
      consentText: removeSpaces(draftToHtml(
        convertToRaw(formValue.consentText.getCurrentContent())
      ))
    };

    dispatch(createTermsAndConditions({
      params,
      cb: (res) => {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(loadTermsAndConditionsById({ id: res.id }));
          setIsLoading(false);
        }, [1000]);
        successToast("Terms and conditions have been succesfully updated");
      }
    }));
  };

  if (isLoading || isLoadingTC || isLoadingTCFlag) return <PageLoader />;

  return (
    <Box mt={2}>
      <Box sx={{
        display: "flex", justifyContent: "space-between", width: "100%"
      }}
      >
        <Typography variant="newTitle" color={palette.variables.darkPurple2}>
          {t("admin.termsAndConditions")}
        </Typography>
        <AdminConfigButtons
          entity={{
            ...termsAndConditions,
            publishedBy: metaInfo?.publishedBy,
            publishedAt: metaInfo?.publishedAt,
            lastModifiedBy: metaInfo?.lastModifiedBy,
            lastModifiedAt: metaInfo?.lastModifiedAt
          }}
          entityName="termsAndConditions"
          hideVersionNumber
          onPublish={() => dispatch(editTermsAndConditions({
            id: `${termsAndConditions.id}/publish`,
            cb: () => {
              setIsLoading(true);
              setTimeout(() => {
                dispatch(loadTermsAndConditionsById({ id: isAvailableTermsAndConditions.id }));
                navigate("/terms-and-conditions");
                setIsLoading(false);
              }, [800]);
              successToast("New version of terms and conditions has been published.");
            }
          }))}
          onChangelang={() => {
            dispatch(setLanguage(lang === "en" ? "ar" : "en"));
            navigate("/terms-and-conditions");
          }}
          onDelete={() => {}}
          navigateCurrentLink={`/terms-and-conditions/published/${termsAndConditions?.id}/current`}
          navigatePublishLink={`/terms-and-conditions/published/${termsAndConditions?.lastPublishedId}/published`}
          hideDelete
        />
      </Box>
      <Box>
        <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
          Version
        </Typography>
        <BaseTextField
          InputLabelProps={{ required: false }}
          sx={{ marginBottom: "24px" }}
          disabled={lang === "ar"}
          margin="normal"
          fullWidth
          id="version"
          name={t("fields.version")}
          onChange={(event) => setFormValue({
            ...formValue,
            version: event.target.value
          })}
          value={formValue?.version}
        />
      </Box>
      <Box>
        <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
          Consent Text
        </Typography>
        <RichTextEditor
          value={formValue?.consentText}
          onChange={(editorState) => {
            setFormValue({
              ...formValue,
              consentText: editorState
            });
          }}
        />
        {isAvailableTermsAndConditions?.flag && (
        <Box display="flex" justifyContent="flex-end" mr={2} mt={1}>
          <Typography
            color={palette.variables.lightGreen}
            variant="newCaption"
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            {`${t("settings.lastVersionSaved")}: ${termsAndConditions?.version}`}
          </Typography>
          {termsAndConditions?.lastPublished && (
          <Typography
            color={palette.variables.orange}
            variant="newCaption"
          >
            {`${t("settings.lastVersionPublished")}: ${termsAndConditions.lastPublished}`}
          </Typography>
          )}

        </Box>
        )}

      </Box>
      <Box>
        <DisablePermissions permission="write:partner" disable>
          <BaseButton
            customColor={palette.variables.darkPurple}
            onClick={handleSubmit}
            type="submit"
            fullWidth
            variant="contained"
            element={t("save")}
            sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
          />
        </DisablePermissions>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsDetailsPage;
