import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { bool } from "prop-types";
import palette from "../theme/palette";

const PageLoading = ({ loading }) => (
  <Backdrop
    sx={{
      color: palette.variables.lightGreen,
      backgroundColor: "rgb(242 242 242 / 50%)",
      zIndex: (theme) => theme.zIndex.drawer + 1
    }}
    open={loading}
  >
    <CircularProgress
      sx={{ height: "80px !important", width: "80px !important" }}
      color="inherit"
    />
  </Backdrop>
);

export default PageLoading;

PageLoading.propTypes = {
  loading: bool.isRequired
};
