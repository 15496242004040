import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box, IconButton, InputBase, MenuItem, Paper, TextField, Typography
} from "@mui/material";
import { getMonth, getYear } from "date-fns";
import { bool, func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ddmmyyyy } from "utils/functions";
import { MONTHS, YEARS } from "utils/staticValues";

const DatePickert = ({
  onChangeDate, customInput, showInput, dateValue, showCustomHeader, ...DatePickerProps
}) => {
  // const [date, setDate] = useState([null, null]);
  const [startDate, setStartDate] = useState(dateValue[0]);
  const [endDate, setEndDate] = useState(dateValue[1]);

  useEffect(() => {
    if (dateValue[0] === null && dateValue[1] === null) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [dateValue]);

  const years = YEARS;
  const months = MONTHS;

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onChangeDate) {
      onChangeDate([start, end]);
    }
  };

  const renderHeader = ({
    date,
    changeYear,
    customHeaderCount,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <IconButton
        size="small"
        style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <TextField
        select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
        sx={{ marginTop: 0, fontSize: "11px !important" }}
        inputProps={{ style: { borderBottom: "none !important", borderBottomColor: "#fff !important" } }}
      >
        {years.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ marginTop: 0, fontSize: "11px !important" }}
        select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <IconButton
          // style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
        size="small"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );

  return (
    <>
      {showInput && (
        <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }} mb={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="newBody1" mr={1}>From</Typography>
            <Paper
              variant="outlined"
              elevation={0}
              sx={{
                borderRadius: 8,
                backgroundColor: "#FFFFFF",
                width: "100px",
                height: "29px",
                p: "2px",
                alignItems: "center",
                display: "flex",
                paddingX: 2,
                borderColor: "#C6C6C6 !important"
              }}
            >
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  fontSize: "11px",
                  lineHeight: "14px"
                }}
                value={ddmmyyyy(startDate) || ""}
                readOnly
              />
            </Paper>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="newBody1" mr={1}>To</Typography>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                alignItems: "center",
                display: "flex",
                borderRadius: 8,
                backgroundColor: "#FFFFFF",
                width: "100px",
                height: "29px",
                p: "2px",
                paddingX: 2,
                borderColor: "#C6C6C6 !important"
              }}
            >
              <InputBase
                sx={{
                  ml: 1, flex: 1, fontSize: "11px", lineHeight: "14px"
                }}
                value={ddmmyyyy(endDate) || ""}
                readOnly
              />
            </Paper>
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <DatePicker
          formatWeekDay={(day) => day.toDateString().substring(0, 1)}
          monthsShown={1}
          selectsRange
          maxDate={new Date()}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          customInput={customInput}
          {...(showCustomHeader && { renderCustomHeader: renderHeader })}
          {...DatePickerProps}
        />
      </Box>
    </>
  );
};

DatePickert.propTypes = {
  onChangeDate: func.isRequired,
  customInput: instanceOf(Object),
  showInput: bool,
  dateValue: instanceOf(Array),
  showCustomHeader: bool
};

DatePickert.defaultProps = {
  customInput: null,
  showInput: false,
  dateValue: [null, null],
  showCustomHeader: false
};

export default DatePickert;
