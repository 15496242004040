import React, { useEffect, useState } from "react";
import { bool, string } from "prop-types";
import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import palette from "../../theme/palette";

const ValidationCheckbox = ({
  label, textHelper, defaultChecked, error, ...rest
}) => {
  const [formError, setFormError] = useState(error);

  useEffect(() => {
    if (error) {
      setFormError(textHelper);
    }
  }, [error]);

  return (
    <div>
      <FormControlLabel
        value="start"
        control={(
          <Checkbox
            checked={defaultChecked}
            color={!error ? "coolWhite" : "error"}
            sx={{ paddingTop: 0 }}
            {...rest}
          />
          )}
        label={label}
        labelPlacement="end"
      />
      <Typography
        color={palette.pink["500"]}
        variant="caption"
        align="left"
        display="block"
        gutterBottom
      >
        {formError}
      </Typography>
    </div>
  );
};

ValidationCheckbox.propTypes = {
  textHelper: string,
  label: string,
  error: string,
  defaultChecked: bool.isRequired
};

ValidationCheckbox.defaultProps = {
  error: null,
  label: "",
  textHelper: ""
};

export default ValidationCheckbox;
