import { createAsyncThunk } from "@reduxjs/toolkit";
import { failureToast } from "utils";
import TermsAndConditionsService from "services/TermsAndConditionsService";

export const loadTermsAndConditionsById = createAsyncThunk(
  "loadTermsAndConditions",
  async (params, thunkAPI) => {
    try {
      const response = await TermsAndConditionsService.getTermsAndConditionsById({ params });
      //  if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Terms and conditions could not be loaded");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const loadAllTermsAndConditions = createAsyncThunk(
  "loadTermsAndConditionsList",
  async (params, thunkAPI) => {
    try {
      const response = await TermsAndConditionsService.getAllTermsAndConditions();
      // if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      //  failureToast("Terms and conditions could not be loaded");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const loadTermsAndConditionsCheckFlag = createAsyncThunk(
  "loadTermsAndConditionsLastSaved",
  async (params, thunkAPI) => {
    try {
      const response = await TermsAndConditionsService.getTermsAndConditionsLastSavedFlag();
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const editTermsAndConditions = createAsyncThunk(
  "editTermsAndConditions",
  async (params, thunkAPI) => {
    try {
      const response = await TermsAndConditionsService.editDataById({ params });
      if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Terms and conditions could not be updated");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createTermsAndConditions = createAsyncThunk(
  "createTermsAndConditions",
  async (params, thunkAPI) => {
    try {
      const response = await TermsAndConditionsService.postTermsAndConditions({ params });
      if (params.cb) params.cb(response);
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      failureToast("Terms and conditions could not be created");
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
