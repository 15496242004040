import React from "react";
import palette from "theme/palette";
import StatisticsBarChart from "./StatisticsBarChart";

const NationalitiesBarChart = ({ nationalitiesData, ...NationalitiesBarChartProps }) => {
  const formatData = nationalitiesData?.map((item) => ({
    ...item,
    quantityRounded: Math.round(Math.abs(item.quantity / 1000))
  }));

  return (
    <StatisticsBarChart
      data={formatData}
      activeBarColor={palette.variables.darkPurple2}
      tooltipValue="quantity"
      barColor={palette.variables.lightPink}
      minHeight={280}
      {...NationalitiesBarChartProps}
    />
  );
};

export default NationalitiesBarChart;
