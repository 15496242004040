import React from "react";
import DateToStringFormatter from "components/DateToStringFormatter/DateToStringFormatter";
import UserPointsFormatter from "components/UserPointsFormatter/UserPointsFormatter";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";

export const UserPointsListHeaders = [
  {
    key: "description",
    label: "User",
    Component: (props) => (
      <ImageFormatter
        imgWidth="30px"
        fontWeight={500}
        fontVariant="newBody2"
        imgKey="iconUrl"
        {...props}
      />
    ),
    minWidth: 180,
    textAlign: "start"
  },
  {
    key: "dateTime",
    label: "Date",
    textAlign: "center",
    minWidth: 120,
    Component: DateToStringFormatter
  },
  {
    key: "points",
    label: "Points",
    textAlign: "end",
    minWidth: 120,
    Component: UserPointsFormatter
  }
];
