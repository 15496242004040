import React from "react";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import ProductCategoriesFormatter from "components/ProductCategoryFormatter/ProductCategoriesFormatter";

export const PartnersTableHeaders = [
  {
    key: "name",
    label: "Partner",
    Component: (props) => (
      <ImageFormatter
        imgKey="logoUrl"
        fontVariant="rowHeading"
        fontWeight={700}
        tooltip="Partner details"
        navigateTo="/partners/details"
        {...props}
      />
    ),
    textAlign: "start",
    minWidth: 320
  },
  {
    key: "categoryIds",
    label: "Product Categories",
    Component: ProductCategoriesFormatter,
    textAlign: "start"
  }
];
