import React, { useRef } from "react";
import {
  Box,
  Card, CardContent, CardMedia, TextField, Typography
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setImage } from "store/actions/image";
import { useTranslation } from "react-i18next";
import {
  bool, func, instanceOf, string
} from "prop-types";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";

const FormUploadImage = ({
  lang, onChange, item, errors, disableEdit
}) => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const { t } = useTranslation();

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const url = await dispatch(setImage(file));
    return url.payload.url;
  };

  const onImageUploaded = async (event) => {
    const url = await onChangeFile(event);
    onChange("image", url);
  };
  return (
    <Card
      sx={{
        boxShadow: 0,
        display: "flex",
        alignItems: "flex-start",
        height: "170px",
        width: "500px",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <CardMedia
        style={lang === "en" ? { cursor: "pointer" } : {}}
        onClick={() => inputFile.current.click()}
        component="img"
        height="170px"
        image={item.iconUrl}
        alt="Export image"
        sx={{ maxWidth: "340px", borderRadius: 1, objectFit: "contain" }}
      />
      <input
        accept=".jpg, .png, .jpeg"
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none", objectFit: "contain" }}
        onChange={onImageUploaded}
        disabled={lang === "ar" || disableEdit}
      />
      {errors?.image && <FormRequiredText>Expert image is required</FormRequiredText>}
      <CardContent sx={{
        pt: 0, pl: { xs: 0, sm: 2 }, display: "flex", alignItems: "center", height: "170px", width: "500px !important"
      }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column"
        }}
        >
          <Typography variant="smallTitle" color="common.darkPurple">{t("fields.expertName")}</Typography>
          <TextField
            style={{ fontWeight: 700, lineHeight: "20px", fontSize: "14px" }}
            placeholder="Name"
            value={item.name}
            onChange={(event) => {
              onChange(
                "name",
                event.target.value
              );
            }}
            sx={{ mb: 3, mt: 0 }}
            variant="outlined"
            name="Name"
            helperText={errors?.name && t("validation.required")}
            color={!errors?.name && item.name ? "success" : ""}
            disabled={disableEdit}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

FormUploadImage.propTypes = {
  lang: string.isRequired,
  item: instanceOf(Object).isRequired,
  onChange: func,
  errors: instanceOf(Object),
  disableEdit: bool
};

FormUploadImage.defaultProps = {
  onChange: null,
  errors: null,
  disableEdit: false
};

export default FormUploadImage;
