import {
  createSelector
} from "reselect";

const getExperts = (state) => state.experts.experts;

const getExpert = (state) => state.experts.expert;

const getExpertUnlinkedEntities = (state) => state.experts.unlinkedEntities;

const getIsLoading = (state) => state.experts.loading;

const getIsLoadingFaqs = (state) => state.experts.loadingFaqs;

const getIsLoadingEntities = (state) => state.experts.loadingEntities;

export const getExpertsSelector = createSelector(
  getExperts,
  (experts) => experts
);

export const getExpertSelector = createSelector(
  getExpert,
  (expert) => expert
);

export const getExpertUnlinkedEntitiesSelector = createSelector(
  getExpertUnlinkedEntities,
  (unlinkedEntities) => unlinkedEntities
);

export const getisLoadingExpertsSelector = createSelector(
  getIsLoading,
  (loading) => loading
);

export const getisLoadingExpertFaqsSelector = createSelector(
  getIsLoadingFaqs,
  (loadingFaqs) => loadingFaqs
);

export const getisLoadingExpertEntitiesSelector = createSelector(
  getIsLoadingEntities,
  (loadingEntities) => loadingEntities
);
