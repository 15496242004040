import { createSlice } from "@reduxjs/toolkit";

import {
  downloadUsersReport,
  loadGeneralUsersStats,
  loadUserHealthData,
  loadUserPoints,
  loadUserRewards,
  loadUsersStats
} from "store/actions/userStats";

const initialState = {
  usersRecords: {},
  usersStats: {},
  userPoints: [],
  userRewards: {},
  userHealth: {},
  downloadedReport: "",
  filters: {
    searchString: "",
    sortBy: "name",
    sortDir: "asc",
    page: 1
  },
  loading: false,
  loadingChart: false,
  loadingGeneralStats: false
};

const userStatsSlice = createSlice({
  name: "userStats",
  initialState,
  reducers: {
    setUserFilters: (state, { payload }) => {
      state.filters = payload;
    }
  },
  extraReducers: {
    [loadUsersStats.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadUsersStats.fulfilled]: (state, { payload }) => {
      state.usersRecords = payload;
      state.loading = false;
    },
    [loadUsersStats.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadGeneralUsersStats.pending]: (state) => {
      state.loadingGeneralStats = true;
      state.error = null;
    },
    [loadGeneralUsersStats.fulfilled]: (state, { payload }) => {
      state.usersStats = payload;
      state.loadingGeneralStats = false;
    },
    [loadGeneralUsersStats.rejected]: (state, { payload }) => {
      state.loadingGeneralStats = false;
      state.error = payload;
    },
    [downloadUsersReport.pending]: (state) => {
      state.loadings = true;
      state.error = null;
    },
    [downloadUsersReport.fulfilled]: (state, { payload }) => {
      state.downloadedReport = payload;
      state.loading = false;
    },
    [downloadUsersReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadUserPoints.pending]: (state) => {
      state.error = null;
    },
    [loadUserPoints.fulfilled]: (state, { payload }) => {
      state.userPoints = payload;
      state.loading = false;
    },
    [loadUserPoints.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadUserHealthData.pending]: (state) => {
      state.loadingChart = true;
      state.error = null;
    },
    [loadUserHealthData.fulfilled]: (state, { payload }) => {
      state.userHealth = payload;
      state.loadingChart = false;
    },
    [loadUserHealthData.rejected]: (state, { payload }) => {
      state.loadingChart = false;
      state.error = payload;
    },
    [loadUserRewards.pending]: (state) => {
      state.error = null;
    },
    [loadUserRewards.fulfilled]: (state, { payload }) => {
      state.userRewards = payload;
      state.loading = false;
    },
    [loadUserRewards.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }

  }
});

export const { setUserFilters } = userStatsSlice.actions;

export default userStatsSlice.reducer;
