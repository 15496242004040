import React, { useState } from "react";
import {
  FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import { func, instanceOf, string } from "prop-types";

const Dropdown = ({
  data, onChange, objectKey, children, selected
}) => {
  const [value, setValue] = useState(selected);

  const handleChange = (event) => {
    setValue(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{children}</InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label=""
        onChange={handleChange}
       /* sx={{ "& .MuiSelect-iconOutlined": { display: value ? "none" : "" },
       "&.Mui-focused .MuiIconButton-root":{ color: "gray" } }}
        endAdornment={(
          <IconButton sx={{ visibility: value ? "visible" : "hidden" }} onClick={handleClearClick}>
            <ClearIcon />
          </IconButton>
        )} */
      >
        {
      data?.map((item) => (
        <MenuItem key={item.id} value={item[objectKey]}>{item.name}</MenuItem>
      ))
    }
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  data: instanceOf(Array).isRequired,
  children: string,
  onChange: func,
  objectKey: string.isRequired,
  selected: string
};

Dropdown.defaultProps = {
  onChange: null,
  children: "",
  selected: ""
};

export default Dropdown;
