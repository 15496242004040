import React from "react";
import { useSelector } from "react-redux";
import { instanceOf } from "prop-types";
import { Box, Chip } from "@mui/material";

const ProductCategoriesFormatter = ({ children }) => {
  const { productCategory } = useSelector((state) => state.products);

  const partnerCategories = productCategory.filter(
    (category) => children.find((item) => item === category.id)
  );

  return (
    <Box sx={{
      width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"
    }}
    >
      { partnerCategories?.map((category) => (
        <Chip
          key={category.id}
          label={category.name}
          color="success"
          sx={{
            color: "white", fontWeight: 400, fontSize: "11px", lineHeight: "14px", mr: 2
          }}
        />

      ))}
    </Box>
  );
};

ProductCategoriesFormatter.propTypes = {
  children: instanceOf(Array).isRequired
};

export default ProductCategoriesFormatter;
