import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: {
      forgot: "Forgot",
      logIn: "Log In",
      signIn: "Sign in",
      signOut: "Sign Out",
      welcomeBack: "Welcome back, you’ve been missed!",
      orLoginWith: "or Log In with",
      isNew: "New to Daleel?",
      register: "Register",
      createAccount: "Create an account to continue!",
      alreadyHavaAccount: "Already have an account?",
      next: "Next",
      buildYourProfile: "Build your profile",
      address: "Address",
      skipForNow: "Skip for now",
      step1Of2: "Step 1/2",
      step2Of2: "Step 2/2",
      connect: "Connect",
      congratulations: "Congratulations!",
      connectHealthData: "Connect health data",
      startYourWealth: "Start your wealth and health journey today!",
      youAreAllSet: "You are all set! Your financial journey starts today",
      homePage: "Home page",
      orRegisterWith: "or Register with",
      forgotPassword: "Forgot password?",
      enterYourEmail: "Enter your email to recover your password",
      recoverViaPhone: "Recover via phone number",
      backToLogin: "back to log in",
      sendLink: "Send Link",
      sendCode: "Send Code",
      recoverViaEmail: "Recover via email",
      enterYourPhone: "Enter your phone number to recover your password",
      authenticationCode: "An authentication code has been sent to",
      resend: "Resend",
      resetPassword: "Reset Password",
      atLeastCharacters: "At least 8 characters, with uppercase and lowercase letters",
      wentWrong: "Oops! Something went wrong.",
      contactSupport: "Contact Support",
      wentWrongText:
        "We encountered and error while trying to connect our server. Please retry or contact support.",
      dontReceiveCode: "Don’t receive the code",
      verify: "Verify",
      verificationCode: "Verification code",
      verificationCodeSent: "An verification code has been sent to",
      yourPasswordReset: "Your password has been successfully reset",
      done: "Done",
      error: "Error",
      somethingWentWrong: "Something went wrong. Please try again.",
      retry: "Retry",
      emailVerified: "Your email is verified",
      dataCannotConnected: "The data cannot be connected using the browser. ",
      name: "Name",
      surname: "Surname",
      yourScore: "Your Score",
      points: "Points",
      searchCompare: "Search & Compare",
      creditCard: "Credit Cards",
      personalLoans: "Personal Loans",
      carLoans: "Car Loans",
      depositAccount: "Deposit Accounts",
      savingAccounts: "Saving Accounts",
      crypto: "Crypto",
      rememberPassword: "Remember password?",
      sentPassword:
        "We have sent a password reset code by {{method}} to {{email}}. Enter it below to reset your password.",
      readMore: "Read more",
      markAllRead: "Mark all read",
      startQuiz: "Start quiz",
      noNotificationYet: "No notifications yet",
      notifications: "Notifications",
      allCreditCards: "All credit cards",
      annualFee: "Annual Fee",
      interestRate: "Interest Rate",
      creditCards: "Credit cards",
      mostPopular: "Most Popular",
      cashBackCards: "Cashback Cards",
      travelCards: "Travel Cards",
      filter: "Filter",
      filterDescription: "You’re looking for a credit card to help you:",
      clearAll: "Clear All",
      filters: {
        points: "Earn points, miles and travel offers",
        cashback: "Get cashback",
        interest: "Save money on interest",
        manage: "Better way to manage finances and spending"
      },
      validation: {
        required: "Field is required",
        codeLength: "Code must be 3 characters long",
        email: "Email is not valid",
        password: "Incorrect password",
        passwordMismatch: "Password mismatch",
        tooShort: "Too short",
        fieldMustBeChecked: "Field must be checked",
        invalidPassword: "Invalid password",
        passwordRules: `The password should contain:
   · At least 8 characters
   · 5 or more unique characters
   · At least 3 of the following: uppercase, lowercase, numeric, or special characters.`
      },
      resources: {
        unlockPoints: "Unlock points",
        meetOurExperts: "Meet Our Experts",
        about: "About",
        yourFAQsAnswered: "Your FAQs answered",
        guidebooks: "Guidebooks",
        areaOfExpertise: "Area of expertise:",
        takeQuiz: "Take Quiz"
      },
      calculator: {
        amount: "Amount",
        homeLoan: "Home Loan",
        personalLoan: "Personal Loan",
        autoLoan: "Auto Loan",
        homeLoanAmount: "Home Loan Amount",
        interestRate: "Interest Rate",
        loanTenure: "Loan Tenure",
        loanEMI: "Loan EMI",
        totalInterestPayable: "Total Interest Payable",
        totalPayment: "Total Payment (Principal + Interest)",
        breakUpTotalPayment: "Break-up Total Payment",
        totalInterest: "Total Interest",
        principalLoanAmount: "Principal Loan Amount"
      },
      admin: {
        welcomeBack: "Welcome Back,",
        welcomeBackInternal: "Welcome Back!",
        partners: "Partners",
        products: "Products",
        experts: "Experts",
        learn: "Learn",
        resources: "Resources",
        quizzes: "Quizzes",
        notifications: "Notifications",
        tailored: "Tailored Notifications",
        quizTheme: "Quiz Themes",
        tags: "Tags",
        users: "Users",
        faqs: "FAQs",
        filterTags: "Filter Tags",
        rewards: "Rewards",
        allOffers: "All Offers",
        addPartner: "Add Partner",
        addProduct: "Add Product",
        editDetails: "Edit Details",
        addMore: "Add More",
        interestRate: "Interest Rate",
        annualFee: "Annual Fee",
        noProductsAvailable: "No products available",
        addNewPartner: "Add a new partner",
        editPartner: "Edit partner",
        editQuiz: "Edit a Quiz",
        addQuiz: "Add a Quiz",
        productCategory: "Product Category",
        productCategories: "Product Categories",
        addNewProductCategory: "Add a new product category",
        productDetails: "Product Details",
        filters: "Filters",
        addFilter: "Add a filters",
        question: "Question",
        newQuestion: "New Question",
        guidebooks: "Guidebooks",
        allTags: "All Tags",
        productCategoryTags: "Product Category Tags",
        editProductTag: "Edit a product tag",
        addProductTag: "Add a new product tag",
        editQuizTag: "Edit a quiz tag",
        addQuizTag: "Add a new quiz tag",
        editFaqTag: "Edit a FAQ tag",
        addFaqTag: "Add a new FAQ tag",
        editRewardTag: "Edit a Reward tag",
        addRewardTag: "Add a new Reward tag",
        quizTags: "Quiz Tags",
        faqTags: "FAQs Tags",
        rewardTags: "Rewards Tags",
        addAnOffer: "Add an Offer",
        editAnOffer: "Edit an Offer",
        addUser: "Add a user",
        editUser: "Edit user",
        statisticsOverview: "Statistics Overview for",
        averageRating: "Average Rating",
        ratingNbbTxt: "The average rating of nbb cards in the app",
        ratingNonNbbTxt: "The average rating of other credit cards in the app",
        termsAndConditions: "Terms & Conditions"
      },
      settings: {
        termsAndConditions: "Terms & Conditions",
        lastVersionSaved: "Last Version Saved",
        lastVersionPublished: "Last Version Published"
      },
      external: {
        insights: "Insights",
        customerInsights: "Customer Insights",
        demographics: "Demographics",
        usersApplyingText: "Users Who Have Applied For Your Product",
        financialInsights: "Financial Insights",
        categorizedSpending: "Categorized Spending",
        averageBalances: "Average Balances",
        balanceBeforePayday: "Balance Before Payday",
        editProfile: "Edit Profile"
      },
      statistics: {
        generalStats: "General Stats",
        reports: "Reports",
        usersRegistered: "registered, in total",
        usersVerified: "including phone number and email",
        userListHeader: "Users List",
        redemptionReport: "Redemption Report",
        redemptionText: "Here's a list of all the reward redemption",
        userInfoReport: "User Information",
        pointsHeader: "Points History",
        healthDataHeader: "Health Data",
        rewardsHeader: "Rewards Redeemed",
        avgSteps: "Average Steps Taken per Day: ",
        noPhone: "No phone available",
        partnersReport: "Partners Report",
        partnersTxt: "Here are all the partners and the products on the app",
        behaviourReport: "Users Search Behaviour Report",
        behaviourTxt: "Here is how your users are behaving with the search filters",
        partnerSelection: "Select a Bank Partner",
        externalProductsHeader: "Your Product's Statistics",
        externalProductsTxt: "Here is how your products are performing in our app",
        reportClicks: "Average Clicks on Apply",
        externalOverviewCardsTxt: "Since you started in our app",
        totalViewsTitle: "Total Views",
        totalClicksTitle: "Total Clicks on Apply",
        ratingTitle: "Avg. Rating",
        ratingTxt: "out of 5",
        statisticsOverviewInternal: "Statistics Overview for",
        statisticsOverviewExternal: "Statistics Overview"
      },
      fields: {
        name: "Name",
        nameAr: "Arabic Name",
        nameEn: "English Name",
        thumbnail: "Thumbnail Image",
        fullsizeImage: "Full size Image",
        logo: "Logo",
        image: "Image",
        picture: "Picture",
        choosePartner: "Choose Partner",
        previewText: "Preview Text",
        productDescription: "Product Description",
        additionalDetails: "Additional Details",
        howToApply: "How To Apply",
        primaryTag: "Primary Tag",
        filterTag: "Filter Tag",
        field: "Field",
        description: "Description",
        usePreviewField: "use as preview field",
        about: "About",
        expert: "Expert",
        expertName: "Expert Name",
        areaOfExpertise: "Area of expertise",
        topic: "Topic",
        topics: "Topics",
        faqs: "FAQs",
        question: "Question",
        answer: "Answer",
        tag: "Tag",
        file: "File",
        publisher: "Publisher",
        title: "Title",
        authorName: "Author Name",
        questionBank: "Question bank",
        typeOfQuestion: "Type of Question",
        linkedToGuideBook: "Linked to Guidebook",
        questionSign: "?",
        type: "Type",
        multipleAnswer: "Multiple Answer",
        correctAnswer: "Correct Answer",
        points: "Points",
        primary: "Primary",
        icon: "Icon",
        filename: "File Name",
        lowestValue: "Lowest Value",
        highestValue: "Highest Value",
        valueSuffix: "Value suffix",
        filterQuestion: "Filter Question",
        questionType: "Question Type",
        selection: "Selection",
        minimumValue: "Minimum Value",
        maximumValue: "Maximum Value",
        reward: "Reward",
        partner: "Partner",
        validity: "Validity",
        offer: "Offer",
        details: "Details",
        date: "Date",
        offerIcon: "Offer Icon",
        redemptionSteps: "Redemption Steps",
        rewardsTag: "Rewards Tag",
        email: "Email",
        status: "Status",
        role: "Role",
        partnerPackage: "Partner Package",
        action: "Action",
        provider: "Provider",
        shortName: "Short Name",
        someText: "Some Text",
        multiple: "Multiple",
        single: "Single",
        dropdown: "Dropdown",
        checkboxes: "Checkboxes",
        slider: "Slider",
        externalEditor: "EXTERNAL_EDITOR",
        internalEditor: "INTERNAL_EDITOR",
        externalPublisher: "EXTERNAL_PUBLISHER",
        internalPublisher: "INTERNAL_PUBLISHER",
        pro: "EXTERNAL_PRO",
        premium: "EXTERNAL_PREMIUM",
        basic: "EXTERNAL_BASIC",
        external: "EXTERNAL",
        enabled: "Enabled",
        disabled: "Disabled",
        value: "Values",
        body: "Body",
        deeplinkId: "Deep Link Id",
        published: "Published",
        label: "Label",
        goToLink: "Go to Link",
        unlink: "Unlink",
        category: "Category",
        slaDays: "SLA Days",
        phoneNumber: "Phone Number",
        version: "Version",
        selectCategory: "Select Category"
      },
      sidebar: {
        dashboard: "Dashboard",
        logout: "Logout",
        settings: "Settings",
        activity: "Activity",
        stats: "Stats",
        external: "Stats (external)",
        daleel: "Daleel",
        insights: "Insights",
        profile: "Profile",
        products: "Products"
      },
      add: "add",
      cancel: "cancel",
      addQuestion: "Add a question",
      search: "Search",
      save: "Save",
      saveExpert: "Save Expert",
      send: "Send",
      viewAll: "View All",
      addFaq: "Add Faq",
      yes: "Yes",
      no: "No",
      edit: "Edit",
      delete: "Delete",
      logoutConfirmation: "Are you sure you want to Logout?",
      delConfirmation: "Are you sure you want to delete it?",
      expertDelConfirmation:
        "Deleting this will automatically delete FAQs and resources / guidebooks for this expert. Are you sure you want to delete it?",
      topicDelConfirmation:
        "Are you sure you want to delete this topic?"

    },
    textEditor: {
      "components.controls.blocktype.h4": "title",
      "components.controls.blocktype.h5": "subtitle",
      "components.controls.blocktype.h6": "normal",
      "components.controls.blocktype.blocktype": "List"
    }
  },
  ar: {
    translation: {
      fields: {
        name: "Name",
        thumbnail: "Thumbnail Image",
        fullsizeImage: "Full size Image",
        logo: "Logo",
        image: "Image",
        picture: "Picture",
        choosePartner: "Choose Partner",
        previewText: "Preview Text",
        productDescription: "Product Description",
        additionalDetails: "Additional Details",
        howToApply: "How To Apply",
        primaryTag: "Primary Tag",
        filterTag: "Filter Tag",
        field: "Field",
        description: "Description",
        usePreviewField: "use as preview field",
        about: "About",
        expert: "Expert",
        expertName: "Expert Name",
        areaOfExpertise: "Area of expertise",
        topic: "Topic",
        topics: "Topics",
        faqs: "FAQs",
        question: "Question",
        answer: "Answer",
        tag: "Tag",
        file: "File",
        publisher: "Publisher",
        title: "Title",
        authorName: "Author Name",
        questionBank: "Question bank",
        typeOfQuestion: "Type of Question",
        linkedToGuideBook: "Linked to Guidebook",
        questionSign: "?",
        type: "Type",
        multipleAnswer: "Multiple Answer",
        correctAnswer: "Correct Answer",
        points: "Points",
        primary: "Primary",
        icon: "Icon",
        filename: "File Name",
        lowestValue: "Lowest Value",
        highestValue: "Highest Value",
        valueSuffix: "Value suffix",
        filterQuestion: "Filter Question",
        questionType: "Question Type",
        selection: "Selection",
        minimumValue: "Minimum Value",
        maximumValue: "Maximum Value",
        reward: "Reward",
        partner: "Partner",
        validity: "Validity",
        offer: "Offer",
        details: "Details",
        date: "Date",
        offerIcon: "Offer Icon",
        redemptionSteps: "Redemption Steps",
        rewardsTag: "Rewards Tag",
        email: "Email",
        status: "Status",
        role: "Role",
        partnerPackage: "Partner Package",
        action: "Action",
        provider: "Provider",
        shortName: "Short Name",
        someText: "Some Text",
        multiple: "Multiple",
        single: "Single",
        dropdown: "Dropdown",
        checkboxes: "Checkboxes",
        slider: "Slider",
        externalEditor: "EXTERNAL_EDITOR",
        internalEditor: "INTERNAL_EDITOR",
        externalPublisher: "EXTERNAL_PUBLISHER",
        internalPublisher: "INTERNAL_PUBLISHER",
        external: "EXTERNAL",
        enabled: "Enabled",
        disabled: "Disabled",
        value: "Values",
        body: "Body",
        deeplinkId: "Deep Link Id",
        category: "Category",
        label: "Label",
        published: "Published",
        goToLink: "Go to Link",
        slaDays: "SLA Days",
        phoneNumber: "Phone Number"
        // name: 'اسم',
        // thumbnail: 'صورة مصغرة',
        // fullsizeImage: 'الصورة بالحجم الكامل',
        // logo: 'شعار',
        // choosePartner: 'اختر الشريك',
        // previewText: 'معاينة النص',
        // productDescription: 'وصف المنتج',
        // additionalDetails: 'تفاصيل اضافية',
        // howToApply: 'كيفية التقديم',
        // primaryTag: 'العلامة الأولية',
        // filterTag: 'علامة تصفية',
        // field: 'الحقل',
        // description: 'الوصف',
        // usePreviewField: 'استخدم كحقل معاينة',
        // about: 'حول',
        // expert: 'خبير',
        // areaOfExpertise: 'مجال الخبرة',
        // faqs: 'الأسئلة الشائعة',
        // question: 'سؤال',
        // answer: 'إجابه',
        // tag: 'العلامات',
        // file: 'ملف',
        // publisher: 'الناشر',
        // title: 'لقب',
        // authorName: 'اسم المؤلف',
        // questionBank: 'بنك الأسئلة',
        // typeOfQuestion: 'نوع السؤال',
        // linkedToGuideBook: 'مرتبط بالدليل',
        // questionSign: '؟',
        // type: 'أنواع',
        // multipleAnswer: 'إجابة متعددة',
        // correctAnswer: 'اجابة صحيحة',
        // points: 'درجات',
        // primary: 'الأولية',
        // icon: 'أيقونة',
        // filename: 'اسم الملف',
        // lowestValue: 'أدنى قيمة',
        // highestValue: 'أعلى قيمة',
        // valueSuffix: 'لاحقة القيمة',
        // filterQuestion: 'سؤال المرشحات',
        // questionType: 'نوع السؤال',
        // selection: 'اختيار',
        // minimumValue: 'الحد الأدنى للقيمة',
        // maximumValue: 'القيمة القصوى',
        // reward: 'المكافآت',
        // partner: 'شريك',
        // validity: 'صلاحية',
        // offer: 'عرض',
        // details: 'تفاصيل',
        // date: 'تاريخ',
        // offerIcon: 'عرض أيقونة',
        // redemptionSteps: 'خطوات الاسترداد',
        // rewardsTag: 'علامة المكافآت',
        // email: 'البريد الإلكتروني',
        // status: 'حالة',
        // role: 'وظيفة',
        // action: 'عمل',
        // provider: 'مزود',
        // shortName: 'اسم قصير',
        // someText: 'بعض النصوص',
        // multiple: 'مضاعف',
        // single: 'مفرد',
        // dropdown: 'اسقاط',
        // checkboxes: 'مربعات الاختيار',
        // slider: 'المنزلق',
        // externalEditor: 'محرر خارجي',
        // internalEditor: 'محرر داخلي',
        // enabled: 'ممكن',
        // disabled: 'معاق'
      },
      sidebar: {
        dashboard: "Dashboard",
        logout: "Logout",
        settings: "Settings",
        activity: "Activity",
        stats: "Stats",
        daleel: "Daleel",
        external: "Stats (external)",
        profile: "Profile",
        products: "Products"
        // dashboard: 'لوحة القيادة',
        // logout: 'تسجيل خروج',
        // settings: 'إعدادات',
        // activity: 'نشاط',
        // daleel: 'دليل'
      },
      admin: {
        welcomeBack: "Welcome Back,",
        welcomeBackInternal: "Welcome Back!",
        partners: "Partners",
        products: "Products",
        experts: "Experts",
        learn: "Learn",
        resources: "Resources",
        quizzes: "Quizzes",
        notifications: "Notifications",
        tailored: "Tailored Notifications",
        quizTheme: "Quiz Theme",
        tags: "Tags",
        users: "Users",
        faqs: "FAQs",
        interestRate: "Interest Rate",
        annualFee: "Annual Fee",
        filterTags: "Filter Tags",
        addPartner: "Add Partner",
        addProduct: "Add Product",
        editDetails: "Edit Details",
        addMore: "Add More",
        noProductsAvailable: "No products available",
        rewards: "Rewards",
        allOffers: "All Offers",
        addNewPartner: "Add a new partner",
        editPartner: "Edit partner",
        editQuiz: "Edit a Quiz",
        addQuiz: "Add a Quiz",
        productCategory: "Product Category",
        productCategories: "Product Categories",
        addNewProductCategory: "Add a new product category",
        productDetails: "Product Details",
        filters: "Filters",
        addFilter: "Add a filters",
        question: "Question",
        newQuestion: "New Question",
        guidebooks: "Guidebooks",
        allTags: "All Tags",
        productCategoryTags: "Product Category Tags",
        editProductTag: "Edit a product tag",
        addProductTag: "Add a new product tag",
        editQuizTag: "Edit a quiz tag",
        addQuizTag: "Add a new quiz tag",
        editFaqTag: "Edit a FAQ tag",
        addFaqTag: "Add a new FAQ tag",
        editRewardTag: "Edit a Reward tag",
        addRewardTag: "Add a new Reward tag",
        quizTags: "Quiz Tags",
        faqTags: "FAQs Tags",
        rewardTags: "Rewards Tags",
        addAnOffer: "Add an Offer",
        editAnOffer: "Edit an Offer",
        addUser: "Add a user",
        editUser: "Edit user",
        averageRating: "Average Rating",
        ratingNbbTxt: "The average rating of nbb cards in the app",
        ratingNonNbbTxt: "The average rating of other credit cards in the app",
        termsAndConditions: "Terms & Conditions"
        // welcomeBack: 'مرحبًا بعودتك',
        // partners: 'شركاء',
        // products: 'منتجات',
        // experts: 'خبراء',
        // resources: 'موارد',
        // quizzes: 'الإختبارات',
        // rewards: 'المكافآت',
        // faqs: 'الأسئلة الشائعة',
        // filterTags: 'علامة تصفية',
        // tags: 'العلامات',
        // users: 'المستخدمون',
        // allOffers: 'كل العروض',
        // addNewPartner: 'إضافة شركاء جدد',
        // editPartner: 'تحرير الشركاء',
        // editQuiz: 'تحرير اختبار',
        // addQuiz: 'أضف اختبارًا',
        // productCategory: 'فئة المنتج',
        // productCategories: 'فئات المنتجات',
        // addNewProductCategory: 'أضف فئة منتج جديدة',
        // productDetails: 'تفاصيل المنتج',
        // filters: 'المرشحات',
        // addFilter: 'أضف مرشحات',
        // question: 'سؤال',
        // newQuestion: 'سؤال جديد',
        // guidebooks: 'كتيبات إرشادية',
        // allTags: 'كل العلامات',
        // productCategoryTags: 'علامات فئة المنتج',
        // editProductTag: 'قم بتحرير علامة المنتج',
        // addProductTag: 'أضف علامة منتج جديدة',
        // editQuizTag: 'تحرير علامة اختبار',
        // addQuizTag: 'أضف علامة اختبار جديدة',
        // editFaqTag: 'تحرير علامة التعليمات',
        // addFaqTag: 'أضف علامة أسئلة وأجوبة جديدة',
        // editRewardTag: 'تحرير علامة مكافأة',
        // addRewardTag: 'أضف علامة مكافأة جديدة',
        // quizTags: 'علامات الاختبار',
        // faqTags: 'علامات التعليمات',
        // rewardTags: 'علامات المكافآت',
        // addAnOffer: 'أضف عرضًا',
        // editAnOffer: 'تحرير عرض',
        // addUser: 'إضافة مستخدم',
        // editUser: 'تحرير مستخدم'
      },
      settings: {
        termsAndConditions: "Terms & Conditions",
        lastVersionSaved: "Last Version Saved",
        lastVersionPublished: "Last Version Published"
      },
      external: {
        insights: "Insights",
        customerInsights: "Customer Insights",
        demographics: "Demographics",
        usersApplyingText: "Users Who Have Applied For Your Product",
        financialInsights: "Financial Inisights",
        categorizedSpending: "Categorized Spending",
        averageBalances: "Average Balances",
        balanceBeforePayday: "Balance Before Payday",
        editProfile: "Edit Profile"
      },
      statistics: {
        generalStats: "General Stats",
        reports: "Reports",
        usersRegistered: "registered, in total",
        usersVerified: "including phone number and email",
        userListHeader: "Users List",
        redemptionReport: "Redemption Report",
        redemptionText: "Here's a list of all the reward redemption",
        userInfoReport: "User Information",
        pointsHeader: "Points History",
        healthDataHeader: "Health Data",
        rewardsHeader: "Rewards Redeemed",
        avgSteps: "Average Steps Taken per Day: ",
        noPhone: "No phone available",
        partnersReport: "Partners Report",
        partnersTxt: "Here are all the partners and the products on the app",
        behaviourReport: "Users Search Behaviour Report",
        behaviourTxt: "Here is how your users are behaving with the search filters",
        partnerSelection: "Select a Bank Partner",
        externalProductsHeader: "Your Product's Statistics",
        externalProductsTxt: "Here is how your products are performing in our app",
        reportClicks: "Average Clicks on Apply",
        externalOverviewCardsTxt: "Since you started in our app",
        totalViewsTitle: "Total Views",
        totalClicksTitle: "Total Clicks on Apply",
        ratingTitle: "Avg. Rating",
        ratingTxt: "out of 5",
        statisticsOverviewInternal: "Statistics Overview for",
        statisticsOverviewExternal: "Statistics Overview"
      },
      validation: {
        required: "الحقل مطلوب",
        codeLength: "يجب أن يتكون الرمز من 3 أحرف",
        email: "البريد الإلكتروني غير صالح",
        password: "كلمة سر خاطئة",
        passwordMismatch: "كلمة المرور غير متطابقة",
        tooShort: "قصير جدا",
        fieldMustBeChecked: "يجب فحص الحقل",
        invalidPassword: "رمز مرور خاطئ",
        passwordRules: `يجب أن تحتوي كلمة المرور على:
        · 8 أحرف على الأقل
        5 أو أكثر من الشخصيات الفريدة
        · ما لا يقل عن 3 مما يلي: أحرف كبيرة أو صغيرة أو رقمية أو أحرف خاصة.`
      },
      add: "add",
      cancel: "cancel",
      addQuestion: "Add a question",
      search: "Search",
      save: "Save",
      saveExpert: "Save Expert",
      viewAll: "View All",
      addFaq: "Add Faq",
      yes: "Yes",
      no: "No",
      edit: "Edit",
      delete: "Delete",
      delConfirmation: "Are you sure you want to delete it?",
      expertDelConfirmation:
        "Deleting this will automatically delete FAQs and resources / guidebooks for this expert. Are you sure you want to delete it?",
      topicDelConfirmation:
        "Deleting this entity will automatically delete the experts linked to this topic. Are you sure you want to delete it?"

      // add: 'يضيف',
      // cancel: 'إلغاء',
      // addQuestion: 'أضف سؤالا',
      // search: 'بحث',
      // save: 'حفظ',
      // viewAll: 'مشاهدة الكل',
      // addFaq: 'إضافة التعليمات',
      // yes: 'نعم',
      // no: 'رقم',
      // edit: 'تعديل',
      // delete: 'حذف'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
