import React from "react";
import InternalUserFormatter from "components/InternalUserFormatter/InternalUserFormatter";
import HealthDataFormatter from "components/HealthDataFormatter/HealthDataFormatter";
import UsersPhoneFormatter from "components/UsersPhoneFormatter/UsersPhoneFormatter";
import RoundDecimal from "components/RoundDecimal/RoundDecimal";

export const UserTableHeaders = [
  {
    key: "name",
    label: "User",
    Component: (props) => <InternalUserFormatter hasLink hasEmail {...props} />,
    sticky: true,
    minWidth: 150,
    textAlign: "start"
  },
  {
    key: "phone",
    label: "Phone Number",
    Component: UsersPhoneFormatter,
    minWidth: 120
  },
  {
    key: "healthConnected",
    label: "Health Data",
    Component: HealthDataFormatter,
    minWidth: 120
  },
  {
    key: "avgStepsPerDay",
    label: "Av. Steps Taken per Day",
    Component: RoundDecimal,
    minWidth: 150
  },
  {
    key: "totalPointsEarned",
    label: "Points Earned",
    minWidth: 100,
    fontWeight: 600,
    color: "#68B6B5"
  },
  {
    key: "totalPointsRedeemed",
    label: "Points Redeemed",
    minWidth: 100,
    fontWeight: 600,
    color: "#F39468"
  },
  {
    key: "currentPoints",
    label: "Current Points",
    minWidth: 100,
    fontWeight: 600,
    color: "#DEA8DD"
  },
  {
    key: "redeemedRewards",
    label: "Rewards Redeemed",
    minWidth: 120
  }
];
