/* eslint-disable eqeqeq */
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { NumToString, StringToNum } from "utils/functions";

const RangeFilterOption = ({ rangeItem, handleChange, filters }) => {
  const { t } = useTranslation();
  return (
    <>
      <BaseTextField
        InputLabelProps={{ required: false }}
        sx={{ marginBottom: "24px" }}
        margin="normal"
        fullWidth
        id="minimumValue"
        label={t("fields.minimumValue")}
        name="minimumValue"
        onChange={(e) => {
          handleChange({
            ...e,
            target: {
              name: "ranges.lower",
              value: StringToNum(e.target.value) ? StringToNum(e.target.value) : "0"
            }
          });
        }}
        value={NumToString(rangeItem.lower)}
      />
      <BaseTextField
        InputLabelProps={{ required: false }}
        sx={{ marginBottom: "24px" }}
        margin="normal"
        fullWidth
        id="maximumValue"
        label={t("fields.maximumValue")}
        name="maximumValue"
        onChange={(e) => {
          handleChange({
            ...e,
            target: {
              name: "ranges.higher",
              value: StringToNum(e.target.value) ? StringToNum(e.target.value) : "0"
            }
          });
        }}
        value={NumToString(rangeItem.higher)}
      />
      <ToggleButtonGroup
        sx={{ flexWrap: "wrap", gap: "7px" }}
        color="primary"
        value={rangeItem.tags.map((tag) => tag.id)}
        onChange={(event, value) => {
          handleChange({
            ...event,
            target: {
              name: "ranges.tags",
              value: filters.filter((f) => !!value.find((id) => id == f.id))
            }
          });
        }}
      >
        {filters
          .filter((filter) => !!filter.valueSuffix)
          ?.map((_filter) => (
            <ToggleButton
              sx={{
                display: "flex",
                gap: "8px",
                overflow: "auto",
                marginBottom: "15px",
                flexWrap: "nowrap",
                width: "max-content"
              }}
              selected={rangeItem.tags.map((tag) => tag.id).includes(_filter.id)}
              key={_filter.id}
              value={_filter.id}
            >
              {_filter.valueSuffix}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>

      <br />
    </>
  );
};

RangeFilterOption.propTypes = {
  rangeItem: instanceOf(Object).isRequired,
  handleChange: func.isRequired,
  filters: instanceOf(Array).isRequired
};

export default RangeFilterOption;
