/* eslint-disable no-param-reassign */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import DatePickert from "components/DatePicker/DatePicker";
import { useSelector } from "react-redux";
import { ReactComponent as Filter } from "assets/icons/filter-icon.svg";
import { getPublishedRewardsSelector } from "store/selectors/rewards";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import CheckboxItem from "components/Checkbox/Checkbox";

const FilterMenuItemWithChildren = ({
  onChangeFilter, selectedFilters
}) => {
  const publishedRewards = useSelector(getPublishedRewardsSelector);
  const [filters, setFilters] = useState({ ...selectedFilters });

  const [selectedCheckbox, setSelectedCheckbox] = useState({});

  const cleanRewards = publishedRewards?.filter(
    (value, index, self) => index === self.findIndex((t) => (
      t.partner === value.partner
    ))
  );

  // solo cambia el valor de los filtros
  const handleFilterChange = (value, type) => {
    const updatedFilters = { ...filters };

    if (type === "checkbox") {
      const key = "filterPartner";

      setSelectedCheckbox({ ...selectedCheckbox, ...value });

      const obj = { ...selectedCheckbox, ...value };
      const arr = [];
      Object.keys(obj).forEach((it) => {
        if (obj[it] === true) {
          arr.push(it);
        }
        return arr;
      });

      if (!arr.length) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = [...arr].toString();
      }
      setFilters(updatedFilters);
    } else if (type === "date") {
      const key = "filterDate";

      if (!value.length) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = value;
      }
      setFilters(updatedFilters);
    }
  };

  const handleConfirm = () => {
    const arr = [];
    Object.keys(filters).forEach((item) => {
      if (item === "filterPartner") {
        arr.push("partner");
      } else if (item === "filterDate") {
        arr.push("date");
      }
    });
    onChangeFilter({
      ...filters
    }, arr);
  };

  const handleClear = () => {
    setFilters({});
    setSelectedCheckbox({});
    onChangeFilter({}, []);
  };

  const renderBody = () => (
    <>
      <Box mb={3}>
        <Typography variant="smallTitle">Partner</Typography>
        <Grid container mt={1}>
          {cleanRewards?.map((reward) => (
            <Grid
              item
              xs={6}
              sx={{ display: "flex", alignItems: "center" }}
              key={reward.id}
            >
              <CheckboxItem
                selected={selectedCheckbox}
                onChange={(value) => handleFilterChange(value, "checkbox")}
                filterType="checkbox"
                id={reward.partner}
              >
                {reward.partner}
              </CheckboxItem>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Typography variant="smallTitle">Date range</Typography>
        <Box mt={1}>
          <DatePickert
            showCustomHeader
            dateValue={filters?.filterDate}
            showInput
            inline
            onChangeDate={(value) => handleFilterChange(value, "date")}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <ButtonWithModal
      isIcon
      size="small"
      title={<Typography variant="newSubtitle">Filter</Typography>}
      sx={{
        width: "max-content",
        backgroundColor: "#F2F2F2",
        fontSize: "12px"
      }}
      handleAction={handleConfirm}
      body={renderBody()}
      handleClear={handleClear}
      dialogStyle="sm"
      hideCancel
    >
      <Filter style={{ color: "gray" }} />
    </ButtonWithModal>
  );
};

export default FilterMenuItemWithChildren;
