import React, { useEffect } from "react";
import {
  Box, Card, CardContent, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  loadUserHealthData, loadUserPoints, loadUserRewards, loadUsersStats
} from "store/actions/userStats";
import {
  getIsLoadingSelector,
  getUserHealthSelector,
  getUserPointsSelector, getUsersRecordsSelector, getUserRewardsSelector, getUserStatsFilterSelector
} from "store/selectors/userStats";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import UserPhoneFormatter from "components/UserPhoneFormatter/UserPhoneFormatter";
import UserEmailFormatter from "components/UserEmailFormatter/UserEmailFormatter";
import UserPointsTable from "components/UserPointsTable/UserPointsTable";
import NavFilters from "components/NavFilters/NavFilters";
import UserRewardsTable from "components/UserRewardsTable/UserRewardsTable";
import DateSwitcher from "components/DateSwitcher/DateSwitcher";
import { addDays, startOfWeek } from "date-fns";
import { yymmdd } from "utils/functions";
import HealthDataBarChart from "components/Reports/HealthDataBarChart";
import UserHealthVerifiedFormatter from "components/UserHealthVerifiedFormatter/UserHealthVerifiedFormatter";
import PageLoading from "components/PageLoading";
import { useTranslation } from "react-i18next";
import { pointsNavTabs } from "utils/staticValues";
import styles from "../../admin.module.scss";

const UsersInfoAdminPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const usersRecords = useSelector(getUsersRecordsSelector);
  const { records } = usersRecords;

  const userRewards = useSelector(getUserRewardsSelector);
  const userHealth = useSelector(getUserHealthSelector);
  const userPoints = useSelector(getUserPointsSelector);
  const isLoading = useSelector(getIsLoadingSelector);
  const userFilters = useSelector(getUserStatsFilterSelector);

  const userDetails = records?.find((user) => user.id === id);

  const userName = userDetails?.name;

  const firstDayOfWeek = startOfWeek(new Date());
  const userRecordsLength = Object.keys(usersRecords)?.length;

  useEffect(() => {
    if (!userRecordsLength) {
      const initialRequest = {
        ...userFilters
      };
      dispatch(loadUsersStats(initialRequest));
    }
  }, []);

  useEffect(() => {
    const userPointsParams = {
      userId: id
    };

    const userRewardsParams = {
      id,
      page: 1
    };
    const userHealthParams = {
      userId: id,
      startDate: yymmdd(firstDayOfWeek),
      endDate: yymmdd(addDays(firstDayOfWeek, 6))
    };

    dispatch(loadUserPoints(userPointsParams));
    dispatch(loadUserRewards(userRewardsParams));
    dispatch(loadUserHealthData(userHealthParams));
  }, []);

  const handlePointsFilters = (filter) => {
    let params = {};
    if (filter === "all") {
      params = {
        userId: id
      };
    } else {
      params = {
        userId: id,
        key: filter
      };
    }
    dispatch(loadUserPoints(params));
  };

  const onChangeRewardsTablePage = (val) => {
    const params = {
      id,
      page: val
    };
    dispatch(loadUserRewards(params));
  };

  const onChangeDate = (date) => {
    const params = {
      userId: id,
      startDate: yymmdd(date[0]),
      endDate: yymmdd(date[1])
    };
    dispatch(loadUserHealthData(params));
  };

  if (isLoading || !userRecordsLength) {
    return <PageLoading loading={isLoading || !userRecordsLength} />;
  }

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box
        id="user-info"
        mb={4}
        sx={{
          display: "flex", justifyContent: "flex-start", width: "100%"
        }}
      >
        <Box sx={{
          display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "70%"
        }}
        >
          <Typography mb={3} variant="newTitle">{t("statistics.userInfoReport")}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }} mb={3}>
            <UserIcon style={{ marginRight: 5 }} />
            <Typography variant="title" lineHeight="20px">{userName?.length ? userName : "No name available"}</Typography>
          </Box>
          <Box sx={{
            display: "flex", justifyContent: "space-between", alignItems: "center", width: "80%"
          }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <EmailIcon style={{ marginRight: 5 }} />
              <UserEmailFormatter
                emailVerified={userDetails?.emailVerified}
              >
                {userDetails?.email}
              </UserEmailFormatter>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              <PhoneIcon style={{ marginRight: 5 }} />
              {userDetails?.phone.length > 1
                ? (
                  <UserPhoneFormatter
                    phoneVerified={userDetails?.phoneVerified}
                  >
                    {userDetails?.phone}
                  </UserPhoneFormatter>
                )
                : <Typography variant="newBody1">{t("statistics.noPhone")}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        mb={4}
        sx={{
          display: "flex", justifyContent: "space-between", width: "100%"
        }}
      >
        <Box
          id="points-info"
          sx={{
            display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "48%"
          }}
        >
          <Typography variant="title" lineHeight="20px" mb={4}>{t("statistics.pointsHeader")}</Typography>
          <UserPointsTable
            toolbar={<NavFilters data={pointsNavTabs} onChange={handlePointsFilters} />}
            data={userPoints}
          />
        </Box>
        <Box
          id="health-info"
          sx={{
            display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingX: 3, width: "48%"
          }}
        >
          <Typography variant="title" lineHeight="20px" mb={4} ml={3}>{t("statistics.healthDataHeader")}</Typography>
          <Box sx={{ width: "100%" }} mb={3} ml={3}>
            <Card sx={{
              paddingTop: 1, paddingX: 1, borderRadius: "25px"
            }}
            >
              <CardContent>
                <Box>
                  <UserHealthVerifiedFormatter>
                    {userDetails?.healthConnected}
                  </UserHealthVerifiedFormatter>
                </Box>
                <Box my={2}>
                  <Typography variant="smallTitle">
                    {t("statistics.avgSteps")}
                  </Typography>
                  <Typography variant="newBody1">
                    {" "}
                    {Math.round(userDetails?.avgStepsPerDay) || 0}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <DateSwitcher onChange={onChangeDate} />
                </Box>
                <Box>
                  <HealthDataBarChart
                    colorChanges
                    healthData={userHealth?.steps}
                    key1="stepsRounded"
                    xKey="stepsDate"
                    unit="k"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      <Box
        id="rewards-info"
        sx={{
          display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%"
        }}
      >
        <Typography variant="title" lineHeight="20px" mb={4}>{t("statistics.rewardsHeader")}</Typography>
        <UserRewardsTable
          toolbar={(
            <Box width="95%" sx={{ display: "flex", alignItems: "center", paddingLeft: 1 }}>
              <Box mr={10}>
                <Typography variant="smallTitle">Total rewards redeemed: </Typography>
                <Typography variant="newBody1">{userDetails?.redeemedRewards}</Typography>
              </Box>
              <Box>
                <Typography variant="smallTitle">Total points used: </Typography>
                <Typography variant="newBody1">{userDetails?.totalPointsRedeemed}</Typography>
              </Box>
            </Box>
)}
          data={userRewards}
          onChangePage={onChangeRewardsTablePage}
        />
      </Box>
    </Box>
  );
};

export default UsersInfoAdminPage;
