import BaseSelect from "components/formControl/baseSelect/BaseSelect";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const CheckboxFiltersSelectPicker = ({ checkboxes, setFieldValue, filters }) => {
  const { t } = useTranslation();

  return (
    <>
      <br />
      <br />
      <BaseSelect
        name="tag"
        label={t("fields.tag")}
        items={filters.filter((filter) => !!filter.value)}
        initvalue={checkboxes.tag?.id}
        sx={{ width: "100%", marginBottom: 0 }}
        onChange={(value) => {
          setFieldValue("checkboxes.tag.id", value);
        }}
      />
      <br />
    </>
  );
};

CheckboxFiltersSelectPicker.propTypes = {
  checkboxes: instanceOf(Object).isRequired,
  setFieldValue: func.isRequired,
  filters: instanceOf(Array).isRequired
};

export default CheckboxFiltersSelectPicker;
