import React from "react";
import { formatyyyymmdd, mapDateToMonths } from "utils/functions";
import ExternalReportsChart from "./ExternalReportsChart";

const ClickRatingReport = ({ clickData, ...propTypes }) => {
  const handleDateFormat = (date) => {
    if (date.length > 6) {
      return formatyyyymmdd(date);
    }
    return mapDateToMonths(date.slice(-2));
  };

  const formatData = clickData?.map((item) => ({
    ...item,
    nbbClickPercent: Math.round(item.nbbClickPercent),
    nonnbbClickPercent: Math.round(item.nonnbbClickPercent),
    ...("day" in clickData[0] ? { day: item.day.substring(0, 1) } : { date: handleDateFormat(item.date) })
  }));

  return (
    <ExternalReportsChart
      data={formatData}
      {...propTypes}
    />
  );
};

export default ClickRatingReport;
