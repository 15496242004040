import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { bool, string } from "prop-types";

const UserPhoneFormatter = ({ children, phoneVerified }) => {
  const isPhoneVerified = phoneVerified;
  return (
    children?.length > 1 && (
      <Container sx={{ paddingLeft: "0 !important" }}>
        <Box sx={{
          display: "flex", alignItems: "center", width: "230px"
        }}
        >
          <Typography variant="newBody1" mr={4}>{children}</Typography>
          {isPhoneVerified && <Typography data-testid="phone-verified-txt" color="#68B6B5" variant="smallTitle">Verified &#x2713;</Typography>}
        </Box>
      </Container>
    )
  );
};

UserPhoneFormatter.propTypes = {
  children: string.isRequired,
  phoneVerified: bool
};

UserPhoneFormatter.defaultProps = {
  phoneVerified: false
};

export default UserPhoneFormatter;
