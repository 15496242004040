import React, { useEffect, useState } from "react";
import {
  InputBase, Paper
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { func } from "prop-types";

const Searchbar = ({ onChange }) => {
  const [value, setValue] = useState("");
  const [startSeach, setStartSearch] = useState(false);

  const handleSearch = (event) => {
    setValue(event.target.value);
    setTimeout(() => {
      setStartSearch(true);
    }, [500]);
    setStartSearch(false);
  };

  useEffect(() => {
    if (startSeach) {
      onChange(value);
    }
  }, [startSeach]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: "2px 4px", display: "flex", alignItems: "center", width: 200, borderRadius: 8, backgroundColor: "#F2F2F2", paddingX: 2
      }}
    >
      <SearchIcon />
      <InputBase
        sx={{
          ml: 1, flex: 1, fontWeight: 400, fontSize: "12px", lineHeight: "14px"
        }}
        placeholder="Search"
        value={value}
        onChange={handleSearch}
      />
    </Paper>
  );
};

Searchbar.propTypes = {
  onChange: func.isRequired
};

export default Searchbar;
