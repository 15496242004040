import { createSlice } from "@reduxjs/toolkit";
import {
  loadAgeGenderStats,
  loadApplyingUsersByBank,
  loadEmploymentStatusStats,
  loadNationalitiesStats,
  loadSalaryStats
} from "store/actions/customerInsights";

const initialState = {
  ageGenderStats: [],
  nationalitiesStats: [],
  employmentStatusStats: [],
  salaryStats: [],
  usersByBank: [],
  loadingDemographic: false,
  loadingEmployment: false,
  loadingUsers: false
};

export const customerInsightsSlice = createSlice({
  name: "customerInsights",
  initialState,
  reducers: {
    setStateResourceValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data
      };
    }
  },
  extraReducers: {
    [loadAgeGenderStats.pending]: (state) => {
      state.loadingDemographic = true;
      state.error = null;
    },
    [loadAgeGenderStats.fulfilled]: (state, { payload }) => {
      state.loadingDemographic = false;
      state.ageGenderStats = payload;
    },
    [loadAgeGenderStats.rejected]: (state, { payload }) => {
      state.loadingDemographic = false;
      state.error = payload;
    },
    [loadNationalitiesStats.pending]: (state) => {
      state.loadingDemographic = true;
      state.error = null;
    },
    [loadNationalitiesStats.fulfilled]: (state, { payload }) => {
      const { nationalities } = payload;
      state.loadingDemographic = false;
      state.nationalitiesStats = nationalities;
    },
    [loadNationalitiesStats.rejected]: (state, { payload }) => {
      state.loadingDemographic = false;
      state.error = payload;
    },
    [loadEmploymentStatusStats.pending]: (state) => {
      state.loadingEmployment = true;
      state.error = null;
    },
    [loadEmploymentStatusStats.fulfilled]: (state, { payload }) => {
      state.loadingEmployment = false;
      state.employmentStatusStats = payload;
    },
    [loadEmploymentStatusStats.rejected]: (state, { payload }) => {
      state.loadingEmployment = false;
      state.error = payload;
    },
    [loadSalaryStats.pending]: (state) => {
      state.loadingEmployment = true;
      state.error = null;
    },
    [loadSalaryStats.fulfilled]: (state, { payload }) => {
      state.loadingEmployment = false;
      state.salaryStats = payload;
    },
    [loadSalaryStats.rejected]: (state, { payload }) => {
      state.loadingEmployment = false;
      state.error = payload;
    },
    [loadApplyingUsersByBank.pending]: (state) => {
      state.loadingUsers = true;
      state.error = null;
    },
    [loadApplyingUsersByBank.fulfilled]: (state, { payload }) => {
      state.loadingUsers = false;
      state.usersByBank = payload;
    },
    [loadApplyingUsersByBank.rejected]: (state, { payload }) => {
      state.loadingUsers = false;
      state.error = payload;
    }
  }
});

export default customerInsightsSlice.reducer;
