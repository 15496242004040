import React, { useEffect, useState } from "react";
import { Slider, Typography } from "@mui/material";
import { func, instanceOf } from "prop-types";

const marks = [
  {
    value: 0,
    label: <Typography variant="newBody1">&#11088; 0</Typography>
  },
  {
    value: 1,
    label: <Typography variant="newBody1">&#11088; 1</Typography>
  },
  {
    value: 2,
    label: <Typography variant="newBody1">&#11088; 2</Typography>
  },
  {
    value: 3,
    label: <Typography variant="newBody1">&#11088; 3</Typography>
  },
  {
    value: 4,
    label: <Typography variant="newBody1">&#11088; 4</Typography>
  },
  {
    value: 5,
    label: <Typography variant="newBody1">&#11088; 5</Typography>
  }
];

const RangeSlider = ({ onChange, selected }) => {
  const minDistance = 1;
  const [value, setValue] = useState(selected);

  useEffect(() => {
    if (selected.length < 1) {
      setValue([0, 5]);
    }
  }, [selected]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let val;
    if (activeThumb === 0) {
      val = [Math.min(newValue[0], value[1] - minDistance), value[1]];
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
      if (onChange) {
        onChange(val);
      }
    } else {
      val = [value[0], Math.max(newValue[1], value[0] + minDistance)];
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
      if (onChange) {
        onChange(val);
      }
    }
  };

  return (
    <Slider
      size="small"
      max={5}
      min={0}
      getAriaLabel={() => "Temperature range"}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="off"
      sx={{ color: "#69B5B5" }}
      marks={marks}
    />
  );
};

RangeSlider.propTypes = {
  onChange: func,
  selected: instanceOf(Array)
};

RangeSlider.defaultProps = {
  onChange: null,
  selected: [0, 5]
};

export default RangeSlider;
