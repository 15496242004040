import {
  createSelector
} from "reselect";

const getQuizThemes = (state) => state.quizThemes.quizThemes;
const getIsLoadingQuizThemes = (state) => state.quizThemes.loading;

export const getQuizThemesSelector = createSelector(
  getQuizThemes,
  (quizThemes) => quizThemes
);

export const getisLoadingQuizThemesSelector = createSelector(
  getIsLoadingQuizThemes,
  (loading) => loading
);
