import React from "react";
import { Chip } from "@mui/material";
import { bool, string } from "prop-types";

const HealthDataFormatter = ({ children, size }) => (
  children ? (
    <Chip
      size={size}
      label="Connected &#x2713;"
      color="success"
      sx={{
        color: "white", width: "100%", fontWeight: 400, fontSize: "11px", lineHeight: "14px"
      }}
    />
  )
    : (
      <Chip
        size={size}
        label="Not Connected"
        sx={{
          width: "100%", fontWeight: 400, fontSize: "11px", lineHeight: "14px"
        }}
      />
    )
);

HealthDataFormatter.propTypes = {
  children: bool.isRequired,
  size: string
};

HealthDataFormatter.defaultProps = {
  size: "medium"
};

export default HealthDataFormatter;
