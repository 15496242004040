import React, { useEffect } from "react";
import {
  Box, Typography, Card, CardContent
} from "@mui/material";
import { yymmdd } from "utils/functions";
import CustomDateRangePicker from "components/CustomDateRangePicker/CustomDateRangePicker";
import PageLoading from "components/PageLoading";
import StarRating from "components/StarRating/StarRating";
import { useTranslation } from "react-i18next";
import {
  getExternalReportsSelector,
  getisLoadingExternalOverviewSelector,
  getReportOverviewSelector
} from "store/selectors/reports";
import { getReportOverview, loadClickRatingReport } from "store/actions/reports";
import { useDispatch, useSelector } from "react-redux";
import ClickRatingReport from "components/Reports/ClickRatingReport";
import StatisticOverviewCards from "components/StatisticOverviewCards/StatisticOverviewCards";
import { useParams } from "react-router-dom";
import { subDays } from "date-fns";
import { fetchPartnerById } from "store/actions/partners";
import { getPartnerByIdSelector } from "store/selectors/partners";
import { getUserByIdSelector, getUserTypeSelector } from "store/selectors/user";
import styles from "./user.module.scss";

const StatisticsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const externalReports = useSelector(getExternalReportsSelector);
  const reportOverview = useSelector(getReportOverviewSelector);
  const currentExternalPartner = useSelector(getPartnerByIdSelector);
  const isLoadingOverview = useSelector(getisLoadingExternalOverviewSelector);
  const cmsUser = useSelector(getUserByIdSelector);
  const cmsUserType = useSelector(getUserTypeSelector);

  useEffect(() => {
    const datePeriod = {
      period: "weekly",
      from: yymmdd(subDays(new Date(), 6), true),
      to: yymmdd(new Date(), true),
      providerId: id
    };
    dispatch(loadClickRatingReport(datePeriod));
    dispatch(getReportOverview({ providerId: id }));
  }, []);

  useEffect(() => {
    dispatch(fetchPartnerById({ id: `${id}/published` }));
  }, []);

  const onChangeDateFilter = (datePeriod) => {
    const params = {
      ...datePeriod,
      providerId: id
    };
    dispatch(loadClickRatingReport(params));
  };

  const handleRenderTitle = () => {
    if (cmsUserType === "EXTERNAL") {
      return <>{t("statistics.statisticsOverviewExternal")}</>;
    }
    return (
      <>
        {t("statistics.statisticsOverviewInternal")}
        {" "}
        {currentExternalPartner?.name}
        {" "}
      </>
    );
  };

  if (isLoadingOverview) return <PageLoading loading={isLoadingOverview} />;

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box
        sx={{
          display: "flex", justifyContent: "flex-start", width: "100%", flexDirection: "column"
        }}
        mb={3}
      >
        <Typography variant="newTitle" mb={2}>
          {t("admin.welcomeBack")}
          {" "}
          {`${cmsUser.name}`}
        </Typography>
        <Typography variant="newTitle">
          {handleRenderTitle()}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }} mb={5}>
        <StatisticOverviewCards cards={reportOverview} providerId={id} />
      </Box>
      <Box
        sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "flex-start", width: "100%"
        }}
        my={4}
      >
        <CustomDateRangePicker onChange={onChangeDateFilter} />
      </Box>
      {
        externalReports?.map(({
          categoryId, click, rating, categoryName
        }) => (
          <Box
            key={categoryId}
            mb={10}
            sx={{
              width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
            }}
          >
            <Box mb={5} sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
              <Typography variant="newSubtitle">{categoryName}</Typography>
            </Box>
            <Box sx={{
              width: "100%", display: "flex", justifyContent: "center", alignItems: "center"
            }}
            >
              <Box sx={{
                width: "80%", display: "flex", flexDirection: "column"
              }}
              >
                <Card sx={{
                  paddingTop: 1, borderRadius: "25px"
                }}
                >
                  <CardContent>
                    <Box px={2}>
                      <Typography variant="smallTitle">{t("statistics.reportClicks")}</Typography>
                      <Box mt={4}>
                        <ClickRatingReport
                          clickData={click}
                          bankName={currentExternalPartner?.shortName}
                          key1="nbbClickPercent"
                          key2="nonnbbClickPercent"
                          unit="%"
                          subtitle={t("statistics.reportClicks")}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box sx={{ width: "20%", marginX: 3 }}>
                <Card sx={{
                  width: "250px", height: "290px", borderRadius: "25px", paddingTop: 1
                }}
                >
                  <CardContent sx={{ marginLeft: 1 }}>
                    <Typography variant="smallTitle" sx={{ fontWeight: "600" }}>{t("admin.averageRating")}</Typography>
                    <Box mt={2}>
                      <Box mb={3}>
                        <StarRating
                          size={27}
                          text={`The average rating of ${currentExternalPartner?.shortName} ${categoryName.toLowerCase()} in the app`}
                          fontSize="body1"
                          justify="flex-start"
                        >
                          {rating.nbbRatingAvg}
                        </StarRating>
                      </Box>
                      <StarRating
                        size={27}
                        text={`The average rating of other ${categoryName.toLowerCase()} in the app`}
                        fontSize="body1"
                        justify="flex-start"
                      >
                        {rating.nonnbbRatingAvg}
                      </StarRating>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>

        ))
}
    </Box>
  );
};

export default StatisticsPage;
