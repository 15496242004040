import React from "react";
import Input from "@mui/material/Input";
import { Box, Typography } from "@mui/material";
import { string } from "prop-types";
import classnames from "classnames";
import "./baseInput.scss";

const BaseInput = ({
  label, helperText, inputType, isRoundInput, ...props
}) => (
  <Box className={classnames([{ rounded: props.rounded }])}>
    <div>
      <Typography variant="body2" color="common.darkPurple">
        {label}
      </Typography>
    </div>
    <Input type={inputType} {...props} spellCheck />
    <div className="base-input--error-text">
      <Typography variant="subtitle1" color="common.error">
        {helperText}
      </Typography>
    </div>
  </Box>
);

BaseInput.propTypes = {
  label: string,
  inputType: string,
  helperText: string
};

BaseInput.defaultProps = {
  label: null,
  inputType: "text",
  helperText: null
};

export default BaseInput;
