import React from "react";
import { Typography } from "@mui/material";
import { number } from "prop-types";

const UserPointsFormatter = ({ children }) => (
  <Typography variant="newBody2">
    {children}
    {" "}
    points
  </Typography>
);

UserPointsFormatter.proptTypes = {
  children: number.isRequired
};

export default UserPointsFormatter;
