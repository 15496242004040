import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { settingPageItems } from "utils/staticValues";
import ItemDashboard from "views/Admin/Dashboard/ItemDashboard";
// import { Permissions } from "components/Permissions";
import { useTranslation } from "react-i18next";
import styles from "../admin.module.scss";

const SettingsAdminPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onGoToCategory = (to) => {
    navigate(to);
  };

  return (
    <Box className={styles.container} mt={3}>
      <Typography variant="h3" mb={5}>
        {t("sidebar.settings")}
      </Typography>

      <Grid container spacing={2}>
        {settingPageItems?.map((item) => (
          <Grid item md={4} xs={6} key={item.id}>
            <ItemDashboard item={item} onClick={() => onGoToCategory(item.to)} translate />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SettingsAdminPage;
