import React from "react";
import {
  Accordion, AccordionDetails, AccordionSummary, Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  bool, instanceOf, number
} from "prop-types";
import palette from "theme/palette";

const AccordionListItem = ({
  item, children, hideItem, itemNum, ...AccordionItemProps
}) => {
  const label = item?.question || item?.name;
  return (
    <Accordion color="transparent" {...AccordionItemProps}>
      { !hideItem && (
      <AccordionSummary
        expandIcon={hideItem ? "" : <ExpandMore color={palette.variables.mediumPurple} />}
      >
        {Boolean(itemNum) && (
        <Typography variant="newSubtitle" mr={4} color={palette.variables.darkPurple2}>
          {itemNum}
          .
        </Typography>
        )}
        <Typography variant="newBody2" color={palette.variables.darkPurple2}>
          {label}
        </Typography>
      </AccordionSummary>
      )}
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>

  );
};

AccordionListItem.propTypes = {
  item: instanceOf(Object),
  children: instanceOf(Object).isRequired,
  itemNum: number,
  hideItem: bool
};

AccordionListItem.defaultProps = {
  hideItem: false,
  itemNum: null,
  item: null
};

export default AccordionListItem;
