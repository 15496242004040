import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { bool, string } from "prop-types";

const UserEmailFormatter = ({ children, emailVerified }) => {
  const isEmailVerified = emailVerified;
  return (
    children?.length > 1 && (
      <Container sx={{ paddingLeft: "0 !important" }}>
        <Box sx={{
          display: "flex", alignItems: "center", width: "280px"
        }}
        >
          <Typography variant="newBody1" mr={3}>{children}</Typography>
          {isEmailVerified && <Typography data-testid="verified-txt" variant="smallTitle" color="#68B6B5" width="80px">Verified &#x2713;</Typography>}
        </Box>
      </Container>
    )
  );
};

UserEmailFormatter.propTypes = {
  children: string.isRequired,
  emailVerified: bool
};

UserEmailFormatter.defaultProps = {
  emailVerified: false
};

export default UserEmailFormatter;
