import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import { Box, ToggleButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import palette from "theme/palette";

// COMPONENTS
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import { loadTermsAndConditionsById } from "store/actions/termsAndConditions";
import { getTermsAndConditionsSelector, getisLoadingTermsAndConditionsSelector } from "store/selectors/termsAndConditions";
import PageLoader from "components/PageLoader/PageLoader";

const TermsAndConditionsDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isPublished = pathname.split("/").at(-1) === "published";
  const termsAndConditions = useSelector(getTermsAndConditionsSelector);
  const isLoading = useSelector(getisLoadingTermsAndConditionsSelector);

  const lang = useSelector(selectLanguage);

  const [formValue, setFormValue] = useState({
    version: "",
    previewText: ""
  });

  useEffect(() => {
    dispatch(loadTermsAndConditionsById({ id: isPublished ? `${id}/published` : id }));
  }, [lang]);

  useEffect(() => {
    if (Object.keys(termsAndConditions)?.length) {
      const contentBlock = htmlToDraft(termsAndConditions.consentText);
      const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
      setFormValue({
        version: termsAndConditions.version,
        consentText: EditorState.createWithContent(contentState)
      });
    }
  }, [termsAndConditions]);

  if (isLoading) return <PageLoader />;

  return (
    <Box mt={2}>
      <Box sx={{
        display: "flex", justifyContent: "space-between", width: "100%"
      }}
      >
        <Typography variant="newTitle" color={palette.variables.darkPurple2}>
          {t("admin.termsAndConditions")}
        </Typography>
        {!(searchParams.get("lang") && searchParams.get("lang") === "ar") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <ToggleButton
            sx={{
              display: "flex",
              gap: "8px",
              overflow: "auto",
              marginRight: "10px",
              flexWrap: "nowrap",
              width: "max-content"
            }}
            selected={lang !== "en"}
            onChange={() => {
              dispatch(setLanguage(lang === "en" ? "ar" : "en"));
              navigate(`/terms-and-conditions/published/${id}/published`);
            }}
            value="check"
          >
            {lang !== "en" ? "English" : "Arabic"}
          </ToggleButton>
        </Box>
        )}
      </Box>
      <Box>
        <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
          Version
        </Typography>
        <BaseTextField
          InputLabelProps={{ required: false }}
          sx={{ marginBottom: "24px" }}
          margin="normal"
          fullWidth
          id="version"
          name={t("fields.version")}
          onChange={(event) => setFormValue({
            ...formValue,
            version: event.target.value
          })}
          disabled
          value={formValue?.version}
        />
      </Box>
      <Box>
        <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
          Consent Text
        </Typography>
        <RichTextEditor
          disabled
          value={formValue?.consentText}
          onChange={(editorState) => {
            setFormValue({
              ...formValue,
              previewText: editorState
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default TermsAndConditionsDetailsPage;
