import React, { useEffect, useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography
} from "@mui/material";
import {
  bool,
  func, instanceOf, node, string
} from "prop-types";

import { Close } from "@mui/icons-material";

const ButtonWithModal = ({
  children,
  title,
  body,
  handleAction,
  handleOpen,
  handleClear,
  confirmLabel,
  isIcon,
  download,
  cancelLabel,
  onCancel,
  hideCancel,
  dialogStyle,
  showDividers,
  disableConfirm,
  forceClose,
  ...ButtonProps
}) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (forceClose) {
      setOpenModal(false);
    }
  }, [forceClose]);

  const handleActionClick = () => {
    handleAction();
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onClear = () => {
    if (handleClear) {
      handleClear();
    }
  };

  const onOpen = () => {
    setOpenModal(true);
    if (handleOpen) {
      handleOpen();
    }
  };

  return (
    <>
      {isIcon
        ? (
          <IconButton
            onClick={onOpen}
            {...ButtonProps}
          >
            {children}
          </IconButton>
        )
        : (
          <Button
            onClick={onOpen}
            {...ButtonProps}
          >
            {children}
          </Button>
        )}
      <Dialog
        disableEscapeKeyDown
        open={openModal}
        scroll="paper"
        onClose={() => setOpenModal(false)}
        fullWidth
        size={dialogStyle}
      >
        <DialogTitle sx={{ marginTop: 1 }}>
          {title}
          <IconButton
            sx={{
              position: "absolute",
              right: "15px",
              top: "16px"
            }}
          >
            <Close
              sx={{
                width: "16px",
                height: "16px"
              }}
              onClick={handleCancel}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={showDividers}>
          {body}
        </DialogContent>
        {(Boolean(handleAction) || Boolean(download))
          && (
            <DialogActions sx={{
              display: "flex", justifyContent: "center", width: "100%", alignItems: "center"
            }}
            >
              { !hideCancel ? (
                <Button
                  style={{
                    backgroundColor: "#fff",
                    width: "140px",
                    maxHeight: "30px !important"
                  }}
                  onClick={handleCancel}
                >
                  <Typography variant="smallTitle" sx={{ color: "#29173B", fontSize: "12px" }}>
                    Cancel
                  </Typography>
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#fff",
                    width: "140px",
                    maxHeight: "30px !important"
                  }}
                  onClick={onClear}
                >
                  <Typography variant="smallTitle" sx={{ color: "#29173B", fontSize: "12px" }}>
                    Clear
                  </Typography>
                </Button>
              )}

              {download
                ? (
                  <Button
                    onClick={() => setOpenModal(false)}
                    style={{
                      backgroundColor: "#251938",
                      width: "140px",
                      maxHeight: "30px !important",
                      height: "30px"
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      href={`data:text/csv;charset=utf-8,${escape(download.file)}`}
                      download={`${download.name}.csv`}
                    >
                      {confirmLabel}
                    </a>
                  </Button>
                )
                : (
                  <Button
                    disabled={disableConfirm}
                    style={{
                      backgroundColor: "#251938",
                      width: "140px",
                      maxHeight: "30px !important",
                      height: "30px"
                    }}
                    onClick={handleActionClick}
                  >
                    <Typography variant="smallTitle" sx={{ color: "#fff", fontSize: "12px" }}>{confirmLabel}</Typography>
                  </Button>
                )}
            </DialogActions>
          )}
      </Dialog>
    </>
  );
};

ButtonWithModal.propTypes = {
  children: node.isRequired,
  title: node,
  body: node.isRequired,
  handleAction: func,
  confirmLabel: string,
  isIcon: bool,
  download: instanceOf(Object),
  cancelLabel: string,
  onCancel: func,
  dialogStyle: string,
  showDividers: bool,
  handleOpen: func,
  hideCancel: bool,
  handleClear: func,
  disableConfirm: bool

};

ButtonWithModal.defaultProps = {
  title: null,
  handleAction: null,
  confirmLabel: "Ok",
  isIcon: false,
  download: null,
  cancelLabel: "Cancel",
  onCancel: null,
  dialogStyle: "md",
  showDividers: false,
  handleOpen: null,
  hideCancel: false,
  handleClear: null,
  disableConfirm: false

};

export default ButtonWithModal;
