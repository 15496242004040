import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

/* eslint-disable quotes */
export const setResourcesPath = (id) => {
  switch (id) {
    case 1:
      return "experts";
    case 2:
      return "community";
    case 3:
      return "guidebooks";
    case 4:
      return "faqs";
    default:
      return "experts";
  }
};

export const setTitleFromPath = (name) => name.substr(1).charAt(0).toUpperCase() + name.slice(2);

export const calculationLoanEMI = (ir, np, pv, fv = 0) => {
  /*
 ir - interest rate per month
 np - number of periods (months)
 pv - present value
 fv - future value (residual value)
 */
  const pmt = (ir * (pv * (ir + 1) ** np + fv)) / ((ir + 1) * ((ir + 1) ** np - 1));
  return Math.ceil(pmt);
};

export const calculationTotalInterest = (loanEMI, loanTenure) => loanEMI * loanTenure * 12;

export const getCalculation = (homeLoanAmount = 1, interestRate = 1, loanTenure = 1) => {
  const periodInmonths = loanTenure * 12;
  const rateInMonth = interestRate / 12 / 100;

  const array = [];
  let totalInterest = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= loanTenure * 12; i++) {
    let data = {};

    if (array.length) {
      data = {
        month: i,
        amount: array[i - 2].remainingPrincipalAmound,
        instalment: calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount),
        interest: Math.round((array[i - 2].remainingPrincipalAmound * interestRate) / 1200),
        principalRepaid: Math.ceil(
          calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount)
            - (array[i - 2].remainingPrincipalAmound * interestRate) / 1200
        ),
        remainingPrincipalAmound: Math.round(
          array[i - 2].remainingPrincipalAmound
            - (calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount)
              - (array[i - 2].remainingPrincipalAmound * interestRate) / 1200)
        )
      };
      totalInterest += Math.round((array[i - 2].remainingPrincipalAmound * interestRate) / 1200);
    } else {
      data = {
        month: i,
        amount: +homeLoanAmount,
        instalment: calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount),
        interest: Math.round((homeLoanAmount * interestRate) / 1200),
        principalRepaid:
          calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount)
          - (homeLoanAmount * interestRate) / 1200,
        remainingPrincipalAmound: Math.round(
          homeLoanAmount
            - (calculationLoanEMI(rateInMonth, periodInmonths, homeLoanAmount)
              - (homeLoanAmount * interestRate) / 1200)
        )
      };
      totalInterest += (homeLoanAmount * interestRate) / 1200;
    }

    array.push(data);
  }

  console.log("array", array);
  return Math.ceil(totalInterest);
};

export const convertArabicToEnglish = (num) => num.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export const StringToNum = (number) => {
  let num = number;
  if (typeof num === "number") return num;
  num = convertArabicToEnglish(number);
  if (Number.isNaN(parseInt(num.replace(",", ""), 10))) {
    return false;
  }
  return parseInt(num.replace(",", ""), 10);
};
export const NumToString = (num) => num.toLocaleString("en-US");

export const removeSpaces = (html) => {
  const domEl = new DOMParser().parseFromString(html, "text/html");
  const lang = localStorage.getItem("language");
  const loop = true;

  console.log("html", domEl);

  while (loop) {
    const nestedUL = domEl.querySelector("ul > ul");

    if (!nestedUL) break;
    nestedUL.style.margin = 0;

    const lastLi = nestedUL.previousElementSibling;
    lastLi.appendChild(nestedUL);
  }

  // add styling to title
  const nestedTitle = domEl.querySelectorAll("h4");
  const nestedSubtitle = domEl.querySelectorAll("h5");
  const nestedBody = domEl.querySelectorAll("p");
  const nestedBody2 = domEl.querySelectorAll("h6");
  const nestedLi = domEl.querySelectorAll("li");

  if (nestedTitle && lang === "en") {
    nestedTitle.forEach((font) => {
      font.style.fontFamily = "Montserrat";
      font.style.lineHeight = "26px";
      font.style.fontWeight = 700;
      font.style.letterSpacing = -0.16;
      font.style.margin = 4;
      font.style.fontStyle = "normal";
    });
  }

  if (nestedSubtitle && lang === "en") {
    nestedSubtitle.forEach((font) => {
      font.style.fontFamily = "Montserrat";
      font.style.lineHeight = "22px";
      font.style.fontWeight = 500;
      font.style.margin = 4;
      font.style.fontStyle = "normal";
    });
  }

  if (nestedBody && lang === "en") {
    nestedBody.forEach((font) => {
      font.style.fontFamily = "Montserrat";
      font.style.lineHeight = "22px";
      font.style.fontWeight = 400;
      font.style.margin = 4;
      font.style.fontStyle = "normal";
    });
  }

  if (nestedBody2 && lang === "en") {
    nestedBody2.forEach((font) => {
      font.style.fontFamily = "Montserrat";
      font.style.lineHeight = "22px";
      font.style.fontWeight = 400;
      font.style.margin = 4;
      font.style.fontStyle = "normal";
    });
  }

  if (nestedLi && lang === "en") {
    nestedLi.forEach((font) => {
      font.style.fontFamily = "Montserrat";
      font.style.fontWeight = 400;
      font.style.marginLeft = 2;
      font.style.fontStyle = "normal";
    });
  }

  if (nestedTitle && lang === "ar") {
    nestedTitle.forEach((font) => {
      font.style.fontFamily = 'FF Shamel Family';
      font.style.lineHeight = "26px";
      font.style.letterSpacing = -0.16;
      font.style.fontWeight = 700;
      font.style.margin = 4;
    });
  }

  if (nestedSubtitle && lang === "ar") {
    nestedSubtitle.forEach((font) => {
      font.style.fontFamily = 'FF Shamel Family';
      font.style.lineHeight = "22px";
      font.style.fontWeight = 500;
      font.style.margin = 4;
    });
  }

  if (nestedBody && lang === "ar") {
    nestedBody.forEach((font) => {
      font.style.fontFamily = 'FF Shamel Family';
      font.style.lineHeight = "20px";
      font.style.fontWeight = 400;
      font.style.margin = 4;
    });
  }

  if (nestedBody2 && lang === "ar") {
    nestedBody2.forEach((font) => {
      font.style.fontFamily = 'FF Shamel Family';
      font.style.lineHeight = "20px";
      font.style.fontWeight = 400;
      font.style.margin = 4;
    });
  }

  if (nestedLi && lang === "ar") {
    nestedLi.forEach((font) => {
      font.style.fontFamily = 'FF Shamel Family';
      font.style.fontWeight = 400;
      font.style.marginRight = 2;
    });
  }
  const parsedHTML = domEl.body.innerHTML;

  let sanitizedHtml = parsedHTML.replace(/<\/span>&nbsp;<\/p>/g, "</span></p>");
  sanitizedHtml = sanitizedHtml.replace(/<\/span>&nbsp;<\/li>/g, "</span></li>");
  sanitizedHtml = sanitizedHtml.replace(/<em>/g, "");
  sanitizedHtml = sanitizedHtml.replace(/<\/em>/g, "");
  sanitizedHtml = sanitizedHtml.replace(/<strong>/g, "");
  sanitizedHtml = sanitizedHtml.replace(/<\/strong>/g, "");

  sanitizedHtml = sanitizedHtml.replace(/&quot;/g, "");
  console.log("sanitized", sanitizedHtml);
  return sanitizedHtml;
};

export const generateList = (N, generator) => {
  const list = [];

  [...Array(N).keys()].forEach(() => {
    const item = generator();
    list.push(item);
  });

  return list;
};

export const mapNumbersToWeekDays = (dayOfWeek) => {
  const week = ["S", "M", "T", "W", "T", "F", "S"];
  const selectedDayOfWeek = week[dayOfWeek];
  return selectedDayOfWeek;
};

export const mapDateToMonths = (monthValue) => {
  const monthsData = {
    "01": {
      month: "January",
      key: "01"
    },
    "02": {
      month: "February",
      key: "02"
    },
    "03": {
      month: "March",
      key: "03"
    },
    "04": {
      month: "April",
      key: "04"
    },
    "05": {
      month: "May",
      key: "05"
    },
    "06": {
      month: "June",
      key: "06"
    },
    "07": {
      month: "July",
      key: "07"
    },
    "08": {
      month: "August",
      key: "08"
    },
    "09": {
      month: "September",
      key: "09"
    },
    10: {
      month: "October",
      key: "10"
    },
    11: {
      month: "November",
      key: "11"
    },
    12: {
      month: "December",
      key: "12"
    }
  };

  const selected = monthsData[monthValue];
  return selected.month;
};

export const yymmdd = (date, replace) => {
  const year = date?.toLocaleString("default", { year: "numeric" });
  const month = date?.toLocaleString("default", { month: "2-digit" });
  const day = date?.toLocaleString("default", { day: "2-digit" });

  let newDate;
  if (replace) {
    newDate = [year, month, day].join("-").replace(/-/g, "");
  } else {
    newDate = [year, month, day].join("-");
  }

  return newDate;
};

export const ddmmyyyy = (date, joiner = "/") => {
  let newDate;
  if (!date) {
    newDate = null;
  } else {
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    newDate = [day, month, year].join(joiner);
  }

  return newDate;
};

export const formatyyyymmdd = (date) => {
  const year = date.slice(0, 4);
  const month = mapDateToMonths(date.slice(4, 6)).substring(0, 3);
  const day = date.slice(-2);

  return `${day} ${month} ${year}`;
};

// get object with key = value
export const mapKeys = (obj) => {
  const props = {};

  Object.keys(obj).forEach((key) => {
    props[key] = key;
  });

  return props;
};

// format data to submit to csv reader
export const formatDataToCsv = (headers, data) => data?.map((item) => {
  let obj = {};
  headers.forEach(({ key }) => {
    obj = {
      ...obj,
      [key]: item[key]
    };
  });
  return obj;
});

export const cleanJSONData = (data) => {
  console.log(data);

  const nestedArray = data?.map((it) => {
    const arr = Object.values(it);
    return arr;
  });
  return nestedArray;
};

export const checkArrayMissingElements = (arr, newArr) => arr.every((v) => newArr.includes(v));

export const capitalizeFirstLetter = (str, separator) => {
  const words = str?.split(separator);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  const capString = words.join(" ");
  return capString;
};

export const renderSelecteddSidebarItem = (userRole, pathname) => {
  if (userRole === "EXTERNAL") {
    const path = pathname.split("/")[2];
    switch (path) {
      case "dashboard":
        return 1;
      case "customer-insights":
        return 2.1;
      case "financial-insights":
        return 2.2;
      case "products":
        return 3;
      case "profile":
        return 4;
      default:
        return 1;
    }
  } else {
    const path = pathname.split("/")[1];
    const isExternal = pathname.split("/")[2] === "external";
    if (isExternal) {
      return 4;
    }
    switch (path) {
      case "dashboard":
        return 1;
      case "settings":
        return 2;
      case "terms-and-conditions":
        return 2;
      case "stats":
        return 3;
      default:
        return 1;
    }
  }
};
