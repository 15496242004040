import React from "react";
import { Box, Typography } from "@mui/material";
import { bool, instanceOf, string } from "prop-types";

const UsersPhoneFormatter = ({ children, rowData, phoneVerified }) => {
  const isPhoneVerified = rowData?.phoneVerified || phoneVerified;
  return (
    children?.length > 1 ? (
      <Box sx={{
        display: "flex", alignItems: "center", justifyContent: "center"
      }}
      >
        <Typography variant="newBody1" mr={1}>{children}</Typography>
        {isPhoneVerified && <Typography color="#68B6B5">&#x2713;</Typography>}
      </Box>
    ) : <Typography variant="newBody1">N/A</Typography>
  );
};

UsersPhoneFormatter.propTypes = {
  children: string.isRequired,
  rowData: instanceOf(Object),
  phoneVerified: bool
};

UsersPhoneFormatter.defaultProps = {
  rowData: null,
  phoneVerified: false
};

export default UsersPhoneFormatter;
