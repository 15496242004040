/* eslint-disable react/no-array-index-key */
import React from "react";
import { instanceOf, string } from "prop-types";
import {
  BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid, Label, ResponsiveContainer, Brush
} from "recharts";
import { CircularProgress } from "@mui/material";
import ChartTooltip from "./ChartTooltip";
import "./styles.css";

const ExternalReportsChart = ({
  data, key1, key2, unit, bankName
}) => {
  const handleNoDataAvailable = () => {
    const val1 = data?.map(
      (item) => item[key1]
    ).reduce((prev, current) => prev + current, 0);
    const val2 = data?.map(
      (item) => item[key2]
    ).reduce((prev, current) => prev + current, 0);
    //  console.log(val1 + val2);
    if (val1 + val2 === 0) {
      return true;
    }
    return false;
  };

  const checkIfDomainEmpty = () => {
    const val1 = data?.map(
      (item) => item[key1]
    ).reduce((prev, current) => prev + current, 0);
    if (val1 === 0) return true;
    return false;
  };

  const checkIfKeyExists = () => {
    if ("day" in data[0]) {
      return "day";
    }
    return "date";
  };

  if (!data?.length) return <CircularProgress size="sm" />;

  return (
    <ResponsiveContainer width="100%" height={210}>
      <BarChart data={data} barCategoryGap="10%" margin={{ left: -10 }}>
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis dataKey={checkIfKeyExists()} fontSize={12} tickMargin={10}>
          <Label position="top" offset={70}>
            {handleNoDataAvailable() ? "No data available" : ""}
          </Label>
        </XAxis>
        <YAxis
          domain={handleNoDataAvailable() ? [0, 100] : [0, "datamax"]}
          unit={unit}
          // tickSize={8}
          fontSize={12}
          tickCount={checkIfDomainEmpty() ? 1 : 5}
        />
        <Tooltip shared={false} content={<ChartTooltip bgColor="#DEA8DD" bankName={bankName} />} />
        {data?.length > 12 && (
        <Brush dataKey={checkIfKeyExists()} height={10} stroke="#69B5B5" width="100%" startIndex={data.length - 12} endIndex={data.length - 1} />
        )}
        <Bar
          dataKey={key1}
          fill="#29173B"
          radius={9}
          unit={unit}
          barSize={20}
        />
        {key2 && (
        <Bar
          dataKey={key2}
          fill="#DEA8DD"
          radius={9}
          unit="%"
          barSize={20}
        />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

ExternalReportsChart.propTypes = {
  data: instanceOf(Array),
  key1: string.isRequired,
  key2: string,
  unit: string,
  bankName: string.isRequired
};

ExternalReportsChart.defaultProps = {
  data: null,
  key2: null,
  unit: ""
};

export default ExternalReportsChart;
