import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { CacheProvider } from '@emotion/react'
// import createCache from '@emotion/cache'
// import rtlPlugin from 'stylis-plugin-rtl'
import { Box } from "@mui/material";
// import { prefixer } from 'stylis'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { selectDirection, setLanguage } from "store/app/appSlice";
import ThemeOptions from "theme/index";
import "react-datepicker/dist/react-datepicker.css";
import "styles/styles.scss";
import AuthenticatedLayout from "layouts/AuthenticatedLayout/AuthenticatedLayout";

const App = () => {
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();
  const theme = createTheme(ThemeOptions({ direction }));

  useEffect(() => {
    dispatch(setLanguage("en"));
  }, []);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <AuthenticatedLayout />
            <ToastContainer
              position="bottom-center"
              theme="colored"
              hideProgressBar
              // autoClose={false}
              autoClose={5000}
            />
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </Box>
  );
};

export default App;
