import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import draftjsToHtml from "draftjs-to-html";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import AccordionListItem from "components/AccordionListItem/AccordionListItem";
import { getFaqById, updateFaqByExpert } from "store/actions/faqs";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { getFaqByIdSelector, getIsLoadingFaqsSelector } from "store/selectors/faqs";
import PageLoading from "components/PageLoading";
import { removeSpaces } from "utils/functions";
import { successToast } from "utils";
import { useTranslation } from "react-i18next";
import { DisablePermissions } from "components/DisablePermissions";
import BaseButton from "components/formControl/baseButton/BaseButton";
import palette from "theme/palette";
import { loadTopicsByExpertId } from "store/actions/topics";
import { loadCategoriesByExpertId } from "store/actions/products";
import BaseSelect from "components/formControl/baseSelect/BaseSelect";
import { getExperts } from "store/actions/experts";
import { getExpertsSelector } from "store/selectors/experts";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";
import { selectLanguage, setLanguage } from "store/app/appSlice";

const ExpertsNewFaqPage = () => {
  const { id, fid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const faq = useSelector(getFaqByIdSelector);
  const lang = useSelector(selectLanguage);
  const [searchParams] = useSearchParams();
  const isLoadingFaq = useSelector(getIsLoadingFaqsSelector);
  const experts = useSelector(getExpertsSelector);
  const [categoriesByAuthor, setCategoriesByAuthor] = useState([]);
  const [topicsByAuthor, setTopicsByAuthor] = useState([]);
  const [formErrors, setFormErrors] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(async () => {
    if (searchParams.get("lang") && searchParams.get("lang") === "ar") {
      await dispatch(setLanguage("ar"));
    }
    dispatch(getFaqById({ id: fid }));
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(loadCategoriesByExpertId({
        params: {
          id
        },
        cb: (res) => setCategoriesByAuthor(res)
      }));
      dispatch(loadTopicsByExpertId({
        params: {
          id
        },
        cb: (res) => setTopicsByAuthor(res)
      }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getExperts());
  }, []);

  const FaqSchema = Yup.object().shape({
    question: Yup.string().required(t("validation.required")),
    authorId: Yup.string().required(t("validation.required"))
  });

  const renderEditorContent = (answer) => {
    const contentBlock = htmlToDraft(`${answer}`);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
  };

  const handleAuthorChange = (value, event, onChange, onChangeField) => {
    onChange({
      ...event,
      target: { name: "authorId", value }
    });
    onChangeField("categoryId", []);
    onChangeField("topics", []);

    dispatch(loadCategoriesByExpertId({
      params: {
        id: value
      },
      cb: (res) => setCategoriesByAuthor(res)
    }));
    dispatch(loadTopicsByExpertId({
      params: {
        id: value
      },
      cb: (res) => setTopicsByAuthor(res)
    }));
  };

  const handleUpdateFaq = (values) => {
    const {
      question, answer, categoryId, topics, authorId
    } = values;
    const domElAnswer = draftjsToHtml(convertToRaw(answer.getCurrentContent()))?.toString();
    const emptyEditor = domElAnswer.includes("<p></p>") || domElAnswer.includes("<p style=\"margin-left:4px;\"></p>");
    const errorObj = {
      ...formErrors,
      ...(!categoryId?.length && !topics?.length && { expertise: true }),
      ...(emptyEditor && { answer: true })

    };
    setFormErrors(errorObj);

    if (Object.values(errorObj).some((item) => item)) return;
    const isArray = Array.isArray(categoryId);
    dispatch(
      updateFaqByExpert({
        id: values.id,
        params: {
          authorId,
          question,
          answer: removeSpaces(
            draftjsToHtml(convertToRaw(values.answer.getCurrentContent()))
          ),
          categoryId: isArray ? categoryId[0] : (categoryId || ""),
          topics
        },
        cb: () => {
          successToast("Faq has been updated");
          setIsLoading(true);
          setTimeout(() => {
            navigate(`/experts/${authorId}/faq/${fid}${lang === "ar" ? "?lang=ar" : ""}`);
            dispatch(getFaqById({ id: fid }));
            setIsLoading(false);
          }, 1000);
        }
      })
    );
  };

  if (isLoading || isLoadingFaq) return <PageLoading loading />;

  return (
    <Box sx={{ padding: 2 }} mt={2}>
      <Formik
        initialValues={{
          id: faq?.id,
          authorId: id,
          question: faq?.question,
          categoryId: faq?.categoryId ? [faq.categoryId] : [],
          topics: faq?.topics || [],
          answer: renderEditorContent(faq?.answer)
        }}
        validationSchema={FaqSchema}
        onSubmit={handleUpdateFaq}
      >
        {({
          values, handleChange, handleSubmit, errors, setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <AccordionListItem
                item={faq}
                hideItem
                expanded
              >
                <Box mb={3}>
                  <BaseTextField
                    placeholder={t("fields.someText")}
                    fullWidth
                    label={t("fields.question")}
                    name="question"
                    onChange={handleChange}
                    value={values.question}
                    error={!!errors.question}
                    helperText={errors.question}
                    color={!errors.question && values.question ? "success" : ""}
                  />
                </Box>
                <RichTextEditor
                  value={values.answer}
                  onChange={(editorState) => {
                    setFieldValue("answer", editorState);
                    setFormErrors({ ...formErrors, answer: false });
                  }}
                  error={formErrors.answer}
                />
                <Box mt={1}>
                  <BaseSelect
                    name="authorId"
                    labelStyle={{ fontSize: "11px", fontWeight: 600, lineHeight: "14px" }}
                    label={t("fields.authorName")}
                    items={experts.map((x) => ({
                      id: x.id,
                      title: x.name
                    }))}
                    placeholder="expert"
                    initvalue={values.authorId}
                    onChange={
                      (
                        value,
                        event
                      ) => handleAuthorChange(value, event, handleChange, setFieldValue)
                    }
                    error={formErrors.author}
                    sx={{
                      width: "100%", mb: 3, mt: 3, ml: 0
                    }}
                  />
                </Box>
                <Box mt={1}>
                  <Typography variant="smallTitle">
                    {t("admin.productCategory")}
                    {" "}
                    (Max 1 selected)
                  </Typography>
                  <Box mt={2}>
                    {
                      categoriesByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={categoriesByAuthor}
                          exclusive
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "categoryId", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={values.categoryId}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No categories available for this author</Typography>
                      )
                    }
                  </Box>
                </Box>
                <Box mt={1}>
                  <Typography variant="smallTitle">
                    {t("fields.topics")}
                  </Typography>
                  <Box mt={2}>
                    {
                      topicsByAuthor?.length ? (
                        <MultipleSelectionToggleBtn
                          data={topicsByAuthor}
                          onChange={(event, value) => {
                            handleChange({
                              ...event,
                              target: { name: "topics", value }
                            });
                            setFormErrors({ ...formErrors, expertise: false });
                          }}
                          selected={values.topics}
                        />
                      ) : (
                        <Typography variant="smallTitle" fontWeight={400}>No topics available for this author</Typography>
                      )
                    }
                  </Box>
                  {Boolean(formErrors.expertise) && (
                    <FormRequiredText>At least one category or topic is required</FormRequiredText>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DisablePermissions permission="write:faq" disable>
                    <BaseButton
                      customColor={palette.variables.darkPurple}
                      type="submit"
                      fullWidth
                      variant="contained"
                      element={t("save")}
                      sx={{
                        display: "block",
                        maxWidth: "100px",
                        margin: 2,
                        marginRight: 0,
                        marginLeft: 0
                      }}
                    />
                  </DisablePermissions>
                </Box>
              </AccordionListItem>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ExpertsNewFaqPage;
