import React from "react";
import { Box, Typography } from "@mui/material";
import { bool, instanceOf, string } from "prop-types";

const ChartTooltip = ({
  active, payload, bgColor, isSingleTooltip, bankName, tooltipValue
}) => {
  if (active && payload.length) {
    const { dataKey } = payload[0];
    const tooltipData = payload[0]?.payload;

    const handleBgColor = (barKey) => {
      if (barKey === "nbbClickPercent") {
        return "#29173B";
      }
      return bgColor;
    };

    const handleLabelName = (barKey) => {
      if (barKey === "nbbClickPercent") {
        return bankName;
      }
      return "Others";
    };

    if (dataKey in tooltipData) {
      return (
        <Box
          bgcolor={() => handleBgColor(dataKey)}
          sx={{ borderRadius: 3, padding: 0.5, paddingX: 2 }}
        >
          {isSingleTooltip
            ? <Typography variant="subtitle1" color="white">{tooltipData[tooltipValue]}</Typography>
            : (
              <Typography variant="subtitle1" color="white">
                {handleLabelName(dataKey)}
                {": "}
                { tooltipData[dataKey] }
                %
              </Typography>
            )}
        </Box>
      );
    }
  }
  return null;
};

ChartTooltip.propTypes = {
  active: bool,
  payload: instanceOf(Array),
  isSingleTooltip: bool,
  bankName: string
};

ChartTooltip.defaultProps = {
  active: false,
  payload: [],
  isSingleTooltip: false,
  bankName: "Your bank"
};

export default ChartTooltip;
