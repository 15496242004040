import React, { useState } from "react";
import {
  instanceOf, number, string
} from "prop-types";
import { Box, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ImageFormatter = ({
  children,
  imgKey,
  rowData,
  imgSrc,
  fontVariant,
  flexDirection,
  fontWeight,
  imgWidth,
  tooltip,
  navigateState,
  navigateTo,
  ...ImgProps
}) => {
  const img = imgSrc || rowData[imgKey];
  const navigate = useNavigate();
  const [underline, setUnderline] = useState(false);

  return (
    <Box sx={{
      display: "flex", flexDirection, justifyContent: "flex-start", alignItems: "center", textAlign: "start"
    }}
    >
      <img
        alt={children}
        src={img}
        style={{
          marginRight: 5, objectFit: "contain", width: imgWidth, height: "40px"
        }}
        {...ImgProps}
      />
      {children && (
        !navigateTo ? (
          <Typography variant={fontVariant} fontWeight={fontWeight}>{children}</Typography>
        ) : (
          <Tooltip title={tooltip}>
            <div
              onClick={() => navigate(`${navigateTo}/${rowData.id}`, { state: navigateState })}
              style={{
                width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"
              }}
              onMouseOver={() => setUnderline(true)}
              onMouseOut={() => setUnderline(false)}
              onFocus={() => setUnderline(true)}
              onBlur={() => setUnderline(false)}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <Typography variant={fontVariant} fontWeight={fontWeight} sx={{ cursor: "pointer", textDecoration: underline ? "underline" : "none" }}>
                {children}
              </Typography>
            </div>
          </Tooltip>
        )

      )}
    </Box>
  );
};

ImageFormatter.propTypes = {
  children: string,
  imgKey: string,
  imgSrc: string,
  rowData: instanceOf(Object),
  fontVariant: string,
  flexDirection: string,
  fontWeight: number,
  imgWidth: string,
  navigateTo: string,
  navigateState: instanceOf(Object),
  tooltip: string
};

ImageFormatter.defaultProps = {
  children: null,
  imgKey: null,
  imgSrc: null,
  rowData: null,
  fontVariant: "newBody1",
  flexDirection: "row",
  fontWeight: 400,
  navigateTo: null,
  navigateState: null,
  imgWidth: "59px",
  tooltip: ""
};

export default ImageFormatter;
