import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { addDays, isToday, subDays } from "date-fns";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { func } from "prop-types";
import startOfWeek from "date-fns/startOfWeek";

const DateSwitcher = ({ onChange }) => {
  const firstDayOfWeek = startOfWeek(new Date());
  const currentWeek = [firstDayOfWeek, addDays(firstDayOfWeek, 6)];
  const [date, setDate] = useState([currentWeek[0], currentWeek[1]]);

  const formatDate = (dateObject) => {
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObject
    );

    const day = dateObject.getDate();
    return `${day} ${month}`;
  };

  const handlePrev = () => {
    const newDate = [subDays(date[0], 7), subDays(date[1], 7)];
    onChange(newDate);
    setDate([newDate[0], newDate[1]]);
  };

  const handleNext = () => {
    const newDate = [addDays(date[0], 7), addDays(date[1], 7)];
    onChange(newDate);
    setDate(newDate);
  };

  return (
    <Box mb={2} width="100%" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <IconButton onClick={() => handlePrev()} size="small">
        <ArrowBackIos fontSize="10px" />
      </IconButton>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="newBody1" mr={1}>{formatDate(date[0])}</Typography>
        <Typography mr={1}>{" - "}</Typography>
        <Typography variant="newBody1">{formatDate(date[1])}</Typography>
      </Box>
      <IconButton onClick={() => handleNext()} disabled={isToday(date[1])} size="small">
        <ArrowForwardIos fontSize="10px" />
      </IconButton>

    </Box>
  );
};

DateSwitcher.propTypes = {
  onChange: func.isRequired
};

export default DateSwitcher;
