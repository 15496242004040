import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box, Grid, InputLabel, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import * as Yup from "yup";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import draftjsToHtml from "draftjs-to-html";
import BaseSelect from "components/formControl/baseSelect/BaseSelect";
import BaseTextarea from "components/formControl/baseTextarea/BaseTextarea";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import palette from "theme/palette";
import BaseButton from "components/formControl/baseButton/BaseButton";
import { DisablePermissions } from "components/DisablePermissions";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewProduct, fetchCategoryById, fetchCategorySummary, fetchProductById
} from "store/actions/products";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { setStateProductValue } from "store/reducers/products";
import { getFilterProductTags, getPrimaryProductTags } from "store/actions/tag";
import { fetchPartners } from "store/actions/partners";
import { EditorState, convertToRaw } from "draft-js";
import { getUserRoleSelector } from "store/selectors/user";
import { setImage } from "store/actions/image";
import { selectLanguage } from "store/app/appSlice";
import { currencyOptions } from "utils/staticValues";
import { removeSpaces } from "utils/functions";
import { successToast } from "utils";
import PageLoader from "components/PageLoader/PageLoader";
import ProductMainFields from "components/ProductMainFields/ProductMainFields";

const ProductsCreatePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const lang = useSelector(selectLanguage);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [applyText, setApplyText] = useState(EditorState.createEmpty());
  const {
    error, productCategory, primaryProductTags, filterProductTags
  } = useSelector(
    (state) => state.products
  );
  const cmsUserRole = useSelector(getUserRoleSelector);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryMainFields, setCategoryMainFields] = useState({});
  const [mainFieldsFormValue, setMainFieldsFormValue] = useState({});

  useEffect(async () => {
    dispatch(fetchPartners());
    dispatch(setStateProductValue({ type: "product", data: {} }));
    dispatch(fetchCategorySummary({ id: "summary" }));
    dispatch(setStateProductValue({ type: "productCategory", data: [] }));

    const primaryTagsData = await dispatch(getPrimaryProductTags());
    dispatch(setStateProductValue({ type: "primaryProductTags", data: primaryTagsData.payload }));
    const filterTagsData = await dispatch(getFilterProductTags());
    dispatch(setStateProductValue({ type: "filterProductTags", data: filterTagsData.payload }));
  }, []);

  useEffect(() => {
    if (lang === "ar" && error === "Request failed with status code 412") {
      setApplyText(EditorState.createEmpty());
    }
  }, [lang, error]);

  const renderMainFields = (selCategory) => {
    if (selectedCategory) {
      const arrToParse = Object.values(selCategory);
      const arr = arrToParse.reduce((consumer, _item, index) => {
        let item = {};
        item = _item;
        const propertyName = `main_field${index + 1}`;
        const propertyNotation = `main_field${index + 1}NotationId`;

        const summary = item.value?.split(" ") || [];
        const valueText = summary?.length > 2
          ? item.value
          : summary?.length === 2 && currencyOptions.find(({ title }) => title === summary[0])
            ? summary[1]
            : summary?.length === 2 && currencyOptions.find(({ title }) => title === summary[1])
              ? summary[0]
              : summary[0]?.replace(/%/, "");
        const notationId = summary?.length === 1 && summary[0].includes("%")
          ? 5
          : summary?.length === 2
            ? currencyOptions.find(({ title }) => title === summary[0] || title === summary[1])?.id
            : null;
        consumer[propertyName] = valueText || "";
        consumer[propertyNotation] = notationId || 1;
        return consumer;
      }, {});
      return arr;
    }
    return {};
  };

  const initialState = {
    categoryId: "",
    providerId: id,
    name: "",
    imageThumb: "",
    imageFull: "",
    previewText: "",
    description: "",
    mainFields: {},
    aditional: "",
    primaryTags: [],
    filterTags: [],
    slaDays: "",
    howToApply: ""
  };

  const ProductCreateSchema = Yup.object().shape({
    name: Yup.string().required(t("validation.required")),
    imageFull: Yup.string().required(t("validation.required")),
    imageThumb: Yup.string().required(t("validation.required")),
    description: Yup.string().required(t("validation.required")),
    previewText: Yup.string().required(t("validation.required")),
    aditional: Yup.string().required(t("validation.required"))
  });

  const handleSelectedCategoryChange = (value) => {
    setSelectedCategoryId(value);
    dispatch(fetchCategoryById({
      id: `${value}`,
      cb: (res) => {
        setSelectedCategory(res);
        setCategoryMainFields(renderMainFields(res?.mainFields));
      }
    }));
  };
  const handleSubmitProduct = async (values) => {
    const thumbnailUrl = typeof values.imageThumb === "string"
      ? values.imageThumb
      : (await dispatch(setImage({ params: values.imageThumb, fieldName: "Thumbnail" }))).payload
        .url;
    const imageUrl = typeof values.imageFull === "string"
      ? values.imageFull
      : (await dispatch(setImage({ params: values.imageFull, fieldName: "Full Size" }))).payload
        .url;
    if (!thumbnailUrl) return;
    if (!imageUrl) return;

    const mainFields = Object.keys(mainFieldsFormValue).reduce((consumer, key) => {
      if (key.includes("NotationId")) return consumer;
      const notationId = mainFieldsFormValue[`${key}NotationId`];
      let notation = notationId ? currencyOptions[notationId - 1].title : "";
      notation = notation.replace("None", "");
      const value = !notation || notation === "%"
        ? mainFieldsFormValue[key] + notation
        : `${notation} ${mainFieldsFormValue[key]}`;
      consumer[key] = value;
      return consumer;
    }, {});

    const temp = {
      main_field_0: mainFields.main_field1,
      main_field_1: mainFields.main_field2,
      main_field_2: mainFields.main_field3,
      main_field_3: mainFields.main_field4,
      main_field_4: mainFields.main_field5,
      main_field_5: mainFields.main_field6
    };
    const params = {
      categoryId: values.categoryId,
      providerId: id,
      name: values.name,
      description: values.description,
      previewText: values.previewText,
      additionalText: values.aditional,
      mainFields: temp,
      slaDays: values.slaDays || "",
      imageUrl,
      thumbnailUrl,
      productTags: values.primaryTags,
      filterTags: values.filterTags,
      howToApply: removeSpaces(draftjsToHtml(convertToRaw(applyText.getCurrentContent())))
    };

    dispatch(
      createNewProduct({
        params,
        cb: async (res) => {
          setLoading(true);
          successToast("Product has been created");
          const productData = await dispatch(fetchProductById({ id: res.id }));
          dispatch(setStateProductValue({ type: "product", data: productData.payload }));
          if (cmsUserRole === "EXTERNAL") {
            navigate(`/${id}/products/${res.id}`, { replace: true }, { state: { hideChoosePartner: true } });
          }
          setLoading(false);
        }
      })
    );
  };

  const getFilterTagsToShow = () => {
    const rangeTags = [
      ...new Set(
        selectedCategory?.filter?.productFilterRanges
          ?.map((ques) => ques.tags.map((tag) => tag.id))
          .flat(1)
      )
    ];
    const normalTags = [
      ...new Set(
        selectedCategory?.filter?.productFilterCheckBoxes?.map((ques) => ques.tag.id).flat(1)
      )
    ];
    const allFilterTags = filterProductTags?.filter(
      (_filter) => (!_filter?.valueSuffix && normalTags?.find((f) => f == _filter.id))
        || rangeTags?.find((f) => f == _filter.id)
    );
    return allFilterTags || [];
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box>
      <Formik
        initialValues={initialState}
        validationSchema={ProductCreateSchema}
        onSubmit={handleSubmitProduct}
        enableReinitialize
      >
        {({
          values, errors, handleChange, handleSubmit, setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="bodyBig" component="p" color="common.darkPurple" my={3}>
              {t("admin.productDetails")}
            </Typography>
            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: "24px" }}
              margin="normal"
              fullWidth
              id="name"
              label={t("fields.name")}
              name="name"
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              value={values.name}
              color={!errors.name && values.name ? "success" : ""}
            />
            <Box sx={{ display: "flex" }}>
              {(typeof values.imageThumb === "object") && (
              <img
                style={{ height: "55px", marginRight: "20px" }}
                src={
                  typeof values.imageThumb === "object"
                    && URL.createObjectURL(values.imageThumb)
                }
                alt=""
              />
              )}
              <BaseTextField
                InputLabelProps={{ required: false }}
                margin="normal"
                fullWidth
                name="imageThumb"
                label={`${t("fields.thumbnail")} (.jpeg, .jpg, .png) Max 75KB`}
                id="imageThumb"
                type="file"
                error={!!errors.imageThumb}
                helperText={errors.imageThumb}
                onChange={(e) => setFieldValue("imageThumb", e.currentTarget.files[0])}
                file={values.imageThumb}
                color={!errors.imageThumb && values.imageThumb ? "success" : ""}
                sx={{ marginBottom: "30px" }}
                disabled={lang === "ar"}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              {(typeof values.imageFull === "object") && (
              <img
                style={{ height: "55px", marginRight: "20px" }}
                src={
                   typeof values.imageFull === "object"
                    && URL.createObjectURL(values.imageFull)
                }
                alt=""
              />
              )}
              <BaseTextField
                InputLabelProps={{ required: false }}
                margin="normal"
                fullWidth
                name="imageFull"
                label={`${t("fields.fullsizeImage")} (.jpeg, .jpg, .png) Max 75KB`}
                id="imageFull"
                type="file"
                error={!!errors.imageFull}
                helperText={errors.imageFull}
                onChange={(e) => setFieldValue("imageFull", e.currentTarget.files[0])}
                file={values.imageFull}
                color={!errors.imageFull && values.imageFull ? "success" : ""}
                sx={{ marginBottom: "30px" }}
                disabled={lang === "ar"}
              />
            </Box>
            <InputLabel sx={{ mt: 3 }} variant="outlined">
              {t("fields.selectCategory")}
            </InputLabel>
            <BaseSelect
              defaultSelection="Select Category"
              id="categoryId"
              name="categoryId"
              items={productCategory}
              onChange={(value) => {
                setMainFieldsFormValue({});
                handleSelectedCategoryChange(value);
                setFieldValue("categoryId", value);
              }}
              initvalue={values.categoryId}
              sx={{ width: "100%", marginBottom: 3, m: 0 }}
              error={!!errors.categoryId}
              helperText={errors.categoryId}
              color={!errors.categoryId && values.categoryId ? "success" : ""}
            />
            <BaseTextarea
              style={{ marginBottom: "16px" }}
              label={t("fields.previewText")}
              id="text"
              name="previewText"
              onChange={handleChange}
              error={!!errors.previewText}
              helperText={errors.previewText}
              value={values.previewText}
              placeholder="Add preview text"
            />
            <BaseTextarea
              style={{ marginBottom: "16px" }}
              label={t("fields.productDescription")}
              id="text"
              name="description"
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
              value={values.description}
              placeholder="Add description"
            />
            <Grid container spacing={1}>
              { Object.keys(categoryMainFields).length > 0
      && Object.keys(categoryMainFields).map((key, index) => {
        if (index % 2 !== 0) return;
        const labelValue = selectedCategory?.mainFields[key]?.label || "";
        return (
          <ProductMainFields
            key={key}
            id={key}
            number={index}
            label={labelValue}
            categoryMainFields={categoryMainFields}
            onChange={(val) => {
              console.log("val", val);
              return (
                setMainFieldsFormValue({
                  ...mainFieldsFormValue,
                  ...val
                })
              );
            }}
            productCategory={selectedCategory}
          />
        );
      })}
            </Grid>
            <BaseTextarea
              style={{ marginBottom: "16px" }}
          // color="common.greyLight"
              label={t("fields.additionalDetails")}
              id="text"
              name="aditional"
              onChange={handleChange}
              error={!!errors.aditional}
              helperText={errors.aditional}
              value={values.aditional}
              placeholder="Add description"
            />
            <BaseTextField
              inputLabelProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              sx={{ marginBottom: "24px" }}
              margin="normal"
              type="number"
              fullWidth
              id="slaDays"
              label="SLA Days"
              onChange={handleChange}
              value={values.slaDays}
            />
            <Typography
              style={{ fontWeight: 500 }}
              variant="body2"
              color="common.darkPurple"
              sx={{ mt: 3 }}
            >
              {t("fields.howToApply")}
            </Typography>

            <RichTextEditor
              value={applyText}
              onChange={(editorState) => setApplyText(editorState)}
            />

            <Grid container spacing={2} mt={2}>
              <Grid item xs={10} sx={{ display: "flex" }}>
                {primaryProductTags?.filter(
                  (tag) => tag.categoryId === state?.categoryId
                || tag.categoryId === selectedCategoryId
                || tag.categoryId === values.categoryId
                ).length > 0 && (
                <>
                  <LocalOfferIcon />
                  <Typography
                    style={{ fontWeight: 500 }}
                    sx={{ ml: 2 }}
                    variant="body2"
                    color="common.darkPurple"
                  >
                    {t("fields.primaryTag")}
                  </Typography>
                </>
                )}
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  disabled={lang === "ar"}
                  sx={{ flexWrap: "wrap", gap: "7px" }}
                  color="primary"
                  value={values.primaryTags}
                  onChange={(e, newValues) => {
                    setFieldValue("primaryTags", newValues);
                  }}
                >
                  {primaryProductTags
                    ?.filter(
                      (tag) => tag.categoryId === state?.categoryId
                    || tag.categoryId === selectedCategoryId
                    || tag.categoryId === values.categoryId
                    )
                    ?.map((tag) => (
                      <ToggleButton
                        sx={{
                          display: "flex",
                          gap: "8px",
                          overflow: "auto",
                          marginBottom: "15px",
                          flexWrap: "nowrap",
                          width: "max-content"
                        }}
                        selected={values.primaryTags.includes(tag.id)}
                        key={tag.id}
                        value={tag.id}
                      >
                        {tag.name}
                      </ToggleButton>
                    ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {getFilterTagsToShow()?.length > 0 && (
              <Grid item xs={8} sx={{ display: "flex" }}>
                <LocalOfferIcon />
                <Typography
                  sx={{ ml: 2 }}
                  variant="body2"
                  color="common.darkPurple"
                  style={{ fontWeight: 500 }}
                >
                  {t("fields.filterTag")}
                </Typography>
              </Grid>
              )}
              <Grid item>
                <ToggleButtonGroup
                  disabled={lang === "ar"}
                  sx={{ flexWrap: "wrap", gap: "7px" }}
                  color="primary"
                  value={values.filterTags}
                  onChange={(e, newValues) => {
                    setFieldValue("filterTags", newValues);
                  }}
                >
                  {getFilterTagsToShow()?.map((tag) => (
                    <ToggleButton
                      sx={{
                        display: "flex",
                        gap: "8px",
                        overflow: "auto",
                        marginBottom: "15px",
                        flexWrap: "nowrap",
                        width: "max-content"
                      }}
                      selected={values.filterTags.includes(tag.id)}
                      key={tag.id}
                      value={tag.id}
                    >
                      {tag.valueSuffix || tag.value}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <DisablePermissions permission="write:product" disable>
              <BaseButton
                customColor={palette.variables.darkPurple}
                type="submit"
                fullWidth
                variant="contained"
                element={t("save")}
                sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
              />
            </DisablePermissions>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProductsCreatePage;
