import {
  createSelector
} from "reselect";

const getNotificationTypes = (state) => state.notifications.notificationTypes;

const getIsLoadingNotifications = (state) => state.notifications.loading;

export const getNotificationsTypesSelector = createSelector(
  getNotificationTypes,
  (notificationTypes) => notificationTypes
);

export const getIsLoadingNotificationsSelector = createSelector(
  getIsLoadingNotifications,
  (loading) => loading
);
