/* eslint-disable react/no-array-index-key */
/* eslint-disable react/display-name */
import { instanceOf, string } from "prop-types";
import React from "react";
import {
  Cell, Pie, PieChart, ResponsiveContainer
} from "recharts";

const COLORS = ["#28183A", "#6C557F", "#AA92BF", "#552957", "#926193", "#DEA8DD", "#FFCBFF", "#FFE1FF"];
const RADIAN = Math.PI / 180;
/* const pieData = [
  { ageGroup: "15-19", value: 200 },
  { ageGroup: "20-24", value: 300 },
  { ageGroup: "25-29", value: 300 },
  { ageGroup: "30-34", value: 200 },
  { ageGroup: "35-39", value: 200 },
  { ageGroup: "40-44", value: 400 },
  { ageGroup: "45-49", value: 100 }
]; */

const StatisticsPieChart = ({ data, key }) => {
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const pieLabel = data[index]?.ageGroup;

    return (
      <text style={{ fontSize: "10px", lineHeight: "14px" }} x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {pieLabel}
      </text>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={240}>
      <PieChart width={500}>
        <Pie
          data={data}
          cx="50%"
          cy={100}
          innerRadius={35}
          outerRadius={90}
          fill="#8884d8"
          dataKey={key}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

StatisticsPieChart.propTypes = {
  data: instanceOf(Array).isRequired,
  key: string.isRequired
};

export default StatisticsPieChart;
