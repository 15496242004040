import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { bool, func, instanceOf } from "prop-types";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";

const MultipleSelectionToggleBtn = ({
  data, onChange, selected, isFilter, error, ...MultipleSelectionToggleBtnProps
}) => {
  const [selectedToggleBtn, setSelectedToggleBtn] = useState(selected);

  useEffect(() => {
    if (!selected?.length) {
      setSelectedToggleBtn([]);
    }
  }, [selected]);

  const handleToggle = (event, formats) => {
    setSelectedToggleBtn(formats);
    if (onChange && isFilter) {
      onChange(formats, "toggle");
    } else if (onChange) {
      onChange(event, formats);
    }
  };
  return (
    <>
      <ToggleButtonGroup
        size="small"
        value={selectedToggleBtn}
        onChange={handleToggle}
        sx={{ display: "flex", flexWrap: "wrap", boxSizing: "border-box" }}
        {...MultipleSelectionToggleBtnProps}
      >
        {data?.map((item) => (
          <ToggleButton
            value={item.id}
            key={item.id}
            sx={{
              marginRight: 1, fontSize: "11px !important", lineHeight: "14px !important", width: "max-content", marginBottom: 1
            }}
          >
            {item.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {Boolean(error) && (
      <FormRequiredText>At least one category or topic is required</FormRequiredText>
      )}
    </>
  );
};

MultipleSelectionToggleBtn.propTypes = {
  data: instanceOf(Array).isRequired,
  onChange: func,
  selected: instanceOf(Array),
  isFilter: bool
};

MultipleSelectionToggleBtn.defaultProps = {
  onChange: null,
  selected: [],
  isFilter: false
};

export default MultipleSelectionToggleBtn;
