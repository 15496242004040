import React, { useState } from "react";
import CustomTable from "components/Table/CustomTable";
import UserRewardsTableHeaders from "config/tableHeaders/InternalUsers/UserRewardsTableHeaders";
import { func, instanceOf } from "prop-types";

const UserRewardsTable = ({ data, onChangePage, ...TableProps }) => {
  const [activePage, setActivePage] = useState(1);

  const handleNextArrowClick = () => {
    setActivePage(activePage + 1);
    onChangePage(activePage + 1);
  };

  const handlePrevArrowClick = () => {
    setActivePage(activePage - 1);
    onChangePage(activePage - 1);
  };
  return (
    <CustomTable
      width="100%"
      data={data.records}
      loading={false}
      headers={UserRewardsTableHeaders}
      pagination={{
        activePage,
        totalPages: data.totalPages,
        next: handleNextArrowClick,
        prev: handlePrevArrowClick,
        totalRecords: data.totalRecords

      }}
      {...TableProps}
    />
  );
};

UserRewardsTable.propTypes = {
  data: instanceOf(Object).isRequired,
  onChangePage: func.isRequired
};

export default UserRewardsTable;
