import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPartnerById } from "store/actions/partners";
import { fetchCategory } from "store/actions/products";
import { getPartnerByIdSelector, getisLoadingPartnersSelector } from "store/selectors/partners";
import ProductsByCategoryCards from "components/ProductsByCatgeoryCards/ProductsByCategoryCards";
import { getIsLoadingProductsByCatSelector } from "store/selectors/products";
import { useTranslation } from "react-i18next";
import { Permissions } from "components/Permissions";
import PageLoader from "components/PageLoader/PageLoader";
import palette from "theme/palette";
import styles from "../admin.module.scss";

const ProductsByPartner = () => {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const dispatch = useDispatch();
  const { productCategory } = useSelector((state) => state.products);
  const isLoadingProducts = useSelector(getIsLoadingProductsByCatSelector);

  const partner = useSelector(getPartnerByIdSelector);
  const isLoadingPartners = useSelector(getisLoadingPartnersSelector);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchPartnerById({ id: providerId }));
  }, []);

  const partnerCategories = [];
  productCategory?.forEach(
    (category) => partner?.categoryIds?.forEach((item) => {
      if (item === category.id) {
        const {
          filter, iconUrl, tags, ...cleanCategory
        } = category;
        partnerCategories.push(cleanCategory);
      }
    })
  );

  if (isLoadingPartners || isLoadingProducts) {
    return <PageLoader />;
  }

  return (
    <Box styles={styles.container} padding={2} mt={2}>
      <Box
        sx={{
          display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"
        }}
        mb={3}
      >
        <Typography variant="newTitle" color={palette.variables.darkPurple2}>{partner?.name}</Typography>
        <Button
          variant="newButton"
          sx={{ backgroundColor: "#F2F2F2", fontSize: "12px", height: "35px !important" }}
          onClick={() => navigate(`/partners/create/${providerId}`, { state: { partner } })}
        >
          {t("admin.editDetails")}
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {
        partnerCategories.map((category) => (
          <Box key={category.id} mb={5}>
            <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="newSubtitle" color={palette.variables.darkPurple2}>{category.name}</Typography>
              <Permissions permission="write:product">
                <Button
                  variant="newButton"
                  sx={{ backgroundColor: "#F2F2F2", fontSize: "12px" }}
                  onClick={() => navigate(`/products/create/0/category/${category.id}`, { state: { providerId } })}
                >
                  {t("admin.addMore")}
                </Button>
              </Permissions>
            </Box>
            <ProductsByCategoryCards
              categoryId={category.id}
              name={category.name}
              categoryItem={category}
            />
          </Box>
        ))
      }
      </Box>
    </Box>
  );
};

export default ProductsByPartner;
