import React, { useEffect, useState } from "react";
import {
  Box, Grid, Typography, Card, CardContent, Checkbox
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import palette from "theme/palette";
import { ReactComponent as Wealth } from "assets/icons/Wealth.svg";
import MenuIcon from "@mui/icons-material/Menu";

// COMPONENTS
import PageLoader from "components/PageLoader/PageLoader";
import { Permissions } from "components/Permissions";
import BaseButton from "components/formControl/baseButton/BaseButton";

// ACTIONS
import { editRewardsListOrder, getRewards } from "store/actions/rewards";
import { getRewardsSelector, getisLoadingRewardsSelector } from "store/selectors/rewards";
import DraggableList from "react-draggable-list";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";
import { ddmmyyyy } from "utils/functions";
import { isPast } from "date-fns";
import styles from "./admin.module.scss";

const RewardsAdminPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rewards = useSelector(getRewardsSelector);
  const isLoadingRewards = useSelector(getisLoadingRewardsSelector);

  const [rewardsList, setRewardsList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRewards, setSelectedRewards] = useState({});
  const [error, setError] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState();

  useEffect(async () => {
    dispatch(getRewards());
  }, []);

  useEffect(() => {
    if (rewards) {
      setRewardsList(rewards);
    }
  }, [rewards]);

  useEffect(() => {
    if (rewards) {
      const currentSelected = {};
      rewards.forEach((item) => {
        currentSelected[item.id] = item.isPopular;
      });
      setSelectedRewards(currentSelected);

      const selectedLength = Object.values(currentSelected).filter((item) => item)?.length;

      if (selectedLength === 4) {
        setDisableCheckbox(true);
      }
    }
  }, [rewards]);

  const handleSubmitListOrder = () => {
    const selectedLength = Object.values(selectedRewards).filter((item) => item)?.length;
    if (selectedLength < 2) {
      setError("A minimum of two rewards must be selected as Most Popular");
      return;
    }
    const orderedRewards = rewardsList.map(({ id, name }) => {
      const isPopular = Boolean(selectedRewards[id]);
      return ({
        id,
        name,
        isPopular
      });
    });
    const rewardsPayload = {
      params: orderedRewards,
      cb: () => {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(getRewards());
          setIsLoading(false);
        }, 1000);
      }
    };
    setError();
    dispatch(editRewardsListOrder(rewardsPayload));
  };

  const handleSelectedCheckBox = (event, id) => {
    const currentSelected = {
      ...selectedRewards,
      [id]: event.target.checked
    };
    setSelectedRewards(currentSelected);
    const selectedLength = Object.values(currentSelected).filter((item) => item)?.length;

    if (selectedLength === 4) {
      setDisableCheckbox(true);
    } else {
      setDisableCheckbox(false);
    }
  };

  const rewardsListItem = ({ item, dragHandleProps }) => {
    const [showDragSymbol, setShowDragSymbol] = useState(false);
    const { onMouseDown } = dragHandleProps;

    const rewardValidity = new Date(item?.validUntil);
    const isRewardExpired = isPast(rewardValidity);

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Card
          onMouseEnter={() => {
            if (!isRewardExpired) {
              setShowDragSymbol(true);
            }
          }}
          onMouseLeave={() => {
            if (!isRewardExpired) {
              setShowDragSymbol(false);
            }
          }}
          className={styles.cardPartner}
          sx={{ cursor: "pointer", width: "100%" }}
        >
          <CardContent sx={{ paddingY: 2, paddingLeft: 0 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                sx={2}
                onMouseDown={(e) => {
                  if (!isRewardExpired) {
                    onMouseDown(e);
                  }
                }}
              >
                {
                   showDragSymbol ? <MenuIcon sc={{ color: "#C6C6C6" }} /> : <Box width="28px" />
                }
              </Grid>
              <Grid item sx={2} onClick={() => navigate(`/rewards/${item.id}`)}>
                {item.imageUrl ? (
                  <Box width="100px">
                    <img
                      style={{ height: "55px", maxWidth: "70px", marginRight: "20px" }}
                      src={item.imageUrl}
                      alt=""
                    />
                  </Box>
                ) : (
                  <Box ml={1} mr={5}>
                    <Wealth />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                sm={8}
                style={{ display: "flex", flexDirection: "column", width: "85%" }}
              >
                <Typography
                  mb={1}
                  variant="bodyBig"
                  color="common.darkPurple"
                  onClick={() => navigate(`/rewards/${item.id}`)}
                >
                  {item?.name}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      onClick={() => navigate(`/rewards/${item.id}`)}
                      variant="smallTitle"
                      fontSize="12px"
                      color="common.darkPurple"
                    >
                      {item?.partner}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="smallTitle" fontSize="12px" color="common.darkPurple">
                      {`Validity: ${ddmmyyyy(rewardValidity, "-")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="smallTitle" fontSize="12px" color="common.darkPurple">
                      Most Popular
                    </Typography>
                    <Checkbox
                      onChange={(event) => handleSelectedCheckBox(event, item.id)}
                      checked={selectedRewards[item.id]}
                      disabled={Boolean(!selectedRewards[item.id]) && disableCheckbox}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    );
  };

  if (isLoadingRewards || !rewardsList || isLoading) return <PageLoader />;

  return (
    <>
      <Box sx={{
        display: "flex", justifyContent: "space-between", alignItems: "center", my: 2, mb: 3
      }}
      >
        <Typography variant="newTitle">{t("admin.allOffers")}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Permissions permission="write:reward">
            <BaseButton
              disabled={Object.values(selectedRewards).filter((item) => item)?.length > 4}
              customColor={palette.variables.orange}
              variant="contained"
              element="Save List"
              onClick={handleSubmitListOrder}
              sx={{ display: "flex", marginLeft: 3 }}
            />
          </Permissions>
          <Permissions permission="write:reward">
            <BaseButton
              customColor={palette.variables.orange}
              variant="contained"
              element="Add Reward"
              onClick={() => navigate("/rewards/0")}
              sx={{ display: "flex", marginLeft: 3 }}
            />
          </Permissions>
        </Box>
      </Box>
      {error && (
      <FormRequiredText>
        {error}
      </FormRequiredText>
      )}
      <DraggableList
        list={rewardsList}
        itemKey="id"
        template={rewardsListItem}
        onMoveEnd={(newList) => setRewardsList(newList)}
      />

    </>
  );
};

export default RewardsAdminPage;
