import React from "react";
import {
  Box, Card, IconButton, Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

// COMPONENTS
import CategorisedSpendingCard from "components/CategorisedSpendingCard/CategorisedSpendingCard";
import BalanceBeforePayBarChart from "components/Reports/BalanceBeforePayBarChart";
import AverageBalanceLineChart from "components/Reports/AverageBalanceLineChart";
import palette from "theme/palette";
import { ReactComponent as Filter } from "assets/icons/filter-icon.svg";
import { useSelector } from "react-redux";
import { getUserRoleSelector } from "store/selectors/user";
import styles from "../user.module.scss";

const categorisedSpending = [
  {
    id: "112233",
    percentage: "15.30",
    type: "Eating Out"
  },
  {
    id: "112244",
    percentage: "8.65",
    type: "Travel"
  },
  {
    id: "112255",
    percentage: "9.20",
    type: "Shopping"
  },
  {
    id: "112266",
    percentage: "25.73",
    type: "Eating Out"
  },
  {
    id: "112277",
    percentage: "7.49",
    type: "Eating Out"
  }
];

const FinancialInsightsPage = () => {
  const { t } = useTranslation();
  const cmsUserRole = useSelector(getUserRoleSelector);

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("external.financialInsights")}</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="newSubtitle">{t("external.categorizedSpending")}</Typography>
        <Box mt={3}>
          <Card sx={{
            display: "flex", paddingY: 4, paddingX: 3, borderRadius: "25px", justifyContent: "space-between", filter: cmsUserRole !== "PREMIUM" ? "blur(4px)" : "none"
          }}
          >
            {categorisedSpending.map(({
              id, type, percentage
            }, index) => (
              <Box width="190px" height="300px" key={id}>
                <CategorisedSpendingCard
                  id={index}
                  title={type}
                  percentage={percentage}
                />
              </Box>
            ))}
          </Card>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box width="47%">
          <Typography variant="newSubtitle">{t("external.averageBalances")}</Typography>
          <Box mt={3} sx={{ height: "350px" }}>
            <Card sx={{ padding: 5, borderRadius: "25px", filter: cmsUserRole !== "PREMIUM" ? "blur(4px)" : "none" }}>
              <Box sx={{
                display: "flex", justifyContent: "end", marginTop: -4, marginRight: -2, marginBottom: 1
              }}
              >
                <IconButton
                  size="small"
                  sx={{ backgroundColor: "#F2F2F2" }}
                >

                  <Filter style={{ color: "gray", height: 22, width: 22 }} />
                </IconButton>
              </Box>
              <AverageBalanceLineChart
                xKey="averageBalance"
                yKey="quantityRounded"
                unit="k"
              />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography fontSize="10px" color={palette.variables.greyLight}>Average balances in BHD</Typography>
              </Box>
            </Card>
          </Box>
        </Box>
        <Box width="47%">
          <Typography variant="newSubtitle">{t("external.balanceBeforePayday")}</Typography>
          <Box mt={3} sx={{ height: "350px" }}>
            <Card sx={{ padding: 5, borderRadius: "25px", filter: cmsUserRole !== "PREMIUM" ? "blur(4px)" : "none" }}>
              <BalanceBeforePayBarChart
                key1="quantityRounded"
                xKey="averageBalance"
                colorChanges
                unit="k"
              />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography fontSize="10px" color={palette.variables.greyLight}>Average balance range in BHD</Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default FinancialInsightsPage;
