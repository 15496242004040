/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { getPermissions } from "store/actions/permissions";

const initialState = {
  permissions: [],
  isLoading: false,
  error: null
  // permissions: [
  //   'read:all',
  //   'write:asset',
  //   'write:product',
  //   'write:faq',
  //   // 'write:guidebook',
  //   'write:quizqna',
  //   'write:expert',
  //   'publish:reward'
  //   // 'write:reward',
  //   // 'write:partner'
  //   // 'write:category'
  //   // 'write:tag'
  // ]
  // permissions: ['read:all', 'write:all', 'publish:all']
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data
      };
    }
  },
  extraReducers: {
    [getPermissions.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPermissions.fulfilled]: (state, { payload }) => {
      state.permissions = payload;
      state.isLoading = false;
    },
    [getPermissions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export const { setStateValue } = permissionsSlice.actions;

export default permissionsSlice.reducer;
