import React from "react";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import { string } from "prop-types";
import "./baseTextarea.scss";
import classnames from "classnames";

const BaseTextarea = ({ label, helperText, ...props }) => (
  <Box className={classnames(["base-textarea", { error: props.error }])}>
    <div>
      <Typography
        style={{ fontWeight: 500 }}
        variant="body2"
        color={props.color || "common.darkPurple"}
      >
        {label}
      </Typography>
    </div>
    <TextareaAutosize
      className="base-textarea--input"
      minRows={4}
      maxRows={4}
      resize="false"
      spellCheck
      {...props}
    />
    <div className="base-textarea--error-text">
      <Typography variant="subtitle1" color="common.error">
        {helperText}
      </Typography>
    </div>
  </Box>
);

BaseTextarea.propTypes = {
  label: string.isRequired,
  helperText: string
};

BaseTextarea.defaultProps = {
  helperText: null
};

export default BaseTextarea;
