import React from "react";
import {
  Box, Divider, Grid, Modal, Typography
} from "@mui/material";
import BaseButton from "components/formControl/baseButton/BaseButton";
import palette from "theme/palette";
import { useTranslation } from "react-i18next";
import {
  bool, func, instanceOf
} from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "50%",
  bgcolor: "white",
  border: `2px solid ${palette.variables.orange}`,
  boxShadow: 24,
  borderRadius: 2,
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column"
};

const LinkedUsersEntitiesModal = ({
  handleAction,
  onClose,
  usersList,
  open
}) => {
  const { t } = useTranslation();

  const headers = [t("fields.name"), t("fields.email"), t("fields.role")];

  // if (loading) return <Box><CircularProgress /></Box>;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ p: 2 }}
            component="p"
            variant="bodyBig"
            color={palette.variables.darkPurple}
          >
            Important Information
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ padding: 3, pl: 2 }}
            variant="body1"
            color={palette.variables.darkPurple}
          >
            The partner you are trying to change the package to has users linked to it.
            If you still wish to update the partner click save
          </Typography>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
          {headers.map((header, index) => (
            <Grid
              item
              alignItems="left"
              xs={index === 1 ? 5 : 3}
              key={header}
              sx={{ display: "flex" }}
            >
              <Typography
                sx={{ ml: 0 }}
                variant="subtitle1"
                component="h4"
                color={palette.variables.orange}
              >
                {header}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box>
          {usersList?.map((item) => (
            <Grid key={item.label} container spacing={3} mb={2} sx={{ display: "flex" }}>
              <Grid item xs={3}>
                <Typography ml={3} variant="subtitle1">
                  {item.name}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography ml={3} variant="subtitle1">
                  {item.email}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography ml={3} variant="subtitle1">
                  {item.role}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
        <Divider />
        <Box sx={{
          display: "flex", justifyContent: "flex-end", p: 2, alignSelf: "flex-end"
        }}
        >
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            onClick={onClose}
            element="Cancel"
            sx={{
              display: "flex", width: 100, marginX: 2
            }}
          />
          <span style={{ cursor: "pointer" }}>
            <BaseButton
              customColor={palette.variables.darkPurple}
              variant="contained"
              element="Save Partner"
              onClick={handleAction}
              sx={{
                display: "flex", width: 150, color: "#FFF !important"
              }}
            />
          </span>
        </Box>
      </Box>
    </Modal>
  );
};

LinkedUsersEntitiesModal.propTypes = {
  handleAction: func.isRequired,
  usersList: instanceOf(Array),
  open: bool.isRequired,
  onClose: func.isRequired
};

LinkedUsersEntitiesModal.defaultProps = {
  usersList: []
};

export default LinkedUsersEntitiesModal;
