import TextField from "@mui/material/TextField";
import React from "react";
import { bool } from "prop-types";
import "./baseTextField.scss";
import classNames from "classnames";

const BaseTextField = (
  { rounded, ...props }
) => <TextField className={classNames([{ rounded }])} {...props} spellCheck />;

BaseTextField.propTypes = {
  rounded: bool
};

BaseTextField.defaultProps = {
  rounded: false
};

export default BaseTextField;
