import {
  createSelector
} from "reselect";

const getRewards = (state) => state.rewards.rewards;

const getPublishedRewards = (state) => state.rewards.publishedRewards;

const getIsLoading = (state) => state.rewards.loading;

export const getRewardsSelector = createSelector(
  getRewards,
  (rewards) => rewards
);

export const getPublishedRewardsSelector = createSelector(
  getPublishedRewards,
  (publishedRewards) => publishedRewards
);

export const getisLoadingRewardsSelector = createSelector(
  getIsLoading,
  (loading) => loading
);
