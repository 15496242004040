/* eslint-disable no-throw-literal */
import { API } from "aws-amplify";

/* eslint-disable no-underscore-dangle */
import { getErrorStringPart } from "utils";
import RequestService from "./RequestService";

const reportApiName = "MyAPIGatewayAPI";
const reportsPathname = "/cms/report/internal/user";

class UserInfoService extends RequestService {
  constructor(apiName, path, api) {
    super(apiName, path, api);
  }

  async getPointsHistory(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/points?${new URLSearchParams(params)}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getHealthData(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/steps?${new URLSearchParams(params)}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postRewardsData(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/info`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new UserInfoService(reportApiName, reportsPathname, API);
