import {
  createSelector
} from "reselect";

const getProductById = (state) => state.products.product;

const getProductsByPartner = (state) => state.products.products;

const getProductCategories = (state) => state.products.productCategory;

const getCategoriesByExpert = (state) => state.products.categoriesByExpert;

const getIsLoadingProducts = (state) => state.products.loaders.products;

const getIsLoadingProductById = (state) => state.products.loadingProduct;

const getIsLoadingProductCategories = (state) => state.products.loadingCategories;

export const getProductsByIdSelector = createSelector(
  getProductById,
  (product) => product
);

export const getProductsByPartnerCatSelector = createSelector(
  getProductsByPartner,
  (products) => products
);

export const getProductCategoriesSelector = createSelector(
  getProductCategories,
  (productCategory) => productCategory
);

export const getCategoriesByExpertSelector = createSelector(
  getCategoriesByExpert,
  (categoriesByExpert) => categoriesByExpert
);

export const getIsLoadingProductsByCatSelector = createSelector(
  getIsLoadingProducts,
  (isloading) => isloading
);

export const getIsLoadingProductByIdSelector = createSelector(
  getIsLoadingProductById,
  (loadingProduct) => loadingProduct
);

export const getIsLoadingProductCategoriesSelector = createSelector(
  getIsLoadingProductCategories,
  (loadingCategories) => loadingCategories
);
