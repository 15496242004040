/* eslint-disable react/display-name */
import React, { useState } from "react";
import {
  Tooltip, Typography
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { subDays, subMonths } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { yymmdd } from "utils/functions";
import { func } from "prop-types";
import DatePickert from "components/DatePicker/DatePicker";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";

export const dateButtons = [
  {
    name: "Custom",
    hasIcon: true,
    period: "date"
  },
  {
    name: "7D",
    period: "weekly",
    tooltip: `${subDays(new Date(), 6).toDateString()}, ${new Date().toDateString()}`
  },
  {
    name: "30D",
    period: "date",
    tooltip: `${subDays(new Date(), 29).toDateString()}, ${new Date().toDateString()}`
  },
  {
    name: "3M",
    period: "monthly",
    tooltip: `${subMonths(new Date(), 2).toDateString()}, ${new Date().toDateString()}`
  },
  {
    name: "6M",
    period: "monthly",
    tooltip: `${subMonths(new Date(), 5).toDateString()}, ${new Date().toDateString()}`
  },
  {
    name: "12M",
    period: "monthly",
    tooltip: `${subMonths(new Date(), 11).toDateString()}, ${new Date().toDateString()}`
  }
];

const CustomDateRangePicker = ({ onChange }) => {
  const [selected, setSelected] = useState("7D");
  const [filters, setFilters] = useState([null, null]);
  const [selectDate, setSelectDate] = useState(false);

  const handleToggle = (event, val) => {
    setSelected(val);
    setSelectDate(false);
  };

  const handleDateSelection = (item) => {
    const date = item.tooltip.split(",");
    const from = yymmdd(new Date(date[0]), true);
    const to = item.name !== "Today" ? yymmdd(new Date(date[1]), true) : yymmdd(new Date(date[0]));
    const datePeriod = {
      period: item.period,
      from,
      to
    };
    onChange(datePeriod);
    setFilters([null, null]);
  };

  const handleDateCalendarChange = (dates) => {
    if (dates[0] !== null && dates[1] !== null) {
      // const [start, end] = dates;
      setFilters(dates);
    }
  };

  const handleSubmitCalendarDate = () => {
    const datePeriod = {
      period: "date",
      from: yymmdd(new Date(filters[0]), true),
      to: yymmdd(new Date(filters[1]), true)
    };
    onChange(datePeriod);
  };

  const handleClickPicker = () => {
    setSelected("");
    setSelectDate(true);
  };

  return (
    <>
      <ButtonWithModal
        isIcon
        title={<Typography variant="newSubtitle">Date range</Typography>}
        sx={{
          border: "solid",
          borderWidth: "1px",
          borderColor: "#C6C6C6",
          backgroundColor: selectDate ? "#69B5B5 !important" : "#fffff",
          color: selectDate ? "#fff" : "#4f4d4d",
          marginRight: 1
        }}
        handleOpen={handleClickPicker}
        disableConfirm={filters[0] === null && filters[1] === null}
        handleAction={handleSubmitCalendarDate}
        body={(
          <DatePickert
            showCustomHeader
            dateValue={filters}
            showInput
            onChangeDate={handleDateCalendarChange}
            inline
          />
    )}
      >
        <CalendarMonthIcon />
      </ButtonWithModal>
      <ToggleButtonGroup size="small" value={selected} onChange={handleToggle} exclusive>
        {dateButtons.map((item) => (
          item.name !== "Custom"
            && (
            <Tooltip value={item.name} key={item.name} title={item.tooltip} placement="top" arrow>
              <ToggleButton
                value={item.name}
                sx={{
                  marginRight: 1
                }}
                onClick={() => handleDateSelection(item)}
              >
                {item.hasIcon && <CalendarMonthIcon />}
                <div>{item.name}</div>
              </ToggleButton>
            </Tooltip>
            )
        ))}
      </ToggleButtonGroup>
    </>
  );
};

CustomDateRangePicker.propTypes = {
  onChange: func.isRequired
};

export default CustomDateRangePicker;
