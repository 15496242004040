import {
  createSelector
} from "reselect";

const getTermsAndConditions = (state) => state.termsAndConditions.termsAndConditions;

const getAllTermsAndConditions = (state) => state.termsAndConditions.termsAndConditionsList;

const getIsAvailableTCVersion = (state) => state.termsAndConditions.isTcVersionAvailable;

const getIsLoading = (state) => state.termsAndConditions.loading;

const getIsLoadingTCFlag = (state) => state.termsAndConditions.loadingFlag;

export const getTermsAndConditionsSelector = createSelector(
  getTermsAndConditions,
  (termsAndConditions) => termsAndConditions
);

export const getAllTermsAndConditionsSelector = createSelector(
  getAllTermsAndConditions,
  (termsAndConditionsList) => termsAndConditionsList
);

export const getIsAvailableTCVersionSelector = createSelector(
  getIsAvailableTCVersion,
  (isTcVersionAvailable) => isTcVersionAvailable
);

export const getisLoadingTermsAndConditionsSelector = createSelector(
  getIsLoading,
  (loading) => loading
);

export const getIsLoadingTCFlagSelector = createSelector(
  getIsLoadingTCFlag,
  (loadingFlag) => loadingFlag
);
