import React from "react";
import { Box, Typography } from "@mui/material";

const BehaviourOptionsFormatter = ({ rowData, objectKey }) => {
  const answers = rowData[objectKey];

  const handleColor = (word) => {
    if (word === "selected") {
      return "#69B5B5";
    } if (word === "not selected") {
      return "#F29469";
    }
    return "#251938";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
      }}
    >
      { answers.map(({ option }) => (
        <Typography
          color={handleColor(option.toLowerCase())}
          mb={1}
          key={option}
          variant="newBody1"
        >
          {option}
        </Typography>
      ))}
    </Box>
  );
};

export default BehaviourOptionsFormatter;
