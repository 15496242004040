import React, { useEffect, useRef, useState } from "react";
import {
  Box, Button, List, Tab, Tabs, Typography
} from "@mui/material";
import draftjsToHtml from "draftjs-to-html";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";

import { successToast } from "utils";
import { checkArrayMissingElements, removeSpaces } from "utils/functions";

// ACTIONS
import {
  deleteExpertById,
  getExpertById,
  getExpertLinksById,
  getResoucesByExpert,
  loadUnlinkedEntitiesByExpertId, updateExpert
} from "store/actions/experts";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import { loadGuidebookTopics, loadTopicsByExpertId } from "store/actions/topics";
import { setResourceValue } from "store/reducers/experts";
import { fetchCategorySummary, loadCategoriesByExpertId } from "store/actions/products";

// SELECTORS
import { getGuidebookTopicsSelector, getisLoadingTopicsSelector } from "store/selectors/topics";
import {
  getExpertSelector,
  getExpertUnlinkedEntitiesSelector,
  getisLoadingExpertEntitiesSelector,
  getisLoadingExpertsSelector
} from "store/selectors/experts";
import { getIsLoadingProductCategoriesSelector, getProductCategoriesSelector } from "store/selectors/products";

// COMPONENTS
import FormUploadImage from "components/FormUploadImage/FormUploadImage";
import AdminConfigButtons from "components/AdminConfigButtons/AdminConfigButtons";
import BaseButton from "components/formControl/baseButton/BaseButton";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import FaqsForm from "components/FaqsForm";
import NumberedListItem from "components/NumberedListItem/NumberedListItem";
import { DisablePermissions } from "components/DisablePermissions";
import DeletionModal from "components/modal/DeletionModal";
import BaseModel from "components/modal/BaseModal";

import palette from "theme/palette";
import { ENTITY_DELETION_STATEMENT, EXPERT_UPDATE_STATEMENT, LINKED_ENTITIES_TYPES } from "utils/constants";
import UnlinkedEntitiesModal from "components/UnlinkedEntitiesModal/UnlinkedEntitiesModal";
import { navTabs } from "utils/staticValues";
import PageLoader from "components/PageLoader/PageLoader";

const ExpertNewDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const lang = useSelector(selectLanguage);

  const {
    expertResources: resources
  } = useSelector((state) => state.experts);
  const expert = useSelector(getExpertSelector);
  const productCategory = useSelector(getProductCategoriesSelector);
  const topics = useSelector(getGuidebookTopicsSelector);
  const isLoadingEntities = useSelector(getisLoadingExpertEntitiesSelector);
  const unlinkedEntities = useSelector(getExpertUnlinkedEntitiesSelector);
  const isLoadingExpert = useSelector(getisLoadingExpertsSelector);
  const isLoadingCategories = useSelector(getIsLoadingProductCategoriesSelector);
  const isLoadingTopics = useSelector(getisLoadingTopicsSelector);
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(1);
  const [editorText, setEditorText] = useState(EditorState.createEmpty());
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [showNewFAQForm, setShowNewFAQForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [openUnlinkedEntities, setOpenUnlinkedEntities] = useState(false);
  const [delLinksOpen, setDelLinksOpen] = useState(false);
  const [entityLinks, setEntityLinks] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    if (!!searchParams.get("lang") && searchParams.get("lang") === "ar") {
      dispatch(setLanguage("ar"));
    }
  }, []);

  useEffect(() => {
    dispatch(getExpertById({ id }));
    dispatch(getResoucesByExpert({ authorId: id }));
    dispatch(fetchCategorySummary({ id: "published/summary" }));
    dispatch(loadGuidebookTopics());
    dispatch(loadCategoriesByExpertId({ params: { id } }));
    dispatch(loadTopicsByExpertId({ params: { id } }));
  }, [lang]);

  useEffect(() => {
    if (expert?.categoryIds) {
      setSelectedCategories(expert.categoryIds);
      setSelectedTopics(expert.topics);
    }
  }, [expert?.categoryIds]);

  useEffect(() => {
    if (expert?.aboutText) {
      const contentBlock = htmlToDraft(expert.aboutText);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorText(EditorState.createWithContent(contentState));
      }
    }
  }, [expert.aboutText]);

  const handleChangeForm = (type, value) => {
    if (type === "name") {
      dispatch(
        setResourceValue({
          type: "expert",
          data: { ...expert, name: value }
        })
      );
      setFormErrors({ ...formErrors, name: false });
    } else if (type === "image") {
      dispatch(
        setResourceValue({
          type: "expert",
          data: { ...expert, iconUrl: value }
        })
      );
      setFormErrors({ ...formErrors, image: false });
    } else if (type === "aboutText") {
      dispatch(
        setResourceValue({
          type: "expert",
          data: { ...expert, aboutText: value }
        })
      );
    }
  };

  const handleSubmitExpert = () => {
    const expertPayload = {
      id,
      params: {
        name: expert.name,
        aboutText: removeSpaces(draftjsToHtml(
          convertToRaw(editorText.getCurrentContent())
        )),
        iconUrl: expert.iconUrl,
        categoryIds: selectedCategories,
        topics: selectedTopics,
        publish: expert?.publish || false
      },
      cb: () => {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(getExpertById({ id }));
          dispatch(loadCategoriesByExpertId({ params: { id } }));
          dispatch(loadTopicsByExpertId({ params: { id } }));
          // dispatch(getFaqsByExpert({ authorId: id }));
          setIsLoading(false);
        }, [1000]);
        successToast("Expert has been updated");
      }
    };
    dispatch(updateExpert(expertPayload));

    setOpenUnlinkedEntities(false);
  };

  const anchorRef = useRef(null);

  const onClickLink = async (linkId, type) => {
    if (type === LINKED_ENTITIES_TYPES.GUIDEBOOK) {
      anchorRef.current.href = `#/resources/${linkId}${lang === "ar" ? "?lang=ar" : ""}`;
    } else if (type === LINKED_ENTITIES_TYPES.FAQ) { // when you  click faq drawer is opened
      anchorRef.current.href = `#/experts/${id}/faq/${linkId}${lang === "ar" ? "?lang=ar" : ""}`;
    }
    anchorRef.current.click();
  };
  const checkExpertDependencies = async (id, reload = false) => {
    setIsLoading(true);
    const data = await dispatch(getExpertLinksById({ id: `${id}/entity_links` }));
    if (data.payload) {
      if (!data.payload.length && !reload) {
        setDelLinksOpen(false);
        setDelOpen(true);
      } else {
        setEntityLinks([...data.payload]);
        setDelLinksOpen(true);
      }
    }
    setIsLoading(false);
  };
  const handleExpertDeletion = () => {
    setIsLoading(true);
    setDelOpen(false);
    dispatch(
      deleteExpertById({
        id,
        cb: () => {
          successToast("Expert has been successfully deleted.");
          navigate("/learn", { replace: true });
          setIsLoading(false);
        },
        cbf: () => setIsLoading(false)
      })
    );
  };

  const onUpdateExpert = () => {
    const {
      categoryIds, topics: expertTopics, name, iconUrl
    } = expert;
    const domElAbout = draftjsToHtml(convertToRaw(editorText.getCurrentContent()))?.toString();
    const emptyAbout = domElAbout.includes("<p></p>") || domElAbout.includes("<p style=\"margin-left:4px;\"></p>");
    const errorObj = {
      ...formErrors,
      ...(!name.length && { name: true }),
      ...(!iconUrl.length && { image: true }),
      ...(emptyAbout && { aboutText: true }),
      ...(!selectedCategories?.length && !selectedTopics?.length && { expertise: true })
    };

    console.log("categories", selectedCategories?.length);
    console.log("topics", selectedTopics?.length);

    setFormErrors(errorObj);
    if (Object.values(errorObj).some((item) => item)) return;

    const includesCategories = checkArrayMissingElements(categoryIds, selectedCategories);
    const includesTopics = checkArrayMissingElements(expertTopics, selectedTopics);
    if (includesCategories && includesTopics) {
      const expertPayload = {
        id,
        params: {
          name: expert.name,
          aboutText: removeSpaces(draftjsToHtml(
            convertToRaw(editorText.getCurrentContent())
          )),
          iconUrl: expert.iconUrl,
          categoryIds: selectedCategories,
          topics: selectedTopics,
          publish: expert?.publish || false
        },
        cb: () => {
          setIsLoading(true);
          setTimeout(() => {
            dispatch(getExpertById({ id }));
            dispatch(loadCategoriesByExpertId({ params: { id } }));
            dispatch(loadTopicsByExpertId({ params: { id } }));
            // dispatch(getFaqsByExpert({ authorId: id }));
            setIsLoading(false);
          }, [1000]);
          successToast("Expert has been updated");
        }
      };
      dispatch(updateExpert(expertPayload));
    } else {
      dispatch(loadUnlinkedEntitiesByExpertId({
        id,
        params: {
          categoryIds: selectedCategories,
          topics: selectedTopics
        }
      }));
      setOpenUnlinkedEntities(true);
    }
  };

  if (isLoadingExpert || isLoadingTopics || isLoadingCategories || isLoading) return <PageLoader />;

  return (
    <Box mt={2}>
      <Box sx={{
        display: "flex", justifyContent: "space-between", width: "100%"
      }}
      >
        <Typography variant="newTitle" color={palette.variables.darkPurple2}>{t("fields.expert")}</Typography>
        <Box>
          <AdminConfigButtons
            entity={expert?.metaInfo}
            entityName="expert"
            onPublish={() => {
              dispatch(
                updateExpert({
                  id: `${id}/publish`,
                  cb: () => {
                    setIsLoading(true);
                    setTimeout(() => {
                      dispatch(getExpertById({ id }));
                      setIsLoading(false);
                      successToast("Expert has been published.");
                    }, [1000]);
                  }
                })
              );
            }}
            onChangelang={() => {
              dispatch(setLanguage(lang === "en" ? "ar" : "en"));
              navigate(`/experts/${id}`);
            }}
            onDelete={checkExpertDependencies}
            navigateCurrentLink={`/experts/published/${id}/current${lang === "ar" ? "?lang=ar" : ""}`}
            navigatePublishLink={`/experts/published/${id}/published${lang === "ar" ? "?lang=ar" : ""}`}
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" flexDirection="column">
          <FormUploadImage
            item={expert}
            onChange={(type, value) => handleChangeForm(type, value)}
            lang={lang}
            errors={formErrors}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box mb={3}>
            <Typography fontWeight={600} variant="newSubtitle" color={palette.variables.darkPurple2}>
              {t("fields.about")}
            </Typography>
            <RichTextEditor
              value={editorText}
              onChange={(editorState) => {
                setEditorText(editorState);
                setFormErrors({ ...formErrors, aboutText: false });
              }}
              error={formErrors.aboutText}
            />
          </Box>
          <Box mb={4}>
            <Typography fontWeight={600} variant="newSubtitle" color={palette.variables.darkPurple2}>
              {t("fields.areaOfExpertise")}
            </Typography>
            <Box mt={2}>
              <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
                {t("admin.productCategory")}
              </Typography>
              <Box mt={2}>
                <MultipleSelectionToggleBtn
                  data={productCategory}
                  onChange={(event, value) => {
                    setSelectedCategories(value);
                    setFormErrors({ ...formErrors, expertise: false });
                  }}
                  selected={selectedCategories}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
                {t("fields.topics")}
              </Typography>
              <Box mt={2}>
                <MultipleSelectionToggleBtn
                  data={topics}
                  onChange={(event, value) => {
                    setSelectedTopics(value);
                    setFormErrors({ ...formErrors, expertise: false });
                  }}
                  selected={selectedTopics}
                  error={formErrors?.expertise}
                />
              </Box>
            </Box>
            <DisablePermissions permission="write:expert" disable>
              <BaseButton
                customColor={palette.variables.darkPurple}
                onClick={() => onUpdateExpert(expert.categoryIds, expert.topics)}
                fullWidth
                variant="contained"
                element={t("saveExpert")}
                sx={{ display: "block", maxWidth: 300, marginTop: 3 }}
              />
            </DisablePermissions>
          </Box>
        </Box>
        <UnlinkedEntitiesModal
          open={openUnlinkedEntities}
          loading={isLoadingEntities}
          list={unlinkedEntities}
          text={EXPERT_UPDATE_STATEMENT}
          handleAction={handleSubmitExpert}
          onClose={() => setOpenUnlinkedEntities(false)}
          onPressGoTo={(_id, type) => onClickLink(_id, type)}
          onReload={() => dispatch(loadUnlinkedEntitiesByExpertId({
            id,
            params: {
              categoryIds: expert?.categoryIds,
              topics: expert?.topics
            }
          }))}
        />
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{
            borderBottom: 1, borderColor: "divider", display: "flex", width: "max-content"
          }}
          >
            <Tabs value={selectedTab} onChange={(event, val) => setSelectedTab(val)}>
              {navTabs.map((item) => (
                <Tab
                  sx={{
                    fontSize: "11px", lineHeight: "12px", fontWeight: 600, textTransform: "none"
                  }}
                  key={item.value}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Tabs>
          </Box>
          <Button
            variant="newButton"
            color={palette.variables.darkPurple}
            sx={{ backgroundColor: "#F2F2F2" }}
            onClick={selectedTab === 1 ? () => setShowNewFAQForm(true) : () => navigate("/resources/add", { state: { author: id } })}
          >
            Add more
          </Button>
        </Box>

        <Box mt={3}>
          {selectedTab === 1 ? (
            <FaqsForm
             // faqs={faqs}
              showNewFaq={showNewFAQForm}
              handleCloseNewFaq={() => setShowNewFAQForm(false)}
            />
          ) : (
            <List>
              {
                resources?.map(
                  (item, index) => (
                    <NumberedListItem
                      key={item.id}
                      item={item}
                      itemNum={index + 1}
                      onClickItem={() => navigate(`/resources/${item.id}${lang === "ar" ? "?lang=ar" : ""}`)}
                    />
                  )
                )
            }
            </List>
          )}
        </Box>

      </Box>
      <DeletionModal
        open={delLinksOpen}
        handleClose={() => setDelLinksOpen(false)}
        list={entityLinks}
        text={ENTITY_DELETION_STATEMENT}
        onPressGoTo={(_id, type) => onClickLink(_id, type)}
        onReload={() => checkExpertDependencies(id, true)}
      />
      <BaseModel
        open={delOpen}
        handleClose={() => setDelOpen(false)}
        text={t("delConfirmation")}
        handleSuccess={handleExpertDeletion}
      />
      <a ref={(r) => (anchorRef.current = r)} target="_blank" />
    </Box>
  );
};

export default ExpertNewDetailsPage;
