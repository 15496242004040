/* eslint-disable react/display-name */
import React, { useState } from "react";
import {
  Box, Checkbox, IconButton, Menu, Typography
} from "@mui/material";
import { ReactComponent as Sort } from "assets/icons/sort-icon.svg";
import { func, instanceOf, string } from "prop-types";
import { Close } from "@mui/icons-material";

const internalUserSortDir = [
  {
    key: "asc",
    label: "Asc"
  },
  {
    key: "desc",
    label: "Desc"
  }
];

const SortMenu = ({
  onChange, data, initialChecked, initialDir
}) => {
  const [checked, setChecked] = useState(initialChecked);
  const [sortDir, setSortDir] = useState(initialDir);

  const [anchorEl, setAnchorEl] = useState(null);
  const openSort = Boolean(anchorEl);

  const handleSortByChange = (event) => {
    setChecked(event.target.value);
    onChange({
      sortBy: event.target.value,
      sortDir
    });
  };

  const handleDirChange = (event) => {
    setSortDir(event.target.value);
    onChange({
      sortBy: checked,
      sortDir: event.target.value
    });
  };

  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        sx={{ backgroundColor: "#F2F2F2" }}
      >
        <Sort style={{ color: "gray", height: 22, width: 22 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openSort}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
        >
          <Typography variant="body2" fontSize="12px" sx={{ paddingX: 2, width: "70%" }}>Sort direction:</Typography>
          <IconButton size="small" onClick={() => setAnchorEl(null)} sx={{ marginRight: 1 }}>
            <Close sx={{ height: 12, width: 12 }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex" }}>
          {
          internalUserSortDir.map(({ key, label }) => (
            <Box
              key={key}
              sx={{
                display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: 2
              }}
            >
              <Checkbox
                checked={sortDir === key}
                onChange={handleDirChange}
                value={key}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body2" fontSize="12px">{label}</Typography>
            </Box>
          ))
        }
        </Box>
        <Typography variant="body2" fontSize="12px" sx={{ paddingX: 2 }}>Sort by:</Typography>
        {
          data?.map((sort) => (
            <Box
              key={sort.key}
              sx={{
                display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: 2
              }}
            >
              <Checkbox checked={checked === sort.key} onChange={handleSortByChange} value={sort.key} inputProps={{ "aria-label": "controlled" }} />
              <Typography variant="body2" fontSize="12px">{sort.label}</Typography>
            </Box>
          ))
        }
      </Menu>
    </>
  );
};

SortMenu.propTypes = {
  onChange: func.isRequired,
  data: instanceOf(Array).isRequired,
  initialChecked: string.isRequired,
  initialDir: string.isRequired
};

export default SortMenu;
