import { createSlice } from "@reduxjs/toolkit";
import { editRewardsListOrder, getRewards, loadPublishedRewards } from "store/actions/rewards";

const initialState = {
  rewards: [],
  publishedRewards: [],
  loading: false
};

export const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data
      };
    }
  },
  extraReducers: {
    [loadPublishedRewards.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadPublishedRewards.fulfilled]: (state, { payload }) => {
      state.publishedRewards = payload;
      state.loading = false;
    },
    [loadPublishedRewards.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getRewards.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getRewards.fulfilled]: (state, { payload }) => {
      state.rewards = payload;
      state.loading = false;
    },
    [getRewards.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [editRewardsListOrder.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editRewardsListOrder.fulfilled]: (state) => {
      state.loading = false;
    },
    [editRewardsListOrder.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { setStateValue } = rewardsSlice.actions;

export default rewardsSlice.reducer;
