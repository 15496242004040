import React from "react";
import { Check, Close } from "@mui/icons-material";
import palette from "theme/palette";
import { bool } from "prop-types";

const BooleanFormatter = ({ children }) => (
  children ? <Check sx={{ color: palette.variables.lightGreen }} />
    : <Close sx={{ color: palette.variables.orange }} />
);

BooleanFormatter.propTypes = {
  children: bool.isRequired
};

export default BooleanFormatter;
