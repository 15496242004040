import React from "react";
import {
  Box, InputLabel
} from "@mui/material";
import { DisablePermissions } from "components/DisablePermissions";
import BaseButton from "components/formControl/baseButton/BaseButton";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createGuidebookTopic, editGuidebookTopic } from "store/actions/topics";
import { selectLanguage } from "store/app/appSlice";
import { getGuidebookTopicSelector } from "store/selectors/topics";
import palette from "theme/palette";
import * as Yup from "yup";
import { func, number } from "prop-types";
import { successToast } from "utils";

const GuidebookTopicForm = ({ id, onSubmit }) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const currentGuidebook = useSelector(getGuidebookTopicSelector);

  const { t } = useTranslation();

  const initialState = {
    nameEn: id === 0 ? "" : currentGuidebook?.nameEn,
    nameAr: id === 0 ? "" : currentGuidebook?.nameAr
  };

  const CreateGuidebookCategorySchema = Yup.object().shape({
    nameEn: Yup.string().required(t("validation.required")),
    nameAr: Yup.string().required(t("validation.required"))
  });

  const handleSubmitForm = async (values) => {
    const cb = async () => {
      successToast(`Topic has been succesfully ${+id === 0 ? "created" : "updated"}`);
    };
    if (+id !== 0) {
      const obj = {
        id: lang === "en" ? id : `${id}/i18n_data`,
        params: {
          nameEn: values.nameEn,
          nameAr: values.nameAr
        },
        cb
      };
      dispatch(editGuidebookTopic(obj));
      onSubmit();
    } else {
      const obj = {
        nameEn: values.nameEn,
        nameAr: values.nameAr
      };
      dispatch(createGuidebookTopic({ params: obj, cb }));
      onSubmit();
    }
  };

  return (
    <>
      <Box sx={{ mb: 5, display: "flex", justifyContent: "space-between" }} mt={2} />
      <Box>
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={CreateGuidebookCategorySchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values, errors, handleChange, handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Box mb={2}>
                  <InputLabel>{t("fields.nameEn")}</InputLabel>
                  <BaseTextField
                    InputLabelProps={{ required: false }}
                    placeholder={t("fields.someText")}
                    sx={{ mb: 4, mt: 0 }}
                    fullWidth
                    name="nameEn"
                    onChange={handleChange}
                    error={!!errors.nameEn}
                    helperText={errors.nameEn}
                    value={values.nameEn}
                    color={!errors.nameEn && values.nameEn ? "success" : ""}
                  />
                </Box>
                <Box>
                  <InputLabel dir="rtl">{t("fields.nameAr")}</InputLabel>
                  <BaseTextField
                    InputLabelProps={{ required: false }}
                    placeholder={t("fields.someText")}
                    dir="rtl"
                    sx={{ mb: 4, mt: 0 }}
                    fullWidth
                    name="nameAr"
                    onChange={handleChange}
                    error={!!errors.nameAr}
                    helperText={errors.nameAr}
                    value={values.nameAr}
                    color={!errors.nameAr && values.nameAr ? "success" : ""}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <DisablePermissions permission="write:guidebookCategory" disable>
                    <BaseButton
                      customColor={palette.variables.darkPurple}
                      type="submit"
                      fullWidth
                      variant="contained"
                      element={t("save")}
                      sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
                    />
                  </DisablePermissions>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

GuidebookTopicForm.propTypes = {
  id: number.isRequired,
  onSubmit: func
};

GuidebookTopicForm.defaultProps = {
  onSubmit: null
};

export default GuidebookTopicForm;
