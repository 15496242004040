/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ReactComponent as Filter } from "assets/icons/filter-icon.svg";
import { useSelector } from "react-redux";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import { getPartnersSummarySelector } from "store/selectors/partners";
import { bool, func, instanceOf } from "prop-types";
import {
  Box, Grid, Typography
} from "@mui/material";
import CheckboxItem from "components/Checkbox/Checkbox";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import RangeSlider from "components/RangeSlider/RangeSlider";

const PartnersMenuItemWithChildren = ({
  onChangeFilter, selectedFilters, hidePartners
}) => {
  const { productCategory } = useSelector((state) => state.products);
  const partnersSummary = useSelector(getPartnersSummarySelector);

  const [filters, setFilters] = useState({ ...selectedFilters });

  const [selectedCheckbox, setSelectedCheckbox] = useState({});

  let selectedButtonsArray = filters?.filterCategory?.length ? filters.filterCategory.split(",") : [];
  let selectedRange = filters?.filterRating?.length ? filters.filterRating.split("-") : [];

  // solo cambia el valor de los filtros
  const handleFilterChange = (value, type) => {
    const updatedFilters = { ...filters };

    if (type === "checkbox") {
      const key = "filterProvider";

      setSelectedCheckbox({ ...selectedCheckbox, ...value });

      const obj = { ...selectedCheckbox, ...value };
      const arr = [];
      Object.keys(obj).forEach((it) => {
        if (obj[it] === true) {
          arr.push(it);
        }
        return arr;
      });

      if (!arr.length) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = [...arr].toString();
      }
      setFilters(updatedFilters);
    } else if (type === "toggle") {
      const key = "filterCategory";

      if (!value.length) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = value.toString();
      }
      setFilters(updatedFilters);
    } else if (type === "slider") {
      const key = "filterRating";
      updatedFilters[key] = value.toString().replace(",", "-");
      setFilters(updatedFilters);
    }
  };

  const handleConfirm = () => {
    const arr = [];
    Object.keys(filters).forEach((item) => {
      if (item === "filterProvider") {
        arr.push("provider");
      } else if (item === "filterCategory") {
        arr.push("category");
      } else if (item === "filterRating") {
        arr.push("rating");
      }
    });

    onChangeFilter({
      ...filters
    }, arr);
  };

  const handleClear = () => {
    setFilters({});
    setSelectedCheckbox({});
    selectedButtonsArray = [];
    selectedRange = [];
    onChangeFilter({}, []);
  };

  const renderBody = () => (
    <>
      {!hidePartners && (
      <Box mb={3}>
        <Typography variant="smallTitle">Partner</Typography>
        <Grid container mt={1}>
          {partnersSummary?.map((partner) => (
            <Grid
              item
              xs={4}
              sx={{ display: "flex", alignItems: "center" }}
              key={partner.id}
            >
              <CheckboxItem
                filterType="filterProvider"
                selected={selectedCheckbox}
                onChange={(val) => handleFilterChange(val, "checkbox")}
                id={partner.id}
              >
                {partner.name}

              </CheckboxItem>
            </Grid>
          ))}
        </Grid>
      </Box>
      )}
      <Box mb={1}>
        <Typography variant="smallTitle">Category</Typography>
        <Box mt={2}>
          <MultipleSelectionToggleBtn
            data={productCategory}
            selected={selectedButtonsArray}
            onChange={(val) => handleFilterChange(val, "toggle")}
            isFilter
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Typography variant="smallTitle">Rating</Typography>
        <Box width="290px" pl={2}>
          <RangeSlider selected={selectedRange} onChange={(val) => handleFilterChange(val, "slider")} />
        </Box>
      </Box>
    </>
  );

  return (
    <ButtonWithModal
      isIcon
      size="small"
      title={<Typography variant="newSubtitle">Filter</Typography>}
      sx={{
        width: "max-content",
        backgroundColor: "#F2F2F2",
        fontSize: "12px"
      }}
      handleAction={handleConfirm}
      body={renderBody()}
      handleClear={handleClear}
      hideCancel
    >
      <Filter style={{ color: "gray" }} />
    </ButtonWithModal>
  );
};

PartnersMenuItemWithChildren.propTypes = {
  onChangeFilter: func.isRequired,
  selectedFilters: instanceOf(Object).isRequired,
  hidePartners: bool
};

PartnersMenuItemWithChildren.defaultProps = {
  hidePartners: false
};

export default PartnersMenuItemWithChildren;
