import React from "react";
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip
} from "recharts";
import palette from "theme/palette";
import { instanceOf, number, string } from "prop-types";
import ChartTooltip from "./ChartTooltip";

const StatisticsLineChart = ({
  data, xKey, yKey, tooltipValue, lineColor, minHeight
}) => (
  <ResponsiveContainer width="100%" height={minHeight}>
    <LineChart
      width={730}
      height={250}
      data={data}
      margin={{
        left: -20, right: 10
      }}
    >
      <CartesianGrid vertical={false} strokeDasharray="2" />
      <XAxis
        dataKey={xKey}
        fontSize="11px"
        tickMargin={10}
      />
      <YAxis unit="k" tickSize={8} fontSize="11px" />
      <Tooltip content={(
        <ChartTooltip
          bgColor={palette.variables.darkPurple2}
          tooltipValue={tooltipValue}
          isSingleTooltip
        />
        )}
      />
      <Line dataKey={yKey} stroke={lineColor} />
    </LineChart>
  </ResponsiveContainer>
);

StatisticsLineChart.propTypes = {
  data: instanceOf(Array).isRequired,
  xKey: string.isRequired,
  yKey: string.isRequired,
  tooltipValue: string.isRequired,
  lineColor: string,
  minHeight: number
};

StatisticsLineChart.defaultProps = {
  lineColor: palette.variables.lightPink,
  minHeight: 200
};

export default StatisticsLineChart;
