import { Route, Routes } from "react-router-dom";

import React from "react";

import NoMatch from "pages/NoMatch";

import "amplify/index";
import "utils/logger";

// Admin Pages

import PartnersAdminPage from "pages/adminPages/PartnersAdminPage/PartnersAdminPage";
import PartnerCreateAdminPage from "pages/adminPages/PartnersAdminPage/PartnerCreateAdminPage";
import ProductDetailsAdminPage from "pages/adminPages/ProductsAdminPage/ProductDetailsAdminPage";
import ProductCategoryAdminPage from "pages/adminPages/ProductCategoryAdminPage/ProductCategoryAdminPage";
import ProductCategoryDetailsAdminPage from "pages/adminPages/ProductCategoryAdminPage/ProductCategoryDetailsAdminPage";
import QuizzesAdminPage from "pages/adminPages/QuizzesAdminPage";
import TagsAdminPage from "pages/adminPages/TagsAdminPage";
import UsersAdminPage from "pages/adminPages/UsersAdminPage";
import SettingsAdminPage from "pages/adminPages/SettingsPage/SettingsAdminPage";
import CongratsAdminPage from "pages/adminPages/CongratsAdminPage";
import FilterAdminPage from "pages/adminPages/FilterAdminPage/FilterAdminPage";
import FilterDetailsAdminPage from "pages/adminPages/FilterAdminPage/FilterDetailsAdminPage";

import SignInAdminPage from "pages/adminPages/SignInAdminPage";
import DashboardAdminPage from "pages/adminPages/DashboardAdminPage";
// import ExpertDetailsPage from "pages/adminPages/ExpertsAdminPage/ExpertDetailsPage";
import ExpertCreatePage from "pages/adminPages/ExpertsAdminPage/ExpertCreatePage";
import AdminLayout from "layouts/adminLayout/AdminLayout";
import UserCreatePage from "pages/adminPages/UserAdminPage/UserCreatePage";
import ResourcesDetailsPage from "pages/adminPages/ResourcesAdminPage/ResourcesDetailsPage";
import ResourcesCreatePage from "pages/adminPages/ResourcesAdminPage/ResourcesCreatePage";
// import QuizzesDetailsPage from 'pages/adminPages/QuizzesAdminPage/QuizzesDetailsPage';
import QuizzesCreatePage from "pages/adminPages/QuizzesAdminPage/QuizzesCreatePage";
import TagsProductCategory from "pages/adminPages/TagsAdminPage/TagsProductCategory";
import TagsFaqPage from "pages/adminPages/TagsAdminPage/TagsFaqPage";
import TagsCreateProduct from "pages/adminPages/TagsAdminPage/TagsCreateProduct";
import TagsCreateFilterRange from "pages/adminPages/TagsAdminPage/TagsCreateFilterRange";
import TagsQuizzesPage from "pages/adminPages/TagsAdminPage/TagsQuizzesPage";
import TagsCreateQuiz from "pages/adminPages/TagsAdminPage/TagsCreateQuiz";
import RewardsAdminPage from "pages/adminPages/RewardsAdminPage";
import RewardsCreatePage from "pages/adminPages/RewardsAdminPage/RewardsCreatePage";
import UserDetailsPage from "pages/adminPages/UserAdminPage/UserDetailsPage";
import TagsCreateFaq from "pages/adminPages/TagsAdminPage/TagsCreateFaq";
import TagsFilterPage from "pages/adminPages/TagsAdminPage/TagsFilterPage";
import TagsCreateRewards from "pages/adminPages/TagsAdminPage/TagsCreateRewards";
import TagsRewardsPage from "pages/adminPages/TagsAdminPage/TagsRewardsPage";
import PublicRoute from "components/route/publicRoute";
import PartnerPublishedView from "pages/adminPages/PartnersAdminPage/PartnerPublishedViewPage";
import ProductPublishedView from "pages/adminPages/ProductsAdminPage/ProductPublishedViewPage";
import ProductCategoryPublishedViewPage from "pages/adminPages/ProductCategoryAdminPage/ProductCategoryPublishedViewPage";
import ResourcesPublishedViewPage from "pages/adminPages/ResourcesAdminPage/ResourcesPublishedViewPage";
import RewardsPublishedViewPage from "pages/adminPages/RewardsAdminPage/RewardsPublishedViewPage";
import QuizzesPublishedViewPage from "pages/adminPages/QuizzesAdminPage/QuizzesPublishedViewPage";
import ExpertPublishedViewPage from "pages/adminPages/ExpertsAdminPage/ExpertPublishedViewPage";
import FaqPublishedViewPage from "pages/adminPages/ExpertsAdminPage/FaqPublishedViewPage";
import TagsAllProductCategory from "pages/adminPages/TagsAdminPage/TagsAllProductCategory";
import NotificationsAdminPage from "pages/adminPages/NotificationsAdminPage";
import QuizThemeAdminPage from "pages/adminPages/QuizThemeAdminPage";
import NotificationsCreateAdminPage from "pages/adminPages/NotificationsAdminPage/NotificationsCreateAdminPage";
import QuizThemeCreateAdminPage from "pages/adminPages/QuizThemeAdminPage/QuizThemeCreateAdminPage";
import QuizThemePublishedViewPage from "pages/adminPages/QuizThemeAdminPage/QuizThemePublishedViewPage";
import TailoredNotificationAdminPage from "pages/adminPages/NotificationsAdminPage/TailoredNotificationAdminPage";
import TailoredNotificationCreatePage from "pages/adminPages/NotificationsAdminPage/TailoredNotificationCreatePage";
import ProductStatisticsPage from "pages/adminPages/Statistics/ProductStatisticsPage";
import UsersStatsAdminPage from "pages/adminPages/Statistics/Internal/UsersStatsAdminPage";
import UsersInfoAdminPage from "pages/adminPages/Statistics/Internal/UsersInfoAdminPage";
import RedemptionPage from "pages/adminPages/Statistics/Internal/RedemptionPage";
import PartnersReportPage from "pages/adminPages/Statistics/Internal/PartnersReportPage";
import UsersBehaviourReportPage from "pages/adminPages/Statistics/Internal/UsersBehaviourReportPage";
import StatisticsPage from "pages/externalUserPages/StatisticsPage";
import PartnerSelectionPage from "pages/adminPages/Statistics/PartnerSelectionPage";
import ProductsByPartner from "pages/adminPages/ProductsAdminPage/ProductsByPartner";
import PartnersDraftAdminPage from "pages/adminPages/PartnersAdminPage/PartnersDraftAdminPage";
import LearnAdminPage from "pages/adminPages/LearnAdminPage/LearnAdminPage";
import ExpertNewDetailsPage from "pages/adminPages/ExpertsAdminPage/ExpertNewDetailsPage";
import ExpertsNewFaqPage from "pages/adminPages/ExpertsAdminPage/ExpertsNewFaqPage";
import TermsAndConditionsDetailsPage from "pages/adminPages/SettingsPage/TermsAndConditions/TermsAndConditionsDetailsPage";
import TermsAndConditionsPublishedViewPage from "pages/adminPages/SettingsPage/TermsAndConditions/TermsAndConditionsPublishedViewPage";
import CustomerInsightsPage from "pages/externalUserPages/InsightsPage/CustomerInsightsPage";
import FinancialInsightsPage from "pages/externalUserPages/InsightsPage/FinancialInsightsPage";
import InternalPrivateRoute from "components/route/InternalPrivateRoutes";
import ExternalPrivateRoute from "components/route/ExternalPrivateRoute";
import { UserLayout } from "layouts/UserLayout/UserLayout";
import ProfilePage from "pages/externalUserPages/ProfilePage";
import ProductsCreatePage from "pages/externalUserPages/ProductsPage/ProductsCreatePage";
// import DraftExternal from "pages/adminPages/Statistics/DraftExternal";

export const internalRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={(
        <InternalPrivateRoute>
          <AdminLayout />
        </InternalPrivateRoute>
        )}
    >
      <Route path="/dashboard" element={<DashboardAdminPage />} />

      <Route path="/partners" element={<PartnersDraftAdminPage />} />
      <Route path="/partners/create" element={<PartnerCreateAdminPage />} />
      <Route path="/partners/create/:id" element={<PartnerCreateAdminPage />} />
      <Route path="/partners/published/:id/current" element={<PartnerPublishedView />} />
      <Route path="/partners/published/:id/published" element={<PartnerPublishedView />} />
      <Route
        path="/partners/details/:providerId"
        element={<ProductsByPartner />}
      />
      <Route path="/products/:pId" element={<ProductDetailsAdminPage />} />
      <Route path="/products/published/:pId/current" element={<ProductPublishedView />} />
      <Route path="/products/published/:pId/published" element={<ProductPublishedView />} />
      <Route
        path="/products/create/:pId/category/:categoryId"
        element={<ProductDetailsAdminPage />}
      />
      <Route path="/product-category" element={<ProductCategoryAdminPage />} />
      <Route path="/product-category/:id" element={<ProductCategoryDetailsAdminPage />} />
      <Route
        path="/product-category/published/:id/current"
        element={<ProductCategoryPublishedViewPage />}
      />
      <Route
        path="/product-category/published/:id/published"
        element={<ProductCategoryPublishedViewPage />}
      />
      <Route path="/learn" element={<LearnAdminPage />} />
      <Route path="/experts/:id/faq/:fid" element={<ExpertsNewFaqPage />} />
      <Route path="/experts/published/:id/faq/current" element={<FaqPublishedViewPage />} />
      <Route path="/experts/published/:id/faq/published" element={<FaqPublishedViewPage />} />
      <Route path="/experts/:id" element={<ExpertNewDetailsPage />} />
      <Route path="/experts/published/:id/current" element={<ExpertPublishedViewPage />} />
      <Route path="/experts/published/:id/published" element={<ExpertPublishedViewPage />} />
      <Route path="/experts/add" element={<ExpertCreatePage />} />
      <Route path="/resources/:id" element={<ResourcesDetailsPage />} />
      <Route path="/resources/published/:id/current" element={<ResourcesPublishedViewPage />} />
      <Route path="/resources/published/:id/published" element={<ResourcesPublishedViewPage />} />
      <Route path="/resources/add" element={<ResourcesCreatePage />} />
      <Route path="/quizzes/" element={<QuizzesAdminPage />} />
      <Route path="/quizzes/:id" element={<QuizzesCreatePage />} />
      <Route path="/quizzes/published/:id/current" element={<QuizzesPublishedViewPage />} />
      <Route path="/quizzes/published/:id/published" element={<QuizzesPublishedViewPage />} />
      <Route path="/quizzes/add" element={<QuizzesCreatePage />} />
      <Route path="/tags" element={<TagsAdminPage />} />
      <Route path="/tags/products" element={<TagsAllProductCategory />} />
      <Route path="/tags/product/:id" element={<TagsProductCategory />} />
      <Route path="/tags/quizzes" element={<TagsQuizzesPage />} />
      <Route path="/tags/quizzes/:id" element={<TagsCreateQuiz />} />
      <Route path="/tags/rewards/:id" element={<TagsCreateRewards />} />
      <Route path="/tags/rewards" element={<TagsRewardsPage />} />
      <Route path="/tags/faq" element={<TagsFaqPage />} />
      <Route path="/tags/faq/:id" element={<TagsCreateFaq />} />
      <Route path="/tags/product/add/:id" element={<TagsCreateProduct />} />
      <Route path="/tags/filter/:id" element={<TagsCreateFilterRange />} />
      <Route path="/tags/filter" element={<TagsFilterPage />} />
      <Route path="/users" element={<UsersAdminPage />} />
      <Route path="/users/add" element={<UserCreatePage />} />
      <Route path="/users/:id" element={<UserDetailsPage />} />
      <Route path="/filter" element={<FilterAdminPage />} />
      <Route path="/filter/:id" element={<FilterDetailsAdminPage />} />
      <Route path="/rewards/" element={<RewardsAdminPage />} />
      <Route path="/rewards/:id" element={<RewardsCreatePage />} />
      <Route path="/rewards/published/:id/current" element={<RewardsPublishedViewPage />} />
      <Route path="/rewards/published/:id/published" element={<RewardsPublishedViewPage />} />
      <Route path="/congrats" element={<CongratsAdminPage />} />
      <Route path="/notifications" element={<NotificationsAdminPage />} />
      <Route path="/quiz-theme" element={<QuizThemeAdminPage />} />
      <Route path="/push/:id" element={<NotificationsCreateAdminPage />} />
      <Route path="/tailored" element={<TailoredNotificationAdminPage />} />
      <Route path="/tailored/:type" element={<TailoredNotificationCreatePage />} />
      <Route path="/quiz-theme/:id" element={<QuizThemeCreateAdminPage />} />
      <Route path="/quiz-theme/published/:id/current" element={<QuizThemePublishedViewPage />} />
      <Route path="/stats" element={<UsersStatsAdminPage />} />
      <Route path="/stats/user/:id" element={<UsersInfoAdminPage />} />
      <Route path="/stats/redemption" element={<RedemptionPage />} />
      <Route path="/stats/partners" element={<PartnersReportPage />} />
      <Route path="/stats/users-behaviour" element={<UsersBehaviourReportPage />} />
      <Route path="/stats/external" element={<PartnerSelectionPage />} />
      <Route path="/stats/external/:id" element={<StatisticsPage />} />
      <Route path="/stats/external/:id/products" element={<ProductStatisticsPage />} />

      {/* settings */}
      <Route path="/settings" element={<SettingsAdminPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsDetailsPage />} />
      <Route path="/terms-and-conditions/published/:id/published" element={<TermsAndConditionsPublishedViewPage />} />
      <Route path="/terms-and-conditions/published/:id/current" element={<TermsAndConditionsPublishedViewPage />} />
      {/* <Route path="/stats/external/draft" element={<DraftExternal />} /> */}
      <Route
        path="/quiz-theme/published/:id/published"
        element={<QuizThemePublishedViewPage />}
      />
    </Route>
    <Route
      path="/login"
      element={(
        <PublicRoute>
          <SignInAdminPage />
        </PublicRoute>
        )}
    />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export const externalRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={(
        <ExternalPrivateRoute isExternalUser>
          <UserLayout />
        </ExternalPrivateRoute>
      )}
    >
      <Route path="/:id/dashboard" element={<StatisticsPage />} />
      <Route path="/:id/customer-insights" element={<CustomerInsightsPage />} />
      <Route path="/:id/financial-insights" element={<FinancialInsightsPage />} />
      <Route path="/:id/products" element={<ProductStatisticsPage />} />
      <Route path="/:id/products/:pId" element={<ProductDetailsAdminPage />} />
      <Route path="/:id/products/add" element={<ProductsCreatePage />} />
      <Route path="/:id/products/published/:pId/current" element={<ProductPublishedView />} />
      <Route path="/:id/products/published/:pId/published" element={<ProductPublishedView />} />
      <Route path="/:id/profile" element={<ProfilePage />} />
    </Route>
    <Route
      path="/login"
      element={(
        <PublicRoute>
          <SignInAdminPage />
        </PublicRoute>
        )}
    />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);
