import React, { useEffect, useState } from "react";
import {
  Box, Typography, ToggleButton
} from "@mui/material";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import { Formik } from "formik";
import palette from "theme/palette";
import { useTranslation } from "react-i18next";
import BaseButton from "components/formControl/baseButton/BaseButton";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setImage } from "store/actions/image";
import { createExpert } from "store/actions/experts";
import { fetchCategorySummary } from "store/actions/products";
import { successToast } from "utils";
import { useNavigate } from "react-router-dom";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import { convertToRaw, EditorState } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import { removeSpaces } from "utils/functions";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import { loadGuidebookTopics } from "store/actions/topics";
import { getGuidebookTopicsSelector, getisLoadingTopicsSelector } from "store/selectors/topics";
import PageLoading from "components/PageLoading";

const ExpertCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productCategory } = useSelector((state) => state.products);
  const topics = useSelector(getGuidebookTopicsSelector);
  const isloadingTopics = useSelector(getisLoadingTopicsSelector);
  const lang = useSelector(selectLanguage);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(fetchCategorySummary({ id: "published/summary" }));
    dispatch(loadGuidebookTopics());
  }, []);

  const initialState = {
    name: "",
    imageThumb: "",
    about: EditorState.createEmpty(),
    categoryIds: [],
    topics: [],
    file: ["", "", "", ""]
  };

  const { t } = useTranslation();

  const ExpertSchema = Yup.object().shape({
    name: Yup.string().required(t("validation.required")),
    imageThumb: Yup.string().required(t("validation.required"))
  });

  const handleCreateExpert = async (values) => {
    console.log("values", values);
    const iconUrl = await dispatch(setImage(values.imageThumb));
    const {
      categoryIds, topics: expertTopics, about, name
    } = values;
    const domElAbout = draftjsToHtml(convertToRaw(about.getCurrentContent()))?.toString();
    const emptyAbout = domElAbout.includes("<p></p>") || domElAbout.includes("<p style=\"margin-left:4px;\"></p>");
    const errorObj = {
      ...formErrors,
      ...(emptyAbout && { aboutText: true }),
      ...(!categoryIds?.length && !expertTopics?.length && { expertise: true })
    };

    setFormErrors(errorObj);
    if (Object.values(errorObj).some((item) => item)) return;
    const value = {
      name,
      aboutText: removeSpaces(draftjsToHtml(convertToRaw(about.getCurrentContent()))),
      iconUrl: iconUrl.payload.url,
      categoryIds,
      topics: expertTopics
    };

    dispatch(
      createExpert({
        params: value,
        cb: (res) => {
          successToast("Expert has been created");
          navigate(`/experts/${res.id}${lang === "ar" ? "?lang=ar" : ""}`, {
            replace: true
          });
        }
      })
    );
  };

  if (isloadingTopics) return <PageLoading loading />;

  return (
    <Box mt={2}>
      <Formik
        initialValues={initialState}
        validationSchema={ExpertSchema}
        onSubmit={handleCreateExpert}
        mt={2}
      >
        {({
          values, errors, handleChange, handleSubmit, setFieldValue, setErrors
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <ToggleButton
                sx={{
                  display: "flex",
                  gap: "8px",
                  overflow: "auto",
                  marginRight: "10px",
                  flexWrap: "nowrap",
                  width: "max-content"
                }}
                selected={lang !== "en"}
                onChange={() => {
                  dispatch(setLanguage(lang === "en" ? "ar" : "en"));
                  setErrors({});
                }}
                value="check"
              >
                {lang !== "en" ? "English" : "Arabic"}
              </ToggleButton>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography fontWeight={600} variant="newSubtitle" color={palette.variables.darkPurple2}>
                {t("fields.name")}
              </Typography>
              <BaseTextField
                InputLabelProps={{ required: false }}
                placeholder={t("fields.someText")}
                sx={{ mb: 3, mt: 0 }}
                fullWidth
                id="name"
                name="name"
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                value={values.name}
                color={!errors.name && values.name ? "success" : ""}
              />
              <Typography fontWeight={600} variant="newSubtitle" color={palette.variables.darkPurple2}>
                {t("fields.picture")}
              </Typography>
              <BaseTextField
                InputLabelProps={{ required: false }}
                margin="normal"
                fullWidth
                name="imageThumb"
                id="imageThumb"
                type="file"
                error={!!errors.imageThumb}
                helperText={errors.imageThumb}
                onChange={(e) => setFieldValue("imageThumb", e.currentTarget.files[0])}
                color={!errors.imageThumb && values.imageThumb ? "success" : ""}
                sx={{ marginBottom: "30px" }}
              />
              <Box mt={-3} mb={3}>
                <Typography variant="newBody2">Max size allowed 75KB</Typography>
              </Box>
              <Typography fontWeight={600} variant="newSubtitle" color="common.darkPurple">
                {t("fields.about")}
              </Typography>
              <RichTextEditor
                value={values.about}
                onChange={(editorState) => {
                  setFieldValue("about", editorState);
                  setFormErrors({ ...formErrors, aboutText: false });
                }}
                error={formErrors.aboutText}
              />
              <Box my={4}>
                <Typography fontWeight={600} variant="newSubtitle" color={palette.variables.darkPurple2}>
                  {t("fields.areaOfExpertise")}
                </Typography>
                <Box mt={2}>
                  <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
                    {t("admin.productCategory")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={productCategory}
                      onChange={(event, value) => {
                        handleChange({
                          ...event,
                          target: { name: "categoryIds", value }
                        });
                        setFormErrors({ ...formErrors, expertise: false });
                      }}
                      selected={values?.categoryIds}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="smallTitle">
                    {t("fields.topics")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={topics}
                      onChange={(event, value) => {
                        handleChange({
                          ...event,
                          target: { name: "topics", value }
                        });
                        setFormErrors({ ...formErrors, expertise: false });
                      }}
                      selected={values?.topics}
                      error={formErrors.expertise}
                    />
                  </Box>
                </Box>
                <BaseButton
                  customColor={palette.variables.darkPurple}
                  type="submit"
                  fullWidth
                  variant="contained"
                  element={t("saveExpert")}
                  sx={{ display: "block", maxWidth: 300, marginTop: 3 }}
                />
              </Box>

            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ExpertCreatePage;
