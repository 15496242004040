import React from "react";
import BooleanFormatter from "components/BooleanFormatter.js/BooleanFormatter";
import EmploymentStatusFormattter from "components/EmploymentStatusFormatter/EmploymentStatusFormattter";
import InternalUserFormatter from "components/InternalUserFormatter/InternalUserFormatter";

export const AppliedUsersTableHeaders = [
  {
    key: "name",
    label: "Name",
    Component: (props) => <InternalUserFormatter hasEmail {...props} />,
    textAlign: "start"
  },
  {
    key: "eligibility",
    label: "Eligibility",
    Component: BooleanFormatter
  },
  {
    key: "employmentStatus",
    label: "Employment Status",
    Component: EmploymentStatusFormattter,
    minWidth: 150
  },
  {
    key: "salaryRange",
    label: "Salary Range",
    minWidth: 150
  },
  {
    key: "productsApplied",
    label: "Products Applied For"
  },
  {
    key: "existingProducts",
    label: "Existing Products"
  }
];
