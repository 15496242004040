import React, { useEffect, useRef, useState } from "react";
import {
  Box, Button, Card, CardContent, Grid, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import palette from "theme/palette";

// COMPONENTS
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import GuidebookTopicForm from "components/GuidebookTopicForm/GuidebookTopicForm";
import PageLoading from "components/PageLoading";
import { Permissions } from "components/Permissions";
import WarningEntityLinksModal from "components/WarningEntityLinksModal.js/WarningEntityLinksModal";
import DeletionModal from "components/modal/DeletionModal";
import BaseModel from "components/modal/BaseModal";

// ACTIONS
import { getResources, getResourcesByArabicLang } from "store/actions/resources";

// SELECTORS
import { getGuidebooksSelector } from "store/selectors/resources";
import { getGuidebookTopicsSelector, getisLoadingTopicsSelector } from "store/selectors/topics";
import { getExperts, getExpertsByArabicLang } from "store/actions/experts";
import { getExpertsSelector, getisLoadingExpertsSelector } from "store/selectors/experts";
import { useNavigate } from "react-router-dom";
import {
  deleteGuidebookTopic, getTopicLinksById, loadGuidebookTopicById, loadGuidebookTopics
} from "store/actions/topics";
import { successToast } from "utils";
import { ENTITY_DELETION_STATEMENT, LINKED_ENTITIES_TYPES, WARNING_ENTITY_DELETION } from "utils/constants";
import { useTranslation } from "react-i18next";
import { setLanguage } from "store/app/appSlice";

const LearnAdminPage = () => {
  const dispatch = useDispatch();
  const guidebooks = useSelector(getGuidebooksSelector);
  const guidebookTopics = useSelector(getGuidebookTopicsSelector);

  const experts = useSelector(getExpertsSelector);
  const isLoadingExperts = useSelector(getisLoadingExpertsSelector);
  const isLoadingTopics = useSelector(getisLoadingTopicsSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [delLinksOpen, setDelLinksOpen] = useState(false);
  const [warningLinksOpen, setWarninLinksOpen] = useState(false);
  const [entityLinks, setEntityLinks] = useState([]);
  const [closeTopicsModal, setCloseTopicsModal] = useState();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // necessary when coming back from arabic experts
    dispatch(setLanguage("en"));
    dispatch(loadGuidebookTopics());
  }, []);

  useEffect(() => {
    dispatch(getResources());
    dispatch(getResourcesByArabicLang({ lang: "AR" }));
    dispatch(getExperts());
    dispatch(getExpertsByArabicLang({ lang: "AR" }));
  }, []);

  // to delete a topic
  const anchorRef = useRef(null);

  const onClickLink = async (linkId, type, expertId) => {
    if (type === LINKED_ENTITIES_TYPES.GUIDEBOOK) {
      anchorRef.current.href = `#/resources/${linkId}`;
    } else if (type === LINKED_ENTITIES_TYPES.FAQ) {
      anchorRef.current.href = `#/experts/${expertId}/faq/${linkId}`;
    }

    anchorRef.current.click();
  };

  const checkTopictDependencies = async (id) => {
    setIsLoading(true);
    const data = await dispatch(getTopicLinksById({ id: `${id}/entity_links` }));
    const links = data.payload;

    if (links) {
      const entityHasDiffValues = links.some((item) => item.type !== "Expert");
      if (links.length > 0 && entityHasDiffValues) {
        setEntityLinks([...data.payload]);
        setDelLinksOpen(true);
      } else if (links.length > 0 && !entityHasDiffValues) {
        setDelLinksOpen(false);
        setEntityLinks([...data.payload]);
        setWarninLinksOpen(true);
      } else {
        setDelOpen(true);
        setDelLinksOpen(false);
        setWarninLinksOpen(false);
      }
    }
    setIsLoading(false);
  };

  const handleDeleteTopic = (guidebookId) => {
    const obj = {
      id: guidebookId,
      cb: () => {
        successToast("Topic has been succesfully deleted");
      }
    };
    setIsLoading(true);
    setDelOpen(false);
    setWarninLinksOpen(false);
    dispatch(deleteGuidebookTopic(obj));
    setTimeout(() => {
      dispatch(loadGuidebookTopics());
      setIsLoading(false);
    }, [1000]);
  };

  const handleSubmitTopic = () => {
    setCloseTopicsModal(true);
    setIsLoading(true);
    setTimeout(() => {
      dispatch(loadGuidebookTopics());
      setIsLoading(false);
    }, [1000]);
  };

  if (isLoadingExperts || isLoadingTopics || isLoading) {
    return <PageLoading loading />;
  }

  return (
    <Box padding={2}>
      <Box mb={3}>
        <Typography variant="newTitle">Learn</Typography>
      </Box>
      <Box mb={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box width="45%">
          <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="newSubtitle">Topics</Typography>
            <Permissions permission="write:guidebookTopic">
              <ButtonWithModal
                variant="newButton"
                sx={{ backgroundColor: "#F2F2F2", fontSize: "12px" }}
                title="Create Topic"
                body={(
                  <GuidebookTopicForm
                    id={0}
                    onSubmit={() => handleSubmitTopic()}
                  />
              )}
                forceClose={closeTopicsModal}
              >
                Add more
              </ButtonWithModal>
            </Permissions>
          </Box>
          <Card sx={{
            width: "100%", borderRadius: "25px", padding: 1, height: "345px", overflowY: "auto"
          }}
          >
            <CardContent>
              {
                guidebookTopics.map((topic) => (
                  <Box key={topic.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mb={1}>
                    <Box width="70%">
                      <Typography variant="newBody2">{topic.name}</Typography>
                    </Box>
                    <Box width="30%" sx={{ display: "flex", justifyContent: "end" }}>
                      <ButtonWithModal
                        sx={{
                          fontWeight: 700,
                          fontSize: "10px",
                          lineHeight: "12px",
                          color: palette.variables.lightGreen,
                          textTransform: "none",
                          minWidth: "0 !important"
                        }}
                        title="Edit Topic"
                        handleOpen={() => dispatch(loadGuidebookTopicById({ id: topic.id }))}
                        body={(
                          <GuidebookTopicForm
                            id={topic.id}
                            onSubmit={() => handleSubmitTopic()}
                          />
                        )}
                      >
                        Edit
                      </ButtonWithModal>
                      <Permissions permission="write:guidebookTopic">
                        <Button
                          sx={{
                            fontWeight: 700,
                            fontSize: "10px",
                            lineHeight: "12px",
                            color: palette.variables.orange,
                            textTransform: "none",
                            minWidth: "0 !important"
                          }}
                          onClick={() => checkTopictDependencies(topic.id)}
                        >
                          Delete
                        </Button>
                      </Permissions>
                    </Box>
                    <DeletionModal
                      open={delLinksOpen}
                      hideExperts
                      handleClose={() => setDelLinksOpen(false)}
                      list={entityLinks}
                      text={ENTITY_DELETION_STATEMENT}
                      onPressGoTo={(_id, type, expertId) => onClickLink(_id, type, expertId)}
                      hideLink
                      onReload={() => checkTopictDependencies(topic.id, true)}
                    />
                    <WarningEntityLinksModal
                      open={warningLinksOpen}
                      list={entityLinks}
                      warningText={WARNING_ENTITY_DELETION}
                      onDelete={() => handleDeleteTopic(topic.id)}
                      handleClose={() => setWarninLinksOpen(false)}
                    />
                    <BaseModel
                      open={delOpen}
                      handleClose={() => setDelOpen(false)}
                      text={t("topicDelConfirmation")}
                      handleSuccess={() => handleDeleteTopic(topic.id)}
                    />
                    <a ref={(r) => (anchorRef.current = r)} target="_blank" />
                  </Box>
                ))
              }
            </CardContent>
          </Card>
        </Box>
        <Box width="45%">
          <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "" }}>
            <Typography variant="newSubtitle">Guidebooks</Typography>
            <Permissions permission="write:guidebook">
              <Button
                onClick={() => navigate("/resources/add")}
                variant="newButton"
                sx={{
                  backgroundColor: "#F2F2F2", fontSize: "12px"
                }}
              >
                Add more
              </Button>
            </Permissions>
          </Box>
          <Card sx={{
            width: "100%", borderRadius: "25px", padding: 1, height: "345px", overflowY: "auto"
          }}
          >
            <CardContent>
              {
              guidebooks.map((item) => (
                <Box key={item.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mb={1}>
                  <Box>
                    <Typography variant="newBody2">{item.name}</Typography>
                    <Typography variant="newBody1" ml={3}>{item?.authorId ? item.authorId : "no author"}</Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      onClick={() => navigate(`/resources/${item.id}${item.type ? `?lang=${item.type}` : ""}`)}
                      sx={{
                        fontWeight: 700,
                        fontSize: "10px",
                        lineHeight: "12px",
                        color: palette.variables.lightGreen,
                        textTransform: "none",
                        minWidth: "0 !important"
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>

              ))
              }
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box width="100%">
        <Box mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="newSubtitle">Experts</Typography>
          <Permissions permission="write:expert">
            <Button
              variant="newButton"
              onClick={() => navigate("/experts/add")}
              sx={{ backgroundColor: "#F2F2F2", fontSize: "12px" }}
            >
              Add more
            </Button>
          </Permissions>
        </Box>
        <Grid container spacing={3}>
          {
            experts?.map((expert) => (
              <Grid item xs={10} md={6} lg={4} spacing={1} key={expert.id}>
                <Card
                  key={expert.id}
                  sx={{
                    borderRadius: "25px", padding: 1, height: "170px", overflowY: "auto"
                  }}
                >
                  <CardContent>
                    <Box mb={2}>
                      <img src={expert.iconUrl} alt="expert logo" style={{ objectFit: "contain", width: "112px", height: "70px" }} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="newSubtitle" lineHeight="25px">{expert.name}</Typography>
                      <Typography
                        color={palette.variables.lightGreen}
                        sx={{
                          fontWeight: 700, fontSize: "10px", lineHeight: "12px", cursor: "pointer"
                        }}
                        onClick={() => navigate(`/experts/${expert.id}${expert.type ? `?lang=${expert.type}` : ""}`)}
                      >
                        Edit
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default LearnAdminPage;
