import React, { useState } from "react";
import { bool, instanceOf, string } from "prop-types";
import { Box, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const InternalUserFormatter = ({
  children, hasEmail, rowData, hasLink
}) => {
  const navigate = useNavigate();
  const [underline, setUnderline] = useState(false);
  const isEmailVerified = rowData?.emailVerified;
  const email = rowData?.email || rowData?.userEmail;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        {hasLink && children.length > 1
          ? (
            <Tooltip title="User details">
              <div
                onClick={() => navigate(`/stats/user/${rowData.id}`)}
                onMouseOver={() => setUnderline(true)}
                onMouseOut={() => setUnderline(false)}
                onFocus={() => setUnderline(true)}
                onBlur={() => setUnderline(false)}
                style={{
                  width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <Typography variant="rowHeading" sx={{ cursor: "pointer", textDecoration: underline ? "underline" : "none" }}>
                  {children}
                </Typography>
              </div>
            </Tooltip>
          )
          : <Typography variant="rowHeading">{children}</Typography>}
      </Box>
      { hasEmail
    && (
      <Box sx={{
        display: "flex", alignItems: "center", justifyContent: "flex-start"
      }}
      >
        {
          children.length === 0 ? (
            <Tooltip title="User details">
              <div
                onClick={() => navigate(`/stats/user/${rowData.id}`)}
                onMouseOver={() => setUnderline(true)}
                onMouseOut={() => setUnderline(false)}
                onFocus={() => setUnderline(true)}
                onBlur={() => setUnderline(false)}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <Typography variant="newBody1" mr={2} sx={{ cursor: "pointer", textDecoration: underline ? "underline" : "none" }}>
                  {email}
                </Typography>
              </div>
            </Tooltip>
          )
            : <Typography variant="newBody1" mr={1}>{email}</Typography>
       }
        {Boolean(isEmailVerified) && <Typography color="#68B6B5">&#x2713;</Typography>}
      </Box>
    )}
    </>
  );
};

InternalUserFormatter.propTypes = {
  children: string,
  hasEmail: bool,
  hasLink: bool,
  rowData: instanceOf(Object).isRequired
};

InternalUserFormatter.defaultProps = {
  hasEmail: false,
  children: "",
  hasLink: false
};

export default InternalUserFormatter;
