import React from "react";
import SimpleReportCard from "components/Reports/SimpleReportCard";
import { ReactComponent as CreditCard } from "assets/icons/icon-credit-card.svg";
import { ReactComponent as Star } from "assets/icons/icon-star.svg";
import { ReactComponent as Views } from "assets/icons/icon-views.svg";
import { ReactComponent as Select } from "assets/icons/icon-select.svg";
import { instanceOf } from "prop-types";
import { Box, Typography } from "@mui/material";
import palette from "theme/palette";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StatisticOverviewCards = ({ cards, providerId }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const TOTAL_PRODUCTS = "totalProducts";
  const AVG_VIEWS = "avgView";
  const AVG_CLICKS = "avgApplyClick";
  const AVG_RATING = "avgRating";

  const handleNavigation = () => {
    if (pathname.includes("stats")) {
      return "products";
    }
    return `/${providerId}/products`;
  };
  return (
    <>
      <SimpleReportCard
        title={t("admin.products")}
        width="260px"
        cursor="pointer"
        icon={<CreditCard />}
        handleAction={() => navigate(handleNavigation(), { replace: true })}
        body={(
          <>
            <Typography fontSize="18px" lineHeight="25px" fontWeight={700} my={2}>{cards[TOTAL_PRODUCTS]}</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="newBody1">uploaded</Typography>
              <Typography
                variant="newBody1"
                color={palette.variables.lightGreen}
                sx={{ fontSize: "10px", fontWeight: 700, lineHeight: "12px" }}
              >
                View all
              </Typography>
            </Box>
          </>
    )}
      />
      <SimpleReportCard
        title={t("statistics.totalViewsTitle")}
        width="260px"
        icon={<Views />}
        body={(
          <>
            <Typography fontSize="18px" lineHeight="25px" fontWeight={700} my={2}>{cards[AVG_VIEWS]}</Typography>
            <Typography variant="newBody1">{t("statistics.externalOverviewCardsTxt")}</Typography>
          </>
    )}
      />
      <SimpleReportCard
        title={t("statistics.totalClicksTitle")}
        width="260px"
        icon={<Select />}
        body={(
          <>
            <Typography fontSize="18px" lineHeight="25px" fontWeight={700} my={2}>{cards[AVG_CLICKS]}</Typography>
            <Typography variant="newBody1">{t("statistics.externalOverviewCardsTxt")}</Typography>
          </>
    )}
      />
      <SimpleReportCard
        title={t("statistics.ratingTitle")}
        width="260px"
        icon={<Star />}
        body={(
          <>
            <Typography fontSize="18px" lineHeight="25px" fontWeight={700} my={2}>{Math.round(cards[AVG_RATING])}</Typography>
            <Typography variant="newBody1">{t("statistics.ratingTxt")}</Typography>
          </>
      )}
      />
    </>
  );
};

StatisticOverviewCards.propTypes = {
  cards: instanceOf(Object).isRequired
};

export default StatisticOverviewCards;
