/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import userReducer from "./reducers/user";
import productsReducer from "./reducers/products";
import dashboardReducer from "./reducers/dashboard";
import resourcesReducer from "./reducers/resources";
import pointsReducer from "./reducers/points";
import partnersReducer from "./reducers/partners";
import expertsReducer from "./reducers/experts";
import tagsSlice from "./reducers/tags";
import rewardsSlice from "./reducers/rewards";
import faqSlice from "./reducers/faqs";
import quizzesSlice from "./reducers/quizzes";
import filterSlice from "./reducers/filters";
import permissionsSlice from "./reducers/permissions";
import quizThemesSlice from "./reducers/quizThemes";
import reportsReducer from "./reducers/reports";
import userStatsSlice from "./reducers/userStats";
import guidebookSlice from "./reducers/topics";
import notificationsSlice from "./reducers/notifications";
import termsAndConditionsSlice from "./reducers/termsAndConditions";
import customerInsightsSlice from "./reducers/customerInsights";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    products: productsReducer,
    dashboard: dashboardReducer,
    resources: resourcesReducer,
    points: pointsReducer,
    partners: partnersReducer,
    experts: expertsReducer,
    tags: tagsSlice,
    faqs: faqSlice,
    rewards: rewardsSlice,
    quizzes: quizzesSlice,
    filters: filterSlice,
    permissions: permissionsSlice,
    quizThemes: quizThemesSlice,
    reports: reportsReducer,
    userStats: userStatsSlice,
    guidebooks: guidebookSlice,
    notifications: notificationsSlice,
    termsAndConditions: termsAndConditionsSlice,
    customerInsights: customerInsightsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});
