/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { func, instanceOf } from "prop-types";

const MultipleTypeFilter = ({ multiple, handleChange, setFieldValue }) => {
  const { t } = useTranslation();
  const answers = multiple?.answers;
  return (
    answers.map((item, _index) => (
      <Grid container alignItems="center" mt={3} key={_index}>
        <Grid item xs={10}>
          <BaseTextField
            InputLabelProps={{ required: false }}
            sx={{ marginBottom: "24px" }}
            margin="normal"
            fullWidth
            id="selection"
            label={`${t("fields.selection")} ${_index + 1}`}
            name={`selections[${_index}].title`}
            onChange={(e) => {
              handleChange({
                ...e,
                target: {
                  name: `multiple.answers[${_index}].answer`,
                  value: e.target.value
                }
              });
            }}
            value={item.answer}
          />
        </Grid>
        <Grid item xs={2} pl={2}>
          <Box
            style={{
              width: "70px",
              display: "flex",
              justifyContent: "right"
            }}
          >
            {answers.length > 1 && (
            <button
              type="button"
              onClick={() => {
                // console.log([...multiple.answers.filter((a, i) => i !== _index)])
                setFieldValue("multiple.answers", [
                  ...multiple.answers.filter((a, i) => i !== _index)
                ]);
              }}
              style={{
                border: 0,
                backgroundColor: "#fff",
                borderRadius: "20px"
              }}
            >
              <RemoveCircleOutlineOutlinedIcon sx={{ color: "common.error" }} />
            </button>
            )}
            {(_index == answers.length - 1) && (
            <button
              type="button"
              onClick={() => setFieldValue("multiple.answers", [...multiple.answers, { answer: "" }])}
              style={{
                border: 0,
                backgroundColor: "#fff",
                borderRadius: "20px"
              }}
            >
              <AddCircleOutlineOutlinedIcon sx={{ color: "common.green" }} />
            </button>
            )}
          </Box>
        </Grid>
      </Grid>
    ))
  );
};

MultipleTypeFilter.propTypes = {
  multiple: instanceOf(Object).isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired
};

export default MultipleTypeFilter;
