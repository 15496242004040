/* eslint-disable import/prefer-default-export */
import { instanceOf, string } from "prop-types";
import { useSelector } from "react-redux";

export const Permissions = ({ children, permission }) => {
  const { permissions } = useSelector((state) => state.permissions);
  const all = `${permission.split(":")[0]}:all`;
  return permissions?.find((per) => per === all || per === permission || per === "admin:all")
    ? children
    : null;
};

Permissions.propTypes = {
  children: instanceOf(Object).isRequired,
  permission: string.isRequired
};
