import React from "react";
import {
  ListItem, ListItemIcon, Typography, Box
} from "@mui/material";
import { func, instanceOf, number } from "prop-types";
import palette from "theme/palette";

const NumberedListItem = ({ item, onClickItem, itemNum }) => (
  <ListItem divider>
    <ListItemIcon sx={{ marginRight: "5px !important" }}>
      <Typography variant="newSubtitle" color={palette.variables.darkPurple2}>
        {itemNum}
        .
      </Typography>
    </ListItemIcon>
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Typography
        variant="newBody2"
        color={palette.variables.darkPurple2}
      >
        {item.name}
      </Typography>
      <Typography
        variant="newCaption"
        color={palette.variables.lightGreen}
        onClick={onClickItem}
        sx={{ cursor: "pointer" }}
      >
        Edit
      </Typography>
    </Box>
  </ListItem>
);

NumberedListItem.propTypes = {
  item: instanceOf(Object).isRequired,
  itemNum: number.isRequired,
  onClickItem: func.isRequired
};

export default NumberedListItem;
