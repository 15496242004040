import React from "react";
import { Box, Typography } from "@mui/material";

const BehaviourSelectedFormatter = ({ rowData, objectKey }) => {
  const answers = rowData[objectKey];

  return (
    <Box
      sx={{
        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
      }}
    >
      { answers.map((item) => (
        <Typography mb={1} key={item.selectedCount} variant="newBody1">
          {item.selectedCount}
        </Typography>
      ))}
    </Box>
  );
};

export default BehaviourSelectedFormatter;
