import React from "react";
import palette from "theme/palette";
import StatisticsLineChart from "./StatisticsLineChart";

const data = [
  { averageBalance: 0, quantity: 2000 },
  { averageBalance: 2500, quantity: 10800 },
  { averageBalance: 5000, quantity: 6300 },
  { averageBalance: 10000, quantity: 100 }
];
const AverageBalanceLineChart = ({ ...AverageBalanceLineChartProps }) => {
  const formattedData = data?.map((item) => ({
    ...item,
    quantityRounded: Math.round(Math.abs(item.quantity / 1000))
  }));

  return (
    <StatisticsLineChart
      data={formattedData}
      tooltipValue="quantity"
      lineColor={palette.variables.lightPink}
      minHeight={300}
      {...AverageBalanceLineChartProps}
    />
  );
};

export default AverageBalanceLineChart;
