/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Container, IconButton, Menu, Typography
} from "@mui/material";
import { ReactComponent as UserIcon } from "assets/icons/users-card.svg";
import { ReactComponent as VerifiedIcon } from "assets/icons/verified-users-card.svg";
import { ReactComponent as Download } from "assets/icons/download.svg";
import { ReactComponent as Filter } from "assets/icons/filter-icon.svg";
import { Close } from "@mui/icons-material";

// HEADERS
import { UserTableHeaders } from "config/tableHeaders/InternalUsers/UserListTableHeaders";

// COMPONENTS
import SortMenu from "components/SortMenu/SortMenu";
import Searchbar from "components/Searchbar/Searchbar";
import CustomTable from "components/Table/CustomTable";
import PageLoading from "components/PageLoading";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import SimpleReportCard from "components/Reports/SimpleReportCard";
import FilterMenuItem from "components/FilterMenuItem/FilterMenuItem";

// ACTIONS
import { downloadUsersReport, loadGeneralUsersStats, loadUsersStats } from "store/actions/userStats";
import { setUserFilters } from "store/reducers/userStats";

// SELECTORS
import {
  getDownloadedUsersReportSelector,
  getIsLoadingGeneralStatsSelector,
  getIsLoadingSelector, getUsersRecordsSelector, getUsersStatsSelector, getUserStatsFilterSelector
} from "store/selectors/userStats";
import { internalReports, internalUserSortData } from "utils/staticValues";

// UTILS
import palette from "theme/palette";
import { yymmdd } from "utils/functions";
import { useNavigate } from "react-router-dom";
import styles from "../../admin.module.scss";

const internalUserFilterData = [
  {
    key: "filterVerified",
    label: "Verified"
  },
  {
    key: "filterHealth",
    label: "Health connected"
  },
  {
    key: "filterRedemption",
    label: "Redemption"
  }
];

const UsersStatsAdminPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usersRecords = useSelector(getUsersRecordsSelector);
  const usersStats = useSelector(getUsersStatsSelector);
  const downloadedReport = useSelector(getDownloadedUsersReportSelector);
  const isLoading = useSelector(getIsLoadingSelector);
  const isLoadingGeneralStats = useSelector(getIsLoadingGeneralStatsSelector);
  const userFilters = useSelector(getUserStatsFilterSelector);

  const [filterKeys, setFilterKeys] = useState([]);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    const initialRequest = {
      searchString: "",
      sortBy: "name",
      sortDir: "asc",
      page: 1
    };
    if (!Object.keys(usersRecords)?.length) {
      dispatch(loadUsersStats(initialRequest));
    }
    dispatch(downloadUsersReport({ download: true }));
  }, []);

  useEffect(() => {
    if (!Object.keys(usersStats)?.length) {
      dispatch(loadGeneralUsersStats());
    }
  }, []);

  // filter states
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);

  const handleFilterChange = (filter, selKeys) => {
    setFilterKeys([...selKeys]);

    const filtersData = {
      sortBy: userFilters.sortBy,
      sortDir: userFilters.sortDir,
      searchString: userFilters.searchString,
      filterBy: [...selKeys].toString(),
      ...(filter?.filterVerified && { filterVerified: filter.filterVerified }),
      ...(filter?.filterHealth && { filterHealth: filter.filterHealth }),
      ...(filter?.filterRedemption && { filterRedemption: filter.filterRedemption }),
      page: 1
    };

    dispatch(loadUsersStats(filtersData));
    dispatch(setUserFilters(filtersData));
  };

  const handleSearchChange = (val) => {
    setActivePage(1);
    const updatedFilters = {
      ...userFilters,
      page: 1,
      searchString: val
    };

    dispatch(loadUsersStats(updatedFilters));
    dispatch(setUserFilters(updatedFilters));
  };

  const handleCheckboxChange = (sortItem) => {
    setActivePage(1);
    const updatedFilters = {
      ...userFilters,
      ...sortItem,
      page: 1
    };

    dispatch(loadUsersStats(updatedFilters));
    dispatch(setUserFilters(updatedFilters));
  };

  const handlePrevArrowClick = () => {
    setActivePage(activePage - 1);
    const updatedFilters = {
      ...userFilters,
      page: activePage - 1
    };

    dispatch(loadUsersStats(updatedFilters));
  };

  const handleNextArrowClick = () => {
    setActivePage(activePage + 1);
    const updatedFilters = {
      ...userFilters,
      page: activePage + 1
    };

    dispatch(loadUsersStats(updatedFilters));
  };

  const totalUsers = Number(usersStats?.totalUsers).toLocaleString("en") || "";
  const totalVerified = Number(usersStats?.totalVerified).toLocaleString("en") || "";

  if (isLoadingGeneralStats) return <PageLoading loading={isLoadingGeneralStats} />;

  const today = yymmdd(new Date());

  return (
    <Box className={styles.container} padding={2}>
      <Box
        sx={{
          display: "flex", justifyContent: "flex-start ", alignItems: "center", width: "100%", minWidth: "75rem"
        }}
        mb={3}
      >
        <Box
          sx={{
            display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "50%", marginRight: 3
          }}
        >
          <Typography variant="bodyBig" fontWeight="600" mb={3}>{t("statistics.generalStats")}</Typography>
          <Box sx={{
            display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%"
          }}
          >
            <Container sx={{ padding: "0 !important", marginRight: 2 }}>
              <SimpleReportCard
                title="Users"
                width="259px"
                icon={<UserIcon color={palette.variables.lightPink} />}
                body={(
                  <>
                    <Typography fontSize="18px" lineHeight="15px" fontWeight={700} my={2}>{totalUsers}</Typography>
                    <Typography variant="newBody1">{t("statistics.usersRegistered")}</Typography>
                  </>
              )}
              />
            </Container>
            <Container sx={{ padding: "0 !important", marginRight: 3 }}>
              <SimpleReportCard
                title="Verified users"
                width="259px"
                icon={<VerifiedIcon color={palette.variables.lightPink} />}
                body={(
                  <>
                    <Typography fontSize="18px" lineHeight="15px" fontWeight={700} my={2}>{totalVerified}</Typography>
                    <Typography variant="newBody1">{t("statistics.usersVerified")}</Typography>
                  </>
              )}
              />
            </Container>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "50%"
          }}
          my={3}
        >
          <Typography variant="title" mb={3}>{t("statistics.reports")}</Typography>
          <Box sx={{
            display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%"
          }}
          >
            {
              internalReports.map((card) => (
                <Container key={card.title} sx={{ padding: "0 !important", marginRight: 2 }}>
                  <SimpleReportCard
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    cursor="pointer"
                    title={card.title}
                    icon={card.icon}
                    handleAction={() => navigate(`/stats/${card.pathname}`)}
                    footer={(
                      <Typography
                        color={palette.variables.lightGreen}
                        sx={{ fontSize: "10px", fontWeight: 700, lineHeight: "12px" }}
                        ml={1}
                      >
                        Generate
                      </Typography>
                    )}
                  />
                </Container>
              ))
            }
          </Box>
        </Box>
      </Box>
      <Box
        mb={3}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Box>
          <Typography variant="title" fontWeight={600}>{t("statistics.userListHeader")}</Typography>
        </Box>
        <Box sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}
        >
          <Searchbar onChange={handleSearchChange} />
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            sx={{ backgroundColor: "#F2F2F2", marginX: 1 }}
          >
            <Filter style={{ color: "gray" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openFilter}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Box sx={{
              display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
            }}
            >
              <Typography variant="body2" fontSize="12px" sx={{ paddingX: 2, width: "70%" }}>Filter by:</Typography>
              <IconButton size="small" onClick={() => setAnchorEl(null)} sx={{ marginRight: 1 }}>
                <Close sx={{ height: 12, width: 12 }} />
              </IconButton>
            </Box>
            {
              internalUserFilterData.map((filter) => (
                <FilterMenuItem
                  key={filter.key}
                  filterItem={filter}
                  selectedFilters={userFilters}
                  selectedKeys={filterKeys}
                  onChangeFilter={handleFilterChange}
                >
                  {filter.label}
                </FilterMenuItem>
              ))
           }
          </Menu>
          <SortMenu
            initialChecked={userFilters.sortBy}
            initialDir={userFilters.sortDir}
            data={internalUserSortData}
            onChange={handleCheckboxChange}
            mr={2}
          />
          <ButtonWithModal
            title="Download report"
            isIcon
            confirmLabel="Download"
            download={{ name: `users_report_${today}`, file: downloadedReport }}
            handleOpen={() => {
              dispatch(downloadUsersReport({
                download: true,
                ...userFilters
              }));
            }}
            body={<Typography variant="body2">{`Are you sure you want to download users_report_${today}.csv report?`}</Typography>}
            disabled={!downloadedReport.length}
            size="small"
            sx={{ backgroundColor: "#F2F2F2", marginX: 1 }}
          >
            <Download style={{ height: 20, width: 25 }} />
          </ButtonWithModal>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
        <CustomTable
          width="100%"
          minHeight="580px"
          data={usersRecords?.records}
          loading={isLoading}
          headers={UserTableHeaders}
          pagination={{
            activePage,
            totalPages: usersRecords.totalPages,
            next: handleNextArrowClick,
            prev: handlePrevArrowClick,
            totalRecords: usersRecords.totalRecords

          }}
        />
      </Box>
    </Box>
  );
};

export default UsersStatsAdminPage;
