export const PATH = {
  POINTS: "/points",
  REWARDS: "/rewards",
  RESOURCES: "/resources",
  SETTINGS: "/settings"
};
export const IMAGE_SIZE = 15;
export const IMAGE_LIMITS = "(max size 15KB, .png, .jpg, .jpeg)";
export const DEADLINE_RESEND = 30;
export const LANGUAGE_ERROR = "Request failed with status code 412";
export const PUBLISH_ERROR = " EN or AR data is missing";
export const ENTITY_DELETION_STATEMENT = "You need to unlink all listed links / connections for this entity for deletion. Remove connection and then save or publish it.";
export const WARNING_ENTITY_DELETION = "By clicking delete, all the experts linked to this entity will be automatically unlinked. Are you sure you want to delete this topic?";
export const EXPERT_UPDATE_STATEMENT = "Before updating this expert, you must unlink the following quidebooks/faqs as they are linked to one of the categories/topics you want to deselect";

export const LINKED_ENTITIES_TYPES = {
  PRODUCT: "Product",
  PRODUCT_CATEGORY: "Product Category",
  FAQ: "FAQ",
  QUIZ: "Quiz",
  QUIZ_THEME: "Quiz Theme",
  REWARD: "Reward",
  FILTER: "Filter",
  GUIDEBOOK: "Guidebook",
  GUIDEBOOK_TOPIC: "Guidebook Topic",
  QUIZ_QA: "QuizQnA",
  PROVIDER: "Product Provider",
  EXPERT: "Expert",
  PRODUCT_TAG: "Product Tag"
};

export const TAGS_DELETE_TYPE = {
  FAQ: "FAQ",
  QUIZ: "Quiz",
  REWARD: "Reward",
  FILTER: "Filter",
  PRODUCT: "Product"
};
