/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { redemptionsReportSortData } from "utils/staticValues";
import { ReactComponent as Download } from "assets/icons/download.svg";

// HEADERS
import { RedemptionsTableHeaders } from "config/tableHeaders/InternalUsers/RedemptionsTableHeaders";

// ACTIONS
import { downloadRedemptionsReport, loadRedemptionsReport } from "store/actions/reports";
import { loadPublishedRewards } from "store/actions/rewards";

// SELECTORS
import { getisLoadingRewardsSelector } from "store/selectors/rewards";
import { getDownloadedReportSelector, getisLoadingReportsSelector, getRedemptionsReportSelector } from "store/selectors/reports";

// COMPONENTS
import FilterMenuItemWithChildren from "components/FilterMenuItemWIthChildren/FilterMenuItemWithChildren";
import SortMenu from "components/SortMenu/SortMenu";
import Searchbar from "components/Searchbar/Searchbar";
import CustomTable from "components/Table/CustomTable";
import PageLoading from "components/PageLoading";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";

import { yymmdd } from "utils/functions";

import styles from "../../admin.module.scss";

// const redemptionsReport = generateFakeRedemptionsReportList(5);

const RedemptionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redemptionsReport = useSelector(getRedemptionsReportSelector);
  const downloadReport = useSelector(getDownloadedReportSelector);
  const isLoadingReport = useSelector(getisLoadingReportsSelector);
  const isLoadingPartner = useSelector(getisLoadingRewardsSelector);

  const [filters, setFilters] = useState({
    searchString: "",
    filterBy: "",
    sortBy: "partner", // other options are reward, user, date, points
    sortDir: "asc",
    page: 1
  });
  const [activePage, setActivePage] = useState(1);

  console.log(filters);
  useEffect(() => {
    dispatch(loadPublishedRewards());
  }, []);

  useEffect(() => {
    const params = {
      sortBy: "partner", // other options are reward, user, date, points
      sortDir: "asc",
      page: 1,
      ...filters
    };
    if (params.filterDate) {
      const dateKeys = ["filterFrom", "filterTo"];
      const { filterDate } = params;

      params[dateKeys[0]] = yymmdd(new Date(filterDate[0]), true);
      params[dateKeys[1]] = yymmdd(new Date(filterDate[1]), true);

      const key = "filterDate";
      delete params[key];
    }
    dispatch(loadRedemptionsReport(params));
    dispatch(downloadRedemptionsReport({ download: true, ...params }));
  }, [filters]);

  const handleCheckboxChange = (sortItem) => {
    setActivePage(1);
    const updatedFilters = {
      ...filters,
      ...sortItem,
      page: 1
    };
    setFilters(updatedFilters);
  };

  const handlePrevArrowClick = () => {
    setActivePage(activePage - 1);
    const updatedFilters = {
      ...filters,
      page: (activePage - 1)
    };
    dispatch(loadRedemptionsReport(updatedFilters));
  };

  const handleNextArrowClick = () => {
    setActivePage(activePage + 1);
    const updatedFilters = {
      ...filters,
      page: (activePage + 1)
    };
    dispatch(loadRedemptionsReport(updatedFilters));
  };

  const handleSearchChange = (val) => {
    setActivePage(1);
    const updatedFilters = {
      ...filters,
      page: 1,
      searchString: val
    };
    dispatch(loadRedemptionsReport(updatedFilters));
    dispatch(downloadRedemptionsReport({ download: true, ...updatedFilters }));
  };

  const handleFilterChange = (filter, selKeys) => {
    const updatedFilters = {
      sortBy: filters.sortBy,
      sortDir: filters.sortDir,
      searchString: filters.searchString,
      filterBy: [...selKeys].toString(),
      ...(filter?.filterPartner && { filterPartner: filter.filterPartner }),
      ...(filter?.filterDate && { filterDate: filter.filterDate }),
      page: 1
    };

    setFilters(updatedFilters);
  };

  if (isLoadingPartner) return <PageLoading loading={isLoadingPartner} />;

  const today = yymmdd(new Date(), true);
  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("statistics.redemptionReport")}</Typography>
      </Box>
      <Box
        mb={5}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Box>
          <Typography variant="newSubtitle">{t("statistics.redemptionText")}</Typography>
        </Box>
        <Box sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}
        >
          <Searchbar onChange={handleSearchChange} />
          <Box mx={1}>
            <FilterMenuItemWithChildren
              selectedFilters={filters}
              onChangeFilter={handleFilterChange}
            />
          </Box>
          <SortMenu
            initialDir="asc"
            initialChecked="user"
            data={redemptionsReportSortData}
            onChange={handleCheckboxChange}
            mr={2}
          />
          <ButtonWithModal
            title="Download report"
            isIcon
            confirmLabel="Download"
            download={{ name: `redemptions_report_ ${today}`, file: downloadReport }}
            body={<Typography variant="body2">{`Are you sure you want to download redemptions_report_${today}.csv report?`}</Typography>}
            disabled={!downloadReport.length}
            size="small"
            sx={{ backgroundColor: "#F2F2F2", marginX: 1 }}
          >

            <Download style={{ height: 20, width: 25 }} />
          </ButtonWithModal>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
        <CustomTable
          width="100%"
          minHeight="580px"
          data={redemptionsReport?.records}
          loading={isLoadingReport}
          headers={RedemptionsTableHeaders}
          pagination={{
            activePage,
            totalPages: redemptionsReport?.totalPages,
            next: handleNextArrowClick,
            prev: handlePrevArrowClick,
            totalRecords: redemptionsReport?.totalRecords

          }}
        />
      </Box>
    </Box>
  );
};

export default RedemptionPage;
