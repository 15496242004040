/* eslint-disable import/prefer-default-export */
import { bool, instanceOf, string } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { failureToast } from "utils";

export const DisablePermissions = ({ children, permission, disable }) => {
  const { permissions } = useSelector((state) => state.permissions);
  const all = `${permission.split(":")[0]}:all`;
  const shouldDisable = !permissions?.find(
    (per) => per === all || per === permission || per === "admin:all"
  );

  return shouldDisable && disable ? (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => shouldDisable && failureToast("You do not have permissions.")}
      onClick={() => shouldDisable && failureToast("You do not have permissions.")}
    >
      <div style={{ pointerEvents: "none", opacity: 0.5 }}>{children}</div>
    </div>
  ) : (
    children
  );
};

DisablePermissions.propTypes = {
  children: instanceOf(Object).isRequired,
  permission: string.isRequired,
  disable: bool
};

DisablePermissions.defaultProps = {
  disable: false
};
