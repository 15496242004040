import { createSlice } from "@reduxjs/toolkit";
import {
  downloadBehaviourReport,
  downloadExternalProductsReport,
  downloadPartnersReport,
  downloadRedemptionsReport,
  getReportOverview,
  loadClickRatingReport,
  loadExternalProducts,
  loadPartnersReport,
  loadRedemptionsReport,
  loadUsersBehaviourReport
} from "store/actions/reports";

const initialState = {
  externalReports: [],
  reportOverview: {},
  externalProducts: [],
  redemptionsReport: [], // internal
  partnersReport: [],
  usersBehaviourReport: [],
  downloadedReport: "",
  loading: false,
  loadingExternal: false,
  loadingExternalOverview: false
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: {
    [loadClickRatingReport.pending]: (state) => {
      state.loadingExternal = true;
      state.error = null;
    },
    [loadClickRatingReport.fulfilled]: (state, { payload }) => {
      state.externalReports = payload;
      state.loadingExternal = false;
    },
    [loadClickRatingReport.rejected]: (state, { payload }) => {
      state.loadingExternal = false;
      state.error = payload;
    },
    [getReportOverview.pending]: (state) => {
      state.loadingExternalOverview = true;
      state.error = null;
    },
    [getReportOverview.fulfilled]: (state, { payload }) => {
      state.reportOverview = payload;
      state.loadingExternalOverview = false;
    },
    [getReportOverview.rejected]: (state, { payload }) => {
      state.loadingExternalOverview = false;
      state.error = payload;
    },
    [loadExternalProducts.pending]: (state) => {
      state.loadingExternal = true;
      state.error = null;
    },
    [loadExternalProducts.fulfilled]: (state, { payload }) => {
      state.externalProducts = payload;
      state.loadingExternal = false;
    },
    [loadExternalProducts.rejected]: (state, { payload }) => {
      state.loadingExternal = false;
      state.error = payload;
    },
    [downloadExternalProductsReport.pending]: (state) => {
      state.loadingExternal = true;
      state.error = null;
    },
    [downloadExternalProductsReport.fulfilled]: (state, { payload }) => {
      state.downloadedReport = payload;
      state.loadingExternal = false;
    },
    [downloadExternalProductsReport.rejected]: (state, { payload }) => {
      state.loadingExternal = false;
      state.error = payload;
    },
    [loadRedemptionsReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadRedemptionsReport.fulfilled]: (state, { payload }) => {
      state.redemptionsReport = payload;
      state.loading = false;
    },
    [loadRedemptionsReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [downloadRedemptionsReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [downloadRedemptionsReport.fulfilled]: (state, { payload }) => {
      state.downloadedReport = payload;
      state.loading = false;
    },
    [downloadRedemptionsReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadPartnersReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadPartnersReport.fulfilled]: (state, { payload }) => {
      state.partnersReport = payload;
      state.loading = false;
    },
    [loadPartnersReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [downloadPartnersReport.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [downloadPartnersReport.fulfilled]: (state, { payload }) => {
      state.downloadedReport = payload;
      state.loading = false;
    },
    [downloadPartnersReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadUsersBehaviourReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadUsersBehaviourReport.fulfilled]: (state, { payload }) => {
      state.usersBehaviourReport = payload;
      state.loading = false;
    },
    [loadUsersBehaviourReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [downloadBehaviourReport.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [downloadBehaviourReport.fulfilled]: (state, { payload }) => {
      state.downloadedReport = payload;
      state.loading = false;
    },
    [downloadBehaviourReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default reportsSlice.reducer;
