import { API } from "aws-amplify";
import RequestService from "./RequestService";

class NotificationsService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async getNotificationTypes() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}-types`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postNotificationType(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}-type`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

const notificationsApiName = "MyAPIGatewayAPI";
const notificationsPath = "/cms/pushNotification";

export default new NotificationsService(notificationsApiName, notificationsPath, API);
