import { Typography } from "@mui/material";
import { string } from "prop-types";
import React from "react";

const FormRequiredText = ({ children }) => <Typography variant="caption" textTransform="none" color="#C86B6D">{children}</Typography>;

FormRequiredText.propTypes = {
  children: string.isRequired
};

export default FormRequiredText;
