import React from "react";
import { Box, Typography } from "@mui/material";
import { number, string } from "prop-types";
import StarsRating from "stars-rating";

const StarRating = ({
  children, text, size, fontSize, justify
}) => {
  const handleDecimals = (rating) => {
    if (!rating) return 0;
    const num = parseFloat(rating); // Make sure we have a number
    let decimal = (num - parseInt(num, 10));

    decimal = Math.round(decimal * 10);

    if (decimal === 5) {
      return parseInt(num, 10) + 0.5;
    }
    if ((decimal < 3) || (decimal > 7)) {
      return Math.round(num);
    }
    return (parseInt(num, 10) + 0.5);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: justify, alignItems: "center" }}>
        <StarsRating
          edit={false}
          size={size}
          color1="#DDDDDD"
          color2="#F29469"
          count={5}
          value={handleDecimals(children)}
        />
        <Typography variant={fontSize} mt={0.5} ml={2}>
          {handleDecimals(children)}
        </Typography>
      </Box>
      <Typography variant="newBody1">{text}</Typography>
    </Box>
  );
};

StarRating.propTypes = {
  children: number.isRequired,
  text: string,
  size: number,
  fontSize: string,
  justify: string
};

StarRating.defaultProps = {
  text: "",
  size: 15,
  fontSize: "newBody1",
  justify: "center"
};

export default StarRating;
