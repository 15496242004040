/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { externalRoleBasedRoutes } from "utils/staticValues";
import { useSelector } from "react-redux";
import { instanceOf } from "prop-types";
import { getAuthUserSelector } from "store/selectors/user";

const ExternalPrivateRoute = ({ children }) => {
  const location = useLocation();

  const { permissions } = useSelector((state) => state.permissions);
  // const providerId = useSelector(getUserByIdSelector)?.providerId;
  const authUser = useSelector(getAuthUserSelector);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (permissions?.length) {
      // let flag = false;
      externalRoleBasedRoutes.forEach((role) => {
        const key = Object.keys(role)[0];

        // searched if there is a match between the pathname an the regex
        if (role[key]?.reg?.test(location.pathname)) {
          document.title = `${role[key]?.title && `${role[key]?.title} | `}Daleel`; // setting document title
        }
      });
    }
    if (location.pathname === "/dashboard") {
      document.title = "Dashboard | Daleel";
    } else if (location.pathname === "/settings" || location.pathname === "/stats") {
      document.title = "Daleel";
    }
  }, [location]);

  return (
    authUser && authUser !== null ? (
      children
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  );
};

ExternalPrivateRoute.propTypes = {
  children: instanceOf(Object).isRequired
};

export default ExternalPrivateRoute;
