import DateToStringFormatter from "components/DateToStringFormatter/DateToStringFormatter";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import InternalUserFormatter from "components/InternalUserFormatter/InternalUserFormatter";
import React from "react";

export const RedemptionsTableHeaders = [
  {
    key: "rewardName",
    label: "Reward",
    Component: (props) => (
      <ImageFormatter
        fontVariant="newBody2"
        imgKey="imageUrl"
        fontWeight={500}
        {...props}
      />
    ),
    textAlign: "start"
  },
  {
    key: "userName",
    label: "User",
    Component: (props) => <InternalUserFormatter hasEmail {...props} />,
    textAlign: "start"
  },
  {
    key: "redeemedAt",
    label: "Date Redeemed",
    Component: DateToStringFormatter,
    textAlign: "center"
  },
  {
    key: "points",
    label: "Points Used"
  },
  {
    key: "rewardCode",
    label: "Reward Code"
  }
];
