/* eslint-disable no-underscore-dangle */
import { API } from "aws-amplify";
import { getErrorStringPart } from "utils";
import RequestService from "./RequestService";

const reportApiName = "MyAPIGatewayAPI";
const reportsPathname = "/cms/report";

class ReportsService extends RequestService {
  constructor(apiName, path, api) {
    super(apiName, path, api);
  }

  async postReportById(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/external/clickrating`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getReportOverview(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/external/overview?${new URLSearchParams(params)}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postExternalProductsReport({ params }) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/external/products`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postDownloadExternalProductsReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/external/products/download`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  // internal
  async postRedemptionsReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/redemptions`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postDownloadRedemptionsReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/redemptions/download`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getUserStatsOverview() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/internal/stats`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postDownloadUsersReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/users/download`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postInternalUserStats(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/users`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postPartnersReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/partners`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postDownloadPartnersReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/partners/download`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postUsersBehaviourReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/userbehaviour`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postDownloadBehaviourReport(params) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}/internal/userbehaviour/download`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new ReportsService(reportApiName, reportsPathname, API);
