import React, { useEffect, useState } from "react";
import {
  Box, Typography, Input, CircularProgress
} from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { getFaqById } from "store/actions/faqs";

import { useTranslation } from "react-i18next";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import { getTopicsByExpertSelector } from "store/selectors/topics";
import { getCategoriesByExpertSelector, getIsLoadingProductCategoriesSelector } from "store/selectors/products";
import { loadCategoriesByExpertId } from "store/actions/products";
import { loadTopicsByExpertId } from "store/actions/topics";

const FaqPublishedViewPage = () => {
  const { t } = useTranslation();
  const lang = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const isPublished = pathname.split("/").at(-1) === "published";
  const topicsByAuthor = useSelector(getTopicsByExpertSelector);
  const categoriesByAuthor = useSelector(getCategoriesByExpertSelector);
  const isLoadingCategories = useSelector(getIsLoadingProductCategoriesSelector);
  const { error } = useSelector((reduxState) => reduxState.faqs);
  const [searchParams] = useSearchParams();

  const { expertId } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [currentFaq, setCurrentFaq] = useState({});

  useEffect(() => {
    if (error !== null) {
      setCurrentFaq("");
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (searchParams.get("lang") && searchParams.get("lang") === "ar") {
      dispatch(setLanguage("ar"));
    }
    setIsLoading(true);
    dispatch(getFaqById({
      id: isPublished ? `${id}/published` : id,
      cb: (res) => {
        setTimeout(() => {
          setCurrentFaq(res);
          setIsLoading(false);
        }, [800]);
      }
    }));
  }, [id]);

  useEffect(() => {
    dispatch(loadCategoriesByExpertId({
      params: { id: expertId }
    }));
    dispatch(loadTopicsByExpertId({
      params: { id: expertId }
    }));
  }, [lang]);

  const getAnswer = (answer) => {
    if (answer) {
      const contentBlock = htmlToDraft(answer);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
      }
    }
  };

  console.log("faq", currentFaq);

  if (isLoading || isLoadingCategories) {
    return (
      <Box sx={{
        display: "flex", justifyContent: "center", alignItems: "center", height: "500px"
      }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ mt: 3 }}>
      <Formik
        initialValues={{
          id: currentFaq?.id || "",
          question: currentFaq.question,
          answer: getAnswer(currentFaq?.answer),
          topics: currentFaq?.topics,
          categoryId: currentFaq?.categoryId,
          publish: currentFaq?.publish || false
        }}
        enableReinitialize
      >
        {({ values }) => (
          <form>
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box sx={{ ml: 2, width: "100%" }}>
                <Typography variant="smallTitle">Question</Typography>
                <Box mt={1}>
                  <Input
                    fullWidth
                    disabled
                    defaultValue={values.question}
                    sx={{
                      fontSize: "11px",
                      lineHeight: "14px"
                    }}
                  />
                </Box>
                <RichTextEditor value={values.answer} disabled />
                <Box
                  sx={{
                    mt: 3
                  }}
                >
                  <Typography variant="smallTitle">
                    {t("admin.productCategory")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={categoriesByAuthor}
                      disabled
                      selected={[values.categoryId]}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 3
                  }}
                >
                  <Typography variant="smallTitle">
                    {t("fields.topics")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={topicsByAuthor}
                      disabled
                      selected={values.topics}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FaqPublishedViewPage;
