import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Typography
} from "@mui/material";
import { ReactComponent as Download } from "assets/icons/download.svg";

// HEADERS
import { PartnerReportTableHeaders } from "config/tableHeaders/InternalUsers/PartnersReportTableHeaders";

// COMPONENTS
import PartnersMenuItemWithChildren from "components/PartnersMenuWithChildren/PartnersMenuWithChildren";
import SortMenu from "components/SortMenu/SortMenu";
import Searchbar from "components/Searchbar/Searchbar";
import CustomTable from "components/Table/CustomTable";
import PageLoading from "components/PageLoading";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";

// ACTION
import { fetchCategorySummary } from "store/actions/products";
import { loadPartnerSummary } from "store/actions/partners";

// SELECTORS
import { partnersReportSortData } from "utils/staticValues";
import { downloadPartnersReport, loadPartnersReport } from "store/actions/reports";
import { getDownloadedReportSelector, getisLoadingReportsSelector, getPartnersReportSelector } from "store/selectors/reports";
import { getisLoadingPartnersSelector } from "store/selectors/partners";

import { yymmdd } from "utils/functions";
import { useTranslation } from "react-i18next";
import styles from "../../admin.module.scss";

const PartnersReportPage = () => {
  const dispatch = useDispatch();
  const { productCategory } = useSelector((state) => state.products);
  const partnersReport = useSelector(getPartnersReportSelector);
  const downloadReport = useSelector(getDownloadedReportSelector);
  const isLoadingPartners = useSelector(getisLoadingReportsSelector);
  const isLoadingPartnerSummary = useSelector(getisLoadingPartnersSelector);

  const [filters, setFilters] = useState({
    searchString: "",
    filterBy: "",
    sortBy: "provider",
    sortDir: "asc"
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!productCategory?.length) {
      dispatch(fetchCategorySummary({ id: "published/summary" }));
    }
    dispatch(loadPartnerSummary({ id: "published" }));

    // call endpoint to download report
    dispatch(downloadPartnersReport({ download: true }));
  }, []);

  useEffect(() => {
    if (!partnersReport?.length) {
      dispatch(loadPartnersReport(filters));
    }
  }, []);

  const handleSearchChange = (val) => {
    const updatedFilters = {
      ...filters,
      searchString: val
    };
    setFilters(updatedFilters);
    dispatch(loadPartnersReport(updatedFilters));
  };

  const handleCheckboxChange = (sortItem) => {
    const updatedFilters = {
      ...filters,
      ...sortItem
    };
    setFilters(updatedFilters);
    dispatch(loadPartnersReport(updatedFilters));
  };

  const handleFilterChange = (filter, selKeys) => {
    const updatedFilters = {
      sortBy: filters.sortBy,
      sortDir: filters.sortDir,
      searchString: filters.searchString,
      filterBy: [...selKeys].toString(),
      ...(filter?.filterProvider && { filterProvider: filter.filterProvider }),
      ...(filter?.filterCategory && { filterCategory: filter.filterCategory }),
      ...(filter?.filterRating && { filterRating: filter.filterRating })
    };

    setFilters(updatedFilters);
    dispatch(loadPartnersReport(updatedFilters));
  };

  const today = yymmdd(new Date(), true);

  if (!productCategory?.length || isLoadingPartnerSummary) {
    return <PageLoading loading={!productCategory?.length || isLoadingPartnerSummary} />;
  }

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("statistics.partnersReport")}</Typography>
      </Box>
      <Box
        mb={5}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Box>
          <Typography variant="newSubtitle">{t("statistics.partnersTxt")}</Typography>
        </Box>
        <Box sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}
        >
          <Searchbar onChange={handleSearchChange} />
          <Box mx={1}>
            <PartnersMenuItemWithChildren
              selectedFilters={filters}
              onChangeFilter={handleFilterChange}
            />
          </Box>
          <SortMenu
            initialChecked="provider"
            initialDir="asc"
            data={partnersReportSortData}
            onChange={handleCheckboxChange}
            mr={2}
          />
          <ButtonWithModal
            title="Download report"
            isIcon
            confirmLabel="Download"
            download={{ name: `partners_report_${today}`, file: downloadReport }}
            handleOpen={() => {
              dispatch(downloadPartnersReport({
                download: true,
                ...filters
              }));
            }}
            body={<Typography variant="body2">{`Are you sure you want to download partners_report_${today}.csv report?`}</Typography>}
            disabled={!downloadReport.length}
            size="small"
            sx={{ backgroundColor: "#F2F2F2", marginX: 1 }}
          >

            <Download style={{ height: 20, width: 25 }} />
          </ButtonWithModal>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%"
        }}
      >
        {partnersReport?.map((item) => (
          <Box mb={7} key={item.providerId}>
            <CustomTable
              width="100%"
              toolbar={(
                <ImageFormatter
                  imgSrc={item.imageUrl}
                  fontVariant="rowHeading"
                  fontWeight={700}
                >
                  {item.provider}
                </ImageFormatter>
              )}
              data={item.products}
              loading={isLoadingPartners}
              headers={PartnerReportTableHeaders}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PartnersReportPage;
