import {
  createSelector
} from "reselect";

const getIsLoadingQuizzes = (state) => state.quizzes.loading;

export const getisLoadingQuizzesSelector = createSelector(
  getIsLoadingQuizzes,
  (loading) => loading
);
