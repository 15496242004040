import React from "react";
import {
  Box, Card, CardActions, CardContent, Typography
} from "@mui/material";
import {
  func, instanceOf, node, string
} from "prop-types";

const SimpleReportCard = ({
  body, title, icon, handleAction, height, width, onClick, footer, ...CardProps
}) => (
  <Card
    sx={{
      height, padding: 1, width, borderRadius: "25px", ...CardProps
    }}
    onClick={handleAction}
  >
    <CardContent>
      <Box
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Typography variant="smallTitle" width="65%">
          {title}
        </Typography>
        {icon}
      </Box>
      <Typography variant="body2">
        {body}
      </Typography>
    </CardContent>
    {Boolean(footer) && (
    <CardActions sx={{ marginBottom: 1 }}>
      {footer}
    </CardActions>
    )}
  </Card>
);

SimpleReportCard.propTypes = {
  body: instanceOf(Object).isRequired,
  title: string.isRequired,
  handleAction: func,
  icon: node.isRequired,
  height: string,
  width: string,
  onClick: func,
  footer: node
};

SimpleReportCard.defaultProps = {
  handleAction: null,
  height: "125px",
  width: "166px",
  onClick: null,
  footer: null
};

export default SimpleReportCard;
