import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./carousel.css";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const responsive = {
  0: { items: 1 },
  870: {
    items: 2,
    itemsFit: "contain"
  },
  1250: {
    items: 3,
    itemsFit: "contain"
  },
  1550: {
    items: 4
  }
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => setActiveIndex(activeIndex - 3);
  const slideNext = () => setActiveIndex(activeIndex + 3);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  const total = children.length;

  return (
    <>
      <AliceCarousel
        items={children}
        responsive={responsive}
        disableDotsControls
        disableButtonsControls
        activeIndex={activeIndex}
        onSlideChanged={syncActiveIndex}
        controlsStrategy="responsive"
        paddingLeft="2"
      />
      <Box
        className="b-refs-buttons"
        sx={{
          width: "100%", display: "flex", justifyContent: "center", alignItems: "center"
        }}
      >
        <IconButton onClick={slidePrev} disabled={activeIndex === 0}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography variant="newBody2">
          Total:
          {" "}
          {total}
        </Typography>
        <IconButton onClick={slideNext} disabled={activeIndex >= total - 3}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </>
  );
};
export default Carousel;
