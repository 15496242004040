import {
  createSelector
} from "reselect";

const getAgeGenderStats = (state) => state.customerInsights.ageGenderStats;

const getNationalitiesStats = (state) => state.customerInsights.nationalitiesStats;

const getEmploymentStatusStats = (state) => state.customerInsights.employmentStatusStats;

const getSalaryStats = (state) => state.customerInsights.salaryStats;

const getUsersByBankStats = (state) => state.customerInsights.usersByBank;

const getIsLoadingDemographics = (state) => state.customerInsights.loadingDemographics;

const getIsLoadingEmployment = (state) => state.customerInsights.loadingEmployment;

const getIsLoadingUsers = (state) => state.customerInsights.loadingUsers;

export const getAgeGenderStatsSelector = createSelector(
  getAgeGenderStats,
  (ageGenderStats) => ageGenderStats
);

export const getNationalitiesStatsSelector = createSelector(
  getNationalitiesStats,
  (nationalitiesStats) => nationalitiesStats
);

export const getEmploymentStatusStatsSelector = createSelector(
  getEmploymentStatusStats,
  (employmentStatusStats) => employmentStatusStats
);

export const getSalaryStatsSelector = createSelector(
  getSalaryStats,
  (salaryStats) => salaryStats
);

export const getUsersByBankStatsSelector = createSelector(
  getUsersByBankStats,
  (usersByBank) => usersByBank
);

export const getIsLoadingDemograohicsSelector = createSelector(
  getIsLoadingDemographics,
  (loadingDemographics) => loadingDemographics
);

export const getIsLoadingEmploymentSelector = createSelector(
  getIsLoadingEmployment,
  (loadingEmployment) => loadingEmployment
);

export const getIsLoadingApplyingUsersSelector = createSelector(
  getIsLoadingUsers,
  (loadingUsers) => loadingUsers
);
