import React from "react";
import { string } from "prop-types";
import { Typography } from "@mui/material";

const DateToStringFormatter = ({ children }) => {
  const formatDate = (dateObject) => {
    const year = dateObject.getFullYear();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObject
    );

    const day = dateObject.getDate();
    return `${day} ${month} ${year}`;
  };
  return (
    <Typography color="#9B9B9B" variant="newBody1">{formatDate(new Date(children))}</Typography>
  );
};

DateToStringFormatter.propTypes = {
  children: string.isRequired
};
export default DateToStringFormatter;
