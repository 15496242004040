import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as Download } from "assets/icons/download.svg";
import { useDispatch, useSelector } from "react-redux";

// HEADERS
import { ProductTableHeaders } from "config/tableHeaders/ProductTableHeaders";

// COMPONENTS
import SortMenu from "components/SortMenu/SortMenu";
import Searchbar from "components/Searchbar/Searchbar";
import CustomTable from "components/Table/CustomTable";
import PageLoading from "components/PageLoading";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";
import PartnersMenuItemWithChildren from "components/PartnersMenuWithChildren/PartnersMenuWithChildren";
import { Permissions } from "components/Permissions";

// ACTIONS
import { fetchCategorySummary } from "store/actions/products";
import { downloadExternalProductsReport, loadExternalProducts } from "store/actions/reports";

// SELECTORS
import { getDownloadedReportSelector, getExternalProductsSelector, getisLoadingExternalReportsSelector } from "store/selectors/reports";

import { externalPartnersSortData } from "utils/staticValues";
import { yymmdd } from "utils/functions";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserTypeSelector } from "store/selectors/user";
import styles from "../admin.module.scss";

const ProductStatisticsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const externalProducts = useSelector(getExternalProductsSelector);
  const downloadReport = useSelector(getDownloadedReportSelector);
  const isLoading = useSelector(getisLoadingExternalReportsSelector);
  const cmsUserType = useSelector(getUserTypeSelector);
  const { productCategory } = useSelector((state) => state.products);

  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    filterBy: "", // category, views, clicks, rating
    searchString: "",
    sortBy: "name",
    sortDir: "asc",
    providerId: id,
    page: 1

  });
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (!externalProducts.length) {
      dispatch(loadExternalProducts(filters));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCategorySummary({ id: "summary" }));
    dispatch(downloadExternalProductsReport({ download: true, providerId: id }));
  }, []);

  const handleCheckboxChange = (sortItem) => {
    setActivePage(1);
    const updatedFilters = {
      ...filters,
      ...sortItem,
      page: 1
    };
    setFilters(updatedFilters);
    dispatch(loadExternalProducts(updatedFilters));
  };

  const handlePrevArrowClick = () => {
    setActivePage(activePage - 1);
    const updatedFilters = {
      ...filters,
      page: activePage - 1
    };
    dispatch(loadExternalProducts(updatedFilters));
  };

  const handleNextArrowClick = () => {
    setActivePage(activePage + 1);
    const updatedFilters = {
      ...filters,
      page: activePage + 1
    };
    dispatch(loadExternalProducts(updatedFilters));
  };

  const handleSearchChange = (val) => {
    setActivePage(1);
    const updatedFilters = {
      ...filters,
      page: 1,
      searchString: val
    };
    setFilters(updatedFilters);
    dispatch(loadExternalProducts(updatedFilters));
  };

  const handleFilterChange = (filter, selKeys) => {
    const updatedFilters = {
      sortBy: filters.sortBy,
      sortDir: filters.sortDir,
      searchString: filters.searchString,
      providerId: filters.providerId,
      filterBy: [...selKeys].toString(),
      ...(filter?.filterCategory && { filterCategory: filter.filterCategory }),
      ...(filter?.filterRating && { filterRating: filter.filterRating }),
      page: 1
    };

    setFilters(updatedFilters);
    dispatch(loadExternalProducts(updatedFilters));
  };

  const handleRouteNavigation = () => {
    if (cmsUserType === "EXTERNAL") {
      return navigate(`/${id}/products/add`);
    }
    return navigate("/products/0");
  };

  const today = yymmdd(new Date(), true);

  if (!productCategory.length) return <PageLoading loading />;

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
        mb={3}
      >
        <Typography variant="newTitle">{t("statistics.externalProductsHeader")}</Typography>
        <Box sx={{
          display: "flex", alignItems: "center", justifyContent: "end"
        }}
        >
          <Permissions permission="write:product">
            <Button
              variant="newButton"
              sx={{
                backgroundColor: "#F2F2F2", fontSize: "12px", height: "35px !important", marginLeft: 1
              }}
              onClick={() => handleRouteNavigation()}
            >
              {t("admin.addProduct")}
            </Button>
          </Permissions>
        </Box>
      </Box>
      <Box
        mb={5}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Box>
          <Typography variant="newSubtitle">{t("statistics.externalProductsTxt")}</Typography>
        </Box>
        <Box sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}
        >
          <Searchbar onChange={handleSearchChange} />
          <Box mx={1}>
            <PartnersMenuItemWithChildren
              selectedFilters={filters}
              onChangeFilter={handleFilterChange}
              hidePartners
            />
          </Box>
          <SortMenu
            initialDir="asc"
            initialChecked="name"
            data={externalPartnersSortData}
            onChange={handleCheckboxChange}
            mr={2}
          />
          <ButtonWithModal
            title="Download report"
            isIcon
            confirmLabel="Download"
            handleOpen={() => {
              dispatch(downloadExternalProductsReport({
                download: true,
                ...filters
              }));
            }}
            download={{ name: `products_report_ ${today}`, file: downloadReport }}
            body={<Typography variant="body2">{`Are you sure you want to download products_report_${today}.csv report?`}</Typography>}
            disabled={!downloadReport.length}
            size="small"
            sx={{ backgroundColor: "#F2F2F2", marginX: 1 }}
          >
            <Download style={{ height: 20, width: 25 }} />
          </ButtonWithModal>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <CustomTable
          width="100%"
          headers={ProductTableHeaders}
          data={externalProducts.records}
          loading={isLoading}
          pagination={{
            activePage,
            totalPages: externalProducts.totalPages,
            next: handleNextArrowClick,
            prev: handlePrevArrowClick,
            totalRecords: externalProducts.totalRecords

          }}
        />
      </Box>
    </Box>
  );
};

export default ProductStatisticsPage;
