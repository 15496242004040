import {
  createSelector
} from "reselect";

const getGuidebooks = (state) => state.resources.resources;

const getIsLoading = (state) => state.resources.loading;

export const getGuidebooksSelector = createSelector(
  getGuidebooks,
  (guidebooks) => guidebooks
);

export const getisLoadingGuidebooksSelector = createSelector(
  getIsLoading,
  (loading) => loading
);
