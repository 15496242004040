import { Chip } from "@mui/material";
import React from "react";
import palette from "theme/palette";

const EmploymentStatusFormattter = ({ children }) => {
  const handleEmploymentStatusLabel = () => {
    const status = children;
    switch (status) {
      case "employed":
        return "Employed";
      case "selfEmployed":
        return "Self Employed";
      case "nonEmployed":
        return "Unemployed";
      default:
        return "Unavailable";
    }
  };

  const handleEmploymentStatusColor = () => {
    const status = children;
    switch (status) {
      case "employed":
        return palette.variables.lightGreen;
      case "selfEmployed":
        return palette.variables.lightPink;
      case "unemployed":
        return palette.variables.orange;
      default:
        return palette.variables.greyLight;
    }
  };

  return (
    <Chip
      size="small"
      label={handleEmploymentStatusLabel()}
      color="success"
      sx={{
        color: "white", width: "100%", fontWeight: 500, fontSize: "11px", lineHeight: "14px", backgroundColor: handleEmploymentStatusColor()
      }}
    />
  );
};

export default EmploymentStatusFormattter;
