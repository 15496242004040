import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Collapse, ListItemButton, ListItemText, Typography
} from "@mui/material";
import {
  instanceOf, number
} from "prop-types";
import palette from "theme/palette";

const SidebarItemCollapsed = ({
  sidebarItem, dropdownItems, selected, providerId
}) => {
  const [open, setOpen] = useState(false);
  const handleSelected = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        sx={{
          padding: 5,
          maxHeight: 70,
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "12px",
          "&: hover": {
            color: palette.variables.lightGreen
          },
          color: selected === sidebarItem.id
            ? palette.variables.lightGreen : palette.variables.darkPurple

        }}
        onClick={handleSelected}
      >
        <ListItemText
          disableTypography
          primary={(
            <Typography sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "12px"
            }}
            >
              {sidebarItem.name}
            </Typography>
        )}
        />
        <KeyboardArrowDown />
      </ListItemButton>

      <Collapse in={open} timeout="auto">
        {dropdownItems.map((item) => (
          <ListItemButton
            key={item.id}
            component={NavLink}
            to={providerId ? `/${providerId}${item.to}` : item.to}
          >
            <ListItemText
              disableTypography
              primary={(
                <Typography
                  sx={{
                    padding: 3, fontWeight: 600, fontSize: "14px", alignItems: "center", display: "flex", marginLeft: 1
                  }}
                  lineHeight="12px"
                  color={selected === item.id
                    ? palette.variables.lightGreen : palette.variables.darkPurple}
                >
                  {item.name}
                </Typography>
         )}
            />
          </ListItemButton>
        ))}
      </Collapse>
    </>
  );
};

SidebarItemCollapsed.propTypes = {
  selected: number,
  sidebarItem: instanceOf(Object).isRequired,
  dropdownItems: instanceOf(Array).isRequired
};

SidebarItemCollapsed.defaultProps = {
  selected: 1
};

export default SidebarItemCollapsed;
