import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box, Drawer, ListItemButton, List
} from "@mui/material";
import {
  bool, func, instanceOf, string
} from "prop-types";

import { ReactComponent as Logo } from "assets/images/illustrations/logo-default.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CaretLeftIcon } from "assets/icons/sidebar-left-icon.svg";

import palette from "theme/palette";
import { useDispatch, useSelector } from "react-redux";
import { userSignOut } from "store/actions/user";
import { useTranslation } from "react-i18next";
import { getUserTypeSelector } from "store/selectors/user";
import { renderSelecteddSidebarItem } from "utils/functions";
import BaseModel from "./modal/BaseModal";
import SidebarItemCollapsed from "./SidebarItemCollapsed/SidebarItemCollapsed";

const Sidebar = ({
  items, open, setOpen, providerId
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBarItem, setSelectedBarItem] = useState(1);
  const cmsUserType = useSelector(getUserTypeSelector);

  useEffect(() => {
    setSelectedBarItem(renderSelecteddSidebarItem(cmsUserType, pathname));
  }, [pathname]);

  const theme = useTheme();
  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box"
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box sx={{
        display: "flex", alignItems: "center", width: "100%", marginTop: 2
      }}
      >
        <ListItemButton
          component={NavLink}
          to="/dashboard"
          sx={{
            maxHeight: 70, wdth: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer !important", marginTop: 1
          }}
        >
          <Logo />
        </ListItemButton>
        <Box sx={{ width: "18%", cursor: "pointer" }}>
          {theme.direction === "ltr"
            ? (
              <CaretLeftIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              />
            )
            : (
              <ChevronRightIcon onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
              />
            ) }
        </Box>

      </Box>
      <List disablePadding sx={{ marginTop: 3, marginLeft: 2 }}>
        {items?.map((item) => (
          item.items ? (
            <SidebarItemCollapsed
              key={item.id}
              dropdownItems={item.items}
              sidebarItem={item}
              selected={selectedBarItem}
              providerId={providerId}
            />
          ) : (
            <ListItemButton
              key={item.id}
              component={NavLink}
              to={providerId ? `/${providerId}${item.to}` : item.to}
              sx={{
                padding: 5,
                maxHeight: 70,
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "12px",
                "&: hover": {
                  color: palette.variables.lightGreen
                },
                color: selectedBarItem === item.id
                  ? palette.variables.lightGreen : palette.variables.darkPurple

              }}
            >
              {t(`sidebar.${item.name.toLowerCase()}`)}
            </ListItemButton>
          )))}
        <ListItemButton
          onClick={() => {
            setModalOpen(true);
          }}
          sx={{
            padding: 5, fontWeight: 600, fontSize: "14px", maxHeight: 70
          }}
        >
          {t("sidebar.logout")}
        </ListItemButton>
      </List>
      <BaseModel
        text={t("logoutConfirmation")}
        heading="Logout"
        handleClose={() => setModalOpen(false)}
        handleSuccess={() => {
          dispatch(userSignOut());
          setModalOpen(false);
        }}
        open={modalOpen}
      />
    </Drawer>
  );
};

Sidebar.propTypes = {
  items: instanceOf(Array).isRequired,
  open: bool.isRequired,
  setOpen: func.isRequired,
  providerId: string
};

Sidebar.defaultProps = {
  providerId: null
};

export default Sidebar;
