import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "store/actions/permissions";
import { currentAuthenticatedUser, getUserById } from "store/actions/user";
import { setAuthUser } from "store/reducers/user";
import { getIsLoadingCMSUserSelector, getUserByIdSelector } from "store/selectors/user";
import { internalRoutes as InternalRoutes, externalRoutes as ExternalRoutes } from "routes/routes";

const AuthenticatedLayout = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const cmsUser = useSelector(getUserByIdSelector);
  const userType = cmsUser?.role?.split("_")[0];
  const isLoadingCMSUser = useSelector(getIsLoadingCMSUserSelector);

  const auth = async () => {
    dispatch(getPermissions());
    const currentUser = await currentAuthenticatedUser();
    dispatch(setAuthUser({ authUser: currentUser }));
    dispatch(getUserById({ id: currentUser?.signInUserSession?.accessToken?.payload?.username }));
    setLoading(false);
  };

  useEffect(() => {
    auth();
  }, []);

  if (loading || isLoadingCMSUser) return <span>Loading...</span>;

  return (
    cmsUser && userType === "EXTERNAL" ? <ExternalRoutes /> : <InternalRoutes />
  );
};

export default AuthenticatedLayout;
