import React from "react";
import Button from "@mui/material/Button";
import LoopIcon from "@mui/icons-material/Loop";
import "./baseButton.scss";
import { bool, string } from "prop-types";

const BaseButton = ({ customColor, loading, ...props }) => (
  <Button
    style={{
      backgroundColor: customColor
    }}
    disabled={loading}
    {...props}
  >
    {loading ? <LoopIcon className="loop-icon" color="common.coolWhite" /> : props.element}
  </Button>
);

BaseButton.propTypes = {
  customColor: string.isRequired,
  loading: bool
};

BaseButton.defaultProps = {
  loading: false
};

export default BaseButton;
