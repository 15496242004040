import {
  createSelector
} from "reselect";

const getUsersList = (state) => state.user.users;

const getUserById = (state) => state.user.user;

const getCreatedByUser = (state) => state.user.createdByUser;

const getAuthUser = (state) => state.user.authUser;

const getLinkedUsersByPartner = (state) => state.user.linkedUsersByPartner;

const getIsLoadingList = (state) => state.user.listLoading;

const getIsLoadingCMSUser = (state) => state.user.loading;

const getIsLoadingCreatedByUser = (state) => state.user.loadingCreatedBy;

const getIsLoadingLinkedUsers = (state) => state.user.loadingLinkedUsers;

export const getUsersListSelector = createSelector(
  getUsersList,
  (users) => users
);

export const getUserByIdSelector = createSelector(
  getUserById,
  (user) => user
);

export const getCreatedByUserSelector = createSelector(
  getCreatedByUser,
  (user) => user
);

export const getUserTypeSelector = createSelector(
  getUserById,
  (user) => user?.role?.split("_")[0]
);

export const getUserRoleSelector = createSelector(
  getUserById,
  (user) => user?.role?.split("_")[1]
);

export const getAuthUserSelector = createSelector(
  getAuthUser,
  (authUser) => authUser
);

export const getLinkedUsersByPartnerSelector = createSelector(
  getLinkedUsersByPartner,
  (linkedUsersByPartner) => linkedUsersByPartner
);

export const getIsLoadingListSelector = createSelector(
  getIsLoadingList,
  (listLoading) => listLoading
);

export const getIsLoadingCMSUserSelector = createSelector(
  getIsLoadingCMSUser,
  (loading) => loading
);

export const getIsLoadingCreatedByUserSelector = createSelector(
  getIsLoadingCreatedByUser,
  (loadingCreatedBy) => loadingCreatedBy
);

export const getIsLoadingLinkedUsersSelector = createSelector(
  getIsLoadingLinkedUsers,
  (loadingLinkedusers) => loadingLinkedusers
);
