import { createAsyncThunk } from "@reduxjs/toolkit";
import { failureToast } from "utils";
import ReportsService from "services/ReportsService";

export const loadClickRatingReport = createAsyncThunk("postReportById", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postReportById(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getReportOverview = createAsyncThunk("getReportOverview", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.getReportOverview(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadExternalProducts = createAsyncThunk("loadProductsTable", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postExternalProductsReport({ params });

    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    failureToast(`Error searching for products  ${err.msg}`);

    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const downloadExternalProductsReport = createAsyncThunk("downloadExternalProductsReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postDownloadExternalProductsReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

// internal

export const loadRedemptionsReport = createAsyncThunk("postRedemptionsReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postRedemptionsReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const downloadRedemptionsReport = createAsyncThunk("downloadRedemptionsReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postDownloadRedemptionsReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadPartnersReport = createAsyncThunk("postPartnersReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postPartnersReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const downloadPartnersReport = createAsyncThunk("downloadPartnersReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postDownloadPartnersReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadUsersBehaviourReport = createAsyncThunk("postUsersBehaviourReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postUsersBehaviourReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const downloadBehaviourReport = createAsyncThunk("downloadBehaviourReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postDownloadBehaviourReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
