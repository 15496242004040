import React from "react";
import { Typography } from "@mui/material";
import { bool } from "prop-types";

const UserHealthVerifiedFormatter = ({ children }) => {
  const isConnected = children;
  return (
    isConnected
      ? <Typography variant="smallTitle" color="#68B6B5" data-testid="connected-txt">Connected  &#x2713;</Typography>
      : <Typography variant="smallTitle">Not connected</Typography>
  );
};

UserHealthVerifiedFormatter.propTypes = {
  children: bool.isRequired
};

export default UserHealthVerifiedFormatter;
