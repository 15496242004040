import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  ToggleButton
} from "@mui/material";
import {
  useLocation, useParams, useSearchParams
} from "react-router-dom";
import {
  getExpertById
} from "store/actions/experts";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { fetchCategorySummary } from "store/actions/products";
import { useTranslation } from "react-i18next";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import palette from "theme/palette";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormUploadImage from "components/FormUploadImage/FormUploadImage";
import { getGuidebookTopicsSelector } from "store/selectors/topics";
import { loadGuidebookTopics } from "store/actions/topics";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";

const ExpertPublishedViewPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isPublished = pathname.split("/").at(-1).includes("published");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    expert,
    error
  } = useSelector((state) => state.experts);
  const lang = useSelector(selectLanguage);

  const { productCategory } = useSelector((state) => state.products);
  const guidebookTopics = useSelector(getGuidebookTopicsSelector);

  useEffect(async () => await dispatch(setLanguage("en")), []);
  useEffect(async () => {
    if (expert) {
      setLoading(true);
      if (!!searchParams.get("lang") && searchParams.get("lang") === "ar") {
        await dispatch(setLanguage("ar"));
      }
      await dispatch(getExpertById({ id: isPublished ? `${id}/published` : id }));
      await dispatch(fetchCategorySummary({ id: "published/summary" }));
      await dispatch(loadGuidebookTopics());
      setLoading(false);
    }
  }, [lang]);

  useEffect(async () => {
    await dispatch(getExpertById({ id: isPublished ? `${id}/published` : id }));

    if (!!searchParams.get("lang") && searchParams.get("lang") === "ar") {
      await dispatch(setLanguage("ar"));
    }
    setLoading(false);
  }, []);

  const getAnswer = (answer) => {
    const contentBlock = htmlToDraft(`${answer}`);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
  };

  if (!expert || loading) {
    return <>Loading...</>;
  }
  return (
    <Box mt={2}>
      <Formik
        initialValues={{
          name: lang === "ar" && error ? "" : expert.name,
          aboutText: lang === "ar" && error ? "" : getAnswer(expert.aboutText),
          iconUrl: expert.iconUrl,
          categoryIds: expert.categoryIds || [],
          topics: expert.topics || [],
          publish: expert.publish || false
        }}
      >
        {({ values }) => (
          <form>
            {expert?.metaInfo && (
              <Typography
                sx={{ marginBottom: "16px" }}
                component="h2"
                variant="h3"
                color={palette.variables.darkPurple}
              >
                {isPublished
                  ? `Published Version ${expert?.metaInfo?.publishedVersion}`
                  : `Current Version ${expert?.metaInfo?.version}`}
              </Typography>
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between"
              }}
            >
              <FormUploadImage
                item={values}
                disableEdit
                lang={lang}
              />

              {!(searchParams.get("lang") && searchParams.get("lang") === "ar") && (
              <Box display="flex">
                <ToggleButton
                  sx={{
                    display: "flex",
                    gap: "8px",
                    overflow: "auto",
                    marginRight: "10px",
                    flexWrap: "nowrap",
                    width: "max-content"
                  }}
                  selected={lang !== "en"}
                  onChange={() => {
                    dispatch(setLanguage(lang === "en" ? "ar" : "en"));
                  }}
                  value="check"
                >
                  {lang !== "en" ? "English" : "Arabic"}
                </ToggleButton>
              </Box>
              )}
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" component="h3">
                {t("fields.about")}
              </Typography>
              <RichTextEditor
                disabled
                value={values.aboutText}
              />
              <Box my={2}>
                <Typography variant="subtitle1" component="h3" mb={2}>
                  {t("fields.areaOfExpertise")}
                </Typography>
                <Box mb={2}>
                  <Typography variant="smallTitle" component="h3" mb={2}>
                    {t("admin.productCategory")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={productCategory}
                      exclusive
                      disabled
                      selected={values.categoryIds}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="smallTitle" component="h3" mb={2}>
                    {t("fields.topics")}
                  </Typography>
                  <Box mt={2}>
                    <MultipleSelectionToggleBtn
                      data={guidebookTopics}
                      disabled
                      selected={values.topics}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ExpertPublishedViewPage;
