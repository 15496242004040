/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Typography
} from "@mui/material";
import { func, instanceOf, string } from "prop-types";

const FilterMenuItem = ({
  children, filterItem, onChangeFilter, selectedKeys, selectedFilters
}) => {
  const [checked, setChecked] = useState(selectedFilters[filterItem.key] || false);

  const handleCheckItem = (event) => {
    const updatedFilters = { ...selectedFilters };
    const label = filterItem.label.toLowerCase().replace(" ", "");
    let updatedKeys = [...new Set(selectedKeys)];
    setChecked(event.target.checked);

    // delete from object/array if item is not checked
    if (!event.target.checked && filterItem.key in updatedFilters) {
      delete updatedFilters[filterItem.key];

      const value = label;
      updatedKeys = updatedKeys.filter((it) => it !== value);
    } else {
      updatedFilters[filterItem.key] = event.target.checked;
      updatedKeys.push(label);
    }
    onChangeFilter({
      ...updatedFilters
    }, [...updatedKeys]);
  };

  return (
    <Box sx={{
      display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: 2
    }}
    >
      <Checkbox checked={checked} onChange={handleCheckItem} data-testId="checkbox" />
      <Typography variant="body2" fontSize="12px">{children}</Typography>
    </Box>
  );
};

FilterMenuItem.propTypes = {
  children: string.isRequired,
  filterItem: instanceOf(Object).isRequired,
  onChangeFilter: func.isRequired,
  selectedFilters: instanceOf(Object).isRequired
};

export default FilterMenuItem;
