import React from "react";
import { mapNumbersToWeekDays } from "utils/functions";
import palette from "theme/palette";
import StatisticsBarChart from "./StatisticsBarChart";

const HealthDataBarChart = ({ healthData, ...propTypes }) => {
  const formatData = healthData?.map((item) => ({
    ...item,
    stepsDate: mapNumbersToWeekDays(new Date(item.stepsDate).getDay()),
    stepsRounded: Math.round(Math.abs(item.stepsCount / 1000))
  }));

  return (
    <StatisticsBarChart
      data={formatData}
      activeBarColor={palette.variables.lightGreen}
      tooltipValue="stepsCount"
      {...propTypes}
    />
  );
};

export default HealthDataBarChart;
