import { bool, func, instanceOf } from "prop-types";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DefaultDraftBlockRenderMap, EditorState } from "draft-js";
import {
  toggleCustomInlineStyle,
  getSelectedBlocksType
} from "draftjs-utils";
import { Map } from "immutable";
import { Editor } from "react-draft-wysiwyg";
import { resources } from "localization";
import "./textEditor.css";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";
import styles from "../../../pages/adminPages/admin.module.scss";

const blockRenderMap = Map({
  "header-four": {
    element: "section"
  },
  "header-five": {
    element: "section"
  },
  "header-six": {
    element: "section"
  }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const RichTextEditor = ({
  value, disabled, onChange, error
}) => {
  let updatedValue = value;

  // getting stored language
  const lan = localStorage.getItem("language");

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();

    let style = null;
    if (type === "header-four") {
      style = "title";
    } else if (type === "header-five") {
      style = "subtitle";
    } else if (type === "header-six" || type === "unstyled" || type === "ordered-list-item" || type === "unordered-list-item") {
      style = "customBody";
    }
    return style;
  };

  const onChangeEditor = (editorState) => {
    const blockType = getSelectedBlocksType(editorState);

    if (blockType === "header-four") {
      updatedValue = toggleCustomInlineStyle(editorState, "fontSize", 16);
    } else {
      updatedValue = toggleCustomInlineStyle(editorState, "fontSize", 14);
    }
    onChange(updatedValue);
  };

  return (
    <>
      <Editor
        localization={{ locale: "en", translations: resources.en.textEditor }}
        blockRenderMap={extendedBlockRenderMap}
        handlePastedText={() => false}
        toolbar={{
          options: [
            "blockType",
            "inline",
            "list",
            "link",
            "emoji",
            "image",
            "history"
          ],
          inline: {
            options: ["superscript", "subscript"]

          },
          list: {
            className: styles.listEditorStyle,
            options: ["unordered", "ordered"]

          },
          blockType: {
            inDrowdown: true,
            options: ["H4", "H5", "H6"]
          }
        }}
        editorState={updatedValue}
        blockStyleFn={myBlockStyleFn}
        toolbarClassName={[styles.toolbarClassName, disabled && styles.disabled]}
        editorClassName={[styles.editorClassName, disabled && styles.disabled]}
        editorStyle={{
          lineHeight: "22px",
          letterSpacing: "0%",
          fontFamily: lan === "en" ? "Montserrat" : "FF Shamel Family",
          fontSize: 14,
          fontStyle: "normal !important"
        }}
        textAlignment={lan === "en" ? "left" : "right"}
        onEditorStateChange={onChangeEditor}
        readOnly={!!disabled}
        spellCheck
      />
      {Boolean(error) && <FormRequiredText>This field is required</FormRequiredText>}
    </>
  );
};
RichTextEditor.propTypes = {
  value: instanceOf(Object),
  disabled: bool,
  onChange: func
};

RichTextEditor.defaultProps = {
  disabled: false,
  value: EditorState.createEmpty(),
  onChange: null
};
export default RichTextEditor;
