import DateToStringFormatter from "components/DateToStringFormatter/DateToStringFormatter";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import React from "react";

const UserRewardsTableHeaders = [
  {
    key: "name",
    label: "Reward",
    Component: (props) => (
      <ImageFormatter
        imgKey="imageUrl"
        fontWeight={500}
        fontVariant="newBody2"
        {...props}
      />
    ),
    textAlign: "start"
  },
  {
    key: "redeemedAt",
    label: "Date Redeemed",
    textAlign: "center",
    Component: DateToStringFormatter
  },
  {
    key: "points",
    label: "Points Used"
  },
  {
    key: "rewardCode",
    label: "Reward Code"
  }
];

export default UserRewardsTableHeaders;
