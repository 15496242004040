import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box, Typography
} from "@mui/material";
import { ReactComponent as Download } from "assets/icons/download.svg";

// HEADERS
import { UsersBehaviourTableHeaders } from "config/tableHeaders/InternalUsers/UsersBehaviourTableHeaders";

// COMPONENTS
import BehaviourMenuItemWithChildren from "components/BehaviourMenuItemWithChildren/BehaviourMenuItemWithChildren";
import Searchbar from "components/Searchbar/Searchbar";
import CustomTable from "components/Table/CustomTable";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import ButtonWithModal from "components/ButtonWithModal/ButtonWithModal";

// ACTION
import { downloadBehaviourReport, loadUsersBehaviourReport } from "store/actions/reports";
import { fetchCategorySummary } from "store/actions/products";

// SELECTORS
import {
  getDownloadedReportSelector, getisLoadingReportsSelector, getUsersBehaviourReportSelector
} from "store/selectors/reports";

import { yymmdd } from "utils/functions";
import styles from "../../admin.module.scss";

const UsersBehaviourReportPage = () => {
  const dispatch = useDispatch();
  const { productCategory } = useSelector((state) => state.products);
  const behaviourReport = useSelector(getUsersBehaviourReportSelector);
  const downloadReport = useSelector(getDownloadedReportSelector);
  const isLoadingReport = useSelector(getisLoadingReportsSelector);

  const [filters, setFilters] = useState({
    searchString: "",
    filterBy: ""
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!behaviourReport.length) {
      dispatch(loadUsersBehaviourReport(filters));
    }
    dispatch(downloadBehaviourReport({ download: true }));
  }, []);

  useEffect(() => {
    if (!productCategory?.length) {
      dispatch(fetchCategorySummary({ id: "published/summary" }));
    }
  }, []);

  const handleSearchChange = (val) => {
    const updatedFilters = {
      ...filters,
      searchString: val
    };
    setFilters(updatedFilters);
    dispatch(loadUsersBehaviourReport(updatedFilters));
  };

  const handleFilterChange = (filter, selKeys) => {
    const updatedFilters = {
      searchString: filters.searchString,
      filterBy: [...selKeys].toString(),
      ...(filter?.filterCategory && { filterCategory: filter.filterCategory })
    };

    setFilters(updatedFilters);
    dispatch(loadUsersBehaviourReport(updatedFilters));
  };

  const today = yymmdd(new Date(), true);

  return (
    <Box className={styles.container} padding={2} mt={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }} mb={3}>
        <Typography variant="newTitle">{t("statistics.behaviourReport")}</Typography>
      </Box>
      <Box
        mb={5}
        sx={{
          display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
        }}
      >
        <Box>
          <Typography variant="newSubtitle">{t("statistics.behaviourTxt")}</Typography>
        </Box>
        <Box sx={{
          display: "flex", justifyContent: "flex-end", alignItems: "center"
        }}
        >
          <Searchbar onChange={handleSearchChange} />
          <Box mx={1}>
            <BehaviourMenuItemWithChildren
              selectedFilters={filters}
              onChangeFilter={handleFilterChange}
            />
          </Box>
          <ButtonWithModal
            title="Download report"
            isIcon
            confirmLabel="Download"
            download={{ name: `userbehaviour_report_${today}`, file: downloadReport }}
            handleOpen={() => {
              dispatch(downloadBehaviourReport(
                {
                  download: true,
                  ...filters
                }
              ));
            }}
            body={<Typography variant="body2">{`Are you sure you want to download userbehaviour_report_${today}.csv report?`}</Typography>}
            disabled={!downloadReport.length}
            size="small"
            sx={{ backgroundColor: "#F2F2F2" }}
          >

            <Download style={{ height: 20, width: 25 }} />
          </ButtonWithModal>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%"
        }}
      >
        {behaviourReport?.map((item) => (
          <Box mb={7} key={item.categoryId}>
            <CustomTable
              width="100%"
              toolbar={(
                <ImageFormatter
                  imgSrc={item.imageUrl}
                  imgWidth="40px"
                  fontVariant="rowHeading"
                  fontWeight={700}
                >
                  {item.categoryName}
                </ImageFormatter>
              )}
              data={item.list}
              loading={isLoadingReport}
              headers={UsersBehaviourTableHeaders}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UsersBehaviourReportPage;
