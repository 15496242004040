import { createSlice } from "@reduxjs/toolkit";
import {
  createGuidebookTopic,
  deleteGuidebookTopic,
  editGuidebookTopic,
  loadGuidebookTopicById,
  loadGuidebookTopics,
  loadTopicsByExpertId
} from "store/actions/topics";

const initialState = {
  guidebookTopics: [],
  topic: {},
  loadingAll: false,
  loadingTopic: false,
  expertTopics: []
};

const guidebookSlice = createSlice({
  name: "guidebook",
  initialState,
  extraReducers: {
    [loadGuidebookTopics.pending]: (state) => {
      state.loadingAll = true;
      state.error = null;
    },
    [loadGuidebookTopics.fulfilled]: (state, { payload }) => {
      state.guidebookTopics = payload;
      state.loadingAll = false;
    },
    [loadGuidebookTopics.rejected]: (state, { payload }) => {
      state.loadingAll = false;
      state.error = payload;
    },
    [loadGuidebookTopicById.pending]: (state) => {
      state.loadingTopic = true;
      state.error = null;
    },
    [loadGuidebookTopicById.fulfilled]: (state, { payload }) => {
      state.topic = payload;
      state.loadingTopic = false;
    },
    [loadGuidebookTopicById.rejected]: (state, { payload }) => {
      state.loadingTopic = false;
      state.error = payload;
    },
    [createGuidebookTopic.pending]: (state) => {
      state.loadingTopic = true;
      state.error = null;
    },
    [createGuidebookTopic.fulfilled]: (state, { payload }) => {
      state.updatedGuidebook = payload;
      state.loadingTopic = false;
    },
    [createGuidebookTopic.rejected]: (state, { payload }) => {
      state.loadingTopic = false;
      state.error = payload;
    },
    [editGuidebookTopic.pending]: (state) => {
      state.loadingTopic = true;
      state.error = null;
    },
    [editGuidebookTopic.fulfilled]: (state, { payload }) => {
      state.topic = payload;
      state.loadingTopic = false;
    },
    [editGuidebookTopic.rejected]: (state, { payload }) => {
      state.loadingTopic = false;
      state.error = payload;
    },
    [deleteGuidebookTopic.pending]: (state) => {
      state.loadingTopic = true;
      state.error = null;
    },
    [deleteGuidebookTopic.fulfilled]: (state) => {
      state.loadingTopic = false;
    },
    [deleteGuidebookTopic.rejected]: (state, { payload }) => {
      state.loadingTopic = false;
      state.error = payload;
    },
    [loadTopicsByExpertId.pending]: (state) => {
      state.loadingAll = true;
      state.error = null;
    },
    [loadTopicsByExpertId.fulfilled]: (state, { payload }) => {
      state.loadingAll = false;
      state.expertTopics = payload;
    },
    [loadTopicsByExpertId.rejected]: (state, { payload }) => {
      state.loadingAll = false;
      state.error = payload;
    }
  }
});

export default guidebookSlice.reducer;
