import React from "react";
import { useSelector } from "react-redux";
import ImageFormatter from "components/ImageFormatter/ImageFormatter";
import { string } from "prop-types";

const ProductCategoryFormatter = ({ children }) => {
  // console.log("productCategoryId", children);
  const categoryId = children;
  const { productCategory } = useSelector((state) => state.products);
  const selectedCategory = productCategory?.find((category) => category.id === categoryId);

  return (
    <ImageFormatter
      imgSrc={selectedCategory?.iconUrl || "https://media.istockphoto.com/id/1216567290/vector/vector-empty-transparent-background-vector-transparency-grid-seamless-pattern.jpg?s=612x612&w=0&k=20&c=SUCFfkaWSvYXEMNZINgBO3ditpXEuRR8PRU7sZ55fFc="}
      imgWidth="28px"
      flexDirection="column"
    >
      {selectedCategory?.name}
    </ImageFormatter>
  );
};

ProductCategoryFormatter.propTypes = {
  children: string.isRequired
};

export default ProductCategoryFormatter;
