import {
  createSelector
} from "reselect";

const getFaqById = (state) => state.faqs.faq;

const getIsLoadingFaqs = (state) => state.faqs.loading;

export const getFaqByIdSelector = createSelector(
  getFaqById,
  (faq) => faq
);

export const getIsLoadingFaqsSelector = createSelector(
  getIsLoadingFaqs,
  (loading) => loading
);
