/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  bool, instanceOf, number, string
} from "prop-types";
import {
  BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid, Label, ResponsiveContainer, Cell
} from "recharts";
import ChartTooltip from "./ChartTooltip";

const StatisticsBarChart = ({
  data, key1,
  key2, xKey,
  colorChanges,
  barColor, activeBarColor,
  unit, tooltipValue,
  minHeight
}) => {
  const [activeIndex, setActiveIndex] = useState();
  const emptyData = [{
    stepsDate: new Date().toLocaleDateString(),
    [key1]: 0
  }];

  const chartData = data?.length ? data : emptyData;

  const handleNoDataAvailable = () => {
    const val1 = chartData.map(
      (item) => item[key1]
    ).reduce((prev, current) => prev + current, 0);
    const val2 = chartData.map(
      (item) => item.nonnbbClickPercent
    ).reduce((prev, current) => prev + current, 0);
    if (val1 + val2 === 0) {
      return "No data available";
    }
    return "";
  };

  const checkIfDomainEmpty = () => {
    const val1 = chartData.map(
      (item) => item[key1]
    ).reduce((prev, current) => prev + current, 0);
    if (val1 === 0) return true;
    return false;
  };

  const checkIfKeyExists = () => {
    if ("day" in chartData[0]) {
      return "day";
    }
    return xKey || "date";
  };

  const handleColorChange = (data, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height={minHeight}>
      <BarChart
        data={data}
        barCategoryGap="10%"
        width={500}
        margin={{ left: -20, right: 10 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey={checkIfKeyExists()}
          fontSize="11px"
          tickMargin={10}
        >
          <Label position="top" offset={70}>{handleNoDataAvailable()}</Label>
        </XAxis>
        <YAxis
          domain={checkIfDomainEmpty() ? [0, 5] : [0, "datamax"]}
          unit={unit}
          tickSize={8}
          fontSize="11px"
          tickCount={checkIfDomainEmpty() ? 1 : 5}
        />
        <Tooltip content={(
          <ChartTooltip
            bgColor={activeBarColor}
            tooltipValue={tooltipValue}
            isSingleTooltip
          />
)}
        />
        <Bar
          dataKey={key1}
          fill={barColor}
          radius={9}
          unit={unit}
          barSize={20}
          onMouseEnter={colorChanges && (handleColorChange)}
        >
          {data?.map((entry, index) => (
            <Cell fill={index === activeIndex ? activeBarColor : barColor} key={`cell-${index}`} />
          ))}
        </Bar>
        {key2 && (<Bar dataKey={key2} fill="#DEA8DD" radius={9} unit="%" barSize={20} />)}
      </BarChart>
    </ResponsiveContainer>
  );
};

StatisticsBarChart.propTypes = {
  data: instanceOf(Array),
  key1: string.isRequired,
  key2: string,
  xKey: string,
  barColor: string,
  activeBarColor: string,
  colorChanges: bool,
  unit: string,
  minHeight: number,
  tooltipValue: string
};

StatisticsBarChart.defaultProps = {
  data: null,
  key2: null,
  xKey: null,
  minHeight: 280,
  barColor: "#C6C6C6",
  activeBarColor: null,
  colorChanges: false,
  unit: "",
  tooltipValue: ""
};

export default StatisticsBarChart;
