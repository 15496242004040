import { API } from "aws-amplify";
import { getErrorStringPart } from "utils";
import RequestService from "./RequestService";

const apiName = "MyAPIGatewayAPI";
const pathname = "/cms/linked-users";

class LinkedUsersService extends RequestService {
  constructor(apiName, path, api) {
    super(apiName, path, api);
  }

  async getLinkedUsers() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getLinkedUsersByPartner(params) {
    try {
      const { partnerId } = params;
      return await this._httpService.get(this._apiName, `${this._path}/${partnerId}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new LinkedUsersService(apiName, pathname, API);
