import React from "react";
import CustomTable from "components/Table/CustomTable";
import { UserPointsListHeaders } from "config/tableHeaders/InternalUsers/UserPointsListHeaders";
import { string } from "prop-types";

const UserPointsTable = ({ data, ...TableProps }) => (
  <CustomTable
    width="95%"
    minHeight="388px"
    maxHeight="388px"
    data={data}
    loading={false}
    headers={UserPointsListHeaders}
    hideHeaders
    {...TableProps}
  />
);

UserPointsTable.propTypes = {
  data: string.isRequired
};

export default UserPointsTable;
