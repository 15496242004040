import { API } from "aws-amplify";
import { getErrorStringPart } from "utils";

import RequestService from "./RequestService";

const partnerApiName = "MyAPIGatewayAPI";
const pathname = "/cms/reward/published";

class PartnersByRewardService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async getPartnersByRewwards() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      return {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new PartnersByRewardService(partnerApiName, pathname, API);
