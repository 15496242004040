import { createSlice } from "@reduxjs/toolkit";
import {
  createNotifications, loadNotificationsTypes
} from "store/actions/notifications";

const initialState = {
  notificationTypes: [],
  notification: {},
  loading: false
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: {
    [createNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createNotifications.fulfilled]: (state, { payload }) => {
      state.notification = payload;
      state.loading = false;
    },
    [createNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [loadNotificationsTypes.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loadNotificationsTypes.fulfilled]: (state, { payload }) => {
      state.notificationTypes = payload;
      state.loading = false;
    },
    [loadNotificationsTypes.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default notificationsSlice.reducer;
