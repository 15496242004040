/* eslint-disable no-underscore-dangle */
import { API } from "aws-amplify";
import { getErrorStringPart } from "utils";
import RequestService from "./RequestService";

const apiName = "MyAPIGatewayAPI";
const pathname = "/cms/insights";

class CustomerInsightsService extends RequestService {
  constructor(apiName, path, api) {
    super(apiName, path, api);
  }

  async getAgeGenderStats() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/age-gender`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getNationalitiesrStats() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/nationalities`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getEmploymentStatusStats() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/employment-status`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getSalaryStats() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/salary`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getApplyingUsersStats(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/users-applying?${new URLSearchParams(params)}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getApplyingUser(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/users-applying/${params.id}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new CustomerInsightsService(apiName, pathname, API);
