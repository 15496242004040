import { ToggleButton } from "@mui/material";
import {
  bool, func, instanceOf, string
} from "prop-types";
import React from "react";

const ToggleButtonCustom = ({
  text, onChange, selected, style, ...props
}) => (
  <ToggleButton
    {...props}
    sx={{
      display: "flex",
      gap: "8px",
      overflow: "auto",
      marginRight: "10px",
      flexWrap: "nowrap",
      width: "max-content",
      ...style
    }}
    selected={selected}
    onChange={onChange}
    value="check"
  >
    {text}
  </ToggleButton>
);

ToggleButtonCustom.propType = {
  text: string.isRequired,
  onChange: func.isRequired,
  selected: bool.isRequired,
  style: instanceOf(Object)
};

ToggleButtonCustom.defaulProps = {
  style: null
};

export default ToggleButtonCustom;
