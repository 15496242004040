import {
  Grid, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createQuizThemes, getQuizThemesById, updateQuizThemesById } from "store/actions/quizTheme";
import { fetchCategorySummary } from "store/actions/products";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { getQizzesTags } from "store/actions/tag";
import { setImage } from "store/actions/image";
import { successToast } from "utils";
import { selectLanguage, setLanguage } from "store/app/appSlice";
import PageLoading from "components/PageLoading";
import { setStateQuizThemeValue } from "store/reducers/quizThemes";
import palette from "theme/palette";

const QuizThemePublishedViewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isPublished = pathname.split("/").at(-1) === "published";
  const { quizTheme, loading, error } = useSelector((state) => state.quizThemes);
  const { productCategory } = useSelector((state) => state.products);
  const [quizTag, setQuizTag] = useState([]);
  const lang = useSelector(selectLanguage);

  useEffect(async () => {
    dispatch(fetchCategorySummary({ id: "summary" }));
    const res = await dispatch(getQizzesTags());
    if (res.payload) setQuizTag(res.payload.reverse());
  }, []);

  useEffect(() => async () => {
    await dispatch(setLanguage("en"));
    await dispatch(setStateQuizThemeValue({ type: "quizTheme", data: null }));
  }, []);

  useEffect(() => {
    dispatch(getQuizThemesById({ id: isPublished ? `${id}/published` : id }));
  }, [lang]);

  const initialState = {
    name: error && lang === "ar" ? "" : quizTheme?.name || "",
    iconUrl: quizTheme?.iconUrl || "",
    imageUrl: quizTheme?.imageUrl || "",
    categoryIds: quizTheme?.categoryIds || [],
    quizTagIds: quizTheme?.quizTagIds || []
  };

  const QuizThemeCreateSchema = Yup.object().shape({
    name: Yup.string().required(t("validation.required")),
    iconUrl: Yup.string().required(t("validation.required")),
    imageUrl: Yup.string().required(t("validation.required")),
    categoryIds: Yup.array().min(1, t("validation.required")),
    quizTagIds: Yup.array().min(1, t("validation.required"))
  });

  const handleSubmit = async (values) => {
    let convertedIcon;
    if (values.iconUrl !== quizTheme?.iconUrl) {
      convertedIcon = await dispatch(setImage(values.iconUrl));
    }
    let convertedImage;
    if (values.imageUrl !== quizTheme?.imageUrl) {
      convertedImage = await dispatch(setImage(values.imageUrl));
    }

    const _quizTheme = {
      id: lang === "en" ? id : `${id}/i18n_data`,
      params: {
        name: values.name,
        imageUrl: convertedImage?.payload.url || values.imageUrl,
        iconUrl: convertedIcon?.payload.url || values.iconUrl,
        categoryIds: values.categoryIds,
        quizTagIds: values.quizTagIds
      },
      cb: () => {
        successToast(`Quiz Theme has ${t + id === 0 ? "created" : "updated"}`);
        if (+id === 0) {
          navigate("/quiz-theme", { replace: true });
        } else {
          dispatch(getQuizThemesById({ id }));
        }
      }
    };
    try {
      if (+id === 0) {
        await dispatch(createQuizThemes(_quizTheme));
      } else {
        await dispatch(updateQuizThemesById(_quizTheme));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <Box>
      <Box sx={{
        display: "flex", justifyContent: "space-between", alignItems: "center", my: 2
      }}
      >
        {quizTheme?.metaInfo && (
          <Typography
            sx={{ marginBottom: "16px" }}
            component="h2"
            variant="h3"
            color={palette.variables.darkPurple}
          >
            {isPublished
              ? `Published Version ${quizTheme?.metaInfo?.publishedVersion}`
              : `Current Version ${quizTheme?.metaInfo?.version}`}
          </Typography>
        )}
        {+id !== 0 && (
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <ToggleButton
                sx={{
                  display: "flex",
                  gap: "8px",
                  overflow: "auto",
                  marginRight: "10px",
                  flexWrap: "nowrap",
                  width: "max-content"
                }}
                selected={lang !== "en"}
                onChange={() => {
                  dispatch(setLanguage(lang === "en" ? "ar" : "en"));
                }}
                value="check"
              >
                {lang !== "en" ? "English" : "Arabic"}
              </ToggleButton>
            </Box>
          </Box>
        )}
      </Box>
      <PageLoading loading={loading} />
      <Formik
        initialValues={initialState}
        validationSchema={QuizThemeCreateSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          values, errors, handleChange, handleSubmit, setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box component="div">
              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: "24px" }}
                margin="normal"
                fullWidth
                id="name"
                label={t("fields.name")}
                name="name"
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                value={values.name}
                color={!errors.name && values.name ? "success" : ""}
                disabled
              />

              <Box sx={{ display: "flex", ml: 0, alignItems: "center" }}>
                <Typography
                  sx={{ mr: 2 }}
                  variant="body2"
                  color="common.darkPurple"
                  style={{ fontWeight: 500 }}
                >
                  {t("fields.icon")}
                </Typography>
                {(+id !== 0 || typeof values.iconUrl === "object") && (
                <img
                  style={{ height: "55px", marginRight: "20px", marginLeft: "16px" }}
                  src={
                      quizTheme?.iconUrl
                      || (typeof values.iconUrl === "object" && URL.createObjectURL(values.iconUrl))
                    }
                  alt=""
                />
                )}
              </Box>

              <Box sx={{ display: "flex", ml: 0, alignItems: "center" }}>
                <Typography
                  sx={{ mr: 2 }}
                  variant="body2"
                  color="common.darkPurple"
                  style={{ fontWeight: 500 }}
                >
                  {t("fields.image")}
                </Typography>
                {(+id !== 0 || typeof values.imageUrl === "object") && (
                <img
                  style={{ height: "55px", marginRight: "20px", marginLeft: "16px" }}
                  src={
                      quizTheme?.imageUrl
                      || (typeof values.imageUrl === "object" && URL.createObjectURL(values.imageUrl))
                    }
                  alt=""
                />
                )}
              </Box>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={8} sx={{ display: "flex" }}>
                  <LocalOfferIcon />
                  <Typography
                    sx={{ ml: 2 }}
                    variant="body2"
                    color="common.darkPurple"
                    style={{ fontWeight: 500 }}
                  >
                    {t("admin.productCategory")}
                  </Typography>
                </Grid>

                <Grid item>
                  <ToggleButtonGroup
                    disabled
                    sx={{ flexWrap: "wrap", gap: "7px" }}
                    color="primary"
                    value={values.categoryIds}
                    onChange={(e, newValues) => {
                      setFieldValue("categoryIds", newValues);
                    }}
                  >
                    {productCategory?.map((cat) => (
                      <ToggleButton
                sx={{
                          display: "flex",
                          gap: "8px",
                          overflow: "auto",
                          marginBottom: "15px",
                          flexWrap: "nowrap",
                          width: "max-content"
                        }}
                selected={values.categoryIds.includes(cat.id)}
                key={cat.id}
                value={cat.id}
              >
                {cat.name}
              </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              {errors.categoryIds && (
              <Typography variant="subtitle1" color="common.error">
                {errors.categoryIds}
              </Typography>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={8} sx={{ display: "flex" }}>
                  <LocalOfferIcon />
                  <Typography
                    sx={{ ml: 2 }}
                    variant="body2"
                    color="common.darkPurple"
                    style={{ fontWeight: 500 }}
                  >
                    {t("admin.quizTags")}
                  </Typography>
                </Grid>

                <Grid item>
                  <ToggleButtonGroup
                    disabled
                    sx={{ flexWrap: "wrap", gap: "7px" }}
                    color="primary"
                    value={values.quizTagIds}
                    onChange={(e, newValues) => {
                      setFieldValue("quizTagIds", newValues);
                    }}
                  >
                    {quizTag?.map((tag) => (
                      <ToggleButton
                sx={{
                          display: "flex",
                          gap: "8px",
                          overflow: "auto",
                          marginBottom: "15px",
                          flexWrap: "nowrap",
                          width: "max-content"
                        }}
                selected={values.quizTagIds.includes(tag.id)}
                key={tag.id}
                value={tag.id}
              >
                {tag.name}
              </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              {errors.quizTagIds && (
              <Typography variant="subtitle1" color="common.error">
                {errors.quizTagIds}
              </Typography>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default QuizThemePublishedViewPage;
