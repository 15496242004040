import React from "react";
import StarRating from "components/StarRating/StarRating";
import ProductCategoryFormatter from "components/ProductCategoryFormatter/ProductCategoryFormatter";
import ProductImageFormatter from "components/ProductImageFormatter/ProductImageFormatter";
import ProductPublishedDateFormatter from "components/ProductPublishedDateFormatter/ProductPublishedDateFormatter";

export const ProductTableHeaders = [
  {
    key: "name",
    label: "Product",
    Component: (props) => (
      <ProductImageFormatter
        imgKey="imageUrl"
        imgWidth="60px"
        fontVariant="rowHeading"
        fontWeight={700}
        tooltip="Product details"
        navigateState={{ hideChoosePartner: true }}
        {...props}
      />
    ),
    minWidth: 150,
    textAlign: "start"
  },
  {
    key: "categoryId",
    label: "Category",
    Component: ProductCategoryFormatter,
    minWidth: 150
  },
  {
    key: "views",
    label: "Views",
    minWidth: 150
  },
  {
    key: "clicks",
    label: "Clicks On Apply",
    minWidth: 150
  },
  {
    key: "rating",
    label: "Rating",
    Component: (props) => <StarRating hideText {...props} />,
    minWidth: 150,
    textAlign: "center"
  },
  {
    key: "publishedAt",
    label: "Published At",
    Component: ProductPublishedDateFormatter,
    minWidth: 150,
    textAlign: "center"
  }
];
