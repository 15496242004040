import React, { useEffect, useState } from "react";
import {
  FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";
import {
  bool, func, instanceOf, number, string
} from "prop-types";
import "./baseTextField.scss";

const NewBaseSelect = ({
  label, items, onChange, resetValues, disabled, error, selected, sx
}) => {
  const [value, setValue] = useState(selected);

  useEffect(() => {
    if (resetValues) {
      setValue(1);
    }
  }, [resetValues]);

  const handleSelectChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        m: 1, minWidth: 120, background: "transparent", ...sx
      }}
    >
      {!!label && <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>}
      <Select
        disabled={disabled}
        value={value}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={handleSelectChange}
        sx={{ background: "transparent !important" }}
        label="Age"
      >
        {items?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && (<FormRequiredText>This field is required</FormRequiredText>)}
    </FormControl>
  );
};

NewBaseSelect.propTypes = {
  label: string,
  items: instanceOf(Array).isRequired,
  onChange: func,
  resetValues: bool,
  disabled: bool,
  selected: number,
  sx: instanceOf(Object)
};

NewBaseSelect.defaultProps = {
  label: null,
  onChange: null,
  resetValues: false,
  disabled: false,
  selected: 1,
  sx: null
};

export default NewBaseSelect;
