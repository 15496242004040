import React from "react";
import { Box, CircularProgress } from "@mui/material";

const PageLoader = () => (
  <Box sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "600px"
  }}
  >
    <CircularProgress sx={{ height: "60px !important", width: "60px !important" }} />
  </Box>
);

export default PageLoader;
