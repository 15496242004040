import {
  Box, Grid, ImageListItem, Typography
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import BaseSelect from "components/formControl/baseSelect/BaseSelect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import palette from "theme/palette";
import { useTranslation } from "react-i18next";
import { selectLanguage, setLanguage } from "store/app/appSlice";

// COMPONENTS
import BaseTextField from "components/formControl/baseTextField/BaseTextField";
import RichTextEditor from "components/formControl/richTextEditor/RichTextEditor";
import PageLoading from "components/PageLoading";
import MultipleSelectionToggleBtn from "components/MultipleSelectionToggleBtn/MultipleSelectionToggleBtn";
import BaseModel from "components/modal/BaseModal";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import Paper from "@mui/material/Paper";
import { DisablePermissions } from "components/DisablePermissions";
import BaseButton from "components/formControl/baseButton/BaseButton";
import DeletionModal from "components/modal/DeletionModal";
import PageLoader from "components/PageLoader/PageLoader";

// ACTIONS
import { styled } from "@mui/material/styles";
import { setImage } from "store/actions/image";
import { getExpertsByArabicLang } from "store/actions/experts";
import { loadCategoriesByExpertId } from "store/actions/products";
import { loadTopicsByExpertId } from "store/actions/topics";
import { setStateResourceValue } from "store/reducers/resources";
import {
  deleteResourceById, getResourceById, getResourceLinksById, updateResourceById
} from "store/actions/resources";

// SELECTORS
import { successToast } from "utils";
import { removeSpaces } from "utils/functions";
import { ENTITY_DELETION_STATEMENT, LINKED_ENTITIES_TYPES } from "utils/constants";
import FormRequiredText from "components/FormRequiredText/FormRequiredText";
import AdminConfigButtons from "components/AdminConfigButtons/AdminConfigButtons";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  cursor: "pointer",
  color: theme.palette.text.secondary
}));

const ResourcesDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { resource, loading, error: resError } = useSelector((state) => state.resources);

  const [previewText, setPreviewText] = useState(EditorState.createEmpty());
  const [fullText, setFullText] = useState(EditorState.createEmpty());
  const inputFile = useRef(null);
  const [selectedFile, setSeletectedFile] = useState("");
  const { experts } = useSelector((state) => state.experts);
  const lang = useSelector(selectLanguage);
  const [_loading, setLoading] = useState(false);
  const [delLinksOpen, setDelLinksOpen] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [entityLinks, setEntityLinks] = useState([]);
  const [categoriesByAuthor, setCategoriesByAuthor] = useState([]);
  const [topicsByAuthor, setTopicsByAuthor] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const anchorRef = useRef(null);
  const author = resource?.authorId;

  useEffect(() => {
    if (author) {
      dispatch(loadCategoriesByExpertId({
        params: {
          id: author
        },
        cb: (res) => setCategoriesByAuthor(res)
      }));
      dispatch(loadTopicsByExpertId({
        params: {
          id: author
        },
        cb: (res) => setTopicsByAuthor(res)
      }));
    }
  }, [author]);

  useEffect(async () => {
    if (!!searchParams.get("lang") && searchParams.get("lang") === "ar") {
      await dispatch(setLanguage("ar"));
    }
    await dispatch(getResourceById(
      {
        id,
        cb: (res) => {
          setSelectedCategory(res?.categoryId || []);
          setSelectedTopics(res?.topics || []);
        }
      }
    ));
    await dispatch(getExpertsByArabicLang({ lang: "EN" }));
    await dispatch(getExpertsByArabicLang({ lang: "AR" }));
  }, [lang]);

  const setEditors = () => {
    if (resource?.previewText) {
      const contentBlock = htmlToDraft(resource.previewText);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setPreviewText(EditorState.createWithContent(contentState));
      }
    }
    if (resource?.fullText) {
      const contentBlock = htmlToDraft(resource.fullText);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setFullText(EditorState.createWithContent(contentState));
      }
    }
  };

  useEffect(() => {
    setEditors();
  }, [resource?.previewText, resource?.fullText]);

  useEffect(() => {
    if (resError) {
      setPreviewText(EditorState.createEmpty());
      setFullText(EditorState.createEmpty());
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: { ...resource, name: "" }
        })
      );
    } else {
      setEditors();
    }
  }, [resError]);

  const uploadImage = (fileName) => {
    setSeletectedFile(fileName);
    inputFile.current.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const url = await dispatch(setImage(file));
    if (url?.payload?.url) {
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: { ...resource, [selectedFile]: url.payload.url }
        })
      );
    }
  };

  const handleChange = (name, value) => {
    if (name === "author") {
      setSelectedTopics([]);
      setSelectedCategory([]);
      const expert = experts.find((x) => x.id === value);
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: {
            ...resource,
            authorId: expert.id
          }
        })
      );
    } else if (name === "title") {
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: { ...resource, name: value }
        })
      );
    } else if (name === "categoryId" && resource.authorId) {
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: { ...resource, categoryId: value }
        })
      );
    } else if (name === "topics" && resource.authorId) {
      dispatch(
        setStateResourceValue({
          type: "resource",
          data: { ...resource, topics: value }
        })
      );
    }
  };

  const saveChanges = () => {
    const domElPreview = draftToHtml(convertToRaw(previewText.getCurrentContent()))?.toString();
    const domElFull = draftToHtml(convertToRaw(fullText.getCurrentContent()))?.toString();

    const {
      name, authorId
    } = resource;
    const emptyPreview = domElPreview.includes("<p style=\"margin-left:4px;\"></p>") && !domElPreview.includes("</span>");
    const emptyFullText = domElFull.includes("<p style=\"margin-left:4px;\"></p>") && !domElFull.includes("</span>");

    const errorObj = {
      ...formErrors,
      ...(!authorId && { author: true }),
      ...(!name.length && { name: true }),
      ...(!selectedCategory?.length && !selectedTopics?.length && { expertise: true }),
      ...(emptyPreview && { previewText: true }),
      ...(emptyFullText && { fullText: true })
    };
    setFormErrors(errorObj);
    if (Object.values(errorObj).some((item) => item)) return;
    const isArray = Array.isArray(selectedCategory);
    const params = {
      id: lang === "en" ? resource.id : `${resource.id}/i18n_data`,
      params: {
        id: resource.id,
        name,
        iconUrl: resource.iconUrl,
        imageUrl: resource.imageUrl,
        previewText: removeSpaces(draftToHtml(convertToRaw(previewText.getCurrentContent()))),
        fullText: removeSpaces(draftToHtml(convertToRaw(fullText.getCurrentContent()))),
        authorId: resource.authorId,
        categoryId: isArray ? "" : selectedCategory,
        topics: selectedTopics
      },
      cb: () => {
        dispatch(getResourceById({ id }));
        successToast("Resource has been updated");
      }
    };

    dispatch(
      updateResourceById(params)
    );
  };

  const checkLink = async (id, reload = false) => {
    setLoading(true);
    const data = await dispatch(getResourceLinksById({ id: `${id}/entity_links` }));
    if (data.payload) {
      if (!data.payload.length && !reload) {
        setDelLinksOpen(false);
        setDelOpen(true);
      } else {
        setEntityLinks([...data.payload]);
        setDelLinksOpen(true);
      }
    }
    setLoading(false);
  };

  const goToFunction = async (id, type) => {
    if (type === LINKED_ENTITIES_TYPES.QUIZ_QA || type === LINKED_ENTITIES_TYPES.FAQ) {
      anchorRef.current.href = `#/quizzes/${id}`;
    }
    anchorRef.current.click();
  };

  const handleDeletion = () => {
    setLoading(true);
    setDelOpen(false);
    dispatch(
      deleteResourceById({
        id,
        cb: () => {
          successToast("Guidebook has been successfully deleted.");
          navigate(-1, { replace: true });
          setLoading(false);
        },
        cbf: () => setLoading(false)
      })
    );
  };

  if (loading) return <PageLoader />;

  return (
    <>
      <PageLoading loading={_loading} />
      <Box
        mt={2}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          mb: 6
        }}
      >
        <BaseTextField
          name="title"
          label={t("fields.title")}
          fullWidth
          value={resource?.name || ""}
          onChange={(event) => {
            handleChange("title", event.target.value);
            setFormErrors({ ...formErrors, name: false });
          }}
          helperText={formErrors.name && t("validation.required")}
          color={!formErrors.name ? "success" : ""}
        />
        <AdminConfigButtons
          entity={resource?.metaInfo}
          entityName="guidebook"
          onPublish={() => {
            dispatch(
              updateResourceById({
                id: `${id}/publish`,
                cb: () => {
                  dispatch(getResourceById({ id }));
                  successToast("Resource has been published.");
                }
              })
            );
          }}
          onChangelang={() => {
            dispatch(setLanguage(lang === "en" ? "ar" : "en"));
            navigate(`/resources/${id}`);
          }}
          onDelete={() => checkLink(id)}
          navigateCurrentLink={`/resources/published/${id}/current${searchParams.get("lang") === "ar" ? "?lang=ar" : ""}`}
          navigatePublishLink={`/resources/published/${id}/published${searchParams.get("lang") === "ar" ? "?lang=ar" : ""}`}
        />
      </Box>
      <Box>
        <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
          <Grid item xs={3}>
            <Item
              onClick={() => (lang === "en" ? uploadImage("iconUrl") : null)}
              style={lang === "ar" ? { pointerEvents: "none" } : {}}
            >
              <h4>{t("fields.thumbnail")}</h4>
              <ImageListItem
                key={resource?.iconUrl}
                style={{ height: "200px", objectFit: "contain" }}
              >
                <img
                  src={`${resource?.iconUrl}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${resource?.iconUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="iconUrl"
                  loading="lazy"
                />
              </ImageListItem>
            </Item>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onChangeFile}
            />
          </Grid>
          <Grid item xs={3}>
            <Item
              onClick={() => (lang === "en" ? uploadImage("imageUrl") : null)}
              style={lang === "ar" ? { pointerEvents: "none" } : {}}
            >
              <h4>{t("fields.fullsizeImage")}</h4>
              <ImageListItem
                key={resource?.imageUrl}
                style={{ height: "200px", objectFit: "contain" }}
              >
                <img
                  src={`${resource?.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${resource?.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="imageUrl"
                  loading="lazy"
                />
              </ImageListItem>
            </Item>
          </Grid>
        </Grid>
        <Box mb={3}>
          <Typography variant="newBody2">Max size allowed 75KB</Typography>
        </Box>
        <BaseSelect
          name="authorName"
          label={t("fields.authorName")}
          items={experts.map((x) => ({
            id: x.id,
            title: x.name
          }))}
          placeholder="expert"
          initvalue={resource?.authorId}
          onChange={(value) => handleChange("author", value)}
          sx={{
            width: "100%", mb: 2, mt: 3, ml: 0
          }}
          error={formErrors.author}
        />
        <Box my={2}>
          <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
            {t("admin.productCategory")}
          </Typography>
          <Box mt={2}>
            {
            categoriesByAuthor?.length ? (
              <MultipleSelectionToggleBtn
                data={categoriesByAuthor}
                exclusive
                onChange={(event, value) => {
                  setSelectedCategory(value);
                  setFormErrors({ ...formErrors, expertise: false });
                }}
                selected={selectedCategory}
              />
            ) : (
              <Typography variant="smallTitle" fontWeight={400}>No categories available for this author</Typography>
            )
          }
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="smallTitle" color={palette.variables.darkPurple2}>
            {t("fields.topics")}
          </Typography>
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            {
            topicsByAuthor?.length ? (
              <MultipleSelectionToggleBtn
                data={topicsByAuthor}
                onChange={(event, value) => {
                  setSelectedTopics(value);
                  setFormErrors({ ...formErrors, expertise: false });
                }}
                selected={selectedTopics}
              />
            ) : (
              <Typography variant="smallTitle" fontWeight={400}>No topics available for this author</Typography>
            )
          }
            {Boolean(formErrors.expertise) && (
            <FormRequiredText>At least one category or topic is required</FormRequiredText>
            )}
          </Box>
        </Box>
        <Typography
          style={{ fontWeight: 500 }}
          variant="body2"
          color="common.darkPurple"
          sx={{ mt: 3 }}
        >
          {t("fields.previewText")}
        </Typography>
        <RichTextEditor
          value={previewText}
          onChange={(editorState) => {
            setPreviewText(editorState);
            setFormErrors({ ...formErrors, previewText: false });
          }}
          error={formErrors.previewText}
        />
        <Typography
          style={{ fontWeight: 500 }}
          variant="body2"
          color="common.darkPurple"
          sx={{ mt: 3 }}
        >
          {t("fields.about")}
        </Typography>
        <RichTextEditor
          value={fullText}
          onChange={(editorState) => {
            setFullText(editorState);
            setFormErrors({ ...formErrors, fullText: false });
          }}
          error={formErrors.fullText}
        />
        <DisablePermissions permission="write:guidebook" disable>
          <BaseButton
            customColor={palette.variables.darkPurple}
            onClick={saveChanges}
            fullWidth
            variant="contained"
            element={t("save")}
            sx={{ display: "block", maxWidth: 300, marginTop: 5 }}
          />
        </DisablePermissions>
      </Box>
      <DeletionModal
        open={delLinksOpen}
        handleClose={() => setDelLinksOpen(false)}
        list={entityLinks}
        text={ENTITY_DELETION_STATEMENT}
        onPressGoTo={(_id, type) => goToFunction(_id, type)}
        onReload={() => checkLink(id, true)}
      />
      <BaseModel
        open={delOpen}
        handleClose={() => setDelOpen(false)}
        text={t("delConfirmation")}
        handleSuccess={handleDeletion}
      />
      <a ref={(r) => (anchorRef.current = r)} target="_blank" />
    </>
  );
};

export default ResourcesDetailsPage;
