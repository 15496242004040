import React from "react";
import BehaviourSelectedFormatter from "components/BehaviourSelectedFormated/BehaviourSelectedFormatter";
import BehaviourOptionsFormatter from "components/BehaviourOptionsFormatter.js/BehaviourOptionsFormatter";
import BehaviourQuestionFormatter from "components/BehaviourQuestionFormatter.js/BehaviourQuestionFormatter";

export const UsersBehaviourTableHeaders = [
  {
    key: "question",
    label: "Filter Question",
    Component: BehaviourQuestionFormatter,
    minWidth: 200,
    textAlign: "start"
  },
  {
    key: "option",
    label: "Options",
    minWidth: 150,
    Component: (props) => <BehaviourOptionsFormatter objectKey="answers" {...props} />
  },
  {
    key: "selected",
    label: "Selected",
    Component: (props) => <BehaviourSelectedFormatter objectKey="answers" {...props} />,
    minWidth: 150
  }
];
