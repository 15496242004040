import { API } from "aws-amplify";

import RequestService from "./RequestService";

const apiName = "MyAPIGatewayAPI";
const path = "/cms/terms-and-conditions";

class TermsAndConditionsService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async getTermsAndConditionsById({ params }) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/${params.id}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getAllTermsAndConditions() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getTermsAndConditionsLastSavedFlag() {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/last-saved`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async editTermsAndConditions(params) {
    try {
      return await this._httpService.put(this._apiName, `${this._path}`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async postTermsAndConditions({ params }) {
    try {
      return await this._httpService.post(this._apiName, `${this._path}`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: params.params
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

export default new TermsAndConditionsService(apiName, path, API);
