import React from "react";
import { instanceOf } from "prop-types";
import palette from "theme/palette";
import StatisticsBarChart from "./StatisticsBarChart";

const data = [
  { averageBalance: "0-50", quanity: 5000 },
  { averageBalance: "51-100", quantity: 10800 },
  { averageBalance: "101-500", quantity: 4300 },
  { averageBalance: "501-1000", quantity: 3200 }
];
const BalanceBeforePayBarChart = ({ ...BalanceBeforePayBarChartProps }) => {
  const formatData = data?.map((item) => ({
    ...item,
    quantityRounded: Math.round(Math.abs(item.quantity / 1000))
  }));

  return (
    <StatisticsBarChart
      data={formatData}
      activeBarColor={palette.variables.darkPurple2}
      tooltipValue="quantity"
      barColor={palette.variables.lightPink}
      minHeight={300}
      {...BalanceBeforePayBarChartProps}
    />
  );
};
BalanceBeforePayBarChart.propTypes = {
  salaryData: instanceOf(Array).isRequired
};

export default BalanceBeforePayBarChart;
