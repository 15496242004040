import { API } from "aws-amplify";
import RequestService from "./RequestService";

class ExpertsService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async getTopicsByExpertId(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/${params}/guidebook_topic/search`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getCategoriesByExpertId(params) {
    try {
      return await this._httpService.get(this._apiName, `${this._path}/${params}/product_category/search`, {
        headers: {
          "Accept-Language": localStorage.getItem("language")?.toUpperCase()
        }
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }

  async getExpertUnlinkedEntities(params) {
    try {
      const { id, params: payload } = params;
      return await this._httpService.post(this._apiName, `${this._path}/${id}/unlinked_entities`, {
        headers: {
          "Content-Language": localStorage.getItem("language")?.toUpperCase()
        },
        body: payload
      });
    } catch (e) {
      throw {
        message: e.message,
        msg: e.response?.data?.errors
          ? getErrorStringPart(e.response?.data?.errors[0])
          : e.response?.data?.message || ""
      };
    }
  }
}

const apiName = "MyAPIGatewayAPI";
const path = "/cms/expert";

export default new ExpertsService(apiName, path, API);
