import { createAsyncThunk } from "@reduxjs/toolkit";
import UserInfoService from "services/UserInfoService";
import { failureToast } from "utils";
import ReportsService from "../../services/ReportsService";

export const loadUsersStats = createAsyncThunk("loadUsersStats", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postInternalUserStats(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadGeneralUsersStats = createAsyncThunk("loadGeneralStats", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.getUserStatsOverview(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading data  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const downloadUsersReport = createAsyncThunk("downloadUsersReport", async (params, thunkAPI) => {
  try {
    const response = await ReportsService.postDownloadUsersReport(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading report  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadUserPoints = createAsyncThunk("loadUserPoints", async (params, thunkAPI) => {
  try {
    const response = await UserInfoService.getPointsHistory(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading data  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadUserHealthData = createAsyncThunk("loadUserHealthData", async (params, thunkAPI) => {
  try {
    const response = await UserInfoService.getHealthData(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading data  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const loadUserRewards = createAsyncThunk("loadUserRewards", async (params, thunkAPI) => {
  try {
    const response = await UserInfoService.postRewardsData(params);
    return response;
  } catch (err) {
    if (!err.message) {
      throw err;
    }

    failureToast(`Error loading data  ${err.msg}`);
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
